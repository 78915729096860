<template>
  <!-- component template should all be contained in one root element -->
  <div id="root">
    <v-navigationBar />
    <div id="spinner" class="spinner" style="display:none;">
      <img id="img-spinner" src="@/assets/images/spinner.gif" alt="Loading">
    </div>

    <div id="messageDialog" style="display:none;">
      <div id="messageText" />
    </div>

    <div id="site-messages" class="container-fluid">
      <div id="maintenanceMessageDiv" class="maintenanceMessage" style="display:none" />
    </div>
    <router-view />
    <gtex-footer />
  </div>
</template>
<script>
import NavigationBar from '@/components/NavigationBar.vue';
import Footer from '@/components/Footer.vue';
import '@/assets/css/docs.min.css';
import '@/assets/css/portal.css';


export default {
    components: {
        'gtex-footer': Footer,
        'v-navigationBar': NavigationBar
    },
    mounted: function() {}
};
</script>
