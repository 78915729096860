<template>
  <GtexApp />
</template>

<script>
import GtexApp from './components/GtexApp.vue';

export default {
    name: 'App',
    components: {
        GtexApp
    }
};
</script>

<style>

</style>
