<template>
  <div class="container-fluid">
    <div id="body-map-tooltip" />
    <div class="col-md-12 col-lg-6" style="padding-left:0px; padding-right:0px;">
      <div id="body-map-header">
        <h2> Tissue Sampling Sites</h2>
        <p>
          This page provides a visual representation of the biospecimen source sites (BSSs) for the collection of tissue from postmortem/organ procurement cases for the Genotype-Tissue Expression (GTEx) project. <br> The full documentation on tissue collection procedures can be found on the <a href="https://biospecimens.cancer.gov/resources/sops/docs/GTEx_SOPs/BBRB-PR-0004-W1%20GTEx%20Tissue%20Harvesting%20Work%20Instruction.pdf" target="_blank"> GTEx Tissue Harvesting Work Instruction <i class="fas fa-external-link-alt" /></a>.
        </p>
      </div>
      <div id="body-map-svg">
        <v-body-map />
      </div>
    </div>

    <div id="body-map-table-container" class="col-md-12 col-lg-6" style="padding-left:0px; padding-right:5px;margin-top: 25px;">
      <table id="body-map-table" />
    </div>
    <br>
    <br>
  </div>
</template>
<script>
import { tissueMetadataJson } from '@/utils/tissue.js';
import BodyMap from '@/components/BodyMap.vue';
export default {
    components: {
        'v-body-map': BodyMap
    },
    mounted() {
        renderBodyMap();
    }
};
</script>
