<template>
  <div class="container-fluid">
    <!-- QTL violin plot modal root DIVs -->
    <div :id="eqtlViolinDivId" />
    <div :id="sqtlViolinDivId" />
    <div :id="iEqtlScatterDivId" />

    <div class="row">
      <div id="docpage-nav-menu" class="col-md-1 gtx-docs-sidebar">
        <br><br>
        <ul id="sidebar" class="nav nav-stacked affix" />
      </div>
      <div class="col-md-11" style="padding-left:100px">
        <!-- this is the content pane -->
        <h2>
          Variant Page<img src="@/assets/images/GTEx-variant-icon.svg" width="40px" style="margin: 0px 0px 0px 10px;">
        </h2>
        <div id="downloadTempDiv">
          <!-- do not erase this empty div, this div is in use by
                    the download SVG function for cloning a copy of SVG, editing this copy by adding the missing CSS styles
                    before saving to file
                -->
        </div>
        <div class="col-xs-12" style="margin-bottom:2em;">
          <table id="searchResultTable" style="display:none;">
            <!-- a table for multi-SNP search results-->
          </table>
        </div>

        <section id="eqtl-block">
          <div id="eqtl-title" />
          <div id="forestPmDiv" class="col-xs-12" />
          <div id="tableDiv" />
        </section>
        <section id="sqtl-block">
          <div id="sqtl-title" />
          <div id="sqtl-table-div">
            <table id="sqtl-table" />
          </div>
        </section>
        <section id="ieqtl-block">
          <div id="ieqtl-title" />
          <div id="ieqtl-table-div">
            <table id="ieqtl-table" />
          </div>
        </section>
        <section id="isqtl-block">
          <div id="isqtl-title" />
          <div id="isqtl-table-div">
            <table id="isqtl-table" />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import QtlTableMaker from '@/mixins/QtlTableMaker';
import { portalClient, directory } from '@/utils/portal';
import MessageHandler from '@/utils/message-handler';
import EqtlsPage from '@/components/EqtlsPage';
import SpliceQtlPage from '@/components/SpliceQtlPage';
import { RetrieveAllPaginatedData, RetrieveNonPaginatedData } from '../utils/pagination';

export default {
    mixins: [QtlTableMaker],
    data: function() {
        return {
            eqtlViolinDivId: 'eqtl-violin-modal',
            sqtlViolinDivId: 'sqtl-violin-modal',
            iEqtlScatterDivId: 'ieqtl-scatter-modal'
        };
    },
    beforeRouteUpdate(to, from, next) {
        if (to.params.snpId != from.params.snpId) {
            // clear out divs
            $('#forestPmDiv').html('');
            $('#eqtlResultDiv').html('');

            this.getSnpPage(to.params.snpId);
        }
        next();
    },
    mounted: function() {
        this.getSnpPage(this.$route.params.snpId, this.$route.params.term);
        this.createSideBar();
        EqtlsPage.methods.createDialogForQtlViolinPlots(this.eqtlViolinDivId, 'eQTL Violin Plots');
        EqtlsPage.methods.createDialogForQtlViolinPlots(this.sqtlViolinDivId, 'sQTL Violin Plots');
        EqtlsPage.methods.createDialogForQtlViolinPlots(this.iEqtlScatterDivId, 'ieQTL Scatterplots');
    },
    methods: {
        createSideBar() {
            $('#sidebar').empty();
            const menu = {
                '#gtexmenu': { label: 'Top' },
                '#eqtl-block': { label: 'Single-Tissue eQTLs' },
                '#sqtl-block': { label: 'Single-Tissue sQTLs' }
            };
            Object.keys(menu).forEach(k => {
                const link = $('<a>', { href: k }).text(menu[k].label);
                $('#sidebar').append($('<li>', {}).append(link));
            });
        },
        getSnpPage(searchId, termType) {
            const component = this;
            if (termType === undefined) termType = portalClient.parseIdType(searchId);
            let urlObj;
            let url;
            if (termType == 'rsId') {
                urlObj = directory.getVariantUrl();
                url = urlObj.url + '&snpId=' + searchId + '&' + directory.getV10Dataset();
            } else if (termType == 'variantId') {
                urlObj = directory.getVariantUrl();
                url = urlObj.url + '&variantId=' + searchId + '&' + directory.getV10Dataset();
            } else if (termType == 'chromPos') { // search by position
                urlObj = directory.getVariantUrl();
                let chrom; let pos;
                [chrom, pos] = /_/.test(searchId)?searchId.split('_'):searchId.split(':'); // eslint-disable-line prefer-const
                url = urlObj.url + '&chromosome=' + chrom + '&pos=' + pos + '&' + directory.getV10Dataset();
            }

            $('#spinner').show();
            RetrieveAllPaginatedData(url).then(data => {
                const datasetUrl = directory.getDatasetUrl().url + '?datasetId=gtex_v10';
                RetrieveNonPaginatedData(datasetUrl).then(datasetData => {
                    data.dataset = {
                        datasetId: datasetData[0].datasetId,
                        dbSnpBuild: datasetData[0].dbSnpBuild,
                        gencodeVersion: datasetData[0].gencodeVersion,
                        genomeBuild: datasetData[0].genomeBuild
                    };
                    if (!data.length) {
                        const messageHandler = new MessageHandler();
                        messageHandler.showError(`No variant with ID "${searchId}" found.`, 'Variant Not Found');
                    } else {
                        component.parseJson(data);
                    }
                    $('#spinner').hide();
                }).catch(datasetResponse => {
                    console.error('Error: api call failed ' + datasetUrl + ' ' + datasetResponse);
                    $('#spinner').hide();
                });
            }).catch(response => {
                console.error('Error: api call failed ' + url + ' ' + response);
                $('#spinner').hide();
            });
        },
        parseSnpData(snp) {
            /*
             * snp is a SNP json object
             * var snpId = snp.variantId;
             */
            const snpId = snp.hasOwnProperty('shorthand')&&snp.shorthand !== null?snp.shorthand:snp.variantId;
            // eQTL table
            const args = {
                func: 'goEqtlBySnp',
                objId: 'portalClient',
                snpId: snpId,
                queryId: snpId,
                variantId: snp.variantId,
                tissueDisplayName: 'All eQTL Tissues',
                tissueName: 'All',
                eqtlViolinDivId: this.eqtlViolinDivId
            };
            EqtlsPage.methods.goEqtlBySnpV2(args);
            this.showVariantSqtls(snp.variantId);
            this.showVariantIEqtls(snp.variantId);
            this.showVariantISqtls(snp.variantId);


            // update all dataset subtitles:
            const eqtlTitle = '<hr>' + '<h4>Single-Tissue eQTLs for ' + snpId + ' </h4>'
                    + portalClient.versionInfo.getMarkup('genePageV10');
            const sqtlTitle = '<hr>' + '<h4>Single-Tissue sQTLs for ' + snpId + ' </h4>'
                    + portalClient.versionInfo.getMarkup('genePageV10');
            const ieqtlTitle = '<hr>' + '<h4>Single-Tissue ieQTLs for '+ snpId + '</h4>'
                    + portalClient.versionInfo.getMarkup('singleTissue');
            const isqtlTitle = '<hr>' + '<h4>Single-Tissue isQTLs for '+ snpId + '</h4>'
                    + portalClient.versionInfo.getMarkup('singleTissue');
            $('#eqtl-title').html(eqtlTitle);
            $('#sqtl-title').html(sqtlTitle);
            $('#ieqtl-title').html(ieqtlTitle);
            $('#isqtl-title').html(isqtlTitle);
        },
        parseJson(json) {
            const component = this;
            //directory.validateVariantJson(json);
            const jsonKey = 'variant';
            if (json.length > 0) {
                const data = json;
                const tableId = '#searchResultTable';
                const columns = [
                    { title: 'Variant ID', name: 'variantId', data: 'variantId' },
                    { title: 'Shorthand', name: 'shorthand', data: 'shorthand' },
                    { title: 'rs ID ( v' + json.dataset.dbSnpBuild + ' )', name: 'snpId', data: 'snpId' },
                    { title: 'Chromosome', name: 'chromosome', data: 'chromosome' },
                    { title: 'Position', name: 'pos', data: 'pos' },
                    { title: 'MAF >= 1%', name: 'maf01', data: 'maf01' },
                    { title: 'Ref Allele', name: 'ref', data: 'ref' },
                    { title: 'Alt Allele', name: 'alt', data: 'alt' },
                    { title: 'b37 Variant ID', name: 'b37VariantId', data: 'b37VariantId' }
                ];
                const oTable = $(tableId);
                try {
                    oTable.dataTable({
                        data: data,
                        columns: columns,
                        destroy: true,
                        dom: '<"clear">lfrtip', // this line gets rid of the default styling,
                        createdRow: function(row, data, dataIndex) {
                            $(row).css('cursor', 'pointer');
                            $(row).on('click', () => {
                                component.parseSnpData(data);
                                oTable.$('tr.selected').removeClass('selected');
                                $(row).addClass('selected');
                            });
                        }

                    });
                    oTable.addClass('display');
                    oTable.show();

                    // default SNP to display in detail: the first row in the data table
                    oTable.find('tbody tr:first').addClass('selected');
                    const snp = json[0];
                    this.parseSnpData(snp);
                } catch (err) {
                    console.error('SNP search error: ' + err);
                }
            }
        },
        showVariantSqtls(variantId) {
            const component = this;
            const url = `${directory.getSingleTissueSqtlUrl().url}?variantId=${variantId}` + '&' + directory.getV10Dataset();
            RetrieveAllPaginatedData(url).then(data => {
                component.generateSqtlTable(data, '#sqtl-table', component.sqtlViolinDivId);
            }).catch(response => {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Error: Cannot retrieve sQTL table ' + response.responseText);
            });
        },
        showVariantIEqtls(variantId) {
            const component = this;
            const url = `${directory.getSingleTissueIEqtlUrl().url}?variantId=${variantId}`;
            RetrieveAllPaginatedData(url).then(data => {
                component.generateIEqtlTable(data, '#ieqtl-table', component.iEqtlScatterDivId);
            }).catch(response => {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Error: Cannot retrieve ieQTL table ' + response.responseText);
            });
        },
        showVariantISqtls(variantId) {
            const component = this;
            const url = `${directory.getSingleTissueISqtlUrl().url}?variantId=${variantId}`;
            RetrieveAllPaginatedData(url).then(data => {
                component.generateISqtlTable(data, '#isqtl-table', component.iEqtlScatterDivId);
            }).catch(response => {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Error: Cannot retrieve isQTL table ' + response.responseText);
            });
        }
    }
};
</script>
