<template>
  <div id="banner">
    <div class="item">
      <h3><span>Developmental <br>Genotype-Tissue Expression</span></h3>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logoSvg: require('@/assets/images/dgtex/dGTExLogo_withtext.svg')
    }
  }
}
</script>

<style lang="css" scoped>
#banner {
  width: 100%;
  height: var(--jumbotron-size);
  background: center / cover no-repeat url('~@/assets/images/dgtex/banner.png');
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: stretch;
}

#banner h3 {
  font-size: 2em;
  padding-top:5vh;
  padding-bottom:5vh;
}

#banner h3 span {
  color:#173774;
  text-shadow: 0 0 20px #7398CF, 0 0 20px #7398CF;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 1.5em;
}

.item {
  width: 60%;
  margin-right: 10vw;
  text-align: right;
}

@media (min-width: 500px){
  #banner h3 {
    padding-top:80px;
    padding-right:40px;
  }

  #banner{
    margin-bottom:50px;
  }
}

@media (min-width: 650px){
  #banner h3 {
    padding-right:40px;
  }
}

@media (min-width: 880px){
  .item{
    width: 50%;
    margin-right: 50px;
    text-align: left;
  }

  #banner h3 {
    padding-top:10vh;
    padding-bottom:10vh;
  }
}

@media (min-width: 1180px){
  #banner h3 {
    padding-top:15vh;
    padding-right:13vh;
  }
}

@media (min-width: 1500px){
  #banner{
    padding: 5vh 50vh;
  }
}
</style>