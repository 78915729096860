/*
 * Copyright 2013 The Broad Institute, Inc.
 * SOFTWARE COPYRIGHT NOTICE
 * This software and its documentation are the copyright of the
 * Broad Institute, Inc. All rights are reserved.
 *
 * This software is supplied without any warranty or guaranteed support
 * whatsoever. The Broad Institute is not responsible for its
 * use, misuse, or functionality.
 */

/**
 * Main class for portal client.
 */

import {Directory} from '@/utils/directory';
import AutoComplete from '@/utils/autocomplete';
import MessageHandler from '@/utils/message-handler';
import gtexVersion from '@/utils/gtexVersion';
import {getTissueMetadataJson, buildTissueAbbrevTable} from '@/utils/tissue';
import router from '@/router/router';
import {RetrieveAllPaginatedData} from "./pagination";

export let portalClient;
export let initialUrl;
export let hostUrl;
export let directory;
let _eqtlFilter;

// TODO Extract all navigation to a class that only does navigation.

const sups = [];

function cssCheck(path, element, index) {
    let newPath = path + ' ' + element.localName;
    if (element.id) {
        newPath += '#' + element.id;
    }

    if (element.className && 'string' === typeof (element.className)) {
        newPath += '.' + element.className.replace(/ /g, '.');
    }

    sups.push([index, newPath, getComputedStyle(element)]);

    for (let k = 0; k < element.children.length; k++) {
        cssCheck(newPath, element.children[k], k);
    }
}


function doCssCheck() {
    cssCheck('body', $('body')[0], 0);
}

$(document).ready(() => {
    window.mobilecheck = function () {
        let check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|pad|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    if (window.mobilecheck()) {
        $('#mobile-site-nav-link a').attr('href', location.protocol + '//m.' + location.host);
        $('#mobile-site-nav-link').css('display', 'inline');

        $('#mobile-site-nav-link a').click(() => {
            const currTime = (new Date()).getTime();
            const cookieValidTime = 60 * 60 * 1000; // sec * min * 1000
            const cookieExpireTime = new Date(currTime + cookieValidTime);
            document.cookie = 'full_site=false;domain=' + location.host + ';path=/;expires=' + cookieExpireTime.toUTCString();
        });
    }
    const myhost = location.host;
    let mypathname = location.pathname;
    const myprotocol = location.protocol;
    const clversion = process.env.VUE_APP_GTEX_VERSION;
    hostUrl = myprotocol + '//' + myhost + '/';
    const search = location.search;
    $('.expander').simpleexpand();


    /**
     * sometimes we use alternate links to get here.  Strip off the path after the first '/'
     * to keep the paths correct
     * *
     */
    const pathIdx = mypathname.indexOf('/', 1);
    if (pathIdx > 0) {
        mypathname = mypathname.substring(0, pathIdx + 1);
    }

    initialUrl = myprotocol + '//' + myhost + mypathname;
    directory = new Directory(initialUrl);
    directory.initialize(postDirectoryInitialization);

    // bootstrap tooltip
    $('body').tooltip({selector: '[data-toggle=tooltip]'});
    // bootstrap sidebar menu
    $('body').scrollspy({
        target: '.gtx-docs-sidebar',
        offset: 40
    });

    // jQuery plugin for sorting using scientific notation
    jQuery.extend(jQuery.fn.dataTableExt.oSort, {
        'scientific-pre': function (a) {
            return parseFloat(a);
        },
        'scientific-asc': function (a, b) {
            return ((a < b) ? -1 : ((a > b) ? 1 : 0));
        },
        'scientific-desc': function (a, b) {
            return ((a < b) ? 1 : ((a > b) ? -1 : 0));
        }
    });

    portalClient = new PortalClient();

    // why is this called twice?
    const messageDialog = $('#messageDialog');
    messageDialog.dialog({autoOpen: false, modal: true});
    messageDialog.dialog({
        buttons: [{
            text: 'Ok', click: function () {
                $(this).dialog('close');
            }
        }]
    });

    portalClient.initClient();

    AutoComplete.geneAutoComplete('#asdSearchTermGene');
    AutoComplete.geneAutoComplete('#asdSearchTermEqtl');
    AutoComplete.geneAutoComplete('#asdSearchTermGeneral');
    msieversion();

    // spinner
    bindSpinner();
});

function bindSpinner() {
    $('#spinner').bind('ajaxSend', function () {
        $(this).show();
    }).bind('ajaxStop', function () {
        $(this).hide();
    }).bind('ajaxError', function () {
        $(this).hide();
    });
}


function goNewsCarousel() {
    const divId = '#news-slides';
    RetrieveAllPaginatedData(directory.getNewsItemUrl().url).then(
        data => {
            const items = data.sort((x, y) => {
                const i = x.rank;
                const j = y.rank;
                return ((i < j) ? 1 : (i > j) ? -1 : 0);
            });
            items.forEach((d, i) => {
                let news = i == 0 ? '<div class=\'item active\'>' : '<div class=\'item\'>';
                news += '<div style="font-size: 10px;">' + d.dateCreated + '</div>';
                news += '<div>' + d.title + '</div>';
                news += '<a id="news' + d.id + '" class="navLink" style="font-size:11px">Read More >></a>';
                news += '</div>';

                $(divId).append(news);
                $('#news' + d.id).click(e => {
                    router.push({name: 'newsPage', query: {id: d.id}});
                });
            });
        }
    ).catch(
        error => {
            console.log("error: " + error);
        }
    )
}

function postDirectoryInitialization() {

}

function msieversion() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0) {    // If Internet Explorer, return version number
        const version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)));
        if (version < 11) {
            alert('You are using Internet Explorer ' + version + '. The GTEx portal does not support older versions of Internet Explorer. Please update to Internet Explorer 11+ or use the latest version of Firefox, Chrome or Safari.');
        }
    }
}

/*
 * gets news from rest/newsItem/
 * uploads news items into <div id='news_div'>
 * each item is a <li> and a link to view more details
 */
export function updateNews() {
    const div_class = '#news_div';
    const maxNum = 4; // max number of news items to report on the home page
    const url = directory.getNewsItemUrl().url;
    RetrieveAllPaginatedData(url).then(
        data => {
            data.sort((x, y) => {
                const i = x.rank;
                const j = y.rank;
                return ((i < j) ? 1 : (i > j) ? -1 : 0);
            });

            data = data.slice(0, maxNum); // slice the array

            /* html mark-up */

            // adds the <ul>
            $(div_class).append('<div class="news">');

            // add each news item
            $.each(data, (i, item) => {
                const linkText = $(`<a class="navLink">${item.title}</a><br/>`);

                $(linkText).click(e => {
                    router.push({name: 'newsPage', query: {id: item.id}});
                });

                i = $('<div class="news_item">'
                    + '<span class="news_date"> ' + item.dateCreated + '</span> '
                    + '</div>');
                $(i).append(linkText);
                $('.news').append(i);
                $(i).append(shortenDesc(item.newsText) + '...' + '<br>');
            });
        }
    )
}

/**
 * Shortens the newsTest to 10 words as a preview on the front page
 * @param fullDesc
 * @returns {String}
 */
const shortenDesc = (fullDesc) => {
    // if the string has 10 or fewer words, return the original string
    // or else return the first 10 words joined back into a string
    return fullDesc.split(' ').length <= 10 ? fullDesc : fullDesc.split(' ').slice(0, 10).join(' ');
};

function clickToClearText(tag) {
    $(tag).click(function (e) {
        $(this).val(''); // clear the tag's value
    }); // end click
}

function clickToSelectText(tag) {
    $(tag).click(function (e) {
        $(this).select();
    }); // end click
}

function searchSnpById(term, termType) {
    router.push({name: 'snpPage', params: {snpId: term, type: termType}});
}

export function generateSnpUrl(snpId) {
    let url;
    if (!snpId) return; // no URL to generate if there is no snpId

    /*
     * a string with spaces i.e. "rs12345 something something" passed in as
     * a snpId indicates some sort of error being
     * printed in the test your own table - don't generate a link
     */
    if (-1 != snpId.indexOf(' ')) return snpId;

    if (0 === snpId.indexOf('rs')) {
        // this is a dbSnp id, so return an url to dbSnp

        const snpNum = snpId.substring(2); // the number without the RS
        // TODO move base url to External Server entry
        url = `<a href="/home/snp/${snpId}">${snpId}</a>`
            + '<span style="float:right"> <a href="http://www.ncbi.nlm.nih.gov/projects/SNP/snp_ref.cgi?rs=' + snpNum
            + '" target="_blank">'
            + 'dbSNP <i class="fas fa-external-link-alt"></i></a></span>';
    } else {
        // Not a dbSnp id
        url = `<a href="/home/snp/${snpId}">${snpId}</a>`;
    }
    return url;
}

export function generateGeneUrl(geneName, displayText) {
    return `<a href="/home/gene/${geneName}">${displayText ? displayText : geneName}</a>`;
}

export function setEqtlFormVars(args) {
    // clear out search boxes not related to eqtl
    $('#asdSearchTermGene').val('');

    // reset all the search eqtl related vars
    $('#queryId').val('');
    $('#asdSearchTermEqtl').val('');
    $('#tissueSelect').val(args.tissueName);

    if (args.queryId !== undefined) {
        $('#queryId').val(args.queryId);
    } else if (args.geneId != undefined && args.snpId != undefined) {
        $('#snpId5').val(args.snpId);
        $('#geneId').val(args.geneId);
        $('#queryId').val(args.snpId);
        $('#asdSearchTermEqtl').val(args.snpId);
    } else if (args.snpId != undefined) {
        $('#queryId').val(args.snpId);
        $('#asdSearchTermEqtl').val(args.snpId);
    } else if (args.geneId != undefined) {
        $('#queryId').val(args.geneId);
        $('#asdSearchTermEqtl').val(args.geneId);
    } else if (args.variantId !== undefined) {
        $('#queryId').val(args.variantId);
        $('#asdSearchTermEqtl').val(args.variantId);
    }
}

function PortalClient() {
    // the current virtual page.
    this.messageHandler = new MessageHandler();
    this.username = null;
    this.versionInfo = new gtexVersion();

    this.initClient = function () {
        this.initMenuBar();
        getTissueMetadataJson(); // for metadata to report on eqtl page
        buildTissueAbbrevTable(); // tissue abbrevation table
        const portalClient = this;
        portalClient.checkForMaintenanceMessages();
    };


    /**
     * This is called to disable or enable fields as required
     * for when a row is not selected in the table.
     *
     * If the form is readonly, then all fields are disabled.
     * If the form is not readonly, then the ID field is
     * disabled if it is readonly.
     *
     * On some entities, the id field is generated by the database, so the id field
     * must be readonly.  This is achieved by adding an HTML attribute to the field
     * on the detail HTML file:
     *
     *   <input id="id" type="text" class="ui-widget-content shortInput"
     *                      data-readonly="true"></input>
     *
     * For the other entities, the id field is created by the user, but it can't be
     * edited once created, so we must toggle the id input field as enabled when
     * creating a new entity, but disabled when editing an existing entity.
     */
    this.disableFieldsForUnselected = function () {
        const formReadOnly = $('.detailForm').data('readonly');
        if (formReadOnly == undefined) {
            const readonly = $('.detailForm #id').data('readonly');
            if (readonly == undefined) {
                $('.detailForm #id').prop('disabled', false);
                $('.detailForm #id').removeClass('disabled');
            } else {
                $('.detailForm #id').prop('disabled', true);
                $('.detailForm #id').addClass('disabled').width('100%');
            }
        } else {
            // form is readonly so disable all input fields
            $('.detailForm input').prop('disabled', true);
            $('.detailForm input').addClass('disabled').width('100%');
        }
    };

    this.initMenuBar = function () {
        const portalClient = this;

        $('ul.dropdown-menu [data-toggle=dropdown]').mouseenter(function (event) {
            $(this).parent().addClass('open');
        });

        /*
         * TODO XXX Not sure if this is the right place for this initialization of the new stuff - JTL
         */
        const searchGeneById = function (term) {
            const geneId = term.toUpperCase();
            $('#geneId').val(geneId);
            router.push({name: 'genePage', params: {geneId: geneId}});
        };


        this.searchFunction = function (e) { // search Gene by ID
            // clear the other top menu search boxes
            $('#asdSearchTermEqtl').val('');
            $('#asdSearchTermGeneExpr').val('');

            const searchTerm = $('#asdSearchTermGene').val();
            if (searchTerm.indexOf('rs') == 0) {
                alert('Search on SNP ids not yet supported');
            } else {
                searchGeneById(searchTerm);
            }
        };

        $('#asdSearchTermGeneExpr').keydown(e => {
            if (e.keyCode == 13) {
                // clear the other top menu search boxes
                $('#asdSearchTermEqtl').val('');
                $('#asdSearchTermGene').val('');

                const searchTerm = $('#asdSearchTermGeneExpr').val();
                $('#expr-nav-dropdown').dropdown('toggle');
                searchGeneById(searchTerm);
            }
        });


        $('#asdSearchTermGeneral').keydown(e => {
            if (e.keyCode == 13) {
                const searchTerm = $('#asdSearchTermGeneral').val();
                const searchType = portalClient.parseIdType(searchTerm);
                switch (searchType) {
                    case 'rsId':
                        searchSnpById(searchTerm, searchType);
                        break;
                    case 'variantId':
                        searchSnpById(searchTerm, searchType);
                        break;
                    case 'chromPos':
                        searchSnpById(searchTerm, searchType);
                        break;
                    case 'Gene':
                        searchGeneById(searchTerm);
                        break;
                    default:
                        throw 'Search Term unknown.';
                }
            }
        });

        $('#browseEqtls').click(() => {
            portalClient.navigationRouter.navigateTo('browseEqtls');
        });

        // select text when clicking these search boxes
        $.each(['#asdSearchTermGene', '#asdSearchTermEqtl'], (i, v) => {
            clickToSelectText(v);
        });
    };

    // TODO figure out how to generalize this.
    this.parseIdType = function (searchTerm) {
        if (/^\w+_\d+_\w+_\w+_b37/.test(searchTerm)) return 'variantId'; // must be able to differentiate that the search term is a variant ID and not chromosome position, because a position could map to multiple variants
        if (/^\w+_\d+_\w+_\w+_b38/.test(searchTerm)) return 'variantId'; // must be able to differentiate that the search term is a variant ID and not chromosome position, because a position could map to multiple variants
        if (/^chr\w+_\d+_\w+/i.test(searchTerm)) return 'rsId';
        if (/^rs\d+$/i.test(searchTerm)) return 'rsId';
        if (/^\w\d*\:\d+$/.test(searchTerm)) return 'chromPos';
        return 'Gene';
    };

    this.setTableHeader = function (headerText) {
        const text = '<h4>' + headerText + '</h4>';
        $('#tableHeaderDiv').html(text).show();
    };

    this.deleteTable = function () {
        $('#tableHeaderDiv').html('');
        $('#tableDiv').html('<div id="tableHeaderDiv" style="display:none"></div><table id="gridTable" style="display:none"></table>');
    };

    this.checkForMaintenanceMessages = function () {
        const parent = this;
        const url = directory.getMaintenanceMessageUrl().url;
        RetrieveAllPaginatedData(url).then(
            data => {
                const messages = data;
                if (messages.length > 0) {
                    const message = messages[0];
                    if (message) {
                        $('#maintenanceMessageDiv').html(message.message)
                            .show();
                    }
                } else {
                    $('#maintenanceMessageDiv').hide();
                }
            }
        )
    };
}

// Add gtex global to the window for dynamic link hooks.  Eventually, these should all get replaced by Vue templates
window.gtex = window.gtex ? window.gtex : {};
