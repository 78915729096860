<template>
  <div class="container-fluid">
    <!-- this component is now used in tissue dashboard-->
    The following interactive heat map reports the top 50 expressed genes in <b>{{ this.tissueDisplayName }}</b> as well as their expression profiles across all tissues.
    <div class="row">
      <div class="col-xs-12 col-md-6 col-lg-4" />
      <div class="col-xs-12 col-md-6">
        <i id="spinner" class="fas fa-sync fa-spin" style="display:none;" />
      </div>
    </div>
    <div id="messageBox" style="color:#4eb3d3;" />
    <div id="expMapToolbar" style="display:none;" /> <!-- the toolbar Div -->
    <div id="heatmapRoot" style="min-height: 500px;" /> <!-- the heatmap Div -->
    <div id="violinRoot" />
  </div>
</template>
<script>
import { directory } from '@/utils/portal';

export default {

    props: {
        tissueSiteDetailId: {
            type: String,
            default: ''
        },
        tissueDisplayName: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            urls: null
        };
    },
    watch: {
        tissueSiteDetailId(newId, oldId) {
            // run the init method whenever the tissueName from parent component changes
            this.init();
        }
    },
    mounted() {
        // initialize the heatmap when the component is mounted
        this.init();
    },
    methods: {
        init() {
            this.urls = {
                geneExp: directory.getGeneExpressionUrl().url + '?gencodeId=',
                medGeneExp: directory.getMedianGeneExpressionClusteredUrl().url,
                topInTissueFiltered: directory.getTopExpressedGeneUrl().url + '?filterMtGene=true&sortBy=median&sortDirection=desc&tissueSiteDetailId=',
                topInTissue: directory.getTopExpressedGeneUrl().url + '?filterMtGene=false&sortBy=median&sortDirection=desc&tissueSiteDetailId=',

                tissue: directory.getTissueInfoUrl().url,
                tissueSites: directory.getTissueInfoUrl().url,
                geneId: directory.getGeneUrl().url + '?format=json&geneId='

            };


            const tissueId = this.tissueSiteDetailId;
            ExpressionMap.launchTopExpressed(tissueId, 'heatmapRoot', 'violinRoot', this.urls);

        }
    }
};
</script>

<style lang="css" scoped>
.container-fluid {
  width: 100%;
}

#heatmapRoot {
  overflow-x: scroll;
}
</style>
