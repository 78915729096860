<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-10 col-sm-offset-1">
        <h2>GTEx Single Cell Data</h2>
        <div class="row">
          <div class="col-xs-12">
            <h3>Overview</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <p>
              Using 25 archived, frozen tissue samples from 16 donors previously collected as part of the GTEx project, we have begun profiling tissues across the human body at single-cell resolution using snRNA-Seq.
            </p>
            The 8 tissues we have profiled and generated data for are the following:
            <ol>
              <li>breast</li>
              <li>esophagus mucosa</li>
              <li>esophagus muscularis</li>
              <li>heart</li>
              <li>lung</li>
              <li>skeletal muscle</li>
              <li>prostate</li>
              <li>skin</li>
            </ol>

            <h3>Data processing</h3>
            <p>
              Four separate snRNA-seq methods have been applied to each of the samples, generating a cross-tissue atlas of 209,126 nuclei profiles.
              Each method has been benchmarked against data from fresh tissues.
              Additionally, a conditional variational autoencoder (cVAE) was used to integrate data across tissues, donors, and lab methods, and annotate it with an extensive literature-derived marker reference for cell types.
            </p>
            <p>To learn more about our single cell efforts thus far, read our paper in Science <a href="https://www.science.org/doi/10.1126/science.abl4290" target="_blank">here <i class="fas fa-external-link-alt" /></a>.</p>
          </div>

          <div class="col-xs-12 col-md-6">
            <img src="@/assets/images/single_cell_imgs/gtex_single_cell_overview.svg" width=95%>
            <div class="img-caption">A visual overview of the single cell data collection and analysis process</div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-md-6">
            <h3>Accessing the data</h3>
            <h4>Open Access Data</h4>
              <p>Gene expression matrices are available from the GTEx Portal on the <router-link custom v-slot = "{ navigate, isActive }" to="/datasets">
              <a :class = "{active: isActive}" @click="navigate" >datasets page</a></router-link>.</p>            
            <h4>Protected Access Data</h4>
              <p>Raw sequence data are available at AnVIL (dbGaP accession phs000424).  Information on the 
              <router-link 
                custom v-slot = "{ navigate, isActive }"
                to="/protectedDataAccess">
                <a :class = "{active: isActive}" @click="navigate" >protected data access page</a></router-link> 
              describes how to obtain access to these data.</p>          
          </div>
        </div>

        <h3>Exploring single cell data on the GTEx Portal</h3>
        <!-- <div class="row">
          <div class="col-xs-12">
          </div>
        </div> -->
        <div class="row">
          <div class="col-xs-12 col-lg-4 col-xl-3">
            <h4>For a gene of interest</h4>
            <p>
              Single cell expression data of a single gene is reported on the <router-link custom v-slot = "{ navigate, isActive }"  to="/gene/TAGLN">
              <a :class = "{active: isActive}" @click="navigate" >gene page</a></router-link> in a violin plot, where each violin depicts the distribution of expression values from individual cells of a cell type from a tissue site.
              The violin plot could reveal patterns that potentially indicate: 1) the presence of the gene across available cell types and tissues, 2) cell-type or tissue specificity, and 3) fraction of cells in which the gene is detected.
            </p>
          </div>
          <div class="col-xs-12 col-lg-8 col-xl-9">
            <figure>
              <img class="screenshot" src="@/assets/images/single_cell_imgs/single_gene_single_cell_vplot.png" width=95% alt="An example of the single cell violin plots for a single gene.">
              <figcaption class="img-caption">
                An example of the single cell violin plots for gene TAGLN.
                In this view, the violins are first sorted alphabetically by tissue, then cell type.
              </figcaption>
            </figure>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-lg-4 col-xl-3">
            <h4>For a list of genes</h4>
            <p>
              The <router-link custom v-slot = "{ navigate, isActive }"  to="/multiGeneSingleCellQueryPage"><a :class = "{active: isActive}" @click="navigate" >Multi-Gene Single Cell Viewer</a></router-link> is a new visualization developed for exploring single cell expression data for a list of genes from various cell types and tissues, displayed in a matrix format, where each row is a gene and each column is a cell type from a tissue.
              The matrix offers two views for each row-column pair, rendered by default as a dot plot, reporting the median expression value (the dot color) and fraction of cells in which a gene is detected (the dot size). 
              Alternatively, the matrix can be rendered as an aster plot, allowing direct comparison of the expression of all cells versus cells in which a gene is detected (the arc size and color), and simultaneously showing the fraction of cells in which a gene is detected (the arc length).
            </p>
          </div>
          
          <div class="col-xs-12 col-lg-8 col-xl-9">
            <figure>
              <img class="screenshot" src="@/assets/images/single_cell_imgs/single_cell_apm.png" width=95% alt="An example of the single cell violin plots for a single gene.">
              <figcaption class="img-caption">
                An example of the multi-gene single cell query matrix for 5 different genes displayed as aster plots.
                In this view, the matrix data are first sorted alphabetically by tissue, then cell type.
              </figcaption>
            </figure>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-xs-12">
            <h3>Tissue composition</h3>
            <img src="@/assets/images/single_cell_imgs/gtex_single_cell_composition.svg" width=95%>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <h3>UMAPs</h3>
            <img src="@/assets/images/single_cell_imgs/gtex_single_cell_umaps.svg" width=95%>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.screenshot {
  box-shadow: 4px 3px 3px #a1a1a1;
}

.img-caption {
  color: #8c8c8c;
  font-size: 12px;
  padding-top: 5px;
}
</style>