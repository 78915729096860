/*
 * Copyright 2015 The Broad Institute, Inc.
 * SOFTWARE COPYRIGHT NOTICE
 * This software and its documentation are the copyright of the
 * Broad Institute, Inc. All rights are reserved.
 *
 * This software is supplied without any warranty or guaranteed support
 * whatsoever. The Broad Institute is not responsible for its
 * use, misuse, or functionality.
 */

/**
 * Represents a Dataset -- a collection of filesets available for download.
 *
 * @author jnedzel
 */

import Fileset from '@/utils/models/fileset';

export default function Dataset(json) {
    this.id = null;
    this.name = null;
    this.description = null;
    this.dbGapId = null;
    this.order = null;
    this.subpath = null;
    this.filesets = [];

    this.initialize = function(json) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.dbGapId = json.dbgapId;
        this.order = json.order;
        this.subpath = json.subpath;
        const filesetsJson = json.filesets;
        this.parseFilesets(this.subpath, filesetsJson);
    };

    this.parseFilesets = function(rootUrl, filesetsJson) {
        const numFilesets = filesetsJson.length;
        for (let i = 0; i < numFilesets; i++) {
            const filesetJson = filesetsJson[i];
            const fileset = new Fileset(this.subpath, filesetJson);
            this.filesets.push(fileset);
        }
    };

    this.longName = function() {
        if (this.dbGapId) {
            return this.name + ' (dbGaP Accession ' + this.dbGapId + ')';
        } else {
            return this.name;
        }
    };

    this.initialize(json);
}
