import downloadUtils from '@/utils/downloadUtil';
import { tissueLookupTable } from '@/utils/tissue';
export default function() {
    const self = this;
    this.bindDownloadButtons = function() {
        const tempDivId = 'downloadTemp';
        const downloadTask = function(divs) {
            /*
             * divs is a list of <div> IDs
             * each div contains a highcharts plot
             * each SVG is save in a file */
            divs.forEach(d => {
                const $svg = $('#'+d+' .highcharts-container svg'); // find the highcharts SVG in the div
                const fileName = d+'.svg';
                downloadUtils.downloadSVGByObject($svg, fileName, tempDivId);
            });
        };
        // tissue sample count bar plot
        $('#tdsNumSamplesByTissuesDownload').click(() => {
            downloadTask(['tdsNumSamplesByTissuesBarChart']);
        });
        $('#tdsNumSamplesByESGenesDownload').click(() => {
            const divs = ['tdsNumSamplesByESGenesChart', 'tdsNumSamplesByNormESGenesChart'];
            downloadTask(divs);
        });
        $('#donorInfoDownload').click(() => {
            const divs = ['donorSexChart', 'donorAgeChart'];
            downloadTask(divs);
        });
        $('#causeOfDeathDownload').click(() => {
            downloadTask(['donorCauseChart']);
        });
        $('#numOfTissuePerDonorDownload').click(() => {
            downloadTask(['donorNumTissueChart']);
        });
        $('#ageByTissueDownload').click(() => {
            downloadTask(['ageByTissueChart']);
        });
    };

    this.plotDonorSexChart = function() {
        const id = '#donorSexChart';
        const config = {
            colors: ['#1f77b4', '#d62728'],
            title: {
                text: 'Sex'
            },
            plotOptions: {
                pie: {
                    size: '50%',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                            fontWeight: 'normal'
                        }
                    }
                }
            },
            series: [{ 'data': undefined }],
            tooltip: {
                pointFormat: '{point.y} donors ({point.percentage:.1f}%)'
            },
            chart: {
                width: 430,
                height: 200
            }

        };

        // get data from json and draw
        const file = '/data/tmpSummaryData/v10/v10_subject_sex.json';
        $.getJSON(file, json => {
            // parse the json structure to high chart's data structure
            const data = d3.keys(json['SEX']).map(k => {
                let name = 'Male';
                const count = json['SEX'][k];
                if (k == '2') name = 'Female';
                return { 'name': name, 'y': count };
            });
            config.series[0]['data'] = data; // high chart's data structure
            pieChart(id, config);
        });
    };

    this.plotDonorCauseChart = function() {
        const id='#donorCauseChart';
        const config = {
            title: { text: '' },
            xAxis: { categories: ['Traumatic injury', 'Cerebrovascular', 'Heart disease', 'Liver, renal, respiratory', 'Neurological'], title: { text: 'Cause of Death' } },
            yAxis: { title: { text: '% Donors' } },
            series: [{ 'name': 'Age 20-39', 'data': [57.4, 19.1, 15.6, 5.0, 2.8] }, { 'name': 'Age 60-71', 'data': [5.6, 23.2, 49.2, 19.8, 2.2] }],
            tooltip: {
                headerFormat: 'Cause: {point.key}<br>',
                pointFormat: '{point.y} % donors'
            },

            legend: {
                align: 'left',
                verticalAlign: 'top',
                layout: 'horizontal',
                x: 0,
                y: 0
            }
        };
        barChart(id, config);
    };

    this.plotDonorAgeChart = function() {
        const id='#donorAgeChart';
        const config = {
            title: { text: 'Age', 'font-size': 14 },
            xAxis: { categories: [] },
            yAxis: {
                title: 'Counts'
            },
            plotOptions: {
                pie: {
                    size: '30%',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                            fontWeight: 'normal'
                        }
                    }
                }
            },
            series: [{ name: '% Donors', data: undefined }],
            tooltip: {
                pointFormat: '{point.y} donors ({point.percentage:.1f}%)'
            },
            chart: {
                width: 430,
                height: 250,
                marginLeft: 0,
                marginTop: 10
                /*
                 *borderWidth: 1,
                 *plotBorderWidth: 1,
                 */
            }
        };

        // get data from json and draw
        const file = '/data/tmpSummaryData/v10/v10_subject_age.json';
        $.getJSON(file, json => {
            /*
             * parse the json structure into high chart's data structure
             *var sum = json.sum;
             */
            const data = d3.keys(json['AGE']).map(k => {
                //var count = (100*json['AGE'][k]/sum);
                const count = json['AGE'][k];
                return { 'name': k, 'y': count };
            });
            config.series[0]['data'] = data; // high chart's data structure
            //colChart(id, config);
            pieChart(id, config);
        });
    };


    this.plotDonorNumTissueChart = function() {
        const id='#donorNumTissueChart';
        const config = {
            colors: ['#6d7a9a'],
            title: { text: '' },
            xAxis: { categories: [], title: { text: '# of Tissues Per Donor' } },
            yAxis: { title: { text: '# of Donors' } },
            series: [{ showInLegend: false,  'name': 'Donors', data: undefined }],
            tooltip: {
                headerFormat: '{point.key} tissues<br>',
                pointFormat: '{point.y} donors'
            },
            chart: { height: 300 }
        };
        const file = '/data/tmpSummaryData/v10/v10_tissues_per_donor_hist.json';
        $.getJSON(file, json => {
            // parse the json structure into
            config.xAxis.categories = json.bins;
            config.series[0].data = json.counts;
            colChart(id, config);
        });
    };

    this.plotAgeByTissueChart = function() {
        const id='#ageByTissueChart';
        const config = {
            title: { text: '' },
            xAxis: { categories: [], title: { text: 'Age' } },
            yAxis: { title: { text: '% of Samples' } },
            series: [],
            tooltip: {
                headerFormat: '{point.series.name}<br>',
                pointFormat: 'Age: {point.name}' + '<br>' + '{point.y:.2f} %'
            },
            chart: { height: 600 },
            legend: {
                align: 'right',
                verticalAlign: 'top',
                layout: 'vertical',
                x: 0,
                y: 0
            }
        };

        const file = '/data/tmpSummaryData/v8/v8_age_by_tissue.json';
        const cats = {};
        $.getJSON(file, json => {
            const toFilter = [
                'Kidney - Cortex',
                'Kidney - Medulla',
                'Bladder',
                'Fallopian Tube',
                'Cervix - Ectocervix',
                'Cervix - Endocervix'
            ]; // filter tissues that are no longer being collected

            json = json.filter(j => {
                return toFilter.indexOf(j.anno) < 0;
            });
            json.sort((a, b) => {
                if (a.anno < b.anno) return -1;
                if (a.anno > b.anno) return 1;
                return 0;
            });

            json.forEach(j => {
                d3.keys(j.AGE).forEach(k => {
                    cats[k] = 1;
                });
            });

            json.forEach((j, i) => {
                const tId = tissueLookupTable[j.anno].tissueSiteDetailId;
                const data = {
                    name: j.anno,
                    data: d3.keys(cats).map(k => {
                        const y = (typeof j.AGE[k] == 'undefined')?0: j.AGE[k];
                        return {
                            name: k,
                            y: 100*y/ j.sum

                        };
                    }),
                    color: '#'+tissueLookupTable[j.anno].colorHex||'#dddddd'
                };
                config.series.push(data);
            });
            config.xAxis.categories = d3.keys(cats);
            lineChart(id, config);
        });
    };

    this.plotSampleRinChart = function() {
        const id='#sampleRinChart';
        const config = {
            colors: ['#78ADDC'],
            title: { text: '' },
            xAxis: {
                categories: [],
                title: { text: 'RIN' },
                min: 10 // hard-coded, show the 10th bin and up
            },
            yAxis: { title: { text: '# of samples' } },
            series: [{ showInLegend: false, 'data': undefined }],
            tooltip: {
                headerFormat: 'RIN: {point.key} <br>',
                pointFormat: '{point.y} samples'
            },
            chart: { height: 200 }
        };
        const file = '/data/tmpSummaryData/v10/v10_sample_rin_hist.json';
        $.getJSON(file, json => {
            config.xAxis.categories = json.bins;
            config.series[0].data = json.counts;
            colChart(id, config);
        });
    };

    // chart helper functions
    function colChart(fid, fconfig, stacked) {
        const config = jQuery.extend(true, {}, fconfig);
        if (typeof config.chart == 'undefined') config.chart = {};
        config.chart.type = 'column';
        if (stacked) {
            config.plotOptions = {
                series: {
                    stacking: 'normal'
                }
            };
        }
        lineChart(fid, config);
    }

    function barChart(fid, fconfig, stacked) {
        const config = jQuery.extend(true, {}, fconfig);
        if (typeof config.chart == 'undefined') config.chart = {};
        config.chart['type'] = 'bar';
        if (stacked) {
            config.plotOptions = {
                series: {
                    stacking: 'normal'
                }
            };
        }
        lineChart(fid, config);
    }

    function pieChart(fid, fconfig) {
        const config = jQuery.extend(true, {}, fconfig);
        if (typeof config.chart == 'undefined') config.chart = {};
        config.chart['type'] = 'pie';
        lineChart(fid, config);
    }

    function lineChart(fid, fconfig) {
        const config = jQuery.extend(true, {}, fconfig);
        config.credits = {
            enabled: false
        };
        $(fid).highcharts(config);
    }
};
