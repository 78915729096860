<template>
  <div class="container-fluid">
    <h3>Genomic Imprinting Page Has Been Removed</h3>
    <p>
      The Genomic Imprinting data  was based on data from GTEx Release V3.
      This data is out of date and we are no longer providing visualizations based on this data.
    </p>
  </div>
</template>
<script>
</script>
