<template>
  <div id="goals" class="page-content center">
    <h3>Goals</h3>
    <p>The main goal of the project is to establish a molecular and data analysis resource as well as a tissue bank to
      study the regulation of gene expression in multiple relatively healthy reference neonatal, pediatric, and
      adolescent tissues, building on the
      <router-link to="/aboutAdultGTEx">Adult GTEx Project</router-link>
      .
      This resource will be broadly available to enable further research.
    </p>

    <p>This study aims to recruit at least 120 donors with a total of ~30 donors per age group. Donor tissue collection
      protocols have been developed to maximize the diversity of quality tissues recovered, with a minimum of 20 to a
      maximum of 74 different tissue sites sampled per donor.
    </p>

  </div>
</template>


<style scoped>
#goals {
  padding: 3vh 0;
  text-align: left;
}

</style>
<script>
</script>
