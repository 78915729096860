<template>
  <div class="container-fluid">
    <h2>
      GTEx Transcript Browser<img src="@/assets/images/GTEx-transcript-icon.svg" width="40px" style="margin: 0px 0px 0px 10px;">
    </h2>
    <div class="row">
      <div id="spliceVizHelp" style="display:inline-block;">
        Help
        <span class="fas fa-info-circle gtooltip"
              data-toggle="tooltip"
              data-html="true"
              data-placement="auto right"
              title="<p>In the isoform expression view, the median expression of each isoform in each tissue is summarized using a heatmap. The tissues (rows) and isoforms (columns) are ordered based on hierarchical clustering using the Euclidean distance and average linkage. The dendrogram scale shows the cluster distance. The individual isoform structures are illustrated below the heatmap; exons are represented as rectangles, and introns (the distance between two adjacent exons) are scaled up to 1 kilobases (kb) long. By clicking a tissue label (a column label) on the heat map, a lollipop plot of the isoform expression in the selected tissue will appear next to the isoform tracks.</p>
                             <p>Exon-level expression is calculated based on a collapsed gene model. To learn more about the collapsing transcript model, go to Documentation: Analysis Methods. In the exon view, the heatmap summarizes the median read counts per base of each exon of the collapsed gene model across all tissues. The tissues (rows) are hierarchically clustered based on the exon expression data. The exons (columns) are ordered based on genomic location. Below the heatmap, the collapsed gene model is illustrated. Similar to an isoform structure track, in the collapsed gene model, exons are represented as rectangles, and introns are scaled up to 1 kb long. Additionally, the junctions between joined exons of all splice variants are mapped out on the model as arcs. By clicking a tissue label (a row label) on the heatmap, the gene model will change colors scaled by tissue-specific expression. At times, an exon on the gene model may show dashed-line borders, which indicates that the exon or part of it has been filtered in the final collapsed model.</p>
                             <p>In the junction view, the heatmap summarizes the median raw read counts of junctions from individual isoforms. The tissues (rows) are hierarchically clustered based on the junction expression data, and the junctions (columns) are sorted by genomic location.</p>
                             <p>All expression quantifications are described in the Documentation: Analysis Methods -> Expression Quantification section of the portal. </p>
                        "
        />
        <gtex-browser-spinner :id="spinnerId" />
      </div>
      <div class="col-xs-12 col-sm-2">
        <form id="isoformExpressForm" style="padding: 5px;">
          <div class="form-group">
            <!-- form-group for optimum spacing between the label and its associated form input element -->
            <input id="gene" type="text" class="form-control" placeholder="Enter a gencode ID or gene name" style="font-size:12px; text-transform:uppercase;">
            <small>e.g. ACTN1, ACTN3, NDRG4, ENGASE</small>
          </div>
        </form>
      </div>
      <div id="geneInfoBox" class="col-xs-12 col-sm-10">
        <!--Gene Info: To be implemented.-->
      </div>
    </div>
    <div id="searchResult" class="col-xs-12">
      <div id="messageBox" style="color: #CB181D;" />
      <!-- charts -->
      <div id="isoformNav" style="display:none">
        <!-- Nav tabs -->

        <ul class="nav nav-tabs" role="tablist">
          <li id="defaultTab" class="nav-item">
            <a id="isoform" class="nav-link" role="tab" data-toggle="tab">Isoform Expression</a>
          </li>
          <li class="nav-item">
            <a id="exon" class="nav-link" role="tab" data-toggle="tab">Exon Expression</a>
          </li>

          <li class="nav-item">
            <a id="junction" class="nav-link" role="tab" data-toggle="tab">Junction Expression</a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content" style="margin-top:20px;">
          <div id="isoExpressVizRoot" /> <!-- the heatmap/gene model Div -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { directory } from '@/utils/portal';
import GtexBrowserSpinner from './GtexBrowserSpinner.vue';
export default {
    components: { GtexBrowserSpinner },
    data() {
        return {
            spinnerId: 'transcript-spinner'
        };
    },
    mounted: function() {
        this.init();
    },
    methods: {
        init(domId='isoExpressVizRoot') {
            if ($(`#${domId}`).length == 0) throw `Fatal Error: ${domId} does not exist.`;
            $('#gene').keypress(e => {
                if (e.keyCode == 13) {
                    // bind the enter key
                    e.preventDefault(); // Note: prevent the default behavior of the enter key, which is refreshing the page
                    $('.nav-item').removeClass('active');
                    $('#defaultTab').addClass('active');
                    const geneId = $('#gene').val();
                    this.fetch('isoformTransposed', domId, geneId);
                    this.bindTabs(geneId, domId);
                }
            });
        },
        fetch(type, domId, geneId) {
            const urls = {
                // old APIs:
                geneId: directory.getGeneUrl().url + '?geneId=',
                tissue: directory.getTissueInfoUrl().url,

                // new APIs integrated:
                geneModelUnfiltered: directory.getFullGeneModelExonUrl().url + '?gencodeId=',
                geneModel: directory.getGeneModelExonUrl().url + '?gencodeId=',
                //these two URLs have no dataset so the gtex_viz pacakge can tack on the datasetId of gtex_v10
                //Potentially add the datasetId part of the URL here instead of gtex_viz?  
                transcript: directory.getTranscriptUrlNoDataset().url,
                exon: directory.getExonUrlNoDataset().url,
                transcriptExp: directory.getMedianTranscriptExpressionClusteredUrl().url + '?gencodeId=',
                junctionExp: directory.getMedianJunctionExpressionClusteredUrl().url + '?gencodeId=',
                exonExp: directory.getMedianExonExpressionClusteredUrl().url + '?gencodeId='
            };
            $(`#${domId}`).empty();
            $('#isoformNav').show();
            
            TranscriptBrowser.render(type, geneId, domId, urls, this.spinnerId);
        },
        bindTabs(geneId, domId) {
            // first make sure to unbind the previous click events
            ['isoform', 'exon', 'junction'].forEach(d => {
                $(`#${d}`).off('click');
            });
            $('#isoform').on('click', () => { this.fetch('isoformTransposed', domId, geneId); });
            $('#exon').on('click', () => { this.fetch('exon', domId, geneId); });
            $('#junction').on('click', () => { this.fetch('junction', domId, geneId); });
        },
        /**
         * this.embed is used to embed the isoform expression viz of a specific gene on a page
         * @param initType {String} initial view type
         * @param domId {String}
         * @param geneId {String}
         */
        embed(initType='isoformTransposed', domId='isoExpressVizRoot', geneId='SLK') {
            // define domId element width
            $(`#${domId}`).innerWidth(window.innerWidth*0.85);
            if ($(`#${domId}-svg`).length != 0) {
                console.info(`${domId}-svg already exists.`);
            }
            $('.nav-item').removeClass('active');
            $('#defaultTab').addClass('active');
            this.fetch(initType, domId, geneId);
            this.bindTabs(geneId, domId);
        }
    }
};
</script>
