<template>
  <div style="opacity: 0;">
    <i class="fa fa-spin"><img src="@/assets/images/gtex-logo-spinner.png" style="margin:0.5em;" width="20"></i> Fetching data
    <i class="fa fa-blink">...</i>
  </div>

  <!-- spinner -->
  <!-- <div id="locus-browser-spinner" style="display: none">
    <i class="fa fa-spin"><img src="@/assets/images/gtex-logo-spinner.png" style="margin:0.5em;" width="20"></i> Fetching data
    <i class="fa fa-blink">...</i>
  </div> -->
</template>

<script>
export default {
    name: 'gtex-browser-spinner',
};
</script>
