import * as Tissue from '@/utils/tissue.js';
import EqtlsPage, { goTissuePage, fetchEdataByTissue } from '@/components/EqtlsPage';
import { getNormEgeneCount, getNormSgeneCount } from './tissue';

/**
 * creates donut/pie charts for the tissues using highcharts.js
 *
 * @returns
 */
export default function TissueDonutChart() {
    const self = this;
    const cutoff = 70;
    this.setupTissueDonutCharts  = function() {
        this.createEqtlTissueChart('#N60TissueDonutChartHolder', null);
    };

    this.setupTissueBarCharts  = function(tissueSortFunction) {
        $('#tdsNumSamplesByTissuesBarChart').html('');
        this.createAllTissueColumnChart('#tdsNumSamplesByTissuesBarChart', tissueSortFunction);
        this.createESGeneBySampleCountChart('#tdsNumSamplesByESGenesChart', false);
        this.createESGeneBySampleCountChart('#tdsNumSamplesByNormESGenesChart', true);
        // both table have the same content
        this.createTissueSummaryTable('tdsNumSamplesByTissuesTable');
        this.tableClickHandler('#tdsNumSamplesbyTissuesTable');

        $('#tdsPlotTableSwitch1Plot').click(e => {
            e.preventDefault();
            e.stopPropagation();
            $('#tdsNumSamplesByTissuesBarChartWrapper').show();
            $('#tdsNumSamplesByTissuesTable').hide();
            $('#tdsPlotTableSwitch1Table').removeClass('TDS-view-option-selected');
            $('#tdsPlotTableSwitch1Plot').addClass('TDS-view-option-selected');
        });
        $('#tdsPlotTableSwitch1Table').click(e => {
            e.preventDefault();
            e.stopPropagation();
            $('#tdsNumSamplesByTissuesBarChartWrapper').hide();
            $('#tdsNumSamplesByTissuesTable').show();
            $('#tdsPlotTableSwitch1Table').addClass('TDS-view-option-selected');
            $('#tdsPlotTableSwitch1Plot').removeClass('TDS-view-option-selected');
        });
        $('.tip-top').tooltip();
    };

    this.prepEqtlDataForChart = function(sliceName) {
    // Build the data arrays
        const munchkinData = []; // the donut hole
        const donutData = [];    // the donut ring
        let count = 0; let sampleCount = 0;

        // add drilldown data
        $.each(Tissue.tissueMetadataJson, (id, tissue) => {
            if (tissue.eqtlSampleSummary.totalCount >= cutoff) {
                count = count + 1;
                sampleCount = sampleCount + tissue.eqtlSampleSummary.totalCount;
                const dataObj = {
                    name: tissue.tissueSiteDetail,
                    y: tissue.eqtlSampleSummary.totalCount,
                    color: '#' + tissue.colorHex,
                    parameter: id
                };
                donutData.push(dataObj);
            }
        });

        /*
         * add data for the center
         * munchkinData.push({
         *  name: "eQTL Tissues (n >= " + cutoff + ")",
         *    y: sampleCount,
         *    color: '#ddd',
         *    parameter: null
         * });
         */

        return [munchkinData, donutData];
    };

    this.prepAllTissueDataForChart = function() {
    // Build the data arrays
        const stackerData1 = [];
        const stackerData2 = [];
        // add drilldown data
        $.each(Tissue.tissueMetadataJson, (id, tissue) => {
            const sampleCount = tissue.eqtlSampleSummary.totalCount;
            stackerData1.push({
                name: tissue.tissueSiteDetail,
                y: tissue.eqtlSampleSummary.totalCount,
                color: '#' + tissue.colorHex,
                parameter: id,
                rnaSeqAndGenotypeCount: tissue.eqtlSampleSummary.totalCount,
                rnaSeqSampleCount: tissue.rnaSeqSampleSummary.totalCount
            });


            stackerData2.push({
                name: tissue.tissueSiteDetail,
                y: (tissue.rnaSeqSampleSummary.totalCount - tissue.eqtlSampleSummary.totalCount),
                color: '#ccc',
                parameter: id,
                rnaSeqAndGenotypeCount: tissue.eqtlSampleSummary.totalCount,
                rnaSeqSampleCount: tissue.rnaSeqSampleSummary.totalCount
            });
        });

        return [stackerData1, stackerData2];
    };

    this.prepAllTissueDataForEGeneChart = function(useNorm) {
    // Build the data arrays
        const egeneData = [];
        
        // add drilldown data
        $.each(Tissue.tissueMetadataJson, (id, tissue) => {
            if (tissue.eqtlSampleSummary.totalCount >= cutoff && tissue.eGeneCount != null) {
                const y = useNorm ? getNormEgeneCount(id) : tissue.eGeneCount;
                egeneData.push({
                    name: tissue.tissueSiteDetail,
                    y: y,
                    x: tissue.eqtlSampleSummary.totalCount,
                    color: '#' + tissue.colorHex,
                    parameter: id
                });
            }
        });
        return egeneData;
    };

    this.prepAllTissueDataForSGeneChart = function(useNorm) {
        const sgeneData = [];

        $.each(Tissue.tissueMetadataJson, (id, tissue) => {
            if (tissue.eqtlSampleSummary.totalCount >= cutoff && tissue.sGeneCount != null) {
                const y = useNorm ? getNormSgeneCount(id) : tissue.sGeneCount;
                sgeneData.push({
                    name: tissue.tissueSiteDetail,
                    y: y,
                    x: tissue.eqtlSampleSummary.totalCount,
                    color: '#' + tissue.colorHex,
                    parameter: id
                });
            }
        });
        return sgeneData;
    };

    this.createEqtlTissueChart = function(containerId, sliceName) {
        $('#sampleCounts').text(Tissue.eqtlTissueSampleWithGenotypeSum);
        const  chartData1 = this.prepEqtlDataForChart(sliceName);

        // Create the chart
        $(containerId).highcharts({
            chart: {
                type: 'pie',
                marginBottom: 0
            },
            credits: { enabled: false },
            title: { text: '' },

            plotOptions: {
                pie: {
                    shadow: false,
                    center: ['50%', '40%'],
                    data: chartData1[1],
                    //innerSize: '30%',
                    innerSize: '80%', // this is where to change the inner circle size
                    size: '40%',
                    dataLabels: {
                        style: { fontWeight: 'normal' }
                    }
                }

            },
            tooltip: {  valueSuffix: '' },
            series: [
                {
                    name: 'Samples',
                    type: 'pie',
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function(e) {
                                e.preventDefault();
                                const args = {
                                    'tissueName': e.point.parameter,         // not needed?
                                    'tissueDisplayName': e.point.name,
                                    'action': 'fromDonut'
                                };

                                fetchEdataByTissue(args);
                            }
                        }
                    }
                }
            ]
        });
    };

    this.createAllTissueColumnChart = function(containerId, tissueSortFunction) {
        const chartData2 = this.prepAllTissueDataForChart();
        const points = chartData2[0].slice();// make a copy to reorder
        let pointsStack = chartData2[1].slice();

        if (tissueSortFunction != null) {
            points.sort(tissueSortFunction);
            pointsStack.sort(tissueSortFunction);
        }
        pointsStack = pointsStack.slice();// make a copy to reorder
        /*
         * for the second series, replace the y with the genotype count
         * dynamically calculate the bar width
         */
        const divW = $(containerId).innerWidth();
        const barW = Math.floor(divW/points.length) - 6; // -5 for interbar spacing

        $(containerId).highcharts({
            chart: {
                type: 'column',
                height: 350,
                marginLeft: 130 // so that the x-axis labels don't get cut off
                //marginRight: 30
            },
            credits: { enabled: false },
            title: {  text: '' },
            yAxis: {
                title: { text: 'Number of Samples' },
                stackLabels: {
                    enabled: false
                },
                plotLines: [{
                    color: '#FF0000',
                    width: 1,
                    value: cutoff,
                    zIndex: 5,
                    dashStyle: 'dash',
                    label: {
                        text: 'n>=' + cutoff,
                        align: 'left',
                        y: -2,
                        x: -40,

                        style: {
                            color: '#777777'
                        }
                    }
                }] // add a pl
                // ot line of y = cutoff


            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function() {
                    return '<b>' + this.point.name + '</b><br/>' +
                        /*
                         'with genotype : ' + this.point.rnaSeqAndGenotypeCount + '<br/>' +
                         'without genotype: ' + (this.point.rnaSeqSampleCount - this.point.rnaSeqAndGenotypeCount) + '<br/>'*/ 
                        'Total: ' + this.point.rnaSeqSampleCount;
                }
            },

            xAxis: {
                type: 'category',
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Verdana, sans-serif',
                        whiteSpace: 'nowrap',
                        textOverflow: 'none'
                    },
                    step: 1,
                    padding: 0

                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },


            series: [

                {
                    name: 'Number RNAseq samples',
                    data: pointsStack,
                    cursor: 'pointer',
                    stack: 'stack1',
                    point: {
                        events: {
                            click: function(e) {
                                e.preventDefault();
                                const args = {
                                    'tissueName': e.point.parameter,         // not needed?
                                    'tissueDisplayName': e.point.name,
                                    'action': 'fromDonut'
                                };
                                fetchEdataByTissue(args);
                            }
                        }
                    }
                },
                {
                    name: 'Number RNAseq samples with genotype',
                    data: points,
                    cursor: 'pointer',
                    stack: 'stack1',
                    // pointWidth: barW,

                    point: {
                        events: {
                            click: function(e) {
                                e.preventDefault();
                                const args = {
                                    'tissueName': e.point.parameter,         // not needed?
                                    'tissueDisplayName': e.point.name,
                                    'action': 'fromDonut'
                                };
                                fetchEdataByTissue(args);
                            }
                        }
                    }
                }

            ]
        });
    };


    this.createESGeneBySampleCountChart = function(containerId, useNorm) {
        const eGenesDataPoints = this.prepAllTissueDataForEGeneChart(useNorm);
        const sGenesDataPoints = this.prepAllTissueDataForSGeneChart(useNorm);

        $(containerId).highcharts({
            chart: {
                type: 'scatter',
                zoomType: 'xy',
                marginLeft: 100,
                marginRight: 30
            },
            title: {
                text: ''
            },
            legend: {
                enabled: true
            },
            xAxis: {
                title: {
                    enabled: true,
                    text: 'Number of Samples'
                },
                startOnTick: false,
                endOnTick: true,
                showLastLabel: true,
                min: 45,


                plotBands: [{
                    color: '#eee',
                    from: 45, // Start of the plot band
                    to: cutoff, // End of the plot band
                    label: {
                        style: {
                            color: '#777777'
                        },
                        text: 'n >=' + cutoff, // Content of the label.
                        align: 'left', // Positioning of the label.
                        x: 57 // Amount of pixels the label will be repositioned according to the alignment.
                    }
                }],

                plotLines: [{
                    color: 'red', // Color value
                    value: cutoff, // Value of where the line will appear
                    width: 1, // Width of the line
                    dashStyle: 'dash'
                }]
            },
            yAxis: {
                title: {
                    text: useNorm?'Number of e/sGenes / Total Expressed Genes':'Number of e/sGenes'
                }
            },
            series: [
                {
                    name: 'eGenes',
                    data: eGenesDataPoints,
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function(e) {
                                e.preventDefault();
                                const args = {
                                    'tissueName': e.point.parameter,         // not needed?
                                    'tissueDisplayName': e.point.name,
                                    'action': 'fromDonut'
                                };
                                fetchEdataByTissue(args);
                            }
                        }

                    },
                    tooltip: {
                        pointFormat: useNorm?'<b>{point.name}</b><br>{point.x} samples, {point.y:.3f} eGenes/total':'<b>{point.name}</b><br>{point.x} samples, {point.y} eGenes'
                    },
                    marker: {
                        radius: 5
                    }
                },
                {
                    name: 'sGenes',
                    data: sGenesDataPoints,
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function(e) {
                                e.preventDefault();
                                // TODO: link to sqtl page, not sure if there is one yet --Tracy
                            }
                        }
                    },
                    tooltip: {
                        pointFormat: useNorm?'<b>{point.name}</b><br>{point.x} samples, {point.y:.3f} sGenes/total':'<b>{point.name}</b><br>{point.x} samples, {point.y} sGenes'
                    },
                    marker: {
                        symbol: 'diamond',
                        radius: 6
                    }
                }
            ],
            credits: {
                enabled: false
            }
        });
    };


    this.createTissueSummaryTable = function(containerId) {
        $('#'+containerId).html('');

        const table = $('<table id="'+containerId+'Table"></table>');
        $('#'+containerId).append(table);
        const thead = $('<thead></thead>');
        $(table).append(thead);
        const tbody = $('<tbody></tbody>');
        $(table).append(tbody);

        const tr = $('<tr></tr>');
        $(thead).append(tr);
        const td1 = $('<th  style="max-width: 10px !important;"></th>');
        $(tr).append(td1);
        const td2 = $('<th>Tissue</th>');
        $(tr).append(td2);
        const td3 = $('<th>UBERON</th>');
        $(tr).append(td3);
        const td4 = $('<th># RNASeq and Genotyped samples</th>');
        $(tr).append(td4);
        const td5 = $('<th># RNASeq Samples</th>');
        $(tr).append(td5);
        const td6 = $('<th># eGenes</th>');
        $(tr).append(td6);
        const td7 = $('<th>eGenes/Total Expressed Genes</th>');
        $(tr).append(td7);
        const td8 = $('<th># sGenes</th>');
        $(tr).append(td8);
        const td9 = $('<th>sGenes/Total Expressed Genes</th>');
        $(tr).append(td9);

        $.each(Tissue.tissueMetadataJson, (id, tissue) => {
            const tr = $('<tr></tr>');
            $(tbody).append(tr);
            const td1 = $('<td style="width: 10px !important; background-color:'
                        + '#' + tissue.colorHex
                        + ' ;">&nbsp;</td>');
            $(tr).append(td1);
            const td2 = $('<td>'+tissue.tissueSiteDetail+'</td>');
            $(tr).append(td2);
            const td3 = $('<td>' + tissue.ontologyId + '</td>');
            $(tr).append(td3);
            const td4 = $('<td>'+tissue.eqtlSampleSummary.totalCount+'</td>');
            $(tr).append(td4);
            const td5 = $('<td>'+tissue.rnaSeqSampleSummary.totalCount+'</td>');
            $(tr).append(td5);
            const td6 = $('<td>'+tissue.eGeneCount+'</td>');
            $(tr).append(td6);
            const td7 = $('<td>'+ getNormEgeneCount(id).toFixed(2)+'</td>');
            $(tr).append(td7);
            const td8 = $('<td>'+tissue.sGeneCount+'</td>');
            $(tr).append(td8);
            const td9 = $('<td>'+ getNormSgeneCount(id).toFixed(2)+'</td>');
            $(tr).append(td9);
        });

        const buttonProps = {
            'buttons': [{
                extend: 'copy',
                exportOptions: { columns: [1, 2, 3, 4] }
            },
            {

                extend: 'csv',
                exportOptions: { columns: [1, 2, 3, 4] }
            }]
        };

        const oTable = $(table).dataTable({
            deferRender: true,
            destroy: true,
            retrieve: true,
            jQueryUI: true,
            processing: true,
            paging: true,
            dom: 'B<"clear">lrtip',
            orderClasses: false,
            buttons: buttonProps,
            columnDefs: [{ 'targets': 0, 'orderable': false }],
            order: [[3, 'desc']],
            language: {
                'sEmptyTable': 'Please wait - Fetching records'
            }
        });
        oTable.addClass('display');

        $('#'+containerId).hide();
    };

    this.tableClickHandler = function(tableId) {
        $('#tdsNumSamplesByTissuesTableTable' + ' tbody').click(event => {
            $(tableId + ' tr.row_selected').removeClass('row_selected');
            $(event.target).closest('tr').addClass('row_selected');
            //var tissueName = $('tr.row_selected td:nth-child(2)').text().replace(/ /g,"_")
            const tissueName = Tissue.tissueLookupTable[$('tr.row_selected td:nth-child(2)').text()].tissueSiteDetail;

            goTissuePage(tissueName);
        });
    };
}
