<template>
  <div class="container-fluid">
    <h2>
      GTEx Locus Browser (Gene-centric) <img src="@/assets/images/GTEx-locus-browser-icon.svg" width="40px" style="margin: 0px 0px 0px 10px;">
    </h2>
    <hr>
    <div class="row">
      <div class="col-sm-12 col-lg-4">
        <form id="geneSearchForm" style="padding: 5px;">
          <div class="form-group">
            <!-- form-group for optimum spacing between the label and its associated form input element -->
            <input id="geneInput" type="text" class="form-control" placeholder="Enter a gene name or Gencode ID" style="font-size:12px; text-transform:uppercase;">
            <small>(e.g. ACTN3, NDRG4, ENGASE)</small>
          </div>
        </form>
      </div>
      <div class="col-sm-12 col-lg-4">
        <a data-toggle="modal" data-target="#helpModal" style="cursor: pointer;">
          <i class="fas fa-info-circle" /> Help
        </a>
        <!-- animated spinner for data loading -->
        <gtex-browser-spinner :id="spinnerId" />
        <div id="locus-browser-error" style="color:brown" />
      </div>
    </div>
    <hr>
    <div class="row show-if-success" style="opacity: 0; font-size: 0.75em;">
      <div id="variant-form" class="col-sm-12 col-md-6 col-lg-4">
        Locate variants in the current plot <br>
        <small>Variant ID, RS ID and position (e.g. chr16_58499352, chr16_58510847) are supported</small>
        <form id="variantSearchForm" style="padding: 5px;">
          <div class="form-group">
            <!-- form-group for optimum spacing between the label and its associated form input element -->
            <input id="variantInput" type="text" class="form-control" placeholder="Enter IDs in comma separated format" style="font-size:12px;">
          </div>
        </form>
      </div>
      <div id="gene-info" class="col-sm-12 col-md-6 col-lg-4" />
      <div class="col-sm-12">
        <hr>
      </div>
    </div>
    <div id="locus-browser-toolbar" class="row" style="font-size: 0.75em; display:none;">
      <div class="btn-group btn-group-sm col-sm-12 col-md-6 col-lg-4">
        <a id="show-v-id" class="btn btn-default">Show Variant Labels</a>
        <a id="change-qtl-map" class="btn btn-default">Use Bubble Map</a>
        <a id="show-data-menu" class="btn btn-default" data-toggle="modal" data-target="#qtl-modal">
          <i class="fas fa-filter" /> Filter Menu
        </a>
      </div>
      <div class="btn-group btn-group-sm col-sm-12 col-md-6 col-lg-4">
        <a id="zoom-minus" class="btn btn-default"><i class="fa fa-search-minus" /> Zoom Out</a>
        <a id="zoom-plus" class="btn btn-default"><i class="fa fa-search-plus" /> Zoom In</a>
        <a id="zoom-reset" class="btn btn-default">Reset</a>
      </div>
      <div class="col-lg-3">
        <span id="zoom-size" />
      </div>
    </div>

    <div id="locus-browser">
      <!-- Root DOM for the SVG -->
    </div>
    <div id="ld-browser">
      <!-- Root DOM for the LD map-->
    </div>
    <div id="footer-space" style="min-height: 800px">
      <!-- Required to push the GTEx footer down-->
    </div>

    <!-- QTL data filter menu modal -->
    <!-- Modal. reference: https://getbootstrap.com/docs/4.0/components/modal/ -->
    <div id="qtl-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="qtlFilterModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="qtlFilterModalTitle" class="modal-title">
              QTL Data Filter Menu
            </h5>
            <button id="modal-close-btn" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="far fa-times-circle" /></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-3">
                <h6>QTL Type</h6>
                <form id="data-type-menu">
                  <input type="checkbox" name="dataType" value="eQTL" checked="true">eQTL<br>
                  <input type="checkbox" name="dataType" value="sQTL" checked="true">sQTL<br>
                </form>
              </div>
              <div class="col-sm-9">
                <h6>
                  Tissues
                  <button id="modal-clear-tissues-btn" type="button" class="btn btn-sm btn-default">
                    Deselect All
                  </button>
                  <button id="modal-all-tissues-btn" type="button" class="btn btn-sm btn-default">
                    Select All
                  </button>
                </h6>
                <form id="tissue-menu" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button id="modal-filter-btn" type="button" class="btn btn-default" data-dismiss="modal">
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Help Page Modal -->
    <div id="helpModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="helpTitle" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 id="helpTitle" class="modal-title">
              GTEx Locus Browser
            </h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              GTEx Locus Browser is a new gene-based visualization developed for exploring the cis-QTL (Quantitative Trait Loci) of a given gene. Currently, two types of QTL analyses are available in the Locus Browser: eQTL (expression QTL) and sQTL (splicing QTL). To help with interpretation, additional variant data and annotation are also provided. This includes:<br>
              <ul>
                <li>LD (Linkage Disequilibrium) structures</li>
                <li>Functional annotations</li>
                <li>Independent eQTL: conditionally independent eQTL mapped using stepwise regression</li>
                <li>QTL violin plots</li>
              </ul>
              To learn more about the methods used to generate these data, please visit the <a
                href="https://gtexportal.org/home/documentationPage#AboutData"
                target="_blank"
              > documentation page</a>.
            </div>
            <img src="@/assets/images/GTEx Locus Browser Documentation (2).svg"><hr>
            <img src="@/assets/images/GTEx Locus Browser Documentation (3).svg"><hr>
            <img src="@/assets/images/GTEx Locus Browser Documentation (4).svg"><hr>
            <img src="@/assets/images/GTEx Locus Browser Documentation (5).svg"><hr>
            <img src="@/assets/images/GTEx Locus Browser Documentation (6).svg"><hr>
            <img src="@/assets/images/GTEx Locus Browser Documentation (7).svg"><hr>
            <img src="@/assets/images/GTEx Locus Browser Documentation (8).svg"><hr>
            <img src="@/assets/images/GTEx Locus Browser Documentation (9).svg"><hr>
            <img src="@/assets/images/GTEx Locus Browser Documentation (10).svg"><hr>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Violin Plot div for the jQuery dialog window-->
    <div id="qtl-violin-div" />
  </div>
</template>
<script>
/* eslint-disable */

import { directory } from '@/utils/portal';
import router from '@/router/router';
import GtexBrowserSpinner from './GtexBrowserSpinner.vue';
export default {
  components: { GtexBrowserSpinner },
    data: function() {
        const queryDatasetV10 = directory.getV10Dataset();
        const queryGencodeV10 = directory.getV10GencodeGenomeVersion();
        return {
            urls: {
                dyneqtl: directory.getEqtlBoxplotUrl().url + '?' + queryDatasetV10,
                dynsqtl: directory.getDynSqtlUrl().url + '?' + queryDatasetV10,
                eqtls: directory.getSingleTissueEqtlUrl().url + '?' + queryDatasetV10 + '&gencodeId=',
                geneExpression: directory.getMedianGeneExpressionUrl().url + '?' + queryDatasetV10 + '&hcluster=true&pageSize=10000&gencodeId=',
                geneModel: directory.getGeneModelExonUrl().url + '?' + queryDatasetV10 + '&gencodeId=',
                genes: directory.getNeighborGeneUrl().url + '?' + queryGencodeV10 + '&',
                ld: directory.getLDUrl().url + '?' + queryDatasetV10 + '&gencodeId=',
                queryGene: directory.getGeneUrl().url + '?' + queryGencodeV10 + '&geneId=',
                sqtls: directory.getSingleTissueSqtlUrl().url + '?' + queryDatasetV10 + '&gencodeId=',
                tissueInfo: directory.getTissueInfoUrl().url + '?' + queryDatasetV10,
            },
            spinnerId: 'locus-browser-spinner'
        };
    },
    mounted: function() {
        this.init();
    },
    methods: {
        init() {
            const config = this.buildConfig();
            let queryGene = this.$route.params.geneId||undefined;

            const run = () => {
                $('#gene-info').empty();
                $('#locus-browser-toolbar').hide();
                $('#locus-browser').empty();
                $('#ld-browser').empty();
                LocusBrowser.init(queryGene, config);
            };
            if (queryGene) {
                run();
            }
            $('#geneInput').keypress(e => {
                if (e.keyCode == 13) {
                    // bind the enter key
                    e.preventDefault(); // Note: prevent the default behavior of the enter key, which is refreshing the page
                    queryGene = $('#geneInput').val();
                    run();
                }
            });
        },

        buildConfig() {
            const GlobalWidth = window.innerWidth;
            const urls = this.urls;
            // config each panel in the LocusBrowser visualization
            const panels = {
                geneMap: {
                    id: 'gene-map',
                    useLog: true,
                    logBase: null,
                    margin: {
                        top: 20, // provide enough space for the color legend
                        right: 100, // provide enough space for the row labels
                        bottom: 100, // provide enough space for the column labels
                        left: 80
                    },
                    width: GlobalWidth,
                    height: 110, // outer height: this includes top and bottom margins + inner height
                    colorScheme: 'Blues',
                    cornerRadius: 2,
                    columnLabel: {
                        angle: 90,
                        adjust: 10
                    },
                    rowLabel: {
                        width: 100
                    }
                },
                tssTrack: {
                    id: 'tss-track',
                    label: 'Gene locations',
                    data: null,
                    yPos: null, // where the panel should be placed to be calculated based on the panel layout
                    margin: {
                        top: 50,
                        right: 50,
                        bottom: 0,
                        left: 80
                    },
                    width: GlobalWidth,
                    height: 70, // outer height=inner height + top margin + bottom margin
                    color: {
                        background: '#ffffff',
                        feature: '#ababab'
                    }
                },
                geneModelTrack: {
                    id: 'gene-model-track',
                    label: 'Gene exons',
                    yPos: null,
                    margin: {
                        top: 0,
                        right: 50,
                        bottom: 10,
                        left: 80
                    },
                    width: GlobalWidth,
                    height: 30,
                    color: {
                        background: '#ffffff',
                        feature: '#910807'
                    }
                },
                eqtlTrack: {
                    id: 'eqtl-track',
                    label: 'eQTL p-values',
                    yPos: null,
                    margin: {
                        top: 0,
                        right: 50,
                        bottom: 0,
                        left: 80
                    },
                    width: GlobalWidth,
                    height: 20, // outer height. outer height=inner height + top margin + bottom margin.
                    color: {
                        background: '#ffffff',
                        feature: '#ababab'
                    }

                },
                sqtlTrack: {
                    id: 'sqtl-track',
                    label: 'sQTL p-values',
                    data: null,
                    yPos: null,
                    margin: {
                        top: 0,
                        right: 50,
                        bottom: 0,
                        left: 80
                    },
                    width: GlobalWidth,
                    height: 20, // outer height. outer height=inner height + top margin + bottom margin.
                    color: {
                        background: '#ffffff',
                        feature: '#ababab'
                    }
                },
                qtlMap: {
                    id: 'qtl-map', // the bubble heat map of QTLs
                    mapType: 'barmap',
                    width: GlobalWidth,
                    data: null,
                    yPos: null,
                    margin: {
                        top: 150, // at least 70, provide space for the genome position scale and brush lines
                        right: 100, // at least 50 for axis
                        bottom: 20, // at least 20
                        left: 180 // at least 150 for text labels
                    },
                    height: 500, // determined programatically
                    rowHeight: 20, // define a fixed row height
                    colorScheme: 'RdBu', // TODO: review these plot options
                    colorScaleDomain: [-1, 1],
                    useLog: false,
                    logBase: null,
                    showColumnLabel: false,
                    label: {
                        column: {
                            show: true,
                            angle: 90,
                            adjust: 10,
                            location: 'bottom',
                            textAlign: 'left'
                        },
                        row: {
                            show: true,
                            width: 150,
                            angle: 0,
                            adjust: 0,
                            location: 'left',
                            textAlign: 'right'
                        }
                    }
                }
            };
            const ldPanel = { // LD configuration is separate from the panels because it's in its own DIV and is rendered using canvas.
                id: 'ld-browser',
                cutoff: 0.1,
                width: GlobalWidth,
                margin: {
                    top: 5,
                    right: 100,
                    bottom: 0,
                    left: 180 // must be consistent with the QTL map
                },
                colorScheme: 'Greys'
            };
            return {
                id: 'locus-browser',
                ldId: 'ld-browser',
                infoId: 'gene-info',
                spinnerId: this.spinnerId,
                width: GlobalWidth,
                height: null, // should be dynamically calculated
                genomicWindow: 1e6,
                urls: urls===undefined?LocusBrowser.dataUrls:urls,
                parsers: LocusBrowser.dataParsers,
                dataFilters: LocusBrowser.dataFilters,
                data: LocusBrowser.data,
                viz: LocusBrowser.vizComponents,
                dataSort: LocusBrowser.dataSort,
                panels: panels,
                ld: ldPanel,
                callback: this.updateGene
            };
        },
        /**
         * Update the search box and URL with the current query gene
         * @param {String} geneId
         */
        updateGene(geneId) {
            const routeName = this.$route.name;
            $('#gene').prop('placeholder', geneId); // display the query gene in the search box
            router.push({ name: routeName, params: { geneId: geneId } }); // add the query gene to the page's URL
        }
    }
};
</script>
