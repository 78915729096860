<template>
  <div>
    <template v-for="(request, index) in requests"  :key="index">
      <div>
        <span class="project-title">{{ request["Project"] }}</span>
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="project-subtitle">
              Requested by
            </div>
            {{ request["Requestor(s)"] }}
            <!-- <div class="project-subtitle">
              Affiliation
            </div>
            {{ request["Affiliation"] }} -->
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="project-subtitle">
              Affiliation
            </div>
            {{ request["Affiliation"] }}
            <!-- <div class="project-subtitle">
              Date approved
            </div>
            {{ request["Date of Shipment"] }} -->
          </div>
        </div>
        <div class="project-subtitle">
          Description
        </div>
        <span v-html="request['Research Use Statement']" />
        <hr v-if="index < requests.length - 1">
      </div>
    </template>
  </div>
</template>

<script>
import authorizedRequests from '@/assets/data_files/biobank_authorized_requests.tsv';

export default {
    data: function() {
        return {
            requests: authorizedRequests
        };
    }
};
</script>

<style scoped>
.project-title {
  font-size: 20px;
  font-weight: 400;
}
.project-subtitle {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 500;
}

</style>
