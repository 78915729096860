<template>
  <div class="panel panel-default">
    <div class="panel-heading">
      <span class="gtex-p-data-grp-title" title="Copy URL to clipboard" @click="copyTableUrl" >
      {{ dataPanel.displayName }}
      <i v-show="dataPanel.dataPanelGroups[0].displayName === ''" class="far fa-copy" title="Copy URL to clipboard" />
      </span>
    </div>
    <div class="panel-body">
      <div v-if="dataPanel.description" class="gtex-panel-desc">
        <span>
          <p v-html="dataPanel.description" />
        </span>
      </div>
        <data-panel-group-component
          v-for="(dataPanelGroup, j) in dataPanel.dataPanelGroups" :key="dataPanelGroup.id"
          :tabs-container-id="tabsContainerId" :index="j" :tab-id="tabId"
          :fileset="dataPanelGroup.files" :data-panel-group="dataPanelGroup"
          :data-panel="dataPanel"
        />
    </div>
  </div>
</template>

<script>
import DataPanelGroupComponent from './DataPanelGroupComponent.vue';

export default {
    name: 'DataPanelComponent',
    components: { DataPanelGroupComponent },
    props: {
        dataType: {
            type: Object,
            required: true
        },
        dataPanel: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        tabsContainerId: {
            type: String,
            required: true
        },
        tabId: {
            type: String,
            required: true
        }
    },
  methods: {
      copyTableUrl() {
        // if there's no name within the data panel groups and navigator clipboard
        if (this.dataPanel.dataPanelGroups[0].displayName === '' && navigator.clipboard) {
          const dataPanelGroup = this.dataPanel.dataPanelGroups[0];
          const dataPanelId = dataPanelGroup.dataPanel;
          const dataTabId = this.dataPanel.dataTab;
          const dataPanelGroupId = dataPanelGroup.id;
          const tableId = `${dataTabId}-${dataPanelId}-${dataPanelGroupId}`
          const url = window.location.href.indexOf('#') > -1 ? window.location.href.substring(0, window.location.href.indexOf('#')) : window.location.href ;
          navigator.clipboard.writeText(`${url}#${tableId}`);
        }
      },
    }
};
</script>

<style scoped>
.panel-heading {
    font-size: 18px;
}
.panel-body {
    border: solid 1px #A0A0A0;
}

.gtex-panel-desc {
    margin-bottom: 15px;
}

.gtex-p-data-grp-title {
  cursor: pointer;
}
</style>

<style>
/** Overriding datatables CSS */
.gtex-panel-data-grp thead .ui-state-default {
    background: white;
}

</style>
