import {createApp} from 'vue';
import App from './App.vue';
import GtexRouter from '@/router/router.js';
import browserCompatibilityCheck from '@/utils/browser-compatibility-check.js';
import VueGtag from "vue-gtag"


const app = createApp(App)

app.use(GtexRouter)
    
app.use(VueGtag, {
    config: { id: process.env.VUE_APP_G_TAG }, GtexRouter
})

app.mount('#app')


browserCompatibilityCheck();