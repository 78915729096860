<template>
  <div class="container-fluid">
    <div id="biobankCart">
      <h1>Biobank Sample Request</h1>

      <div class="row">
        <div class="col-xs-12">
          <legend style="font-variant:all-small-caps;">
            Submitting a sample request
          </legend>
          <div>
            <ol>
              <li>If you selected samples of interest by browsing the samples in our portal, download the list of samples from your cart using the "Download Samples in Cart" button below.</li>
              <li>Download and fill complete our <a href="https://storage.googleapis.com/gtex-public-data/biobank-sample-request/gtex_sample_request_form_fillable.pdf" target="_blank">sample request form</a>.</li>
              <li>
                Email both files to <a href="mailto:gtex-biobank@broadinstitute.zendesk.com">gtex-biobank@broadinstitute.zendesk.com</a>.
                <ul>
                  <li>
                    When submitting your request, use the email subject format <strong>GTEx Biobank Request - [Date] [Last_Name]</strong>
                  </li>
                </ul>
              </li>
              <li>If your request is approved, you will need to fill out a <a href="https://storage.googleapis.com/gtex-public-data/biobank-sample-request/Appendix_B_GTEX_MTA_FINAL.pdf" target="_blank">material transfer agreement (MTA)</a> before delivery of samples.</li>
            </ol>
          </div>
        </div>
      </div>

      <div class="row">
        <div id="sampleRequestDiv" class="col-xs-12">
          <legend style="font-variant:all-small-caps;">
            Your Current Request Cart (<span id="biobankCartCount" /> samples selected)
          </legend>

          <div style="margin-bottom: 10px;">
            <router-link class="btn btn-md btn-info cart-btn-margin" to="/samplesPage#search" custom v-slot = "{ navigate, isActive }">
              <button :class = "{active: isActive}" @click="navigate" >
              <i class="fas fa-arrow-circle-left" /> Search for more samples</button>
            </router-link>
            <button id="refreshCartButton" role="button" class="btn btn-md btn-primary cart-btn-margin">
              Remove Deselected Samples
            </button>
            <button id="clearCartButton" type="submit" class="btn btn-md btn-primary" role="button">
              Clear Cart
            </button>
          </div>
          <div id="biobankCartTableDiv">
            <table id="biobankCartTable">
            <!-- this table is for the bioank sample cart-->
            </table>
          </div>
          <br><br>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BiobankSampleRequest from '@/utils/biobank-sample-request';
export default {
    mounted: function() {
        BiobankSampleRequest.showSampleCart();
        BiobankSampleRequest.updateCartButton(); // to update the sample counts in the badge
        BiobankSampleRequest.bindCartButtons();
    }
};
</script>

<style scoped>
.cart-btn-margin {
  margin-right: 5px;
}
</style>
