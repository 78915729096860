<template>
  <div class="container-fluid">
    <h2>
      GTEx Multi Gene Query<img src="@/assets/images/GTEx-heatmap-icon.svg" width="40px" style="margin: 0px 0px 0px 10px;">
    </h2>
    <!-- input form -->
    <p>
      <button class="btn btn-info btn-sm" type="button" data-toggle="collapse" data-target="#collapseForm" aria-expanded="false" aria-controls="collapseForm">
        <i class="fa fa-bars" />
      </button>
    </p>

    <div id="collapseForm" class="row collapse show">
      <div class="col-sm-12">
        <form id="geneExpressionForm" style="background:#f2f7fd; padding: 5px;">
          <div class="form-group row">
            <!-- form-group for optimum spacing between the label and its associated form input element -->
            <div class="col-sm-12 col-md-2">
              <label for="genes" class="align-top">Enter Gencode IDs or Gene Names</label>
              <ul style="font-size:12px; list-style-type:square; padding-left: 15px;">
                <li>Limited to 50 entries.</li>
                <li>
                  <a id="searchExample" href="#">A search example</a>
                </li>
              </ul>
              <textarea id="genes" rows="20" class="form-control" style="font-size:12px; text-transform:uppercase;" />
              <br>

              <div id="batchSubmit" class="btn btn-info btn-sm" style="margin-bottom:1em;">
                Search
              </div>
            </div>
            <div class="col-xs-12 col-md-10">
              <label>Select Tissue(s)</label>
              <div id="tissueMenu" class="form-group row">
                <!-- tissue menu check boxes -->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-xs-12">
        <gtex-browser-spinner :id="spinnerId" />
      </div>
      <div id="searchResult" class="col-xs-12 col-md-12">
        <div class="row" style="margin-left: 10px;">
          <div id="heatmapRoot" class="col-xs-12 col-md-12" /> <!-- the heatmap Div -->
          <div id="violinRoot" class="col-xs-12 col-md-12" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from '@/router/router';
import { directory } from '@/utils/portal';
import GtexBrowserSpinner from './GtexBrowserSpinner.vue';

export default {
    components: { GtexBrowserSpinner },
    data: function() {
        return {
            genes: this.$route.params.genes||undefined,
            spinnerId: 'multi-gene-spinner'
        };
    },
    mounted: function() {
        this.init();
    },
    methods: {
        init() {
            const urls = {
                geneExp: directory.getGeneExpressionUrl().url + '?gencodeId=',
                medGeneExp: directory.getMedianGeneExpressionClusteredUrl().url,

                // TODO: following APIs need to be updated to the Dev APIs
                tissue: directory.getTissueInfoUrl().url,
                tissueSites: directory.getTissueInfoUrl().url,
                geneId: directory.getGeneUrl().url + '?format=json&geneId='
            };
            const heatmapRootId = 'heatmapRoot';
            const violinRootId = 'violinRoot';
            const submitId = 'batchSubmit';
            const menuId = 'tissueMenu';
            const formId = 'collapseForm';


            $('#searchExample').click(() => {
                const examples = 'ACTN3, SLK, ENSG00000121879.6, NDRG4, ENSG00000141510.18, ENSG00000039068.20, ENSG00000107485.18, ENSG00000095015.6, ENSG00000141027.22, ENSG00000065526.12, TP53';
                $('#genes').val(examples);
                return false; // this prevents # sign being appended to the URL
            });

            // The BatchGeneExpression (a GTExViz class) launch function creates the tissue menu, which is rendered in a callback of an AJAX call to fetch the GTEx tissues and subsites.
            ExpressionMap.launch(formId, menuId, submitId, heatmapRootId, violinRootId, urls, this.tasks, this.spinnerId);

            // and by default, select all tissues
            this.checkAllTissues();

            // If this is a direct URL query with param, genes
            if (this.genes !== undefined) {
                this.submit();
            }
        },
        tasks() {
            // additional portal-specific tasks when submitting the query
            this.genes = $('#genes').val().split(' ').join('').trim().toUpperCase();
            router.push({ name: this.$route.name, params: { genes: this.genes } });
        },
        checkAllTissues() {
            const selectTissues = () => {
                $('input[name="allTissues"][value="all"]').prop('checked', true);
                $('.tissueGroup').prop('checked', true);
                $('.tissueSubGroup').prop('checked', true);
            };

            // set a timer to wait for the Ajax call to build the tissue menu
            const time = 500; // in ms
            const check = () => {
                return $('#tissueMenu div').length;
            };
            this.wait(check, time, selectTissues);
        },
        submit() {
            $('#genes').val(this.genes);
            const trigger = () => {
                $('#batchSubmit').trigger('click');
            };
            // set a timer to wait for the Ajax call to define the events of the submit button
            const check = () => {
                return $._data($('#batchSubmit').get(0), 'events') !== undefined;
            };
            const time = 500; // in ms
            this.wait(check, 500, trigger);
        },
        wait(check, time, callback, count=0) {
            const self = this;
            if (count == 10) {
                console.warn('The timer is terminated.');
                return;
            } // set the maximum count, so that the recursive function is terminated after X counts regardless the tissue menu is rendered.

            if (check()) {
                callback();
                return;
            } else {
                count++;
                setTimeout(() => {
                    self.wait(check, time, callback, count);
                }, time);
                return;
            }
        }
    }
};
</script>
