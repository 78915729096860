const bigWigUrlRoot = 'https://storage.googleapis.com/gtex-igv-files';

export default Object.freeze({
    H3K27AcCoverage: {
        'Brain_Frontal_Cortex_BA9': `${bigWigUrlRoot}/H3K27ac/Brain.SuperSampMerged.hg38.H3K27ac.bw`,
        'Heart_Left_Ventricle': `${bigWigUrlRoot}/H3K27ac/Heart.SuperSampMerged.hg38.H3K27ac.bw`,
        'Muscle_Skeletal': `${bigWigUrlRoot}/H3K27ac/Muscle.SuperSampMerged.hg38.H3K27ac.bw`,
        'Lung': `${bigWigUrlRoot}/H3K27ac/Lung.SuperSampMerged.hg38.H3K27ac.bw`
    },
    H3K27AcPeak: {
        'Brain_Frontal_Cortex_BA9': `${bigWigUrlRoot}/H3K27ac/Brain.peak.hg38.H3K27ac.bed`,
        'Heart_Left_Ventricle': `${bigWigUrlRoot}/H3K27ac/Heart.peak.hg38.H3K27ac.bed`,
        'Muscle_Skeletal': `${bigWigUrlRoot}/H3K27ac/Muscle.peak.hg38.H3K27ac.bed`,
        'Lung': `${bigWigUrlRoot}/H3K27ac/Lung.peak.hg38.H3K27ac.bed`
    },
    M6ACoverage: {
        'Brain_Cortex': `${bigWigUrlRoot}/M6A/merge.RIP.Brain.bs10.bw`,
        'Heart_Left_Ventricle': `${bigWigUrlRoot}/M6A/merge.RIP.Heart.bs10.bw`,
        'Muscle_Skeletal': `${bigWigUrlRoot}/M6A/merge.RIP.Muscle.bs10.bw`,
        'Lung': `${bigWigUrlRoot}/M6A/merge.RIP.Lung.bs10.bw`
    },
    M6APeak: {
        'Brain_Cortex': `${bigWigUrlRoot}/M6A/Brain.ext75.merged.final_peak.bed`,
        'Heart_Left_Ventricle': `${bigWigUrlRoot}/M6A/Heart.ext75.merged.final_peak.bed`,
        'Muscle_Skeletal': `${bigWigUrlRoot}/M6A/Muscle.ext75.merged.final_peak.bed`,
        'Lung': `${bigWigUrlRoot}/M6A/Lung.ext75.merged.final_peak.bed`
    },
    RNASeqCoverage: {
        'Adipose_Subcutaneous': 'GTEX-WFG8-2326-SM-DIPDX',
        'Adipose_Visceral_Omentum': 'GTEX-14BMU-0626-SM-5P9FJ',
        'Adrenal_Gland': 'GTEX-15UKP-0826-SM-HAV2U',
        'Artery_Aorta': 'GTEX-WYVS-0426-SM-4ONDL',
        'Artery_Coronary': 'GTEX-1GMR3-0626-SM-9WYT3',
        'Artery_Tibial': 'GTEX-1HCVE-0526-SM-ACKW1',
        'Bladder': 'GTEX-OHPL-1926-SM-HAV18',
        'Brain_Amygdala': 'GTEX-WVLH-0011-R4A-SM-3MJFS',
        'Brain_Anterior_cingulate_cortex_BA24': 'GTEX-11DXY-0011-R3a-SM-GIN8U',
        'Brain_Caudate_basal_ganglia': 'GTEX-1O9I2-0011-R5a-SM-F648P',
        'Brain_Cerebellar_Hemisphere': 'GTEX-13X6J-0011-R11a-SM-5P9HE',
        'Brain_Cerebellum': 'GTEX-1F6IF-3026-SM-7P8QN',
        'Brain_Cortex': 'GTEX-1HCVE-3026-SM-CL54G',
        'Brain_Frontal_Cortex_BA9': 'GTEX-13VXU-0011-R10a-SM-H65ZW',
        'Brain_Hippocampus': 'GTEX-15RJE-0011-R1b-SM-H6Q7N',
        'Brain_Hypothalamus': 'GTEX-13G51-0011-R8b-SM-5LZZ4',
        'Brain_Nucleus_accumbens_basal_ganglia': 'GTEX-14BIN-0011-R6a-SM-5S2RH',
        'Brain_Putamen_basal_ganglia': 'GTEX-1HT8W-0011-R7b-SM-CMKH8',
        'Brain_Spinal_cord_cervical_c-1': 'GTEX-14ABY-0011-R9a-SM-5PNZK',
        'Brain_Substantia_nigra': 'GTEX-1PIIG-0011-R2b-SM-F648W',
        'Breast_Mammary_Tissue': 'GTEX-1I6K7-1026-SM-A9SLK',
        'Cells_Cultured_fibroblasts': 'GTEX-12126-0008-SM-5S2UC',
        'Cells_EBV-transformed_lymphocytes': 'GTEX-12KS4-0001-SM-I9BPS',
        'Cervix_Ectocervix': 'GTEX-PX3G-2226-SM-GOQZQ',
        'Cervix_Endocervix': 'GTEX-OHPK-2226-SM-HL9TP',
        'Colon_Sigmoid': 'GTEX-1KXAM-1926-SM-D3LAG',
        'Colon_Transverse': 'GTEX-15SDE-1026-SM-GRR2N',
        'Esophagus_Gastroesophageal_Junction': 'GTEX-1R9JW-1726-SM-F648E',
        'Esophagus_Mucosa': 'GTEX-1LGRB-1626-SM-CNNR7',
        'Esophagus_Muscularis': 'GTEX-1KXAM-0626-SM-DHXKH',
        'Fallopian_Tube': 'GTEX-POMQ-1326-SM-HAUZO',
        'Heart_Atrial_Appendage': 'GTEX-1GN1W-0226-SM-7P8QY',
        'Heart_Left_Ventricle': 'GTEX-1IDJU-0726-SM-CNPQ9',
        'Kidney_Cortex': 'GTEX-QLQW-1626-SM-CMKFE',
        'Kidney_Medulla': 'GTEX-R55E-2026-SM-EZ6L1',
        'Liver': 'GTEX-1LGRB-0626-SM-CNNQH',
        'Lung': 'GTEX-Y5V5-0826-SM-4VBQD',
        'Minor_Salivary_Gland': 'GTEX-1IDJC-1626-SM-AHZ2R',
        'Muscle_Skeletal': 'GTEX-15RIE-5021-SM-HL11W',
        'Nerve_Tibial': 'GTEX-13PVR-2626-SM-5PNXH',
        'Ovary': 'GTEX-1LVAM-1826-SM-DHXKR',
        'Pancreas': 'GTEX-1JKYN-0426-SM-CGQG2',
        'Pituitary': 'GTEX-14DAQ-3226-SM-664OI',
        'Prostate': 'GTEX-1KXAM-1126-SM-E9TJU',
        'Skin_Not_Sun_Exposed_Suprapubic': 'GTEX-1F5PL-0926-SM-7PC2W',
        'Skin_Sun_Exposed_Lower_leg': 'GTEX-15EU6-0426-SM-6PAN5',
        'Small_Intestine_Terminal_Ileum': 'GTEX-178AV-1226-SM-GOR11',
        'Spleen': 'GTEX-1LVAN-1026-SM-CNNQZ',
        'Stomach': 'GTEX-XPT6-0726-SM-GRR2L',
        'Testis': 'GTEX-183FY-1926-SM-7KFRI',
        'Thyroid': 'GTEX-1KANC-0826-SM-D3LA8',
        'Uterus': 'GTEX-1MA7W-1526-SM-DHXKS',
        'Vagina': 'GTEX-1IDJU-1026-SM-AHZ2U',
        'Whole_Blood': 'GTEX-1OJC3-0006-SM-DKPP1'
    },
    WgbsVMR: {
        'hasVMR': [
            'Brain_Amygdala',
            'Brain_Anterior_cingulate_cortex_BA24',
            'Brain_Caudate_basal_ganglia',
            'Brain_Frontal_Cortex_BA9',
            'Brain_Hippocampus',
            'Brain_Hypothalamus',
            'Brain_Nucleus_accumbens_basal_ganglia',
            'Brain_Putamen_basal_ganglia',
            'HC1',
            'Lung',
            'Thyroid'
        ]
    }
});
