<template>
  <div class="container-fluid">
    <!-- eQTL violin plot modal root DIV -->
    <div :id="eqtlViolinDivId" />
    <h2>
      GTEx eQTL Calculator<img src="@/assets/images/GTEx-test-your-own-icon.svg" width="40px" style="margin: 0px 0px 0px 10px;">
    </h2>
    <div class="row">
      <div class="col-sm-12 col-md-5 col-lg-3">
        <ul>
          <li>Limited to 400 entries.</li>
          <li>There must be only one entry per line.</li>
          <li>
            Each entry must be of the form: <span style="font-variant: all-small-caps; font-weight: 600; font-size: 16px;">Variant ID,Gene ID,Tissue name. </span><br>
            <div style="font-size: 12px; margin-top: 7px;">
              <span style="font-variant:all-small-caps; font-weight: 600; font-size: 14px;">Variant ID</span>: GTEx variant ID or dbSNP rs number.<br>
              <span style="font-variant:all-small-caps; font-weight: 600; font-size: 14px;">Gene ID</span>: Gencode ID, Ensembl ID, or HGNC Gene Symbol.<br>
              <span style="font-variant:all-small-caps; font-weight: 600; font-size: 14px;">Tissue name</span> must use <a @click="showTissueNamesDialog">allowable tissue names</a>.<br>
            </div>
          </li>
        </ul>

        <div><a @click="showTestExample">An Example</a></div>
      </div>

      <div id="testYourOwnBatchDiv" class="col-sm-12 col-md-7 col-lg-9">
        <div class="subEqtlSearchMenu col-sm-12" style="padding:0;">
          <div id="tissueNamesDialog" title="Allowable Tissue Names" />
          <form id="testYourOwnBatch" action="">
            <div class="form-group">
              <label>Enter a comma-separated list containing SNP ID, Gene ID, and tissue name, e.g.: rs509556,ENSG00000248746.1,Muscle_Skeletal</label>
              <div class="row">
                <div class="col-xs-8">
                  <textarea id="testYourOwnBatchText" class="form-control" rows="6" placeholder="" />
                </div>
                <button id="testYourOwnBatchButton" class="btn btn-md btn-primary">
                  Calculate Your Own
                </button>
              </div>
            </div>
          </form>
          <div class="col-xs-12">
            <span id="batchTestMessage"><!--what uses this span tag?--></span><br>
          </div>
          <br><br>
        </div>
      </div>
      <div class="col-sm-12">
        <div id="eqtlResultDiv" />
      </div><!-- eQTL box plot goes here -->
      <div id="testYourOwnBatchResultDiv" class="col-sm-12">
        <div id="testYourOwnBatchResultHeaderDiv" />
        <div id="testYourOwnDisclaimer" style="display:inline;">
          <p>
            Note: NES are the effect sizes in a normalized space where <span style="font-variant:all-small-caps; font-weight:600; font-size:16px;">magnitude has no direct biological
              interpretation</span>. Allelic fold-change (aFC) is available for the top variant in the <router-link to="/eqtls/tissue?tissueName=All">
              egene table
            </router-link>.
          </p>
        </div>
        <table id="testYourOwnBatchTable" />
      </div>
    </div>
    <br><br>
  </div>
</template>
<script>
import router from '@/router/router';
import { portalClient, directory, generateSnpUrl } from '@/utils/portal';
import MessageHandler from '@/utils/message-handler';
import EqtlTableMaker from '@/utils/eqtl-table-maker';
import EqtlsPage from '@/components/EqtlsPage';
import TableUtils from '@/utils/table-utils';
import { getAllTissueParameters, validateTissueParameter } from '@/utils/tissue';

export default {
    data: function() {
        return {
            tableMaker: new EqtlTableMaker(),
            eqtlViolinDivId: 'eqtl-violin-modal'
        };
    },
    mounted: function() {
        this.initialize();
    },
    methods: {
        initialize() {
            EqtlsPage.methods.createDialogForQtlViolinPlots(this.eqtlViolinDivId, 'eQTL Violin Plots');
            this.populateTissueParameters();
            this.fetchEvents();
            $('#testYourOwnBatchResultHeaderDiv').hide();
            $('#testYourOwnDisclaimer').hide();
        },
        populateTissueParameters() {
            let allTissueParameters = getAllTissueParameters();

            // Temporary solution to remove tissues without covariates
            const invalidTissues = new Set(['Bladder', 'Cervix_Ectocervix',
                'Cervix_Endocervix', 'Fallopian_Tube', 'Kidney_Cortex']);
            allTissueParameters = allTissueParameters.filter(
                tissue => !invalidTissues.has(tissue));

            allTissueParameters.sort();
            const length = allTissueParameters.length;
            const half = Math.floor(length / 2);
            const firstHalfParams = allTissueParameters.slice(0, half);
            const secondHalfParams = allTissueParameters.slice(half + 1);
            let html = '<div class="subDialogColumn">';
            $.each(firstHalfParams, (index, parameter) => {
                html += parameter + '<br>';
            });

            html += '</div><div class="subDialogColumn">';
            $.each(secondHalfParams, (index, parameter) => {
                html += parameter + '<br>';
            });
            html += '</div>';

            $('#tissueNamesDialog').html(html);
            $('#tissueNamesDialog').dialog({ autoOpen: false, modal: true, width: '700' });
        },
        showTissueNamesDialog() {
            $('#tissueNamesDialog').dialog('open');
        },
        showTestExample() {
            const example = 'rs509556,ENSG00000248746.6,Muscle_Skeletal\nrs509556,ENSG00000189339.12,Whole_Blood\nrs509556,ALAD,Liver';
            $('#testYourOwnBatchText').val(example);
        },
        fetchEvents() {
            this.batchSubmitButton();
        },
        batchSubmitButton() {
            const parent = this;
            $('#testYourOwnBatchButton').click(event => {
                event.preventDefault();
                return parent.batchSubmit();
            });
        },
        batchSubmit() {
            this.clearBatchTestMessage();
            const userInput = $('#testYourOwnBatchText').val();
            let entries;
            try {
                entries = this.parseFromInput(userInput);
            } catch (err) {
                this.showBatchTestMessage(err);
                return;
            }
            const jsonString = JSON.stringify(entries);
            const parent = this;
            const dynUrl = directory.getEqtlBoxplotUrl().url + "?datasetId=gtex_v10";
            $('#spinner').show();
            fetch(dynUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(entries)
            }).then(response => response.json()).then(
                response => {
                    $('#eqtlResultDiv').html('');
                    const data = response.data;
                    parent.batchResults = data;
                    parent.generateBatchResultTable();
                    parent.showBatchResultHeader();
                    $('#spinner').hide();
                }).catch(error => {
                portalClient.messageHandler.showJqXHRInternalServerError('Unable to retrieve batch results',
                    error);
                $('#spinner').hide();
            });
        },
        showBatchTestMessage(message) {
            $('#batchTestMessage').html(message);
        },
        clearBatchTestMessage() {
            $('#batchTestMessage').html('');
        },
        parseFromInput(userInput) {
            const lines = userInput.split('\n');
            if (lines.length > 400) {
                throw 'You can only enter 400 entries';
            }
            const entries = [];
            for (let i = 0; i < lines.length; i++) {
                const line = lines[i].trim();
                if (!line) {
                    continue;
                }
                const tokens = line.split(',');
                const entry = {
                    variantId: tokens[0].trim(),
                    gencodeId: tokens[1].trim().toUpperCase(),
                    tissueSiteDetailId: validateTissueParameter(tokens[2].trim())
                };
                entries.push(entry);
            }
            return entries;
        },
        showBatchResultHeader() {
            const batchResultHeaderDiv = $('#testYourOwnBatchResultHeaderDiv');
            const html = '<h4>Test Your Own SNP Gene Associations Results</h4>'
                   + portalClient.versionInfo.getMarkup('testYourOwn');
            batchResultHeaderDiv.html(html);
            batchResultHeaderDiv.show();
            $('#testYourOwnDisclaimer').show();
        },
        generateBatchResultTable() {
            const nesInfo = '<div class="gtooltip fas fa-info-circle "' +
                      'data-toggle="tooltip" data-html="true" ' +
                      'data-placement="auto" data-container="body" ' +
                      'title="Normalized Effect Size"' + '>'+ '</div>';
            const columnHeadings = ['Gene Symbol', 'Gencode Id', 'Variant Id', 'SNP', 'SNP', 'P-Value', 'P-Value', 'P-Value Threshold',
                'P-Value Threshold', 'NES', nesInfo + ' NES', 'T-statistic', 'T-statistic', 'Tissue', 'Actions'];
            const oTable = $('#testYourOwnBatchTable');
            const tableElt = oTable[0];
            TableUtils.buildTableHtml(tableElt, columnHeadings);

            const buttonProps = {
                'buttons': [{
                    extend: 'copy',
                    exportOptions: { columns: [0, 1, 2, 3, 6, 8, 10, 12, 13, 14] }
                },
                {
                    extend: 'csv',
                    exportOptions: { columns: [0, 1, 2, 3, 6, 8, 10, 12, 13, 14] }
                }]
            };

            // this is to prevent DataTables from putting warnings or errors in an alert box.
            $.fn.dataTableExt.sErrMode = 'throw';
            const parent = this;

            const columnProps = [
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return EqtlsPage.methods.generateGeneExpressUrl(row.gencodeId, row.geneSymbol);
                    }
                },
                { data: 'gencodeId' },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return generateSnpUrl(row.variantId);
                    }
                },
                { data: 'snpId', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return generateSnpUrl(row.snpId);
                    }
                },
                { data: 'pValue', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.pValue).toPrecision(2);
                    }
                },
                { data: 'pValueThreshold', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        if (null === row.pValueThreshold) return 'Not an eGene';
                        return Number(row.pValueThreshold).toPrecision(2);
                    }
                },
                { data: 'nes', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.nes).toPrecision(2);
                    }
                },
                { data: 'tStatistic', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(dat, type, row) {
                        return Number(row.tStatistic).toPrecision(2);
                    }
                },
                {
                    defaultContent: '',
                    'render': function(data, type, row) {
                        return parent.tableMaker.generateTissueUrl(row.tissueSiteDetailId);
                    }
                },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return EqtlsPage.methods.generateEqtlPlotUrl(row.gencodeId, row.variantId, row.tissueSiteDetailId, row.geneSymbol, parent.eqtlViolinDivId);
                    }
                }
            ];

            try {
                if ($.fn.dataTable.isDataTable(oTable)) {
                    const t = oTable.DataTable();
                    t.destroy();
                }

                oTable.dataTable({
                    jQueryUI: true,
                    deferRender: true,
                    processing: true,
                    paginationType: 'full_numbers',
                    data: parent.batchResults,
                    columns: columnProps,
                    orderClasses: false,
                    dom: 'B<"clear">lfrtip',
                    buttons: buttonProps,
                    language: {
                        'sEmptyTable': 'Please wait - Fetching records'
                    },

                    fnInitComplete: function(oSettings) {
                        oSettings.oLanguage.sEmptyTable = 'No results were found';
                        $(oTable).find('.dataTables_empty').html('No results were found');
                    }
                });
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err + ' Unable to create table');
            }
            oTable.addClass('display');
        }
    }
};
</script>
