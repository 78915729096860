import { directory, clientPortal, initialUrl, generateSnpUrl, generateGeneUrl } from '@/utils/portal';
import EqtlTableMaker from '@/utils/eqtl-table-maker';
import TableUtils from '@/utils/table-utils';
import MessageHandler from '@/utils/message-handler';
import { getTissueDisplayName } from '@/utils/tissue';
import details_open_path from '@/assets/images/details_open.png';
import details_close_path from '@/assets/images/details_close.png';

const QtlTableMaker = {
    data: function() {
        return { sGeneTableOpenRow: null };
    },
    methods: {
    /**
     * Creates sQTL table
     * @param  {JSON} sqtlJson - sQTL data return from API.
     * @param  {String} tableDivSelector - e.g. '#tableSelector'
     * @param  {String} violinParentDivId - ID of dialog element's parent to render the violin plot
     * @param {Boolean} includeExportButtons - flag for including export buttons
     * @return None
     */
        generateSqtlTable(sqtlJson, tableDivSelector, violinParentDivId, includeExportButtons=true) {
            const tableMaker = new EqtlTableMaker();
            const sqtls = sqtlJson;
            const objectHeadings = ['Gencode Id', 'Gene Symbol', 'Variant Id', 'SNP Id', 'SNP', 'Phenotype Id', 'Intron Id', 'P-Value', 'P-Value', 'NES', 'NES', 'Tissue', 'Actions'];
            const oTable = $(tableDivSelector);
            const tableElt = oTable[0];
            TableUtils.buildTableHtml(tableElt, objectHeadings);
            const buttonProps = {
                'buttons': [{
                    extend: 'copy',
                    exportOptions: { columns: [0, 1, 2, 3, 5, 6, 8, 10, 11] }
                },
                {
                    extend: 'csv',
                    exportOptions: { columns: [0, 1, 2, 3, 5, 6, 8, 10, 11] }
                }]
            };
            const columnProps = [
                { data: 'gencodeId' },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return generateGeneUrl(row.gencodeId, row.geneSymbol);
                    }
                },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return generateSnpUrl(row.variantId);
                    }
                },
                { data: 'snpId', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return generateSnpUrl(row.snpId);
                    }
                },
                { data: 'phenotypeId', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        const phenotypeId = row.phenotypeId;
                        return phenotypeId.replace(row.chromosome + ':', '').replace(':' + row.gencodeId, '');
                    }
                },
                { data: 'pValue', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.pValue).toPrecision(2);
                    }
                },
                { data: 'nes', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.nes).toPrecision(2);
                    }
                },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return tableMaker.generateTissueUrlFromGenePage(row.gencodeId, row.tissueSiteDetailId);
                    }
                },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return QtlTableMaker.methods.generateSqtlViolinPlotUrl(violinParentDivId + '-dialog', row.phenotypeId, row.snpId, row.variantId, row.tissueSiteDetailId, row.geneSymbol);
                    }
                }
            ];

            $.fn.dataTableExt.sErrMode = 'throw';
            if ($.fn.dataTable.isDataTable(oTable)) {
                const t = oTable.DataTable();
                t.destroy();
            }

            try {
                oTable.dataTable({
                    deferRender: true,
                    destroy: true,
                    retrieve: true,
                    jQueryUI: true,
                    processing: true,
                    paginationType: 'full_numbers',
                    data: sqtls,
                    columns: columnProps,
                    dom: 'B<"clear">lfrtip',
                    orderClasses: false,
                    buttons: includeExportButtons?buttonProps:{ buttons: [] },
                    language: {
                        emptyTable: 'Please wait - Fetching records'
                    },

                    fnInitComplete: function(oSettings) {
                        oSettings.oLanguage.sEmptyTable = 'No sQTL data found.';
                        $(oTable).find('.dataTables_empty').html('No sQTL data found.');
                    }
                });
                oTable.addClass('display');
                oTable.show();
                oTable.fnSort([[7, 'asc']]);
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err + ' Unable to create table for sQTLs');
            }
        },
        generateSGeneTable(tissueSiteDetailId, subTableId, violinParentDivId) {
            const component = this;
            const tissueName = getTissueDisplayName(tissueSiteDetailId);
            const eqtlTableMaker = new EqtlTableMaker();
            const objectHeadings = ['', 'Gencode Id', 'Gene Symbol', 'PhenotypeId', 'Nominal P-Value', 'Empirical P-Value', 'Q-Value', 'nPhenotypes', 'Tissue', 'Actions'];
            const oTable = $(`#${this.sGeneTableId}`);

            const tableElt = oTable[0];
            TableUtils.buildTableHtml(tableElt, objectHeadings);

            const buttonProps = {
                'buttons': [{
                    extend: 'copy',
                    exportOptions: { columns: [1, 2, 3, 4, 5, 6, 7, 8] }
                },
                {
                    extend: 'csv',
                    exportOptions: { columns: [1, 2, 3, 4, 5, 6, 7, 8] }
                }]
            };

            // this is to prevent DataTables from putting warnings or errors in an alert box.
            $.fn.dataTableExt.sErrMode = 'throw';
            // filter the egenes by tissue
            const sGeneUrlSuffix = tissueSiteDetailId=='All' ? '?' : `?tissueSiteDetailId=${tissueSiteDetailId}`;
            const sgeneUrl = directory.getSGeneUrl().url + sGeneUrlSuffix;

            const columnProps = [
                {
                    name: 'icon', orderable: false, searchable: false,
                    defaultContent: '', // open-close icon
                    render: function(data, type, row, meta) {
                        const html = '<img class="expandCollapse" src="' + details_open_path + '">';
                        return html;
                    }
                },
                { name: 'gencodeId', data: 'gencodeId' },
                {
                    name: 'geneSymbol', defaultContent: '',
                    render: function(data, type, row, meta) {
                        return component.generateGeneExpressUrl(row.gencodeId, row.geneSymbol);
                    }
                },
                { name: 'phenotypeId', data: 'phenotypeId' },
                { name: 'pValue', data: 'pValue', type: 'scientific' },
                { name: 'empiricalPValue', data: 'empiricalPValue', type: 'scientific' },
                { name: 'qValue', data: 'qValue', type: 'scientific' }, // Q-value
                { name: 'nPhenotypes', data: 'nPhenotypes' },
                {
                    name: 'tissueSiteDetailId', defaultContent: '',
                    render: function(data, type, row) {
                        return eqtlTableMaker.generateTissueUrl(row.tissueSiteDetailId);
                    }
                },
                {
                    name: 'actions', searchable: false, orderable: false, defaultContent: '',
                    'render': function(data, type, row, meta) {
                        return component.generateBrowserUrl(row.gencodeId, tissueSiteDetailId) + ', ' + component.generateLocusBrowserUrl(row.gencodeId);
                    }
                }
            ];

            try {
                if ($.fn.dataTable.isDataTable(oTable)) {
                    const t = oTable.DataTable();
                    t.destroy();
                }

                oTable.dataTable({
                    serverSide: true,
                    paging: true,
                    pageLength: 10,
                    ajax: {
                        url: sgeneUrl,
                        data: function(d) {
                            const sort_column_index = d.order[0].column;
                            const direction = d.order[0].dir;
                            const sort_column_name = d.columns[sort_column_index].name;
                            if (sort_column_name != 'icon') { d.sortBy = sort_column_name; }
                            if (direction == 'asc') {
                                d.sortDirection = 1;
                            } else if (direction == 'desc') {
                                d.sortDirection = -1;
                            }
                            d.sortDirection = direction;
                            const search_term = d.search.value;
                            if (search_term) { d.searchTerm = search_term; }
                            if ('start' in d) {
                                d.page = d.start / d.length;
                                d.start = null;
                            }
                            if ('length' in d) {
                                d.pageSize = d.length;
                                d.length = null;
                            }
                            d.order = null;
                            d.columns = null;
                            d.search = null;
                        },
                        dataSrc: 'sgene'
                    },
                    jQueryUI: true,
                    deferRender: true,
                    processing: true,
                    paginationType: 'full_numbers',
                    columns: columnProps,
                    dom: 'B<"clear">lfrtip',
                    orderClasses: false,
                    buttons: buttonProps,
                    language: {
                        'sEmptyTable': 'Please wait - Fetching records'
                    },

                    fnInitComplete: function(oSettings) {
                        oSettings.oLanguage.sEmptyTable = 'No significant eQTLs were found for tissue ' +
                            tissueName;
                        $(oTable).find('.dataTables_empty').html('No significant eQTLs were found for tissue ' +
                                tissueName);
                    }
                });
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err + ' Unable to create table for tissue: '
                    + tissueName);
            }

            // this is required for row_selected styles to work.
            oTable.addClass('display');
            oTable.show(); // in case it was hidden
            //oTable.fnSort([ [3, 'asc'] ]);
            $(`#${this.sGeneTableId} tbody`).on('click', 'td img.expandCollapse', function() {
                const row = this.parentElement.parentElement;
                const collapseExpandCell = this;
                if (oTable.fnIsOpen(row)) {
                    /* This row is already open - close it */
                    collapseExpandCell.src = details_open_path;
                    oTable.fnClose(row);
                    component.sGeneTableOpenRow = null;
                } else {
                    if (component.sGeneTableOpenRow != null) {
                        /* only one row open at a time */
                        component.sGeneTableOpenRow.firstChild.firstChild.src = details_open_path;
                        oTable.fnClose(component.sGeneTableOpenRow);
                        component.sGeneTableOpenRow = null;
                    }

                    /* Open this row */
                    collapseExpandCell.src = details_close_path;
                    const data = oTable.fnGetData(row);
                    const selectedGencodeId = data.gencodeId;
                    const tissueSiteDetailId = data.tissueSiteDetailId;
                    const tissueSiteDetail = getTissueDisplayName(tissueSiteDetailId);
                    const html = component.buildSQtlTableHtml(subTableId);
                    oTable.fnOpen(row, html, 'detailrow');
                    component.sGeneTableOpenRow = row;
                    component.openSGeneSubTable(selectedGencodeId, tissueSiteDetailId, subTableId, violinParentDivId);
                }
            });
            return oTable;
        },
        openSGeneSubTable(gencodeId, tissueId, subTableId, violinParentDivId) {
            const component = this;
            const url = `${directory.getSingleTissueSqtlUrl().url}?gencodeId=${gencodeId}&tissueSiteDetailId=${tissueId}`;
            $.ajax({
                url: url, type: 'GET', datatype: 'json',
                success: function(data) {
                    component.generateSqtlTable(data, `#${subTableId}`, violinParentDivId, false);
                },
                error: function(response) {
                    const messageHandler = new MessageHandler();
                    messageHandler.showError('Error: Cannot retrieve sQTL table ' + response.responseText);
                }
            });
        },
        buildSQtlTableHtml(tableId) {
            const tableHeaders = ['Gencode Id', 'Gene Symbol', 'Variant Id', 'SNP Id', 'SNP', 'Phenotype Id', 'P-Value', 'P-Value', 'NES', 'NES', 'Tissue', 'Actions'];
            let html = `<table id="${tableId}"><thead><tr>`;
            tableHeaders.forEach(x => html += `<th>${x}</th>`);
            html += '</tr></thead><tbody></tbody></table>';
            return html;
        },
        generateSqtlViolinPlotUrl(violinParentDivId, featureId, snpId, variantId, tissueSiteDetailId, geneSymbol) {
            const jsUrl = `<a href="javascript:gtex.plotSqtlViolinPlot('${violinParentDivId}', '${featureId}', '${variantId}', '${tissueSiteDetailId}', '${geneSymbol}')">sQTL violin plot</a>`;
            return jsUrl;
        },
        generateIEqtlTable(ieqtlJson, tableDivSelector, scatterDivId, includeExportButtons=true) {
            const component = this;
            const tableMaker = new EqtlTableMaker();

            const ieqtls = ieqtlJson===undefined?ieqtlJson:ieqtlJson.filter(d => d.pValueAdjustedBH<=0.05);

            if (!ieqtls) {
                // this is a hack to deal with a bug causing gene page to be loaded twice.
                return;
            }

            const objectHeadings = [
                'Gencode Id',
                'Gene Symbol',
                'Variant Id',
                'SNP Id',
                'SNP',
                'bG',
                'bG',
                'bGI',
                'bGI',
                'bI',
                'bI',
                'MAF',
                'MAF',
                'P-Val Adjust. BH',
                'P-Val Adjust. BH',
                'P-Val G',
                'P-Val G',
                'P-Val I',
                'P-Val I',
                'P-Val GI',
                'P-Val GI',
                'Tissue',
                'Tissue Cell Type',
                'TSS Distance',
                'Action'
            ];
            const oTable = $(tableDivSelector);
            const tableElt = oTable[0];
            TableUtils.buildTableHtml(tableElt, objectHeadings);
            const buttonProps = {
                'buttons': [
                    {
                        extend: 'copy',
                        exportOptions: { columns: [0, 1, 2, 3, 5, 6, 8, 10] }
                    },
                    {
                        extend: 'csv',
                        exportOptions: { columns: [0, 1, 2, 3, 5, 6, 8, 10] }
                    }
                ]
            };
            const columnProps = [
                { data: 'gencodeId' }, // gencode id
                { // gene symbol
                    defaultContent: '',
                    render: function(data, type, row) {
                        return generateGeneUrl(row.gencodeId, row.geneSymbol);
                    }
                },
                { // variant id
                    defaultContent: '',
                    render: function(data, type, row) {
                        return generateSnpUrl(row.variantId);
                    }
                },
                { data: 'snpId', visible: false, searchable: false }, // snp id
                { // link for snp id
                    defaultContent: '',
                    render: function(data, type, row) {
                        return generateSnpUrl(row.snpId);
                    }
                },
                { data: 'bG', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.bG).toPrecision(2);
                    }
                },
                { data: 'bGI', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.bGI).toPrecision(2);
                    }
                },
                { data: 'bI', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.bI).toPrecision(2);
                    }
                },
                { data: 'maf', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.maf).toPrecision(2);
                    }
                },
                { data: 'pValueAdjustedBH', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.pValueAdjustedBH).toPrecision(3);
                    }
                },
                { data: 'pValueG', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.pValueG).toPrecision(2);
                    }
                },
                { data: 'pValueI', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.pValueI).toPrecision(2);
                    }
                },
                { data: 'pValueGI', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.pValueGI).toPrecision(2);
                    }
                },
                { // tissue site detail id
                    defaultContent: '',
                    render: function(data, type, row) {
                        return tableMaker.generateTissueUrlFromGenePage(row.gencodeId, row.tissueSiteDetailId);
                    }
                },
                { data: 'tissueCellType' },
                { data: 'tssDistance' },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        // for ieQTL
                        return component.generateInteractionScatterplotUrl(scatterDivId, row.gencodeId, row.variantId, row.tissueSiteDetailId, row.tissueCellType, 'ieQTL');
                    }
                }
            ];

            $.fn.dataTableExt.sErrMode = 'throw';
            if ($.fn.dataTable.isDataTable(oTable)) {
                const t = oTable.DataTable();
                t.destroy();
            }
            try {
                oTable.dataTable({
                    deferRender: true,
                    destroy: true,
                    retrieve: true,
                    jQueryUI: true,
                    processing: true,
                    paginationType: 'full_numbers',
                    data: ieqtls,
                    columns: columnProps,
                    dom: 'B<"clear">lfrtip',
                    orderClasses: false,
                    buttons: includeExportButtons?buttonProps:{ buttons: [] },
                    language: {
                        emptyTable: 'Please wait - Fetching records'
                    },

                    fnInitComplete: function(oSettings) {
                        oSettings.oLanguage.sEmptyTable = 'No ieQTL data found.';
                        $(oTable).find('.dataTables_empty').html('No ieQTL data found.');
                    }
                });
                oTable.addClass('display');
                oTable.show();
                oTable.fnSort([[7, 'asc']]);
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err + ' Unable to create table for ieQTLs');
            }
        },
        generateISqtlTable(isqtlJson, tableDivSelector, scatterDivId, includeExportButtons=true) {
            // ToDo: refactor data table generating code
            const component = this;
            const tableMaker = new EqtlTableMaker();

            const isqtls = isqtlJson===undefined?isqtlJson:isqtlJson.filter(d => d.pValueAdjustedBH<=0.05);
            if (!isqtls) {
                // this is a hack to get around bug that is causing gene page to get loaded twice.
                return;
            }
            const objectHeadings = [
                'Gencode Id',
                'Gene Symbol',
                'Variant Id',
                'SNP Id',
                'SNP',
                'Phenotype Id',
                'Intron Id',
                'bG',
                'bG',
                'bGI',
                'bGI',
                'bI',
                'bI',
                'MAF',
                'MAF',
                'P-Val Adjust. BH',
                'P-Val Adjust. BH',
                'P-Val G',
                'P-Val G',
                'P-Val I',
                'P-Val I',
                'P-Val GI',
                'P-Val GI',
                'Tissue',
                'Tissue Cell Type',
                'TSS Distance',
                'Action'
            ];
            const oTable = $(tableDivSelector);
            const tableElt = oTable[0];
            TableUtils.buildTableHtml(tableElt, objectHeadings);
            const buttonProps = {
                'buttons': [
                    {
                        extend: 'copy',
                        exportOptions: { columns: [0, 1, 2, 3, 5, 6, 8, 10] }
                    },
                    {
                        extend: 'csv',
                        exportOptions: { columns: [0, 1, 2, 3, 5, 6, 8, 10] }
                    }
                ]
            };
            const columnProps = [
                { data: 'gencodeId' }, // gencode id
                { // gene symbol
                    defaultContent: '',
                    render: function(data, type, row) {
                        return generateGeneUrl(row.gencodeId, row.geneSymbol);
                    }
                },
                { // variant id
                    defaultContent: '',
                    render: function(data, type, row) {
                        return generateSnpUrl(row.variantId);
                    }
                },
                { data: 'snpId', visible: false, searchable: false }, // snp id
                { // link for snp id
                    defaultContent: '',
                    render: function(data, type, row) {
                        return generateSnpUrl(row.snpId);
                    }
                },
                { data: 'phenotypeId', visible: false, searchable: false }, // phenotype id
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        const phenotypeId = row.phenotypeId;
                        return phenotypeId.replace(row.chromosome + ':', '').replace(':' + row.gencodeId, '');
                    }
                },
                { data: 'bG', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.bG).toPrecision(2);
                    }
                },
                { data: 'bGI', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.bGI).toPrecision(2);
                    }
                },
                { data: 'bI', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.bI).toPrecision(2);
                    }
                },
                { data: 'maf', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.maf).toPrecision(2);
                    }
                },
                { data: 'pValueAdjustedBH', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.pValueAdjustedBH).toPrecision(3);
                    }
                },
                { data: 'pValueG', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.pValueG).toPrecision(2);
                    }
                },
                { data: 'pValueI', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.pValueI).toPrecision(2);
                    }
                },
                { data: 'pValueGI', visible: false, searchable: false },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Number(row.pValueGI).toPrecision(2);
                    }
                },
                { // tissue site detail id
                    defaultContent: '',
                    render: function(data, type, row) {
                        return tableMaker.generateTissueUrlFromGenePage(row.gencodeId, row.tissueSiteDetailId);
                    }
                },
                { data: 'tissueCellType' },
                { data: 'tssDistance' },
                {
                    defaultContent: '',
                    render: function(data, type, row) {
                        // for isQTL
                        return component.generateInteractionScatterplotUrl(scatterDivId, row.phenotypeId, row.variantId, row.tissueSiteDetailId, row.tissueCellType, 'isQTL');
                    }
                }
            ];

            $.fn.dataTableExt.sErrMode = 'throw';
            if ($.fn.dataTable.isDataTable(oTable)) {
                const t = oTable.DataTable();
                t.destroy();
            }
            try {
                oTable.dataTable({
                    deferRender: true,
                    destroy: true,
                    retrieve: true,
                    jQueryUI: true,
                    processing: true,
                    paginationType: 'full_numbers',
                    data: isqtls,
                    columns: columnProps,
                    dom: 'B<"clear">lfrtip',
                    orderClasses: false,
                    buttons: includeExportButtons?buttonProps:{ buttons: [] },
                    language: {
                        emptyTable: 'Please wait - Fetching records'
                    },

                    fnInitComplete: function(oSettings) {
                        oSettings.oLanguage.sEmptyTable = 'No isQTL data found.';
                        $(oTable).find('.dataTables_empty').html('No isQTL data found.');
                    }
                });
                oTable.addClass('display');
                oTable.show();
                oTable.fnSort([[7, 'asc']]);
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err + ' Unable to create table for isQTLs');
            }
        },
        generateInteractionScatterplotUrl(scatterDialogParentId, entryId, variantId, tissueSiteDetailId, tissueCellType, plotType) {
            const HTML = `<a href="javascript:gtex.plotIQtlPlot('${scatterDialogParentId + '-dialog'}', '${entryId}', '${variantId}', '${tissueSiteDetailId}', '${tissueCellType}', '${plotType}')">Scatterplot</a>`;
            return HTML;
        }
    }
};

function plotSqtlViolinPlot(dialogDivId, phenotypeId, snpId, tissueName, geneSymbol) {
    $(`#${dialogDivId}`).dialog('open'); // open the jQuery UI dialog

    // create the plot DIV
    const plot = $('<div/>')
        .attr('class', 'bMap-dialog')
        .css('float', 'left')
        .css('margin', '20px')
        .appendTo($(`#${dialogDivId}-content`));

    // add a header section
    const head = $('<div/>').appendTo(plot);

    // add a window-close icon in the header
    $('<i/>').attr('class', 'fa fa-window-close')
        .css('margin-right', '2px')
        .click(() => {
            plot.remove();
        })
        .appendTo(head);

    // add the plot title
    const splitPhenotypeId = phenotypeId.split(':'); // e.g. chr1:1054551:1054824:clu_55395:ENSG00000188157.14
    const spliceId = `${splitPhenotypeId[0]}:${splitPhenotypeId[1]}:${splitPhenotypeId[2]}:${splitPhenotypeId[3]}`;
    $('<div/>')
        .attr('class', 'title')
        .css('text-align', 'center')
        .html(`<br/>${geneSymbol}<br/>${spliceId}<br/>${snpId}<br/>${getTissueDisplayName(tissueName)}`)
        .appendTo(head);

    // add the violin plot
    const id = 'dEqtl' + Date.now().toString(); // random ID generator
    $('<div/>').attr('id', id).appendTo(plot);

    const vConfig = {
        id: id,
        data: undefined, // this would be assigned by the eqtl violin function
        width: 250,
        height: 200,
        margin: { left: 50, right: 20, top: 20, bottom: 70 },
        showDivider: false,
        xAxis: { show: false, angle: 0, paddingInner: 0.01, paddingOuter: 0.01, textAnchor: 'start', adjustHeight: 0, showLabels: false, showTicks: false },
        yAxis: {
            label: 'Norm. Intron-Excision Ratio'
        },
        sizeAxis: {
            show: true
        },
        showWhisker: false,
        showLegend: false,
        showSampleSize: true,
        vColor: '#a4dced'
    };
    const urls =
        {
            dynsqtl: directory.getDynSqtlUrl().url,
            geneId: directory.getGeneUrl().url + '?geneId=',
            tissue: directory.getTissueInfoUrl().url
        };
    const qtlType = 'sqtl';
    QTLViolinPlot.render(vConfig, phenotypeId, snpId, tissueName, snpId, qtlType, urls);
}

function plotIQtlPlot(dialogDivId, entryId, variantId, tissueSiteDetailId, tissueCellType, plotType='ieQTL') {
    const url = plotType=='ieQTL'?`${directory.getDynIEqtlUrl().url}?gencodeId=${entryId}&variantId=${variantId}&tissueSiteDetailId=${tissueSiteDetailId}&cellType=${tissueCellType}`:`${directory.getDynISqtlUrl().url}?phenotypeId=${entryId}&variantId=${variantId}&tissueSiteDetailId=${tissueSiteDetailId}&cellType=${tissueCellType}`;
    const refAllele = variantId.split('_')[2];
    const altAllele = variantId.split('_')[3];
    const config={
        width: 500,
        height: 500,
        margin: { top: 50, right: 100, bottom: 75, left: 75 },
        yLabel: `${entryId} normalized expression`,
        xLabel: `${tissueCellType} enrichment score`,
        title: variantId,
        colorLabels: [`${refAllele}/${refAllele}`, `${refAllele}/${altAllele}`, `${altAllele}/${altAllele}`]
    };
    $(`#${dialogDivId}`).dialog('open'); // open the jQuery UI dialog
    IQTLScatterPlot.render(url, `${dialogDivId}-content`, config);
}

window.gtex = window.gtex ? window.gtex : {};
window.gtex.plotSqtlViolinPlot = plotSqtlViolinPlot;
window.gtex.plotIQtlPlot = plotIQtlPlot;

export default QtlTableMaker;
