export default {
    downloadSVGByObject: function(svgObj, downloadFileName, tempDownloadDivId) {
        // error-checking
        if ($('#' + tempDownloadDivId).length == 0) {
            throw 'ID not found: ' + tempDownloadDivId;
        }

        const $svgCopy = svgObj.clone()
            .attr('version', '1.1')
            .attr('xmlns', 'http://www.w3.org/2000/svg');

        // parse and add the CSS styling used by the SVG
        const styles = _parseCssStyles(svgObj.get());
        $svgCopy.prepend(styles);

        $('#' + tempDownloadDivId).html('').hide();
        const svgHtml = $('#' + tempDownloadDivId).append($svgCopy).html();

        const svgBlob = new Blob([svgHtml], { type: 'image/svg+xml' });
        saveAs(svgBlob, downloadFileName);

        // clear the temp download div
        $('#' + tempDownloadDivId).html('').hide();
    },

    downloadTSV: function(content, filename) {
        function dictToString(dict){
            if (dict === null){
                return ''
            }
            let str = ''
            for (const key in dict){
                if (dict[key]){
                    str += key + ", "
                }
            }
            return str.substring(0, str.length - 2)
        }
        const header = Object.keys(content[0])
        const tsv = [
            header.join('\t'), // header row first
          ...content.map(row => header.map(field => 
              typeof row[field]==='object' ? dictToString(row[field]) : `${row[field]}`
          ).join('\t'))
        ].join('\r\n')
        const blob = new Blob([tsv], { type: 'text/csv' });
        saveAs(blob, filename);
    }
};
const _parseCssStyles = function(dom) {
    let used = '';
    const sheets = document.styleSheets;

    for (let i = 0; i < sheets.length; i++) { // TODO: walk through this block of code
        try {
            if (sheets[i].cssRules == null) continue;
            const rules = sheets[i].cssRules;

            for (let j = 0; j < rules.length; j++) {
                const rule = rules[j];
                if (typeof(rule.style) != 'undefined') {
                    let elems;
                    //Some selectors won't work, and most of these don't matter.
                    try {
                        elems = $(dom).find(rule.selectorText);
                    } catch (e) {
                        elems = [];
                    }

                    if (elems.length > 0) {
                        used += rule.selectorText + ' { ' + rule.style.cssText + ' }\n';
                    }
                }
            }
        } catch (e) {
            /*
             * In Firefox, if stylesheet originates from a diff domain,
             * trying to access the cssRules will throw a SecurityError.
             * Hence, we must use a try/catch to handle this in Firefox
             */
            if (e.name !== 'SecurityError') throw e;
            continue;
        }
    }

    const s = document.createElement('style');
    s.setAttribute('type', 'text/css');
    s.innerHTML = '<![CDATA[\n' + used + '\n]]>';

    return s;
};
