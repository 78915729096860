<template>
  <div class="container-fluid">
    <h2>GTEx Release History</h2>
    <v-releaseInfoTable />
  </div>
</template>
<script>
import ReleaseInfoTable from '@/components/ReleaseInfoTable';
export default {
    components: {
        'v-releaseInfoTable': ReleaseInfoTable
    }
};
</script>

