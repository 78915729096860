<template>
  <div>
    <div class="table-info">
      Sample request files and forms
    </div>
    <table :id="formsTableId" class="stripe hover order-column row-border">
      <thead>
        <tr>
          <th>File Description</th>
          <th width="10%" class="text-center">
            Version
          </th>
          <th width="8%" class="text-center">
            Download
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(form, index) in forms" :key="index">
          <tr >
            <td>{{ form.desc }}</td>
            <td class="text-center">
              {{ form.version }}
            </td>
            <td class="text-center">
              <a :href="form.url" target="_blank"><i class="fas fa-download" /></a>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <br>

    <div class="table-info">
      Additional documentation about the GTEx biobank
    </div>
    <table :id="docsTableId" class="stripe hover order-column row-border">
      <thead>
        <tr>
          <th>File Description</th>
          <th width="10%" class="text-center">
            Version
          </th>
          <th width="8%" class="text-center">
            Download
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(document, index) in documents" :key="index">
          <tr>
            <td>{{ document.desc }}</td>
            <td class="text-center">
              {{ document.version }}
            </td>
            <td class="text-center">
              <a :href="document.url" target="_blank"><i class="fas fa-download" /></a>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
    props: {
        biobankInventoryFileUrl: {
            type: String,
            default: ''
        }
    },
    data: function() {
        return {
            formsTableId: 'forms-table',
            docsTableId: 'docs-table',
            forms: [
                {
                    version: this.getBiobankInventoryFileVersion(),
                    desc: 'Biobank inventory file. Updated nightly.',
                    url: this.biobankInventoryFileUrl
                },
                {
                    version: '20210223',
                    desc: 'GTEx Sample Request Form (PDF)',
                    url: 'https://storage.googleapis.com/gtex-public-data/biobank-sample-request/gtex_sample_request_form_fillable.pdf'
                },
                {
                    version: '20180417',
                    desc: 'GTEx Project Material Transfer Agreement (PDF)',
                    url: 'https://storage.googleapis.com/gtex-public-data/biobank-sample-request/Appendix_B_GTEX_MTA_FINAL.pdf'
                },
                {
                    version: '20180817',
                    desc: 'GTEx Project Material Transfer Agreement (DOCX)',
                    url: 'https://storage.googleapis.com/gtex-public-data/biobank-sample-request/Appendix_B_GTEX_MTA_FINAL.docx'
                }
            ],
            documents: [
                {
                    version: '20180214',
                    desc: 'GTEx Legacy Specimen Access and Distribution Plan',
                    url: 'https://storage.googleapis.com/gtex-public-data/biobank-sample-request/GTEx_Legacy_Specimen_and_Distribution_Plan_021418.pdf'
                },
                {
                    version: '20180214',
                    desc: 'GTEx Legacy Specimen Access Committee Members',
                    url: 'https://storage.googleapis.com/gtex-public-data/biobank-sample-request/Appendix_A_Committee_Members_14Feb2018.pdf'
                },
                {
                    version: '20180314',
                    desc: 'Broad GTEX Legacy Specimen Withdrawal Policy',
                    url: 'https://storage.googleapis.com/gtex-public-data/biobank-sample-request/Appendix_D_Withdrawal_of_BiospecimensSOP_14Mar2018.pdf'
                }
            ]
        };
    },
    watch: {
        // updates the biobank entry in the form object
        biobankInventoryFileUrl() {
            const biobankFormEntry = this.forms[0];
            biobankFormEntry.url = this.biobankInventoryFileUrl;
            biobankFormEntry.version = this.getBiobankInventoryFileVersion();
        }
    },
    mounted: function() {
        this.displayFormsTable();
        this.displayDocumentsTable();
    },
    methods: {
        /**
       * gets the version string (i.e. date) of the biobank inventory file
       * assumes the string is in the format of 'biobank_collection_20201030_070335.txt'
       */
        getBiobankInventoryFileVersion() {
            const splitFileUrl = this.biobankInventoryFileUrl.split('_');
            if (splitFileUrl.length < 4) return '';
            else return splitFileUrl[3];
        },
        displayFormsTable() {
            $(`#${this.formsTableId}`).dataTable({
                destroy: true,
                processing: true,
                jqueryUI: true,
                dom: 'rt',
                order: [], // leaving sorting as default order
                columns: [
                    null,
                    null,
                    { orderable: false }
                ]
            });
        },
        displayDocumentsTable() {
            $(`#${this.docsTableId}`).dataTable({
                destroy: true,
                processing: true,
                jqueryUI: true,
                dom: 'rt',
                order: [], // leaving sorting as default order
                columns: [
                    null,
                    null,
                    { orderable: false }
                ]
            });
        }
    }
};
</script>

<style scoped>
.table-info {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
</style>
