<template>
  <div class="container-fluid">
    <h2>
      GTEx Multi-Gene Single Cell Query<img src="@/assets/images/GTEx-single-cell-icon.svg" width="40px" style="margin: 0px 0px 0px 10px;">
    </h2>
    <!-- help modal -->
    <div id="help-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <img src="@/assets/images/single_cell_multi_gene_help/GTEx Multi-Gene Single Cell Query.svg"><hr>
            <img src="@/assets/images/single_cell_multi_gene_help/GTEx Multi-Gene Single Cell Query (1).svg"><hr>
            <img src="@/assets/images/single_cell_multi_gene_help/GTEx Multi-Gene Single Cell Query (2).svg"><hr>
            <img src="@/assets/images/single_cell_multi_gene_help/GTEx Multi-Gene Single Cell Query (3).svg"><hr>
            <img src="@/assets/images/single_cell_multi_gene_help/GTEx Multi-Gene Single Cell Query (4).svg"><hr>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- input form -->
    <div id="single-cell-multi-gene-apm-toolbar">
      <div id="single-cell-multi-gene-apm-toolbar-query" class="row">
        <div class="col-xs-10 col-sm-8 col-md-6">
          <textarea id="genes" value="" type="text" rows="4" class="form-control" placeholder="List of gencode IDs or gene names" aria-label="List of gencode IDs" aria-describedby="basic-addon2" />
          <a id="searchExample" href="#">A search example</a><br>
          <div id="batchSubmit" class="btn btn-info btn-sm" style="margin-bottom:1em;">
            Search
          </div>
          <br>
          <small id="geneQueryMessage" style="color: firebrick" />
        </div>
        <div class="col-xs-2 col-sm-4 col-md-6">
          <a data-toggle="modal" data-target="#help-modal" style="cursor: pointer;">
            <i class="fas fa-info-circle" /> Help
          </a>
          <gtex-browser-spinner :id="spinnerId" />
        </div>
      </div>

      <div id="single-cell-multi-gene-apm-toolbar-plot-options">
        <div class="single-cell-multi-gene-apm-toolbar-plot-option">
          <label>Plot Type</label><br>
          <button id="single-cell-multi-gene-apm-type-DotPoint" type="button" class="btn btn-light btn-default apm-toolbar-button">
            Dot
          </button>
          <button id="single-cell-multi-gene-apm-type-AsterPoint" type="button" class="btn btn-light btn-default apm-toolbar-button active">
            Aster
          </button>
        </div>
        <div class="single-cell-multi-gene-apm-toolbar-plot-option">
          <label>X-Axis Grouping</label><br>
          <button id="single-cell-multi-gene-apm-axis-x-Tissue" type="button" class="btn btn-light btn-default apm-toolbar-button active">
            Tissue
          </button>
          <button id="single-cell-multi-gene-apm-axis-x-CellType" type="button" class="btn btn-light btn-default apm-toolbar-button">
            Cell Type
          </button>
        </div>
        <div class="single-cell-multi-gene-apm-toolbar-plot-option">
          <label>Summary Statistic</label><br>
          <button id="single-cell-multi-gene-apm-point-Mean" type="button" class="btn btn-light btn-default apm-toolbar-button active">
            Mean
          </button>
          <button id="single-cell-multi-gene-apm-point-Median" type="button" class="btn btn-light btn-default apm-toolbar-button">
            Median
          </button>
        </div>
        <div id="single-cell-multi-gene-apm-point-summary-data" class="single-cell-multi-gene-apm-toolbar-plot-option hidden">
          <label>Summary Data</label><br>
          <button id="single-cell-multi-gene-apm-point-AllCells" type="button" class="btn btn-light btn-default apm-toolbar-button active">
            All cells
          </button>
          <button id="single-cell-multi-gene-apm-point-DetectedInCells" type="button" class="btn btn-light btn-default apm-toolbar-button">
            Detected in cells
          </button>
        </div>
        <div class="single-cell-multi-gene-apm-toolbar-plot-option">
          <label>Toolbar</label><br>
          <button id="single-cell-multi-gene-apm-download" type="button" class="btn btn-light btn-default apm-toolbar-button inactive"> 
            Download
          </button>
        </div>
      </div>
    </div>
    <div :id="rootId" />
    <div :id="tooltipId" />
  </div>
</template>
<script>
import router from '@/router/router';
import { directory } from "../utils/portal";
import GtexBrowserSpinner from './GtexBrowserSpinner.vue';

export default {
  components: { GtexBrowserSpinner },
    data: function() {
        const examples = ['ENSG00000183091.19', 'ENSG00000196218.12', 'ENSG00000138435.15', 'ENSG00000135902.9', 'ENSG00000134571.10', 'ENSG00000118194.18', 'ENSG00000163017.13', 'ENSG00000175084.11', 'ENSG00000133392.17', 'ENSG00000107796.13', 'ENSG00000113721.13', 'ENSG00000143248.12', 'ENSG00000131620.17', 'ENSG00000157404.15', 'ENSG00000261371.5', 'ENSG00000110799.13', 'ENSG00000138722.9', 'ENSG00000137077.7', 'ENSG00000166819.11', 'ENSG00000181092.9', 'ENSG00000009709.11', 'ENSG00000004948.13', 'ENSG00000011465.16', 'ENSG00000077942.18', 'ENSG00000071991.8', 'ENSG00000123560.13', 'ENSG00000146469.12', 'ENSG00000069482.6', 'ENSG00000129991.12', 'ENSG00000198125.12', 'ENSG00000168530.15', 'ENSG00000159173.18', 'ENSG00000132170.19', 'ENSG00000038945.14', 'ENSG00000110077.14', 'ENSG00000124491.15', 'ENSG00000126353.3', 'ENSG00000179344.16', 'ENSG00000156738.17', 'ENSG00000153064.11', 'ENSG00000172673.10', 'ENSG00000168685.14', 'ENSG00000105374.9', 'ENSG00000115523.16', 'ENSG00000197253.13', 'ENSG00000172236.16', 'ENSG00000116031.8', 'ENSG00000232629.8', 'ENSG00000120215.9', 'ENSG00000185664.14', 'ENSG00000128422.15', 'ENSG00000186847.5', 'ENSG00000148513.17', 'ENSG00000174501.14', 'ENSG00000161798.6', 'ENSG00000161634.11', 'ENSG00000185640.5', 'ENSG00000134824.13', 'ENSG00000203782.5', 'ENSG00000159455.8', 'ENSG00000186049.8', 'ENSG00000172867.3', 'ENSG00000167768.4', 'ENSG00000186081.11', 'ENSG00000171346.15', 'ENSG00000167656.4', 'ENSG00000197641.11', 'ENSG00000170423.12', 'ENSG00000171401.14', 'ENSG00000073282.12', 'ENSG00000145113.21', 'ENSG00000162896.5', 'ENSG00000001626.14', 'ENSG00000117983.17', 'ENSG00000078898.6', 'ENSG00000167749.11', 'ENSG00000167751.12', 'ENSG00000100604.12', 'ENSG00000171951.4', 'ENSG00000174844.14', 'ENSG00000122735.15', 'ENSG00000204305.13', 'ENSG00000105974.11', 'ENSG00000168484.12', 'ENSG00000122852.14', 'ENSG00000164265.8', 'ENSG00000149021.6'];
        return {
            examples: examples,
            genes: this.$route.params.genes || examples.map(d => d),
            rootId: 'single-cell-multi-gene-apm-plot',
            tooltipId: 'single-cell-multi-gene-apm-tooltip',
            legendId: 'single-cell-multi-gene-apm-legend',
            spinnerId: 'single-cellmulti-gene-spinner'
        };
    },
    mounted: function() {
        this.init();
    },
    methods: {
        init() {
            const urls = {
                singleCellExpression: directory.getSingleCellExpressionUrl().url,
                singleCellExpressionSummary: directory.getSingleCellExpressionSummaryUrl().url,
                geneId: directory.getGeneUrl().url + '?geneId='
            };
            $('#searchExample').click(() => {
                $('#genes').val(this.examples);
                return false; // this prevents # sign being appended to the URL
            });
            const config = {
                rootId: this.rootId,
                tooltipId: this.tooltipId,
                legendId: this.legendId,
                spinnerId: this.spinnerId,
                padding: { top: 350, right: 0, bottom: 50, left: 100 },
                dimension: undefined,
                title: 'Any-Point Matrix',
                type: 'AsterPoint', // this is the default setting of this plot type. determines whether to use points.DotPoint or points.AsterPoint
                axis: {
                    x: ['tissue', 'cellType'],
                    y: 'gencodeId'
                },
                points: {
                    DotPoint: {
                        radius: 'percentDetectedInCells',
                        color: 'meanAllCells'
                    },
                    AsterPoint: {
                        innerRadius: 0,
                        outerRadius: 'mean',
                        arcLength: 'percent', // attr hardcoded in _formatAsterData from nonzero/100 and (all - nonzero) / 100
                        color: 'mean'
                    }
                },
                point: {} // gets created in MatrixPlot class via points[type]
            };
            $('#batchSubmit').click(() => {
                this.genes = $('#genes').val().replace(/\s/g, '').toUpperCase().split(',');
                SingleCellMultiGeneAPM.launch(config, this.genes, urls);
            });
            $('#genes').keypress(e => {
                if (e.keyCode == 13) { // bind the enter key
                    e.preventDefault(); // Note: prevent the default behavior of the enter key, which is refreshing the page
                    $('#batchSubmit').trigger('click');
                }
            });
            SingleCellMultiGeneAPM.launch(config, this.genes, urls);
        },
        tasks() {
            // additional portal-specific tasks when submitting the query
            this.genes = $('#genes').val().split(' ').join('').trim().toUpperCase();
            router.push({ name: this.$route.name, params: { genes: this.genes } });
        }
    }
};
</script>
