<template>
  <div class="container-fluid">
    <h2>
      GTEx eQTL Dashboard<img src="@/assets/images/GTEx-eqtl-icon.svg" width="40px" style="margin: 0px 0px 0px 10px;">
    </h2>
    <!-- input form -->
    <p>
      <button class="btn btn-info btn-sm" type="button" data-toggle="collapse" data-target="#collapseForm" aria-expanded="false" aria-controls="collapseForm">
        <i class="fa fa-bars" />
      </button>
    </p>
    <div id="collapseForm" class="row collapse in">
      <div class="col-sm-12">
        <form id="eqtlDashboardForm" style="background:#f2f7fd; padding: 5px;">
          <div class="form-group row">
            <!-- form-group for optimum spacing between the label and its associated form input element -->

            <div class="col-sm-12 col-md-2">
              <label for="geneVariantPairs" class="align-top">Enter Gene-Variant Pairs</label>
              <ul style="font-size:12px; list-style-type:square; padding-left: 15px;">
                <li>Limited to 30 entries.</li>
                <li>
                  <a
                    id="example"
                    href="#"
                  >Example search</a>
                </li>
              </ul>
              <textarea id="geneVariantPairs" rows="20" class="form-control" placeholder="For example: ENSG00000248746.5,chr11_66561248_T_C_b38" style="font-size:9px;" />
              <br>
              <div id="eqtlDashboardSubmit" class="btn btn-info btn-sm" style="margin-bottom:1em;">
                Search
              </div>
            </div>
            <div class="col-xs-12 col-md-10">
              <label>Select Tissue(s)</label>
              <div
                id="tissueBoxes"
                class="form-group row"
              >
                <!-- tissue menu check boxes -->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- eQTL violin plots -->
    <div class="row">
      <div id="messageBox" class="col-sm-12" style="color:firebrick; font-size: 10px;" />
      <div id="eqtlDashboardDiv" class="col-sm-12">
        <div id="eqtlDashboardResults" class="row flex-row" style="padding-left: 15px;overflow:auto;" />
      </div>
    </div>
  </div>
</template>
<script>
import { directory } from '@/utils/portal';

export default {
    mounted: function() {
        this.init();
    },
    methods: {
        init() {
            const queryDatasetV10 = directory.getV10Dataset();
            const queryV39 = directory.getV10GencodeGenomeVersion();
            const urls = {
                tissue: directory.getTissueInfoUrl().url + '?' + queryDatasetV10,
                geneId: directory.getGeneUrl().url + '?' + queryV39 + '&geneId=',
                snp: directory.getVariantUrl().url + queryDatasetV10 + '&snpId=',
                variantId: directory.getVariantUrl().url + + queryDatasetV10 + '&variantId=',
                dyneqtl: directory.getEqtlBoxplotUrl().url + '?' + queryDatasetV10,
            };
            // build the tissue menu
            EqtlDashboard.build('eqtlDashboardResults', 'tissueBoxes', 'geneVariantPairs', 'eqtlDashboardSubmit', 'collapseForm', 'messageBox', urls);

            $('#example').click(() => {
                $('#geneVariantPairs').val('ENSG00000248746.6,chr11_66561248_T_C_b38\nENSG00000065613.15,chr11_66561248_T_C_b38\nENSG00000103034.15,chr16_58427461_G_A_b38');
                return false;
            });
        }
    }
};
</script>
