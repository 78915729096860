<template>
  <div :id="modalId" class="modal fade" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn btn-info pull-right" data-dismiss="modal" aria-label="close">
            Apply changes
          </button>
        </div>
        <div class="modal-body">
          <div>
            <h4>eGTEx Data</h4>
            <v-egtex-menu @check-item="checkItem" />
          </div>
          <hr>
          <div>
            <h4>Annotations and Gene Models</h4>
            <div class="row">
              <div class="col-xs-12 col-sm-4">
                <label><input type="checkbox" value="gwasCatalog" class="annotation-item" checked> GWAS Catalog</label>
              </div>
              <div class="col-xs-12 col-sm-4">
                <label><input type="checkbox" value="collapsedModel" class="annotation-item" checked> Collapsed Gene Models</label>
              </div>
              <div class="col-xs-12 col-sm-4">
                <label><input type="checkbox" value="fullModel" class="annotation-item"> Full Gene Models</label>
              </div>
            </div>
          </div>
          <hr>
          <div>
            <h4>Core GTEx Data</h4>
            <div class="row">
              <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2">
                <div class="checked-data-type">
                  <label><input type="checkbox" checked value="eQTL" class="menu-data-type"> eQTL</label>
                </div>
                <div>
                  <label><input type="checkbox" value="RNA-seq" class="menu-data-type"> RNA-seq</label>
                </div>
              </div>
              <div class="col-xs-12 col-sm-8 col-md-9 col-lg-10" style="border-left: 1px solid #eee">
                <v-tissue-menu @check-tissue="checkTissue" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { tissueLookupTable, getTissueId, getTissueDisplayName, tissueMetadataJson } from '@/utils/tissue';
import EGTExDataMenu from '@/components/igv_browser/EGTExDataMenu.vue';
import TissueMenu from '@/components/igv_browser/TissueMenu.vue';
export default {
    components: {
        'v-egtex-menu': EGTExDataMenu,
        'v-tissue-menu': TissueMenu
    },
    data: function() {
        const modalId = 'igv-menu-modal-id';
        const tissues = tissueMetadataJson;

        return {
            tissues: tissues,
            modalId: modalId,
            dataTypes: new Set(['eQTL'])
        };
    },
    mounted: function() {
        const self = this;
        $('.annotation-item').click(function() {
            const type= $(this).val();
            if ($(this).is(':checked')) {
                self.addTrack({
                    dataType: type
                });
            } else {
                self.removeTrack({
                    dataType: type
                });
            }
        });
        $('.menu-data-type').click(function() {
            const type = $(this).val();
            $(this).parent().toggleClass('checked-data-type');
            if ($(this).is(':checked')) {
                self.dataTypes.add(type);
                $('.menu-item').each(function() {
                    if ($(this).is(':checked')) {
                        const tissueId = getTissueId($(this).val());
                        self.checkItem({
                            status: true,
                            id: tissueId,
                            type: type
                        });
                    }
                });
            } else {
                self.dataTypes.delete(type);
                $('.menu-item').each(function() {
                    if ($(this).is(':checked')) {
                        const tissueId = getTissueId($(this).val());
                        self.checkItem({
                            status: false,
                            id: tissueId,
                            type: type
                        });
                    }
                });
            }
        });
    },
    methods: {
        checkTissue(tissue) {
            this.dataTypes.forEach(type => {
                const item = {
                    status: tissue.status,
                    id: tissue.id,
                    type: type
                };
                this.checkItem(item);
            });
        },
        checkItem(item) {
            const track = {
                tissueId: item.id,
                dataType: item.type,
                tissueName: getTissueDisplayName(item.id)
            };
            if (item.status) {
                this.addTrack(track);
            } else {
                this.removeTrack(track);
            }
        },
        addTrack(track) {
            this.$emit('add-to-browser', track);
        },
        removeTrack(track) {
            this.$emit('remove-from-browser', track);
        }
    }
};
</script>
<style scoped>
.footer {
  border-radius: 4px;
  font-size: 14px;
  background-color: #7F7F7F;
  border-color: #7F7F7F;
}
.checked-data-type {
  background: aliceblue;
}
label {
  cursor: pointer;
  font-weight: normal;
}
label:hover {
  border-bottom: 1px solid lightblue;
}
</style>

