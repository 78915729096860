import DownloadPageUtils from './downloads-page-utils';

export default class DataPanelGroup {
    constructor(group, parentName) {
        this.dataPanel = parentName;
        this.displayName = group.displayName;
        //replace empty space and parenthesis (a special case in ptv and will mess up rendering)
        this.id = group.displayName.replaceAll(/[ ()]/g, '_').toLowerCase();
        this.name = group.name;
        this.description = group.description;
        this.files = group.children.files;
        this.order = group.order;
        for (let i = 0; i < this.files.length; i++) {
            const file = this.files[i];
            file.fileSize = DownloadPageUtils.sizeToReadableSize(file.fileSize);
        }
        //technically still works without following line but put in for consistency
        this.files.sort(DownloadPageUtils.compareOrder)
        this.subGroups = group.children.folders;

        for (let i = 0; i < this.subGroups.length; i++) {
            const subGroup = this.subGroups[i];
            for (let j = 0; j < subGroup.children.files.length; j++) {
                const file = this.subGroups[i].children.files[j];
                file.fileSize = DownloadPageUtils.sizeToReadableSize(file.fileSize);
                //technically still works without following line but put in for consistency
                this.subGroups[i].children.files.sort(DownloadPageUtils.compareOrder)
            }
        }
    }
};
