<template>
  <div class="gtx-panel-data-subgrp">
    <a class="gtex-pdata-subgrp-header" data-toggle="collapse" :href="`#${tableContainer}`" role="button" aria-expanded="false" :aria-controls="tableContainer">
      <span class="gtex-p-data-subgrp-title">{{ fileset.displayName }}</span></a>
    <p v-html="fileset.description" />

    <div v-if="filesetSize>0" :id="tableContainer" class="collapse">
      <table :id="tableId" class="stripe hover" />
    </div>
  </div>
</template>

<script>
import TableUtils from '@/utils/table-utils';
import MessageHandler from '@/utils/message-handler';
export default {
    name: 'DataPanelSubgroupComponent',
    props: {
        parentId: {
            type: String,
            required: true
        },
        fileset: {
            type: Object,
            required: true
        },
        tabsContainerId: {
            type: String,
            required: true
        },
        tabId: {
            type: String,
            required: true
        }
    },
    data() {
        const filesetId = this.fileset.displayName.replaceAll(' ', '_');
        const tableId = `${this.parentId}-${filesetId}`;
        const filesetSize = this.fileset.children.files.length;
        return {
            tableId: tableId,
            tableContainer: `${tableId}-container`,
            filesetSize: filesetSize
        };
    },
   created() {
      this.$watch(
      () =>this.$route.params.hash, 
      (newParam, oldParam) => {
        if (newParam !== oldParam) {
          $(`#${this.tableId}`).DataTable().columns.adjust();
        }
      }
    )
  },
    mounted() {
        this.generateFilesTable();

        const tableContainer = this.tableContainer;
        const tableId = this.tableId;
        // this fixes an issue where:
        // - the datatable header is misasligned from the table because "scrollX" was enabled
        // - setting the size of columns isn't respected because the datatable was created when the section was hidden
        $(`#${tableContainer}`).on('show.bs.collapse', () => {
            setTimeout(() => { $(`#${tableId}`).DataTable().columns.adjust(); }, 5);
        });

        const tabsContainerId = this.tabsContainerId;
        const tabId = this.tabId;
        // this fixes an issue where the datatable header is misasligned from the table because "scrollX" was enabled and resizing happens
        $(`#${tabsContainerId} a[data-toggle="tab"][aria-controls="${tabId}"]`).on('shown.bs.tab', () => {
            $(`#${tableId}`).DataTable().columns.adjust();
        });
    },
    methods: {
        generateFilesTable() {
          console.log("generate subgroup file table");
            const emptyMessage = 'No files were found';

            // we are using an older version of data tables that requires us to generate the table header HTML
            const objectHeadings = ['Order', 'Name', 'Description', 'Size', ''];
            const oTable = $(`#${this.tableId}`);
            const tableElt = oTable[0];
            TableUtils.buildTableHtml(tableElt, objectHeadings);
            const columnProps = [
                { data: 'order', 'visible': false },
                { data: 'displayName' },
                { data: 'description', width: '60%' },
                { data: 'fileSize', width: 60, className: 'dt-body-right' },
                {
                    defaultContent: '',
                    sortable: false,
                    data: 'url',
                    width: 15, // doesn't work if this section starts hidden unless we adjust columns on display
                    render: function(data) {
                        const fileUrl = data;
                        return TableUtils.generateDownloadButton(fileUrl);
                    }
                }
            ];
            $.fn.dataTableExt.sErrMode = 'throw';
            try {
                oTable.dataTable({
                    deferRender: true,
                    destroy: true,
                    retrieve: true,
                    processing: true,
                    paging: true,
                    paginationType: 'full_numbers',
                    data: this.fileset.children.files,
                    columns: columnProps,
                    dom: 'tip',
                    orderClasses: false,
                    scrollX: true,
                    language: { sEmptyTable: 'Please wait - Fetching records' },
                    fnInitComplete: function(oSettings) {
                        oSettings.oLanguage.sEmptyTable = emptyMessage;
                        $(oTable).find('.dataTables_empty').html(emptyMessage);
                    }
                });
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err +
                    'unable to create table for files');
                oTable.addClass('display');
                oTable.show();
                oTable.fnSort([[0, 'asc']]);
                return oTable;
            }
        }
    }

};
</script>

<style scoped>
.gtx-panel-data-subgrp {
  margin-top: 25px;
}

.gtex-pdata-subgrp-header {
  display: flex;
  flex-direction: column;
  color: inherit;
}

.gtex-pdata-subgrp-header:hover, .gtex-pdata-subgrp-header:active, .gtex-pdata-subgrp-header:focus {
  text-decoration: none;
  color: inherit;
}

.gtex-p-data-subgrp-title {
  font-size: 18px;
  line-height: 2;
}

.gtx-panel-data-subgrp a[aria-expanded="false"] .gtex-p-data-subgrp-title::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0fe";
  padding-right: 5px;
  color: var(--teal);
}

.gtx-panel-data-subgrp a[aria-expanded="true"] .gtex-p-data-subgrp-title::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f146";
  padding-right: 5px;
  color: var(--teal);
}
</style>

<style>
/** Overriding datatables CSS */
.gtx-panel-data-subgrp .dataTables_paginate.ui-buttonset a.fg-button {
    background: #fff;
    border: 1px solid #c5dbec;
    font-weight: 500;
    font-size: 12px;
    margin-left: 0;
    margin-right: -1px !important;
}
</style>
