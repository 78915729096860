import { directory } from '@/utils/portal';
import { RetrieveAllPaginatedData } from './pagination';

// TODO we need to fix this.  Way too many globals.

export let eqtlTissueSampleSum = 0; // only tissues with pre-computed data, i.e. n>70
export let eqtlTissueSampleWithGenotypeSum = 0;
/*
 *var eqtlEgeneSum = 8930; // V4, hard-coded value for total unique egenes in all eqtl tissues
 * var eqtlEgeneSum = 27159; // V6
 */
export const eqtlEgeneSum = 31403; // V7 TODO: retrieve this value dynamically from the API
export let totalNumEgenes = 0;
export const minN = 70; // minimum number of RNAseq samples with genotype, TODO: get rid of hard-coded
export let allTissueParameters = [];
export const allTissueParamsHash = [];
export const tissueLookupTable = {};
export const tissueAbbrevTable = {}; // tissue abbrev code mapping table
export let tissueMetadataJson; // this global variable is used to store info from FLASK

export function buildTissueAbbrevTable() {
    // TODO: rewrite this function with the updated tissue web service
    const parseTissueService = function(rawJson) {
        const json = {};
        rawJson.forEach((tissueEntry, i, arr) => {
            tissueAbbrevTable[tissueEntry.tissueSiteDetailAbbr] = tissueEntry.tissueSiteDetailId;
        });
    };
    RetrieveAllPaginatedData(directory.getTissueInfoUrl().url).then(
        data => {
            parseTissueService(data);
        }
    );
}

export function buildTissueLookupTable() {
    $.each(tissueMetadataJson, (id, tissue) => {
        tissueLookupTable[tissue.tissueSiteDetail] = tissue;
    });
}
/**
 * tissueMetadataJson is a JSON object from the Flask service. Valid keys are:
 * - rnaSeqSampleCount
 * - rnaSeqAndGenotypeSampleCount
 * - eGeneCount
 */

export function getTissueMetadataJson(callback) {
    const tissueInfoUrl = directory.getTissueInfoUrlV10().url;

    // TODO: move ajax calls to directory.js
    RetrieveAllPaginatedData(tissueInfoUrl).then(
        tissueInfoData => {
            const dataDict = {};
            tissueInfoData.forEach(tEntry => {
                dataDict[tEntry.tissueSiteDetailId] = tEntry;
            });

            tissueMetadataJson = dataDict;
            // needed for gtex-body-map
            window.tissueMetadataJson = tissueMetadataJson;
            computeSum();
            buildTissueLookupTable();
            if (typeof callback != 'undefined') callback();
        }
    ).catch(
        error => {
            alert('Error: ' + error);
            alert('Error: Cannot retrieve tissue metadata from the tissue info web service. ' + tissueInfoUrl);
        }

    );
}

export function computeSum() {
    eqtlTissueSampleSum = 0; // only tissues with pre-computed data, i.e. n>70
    eqtlTissueSampleWithGenotypeSum = 0;
    totalNumEgenes = 0;
    $.each(tissueMetadataJson, (k, tissueCategory) => {
        const n1 = tissueMetadataJson[k].rnaSeqAndGenotypeSampleCount;
        const n2 = tissueMetadataJson[k].rnaSeqSampleCount;
        const egeneCount = tissueMetadataJson[k].eGeneCount;
        if (egeneCount != null) {
            totalNumEgenes += egeneCount;
        }
        if (n1>=minN) {
            eqtlTissueSampleWithGenotypeSum += n1;
            eqtlTissueSampleSum += n2;
            // egene = tissueMetadataJson[k].eGeneCount;
        }
    }); //end each
}

export function getEgeneCount(tissueSiteDetail) {
    if (typeof tissueMetadataJson[tissueSiteDetail] == 'undefined') return undefined;
    if (typeof tissueMetadataJson[tissueSiteDetail].eGeneCount == 'undefined') tissueMetadataJson[tissueSiteDetail].eGeneCount = 0;
    return tissueMetadataJson[tissueSiteDetail].eGeneCount;
}

export function getNormEgeneCount(tissueSiteDetail) {
    if (typeof tissueMetadataJson[tissueSiteDetail] == 'undefined') return undefined;
    if (typeof tissueMetadataJson[tissueSiteDetail].expressedGeneCount == 'undefined' || tissueMetadataJson[tissueSiteDetail].expressedGeneCount == 'null') return undefined;
    return getEgeneCount(tissueSiteDetail)/tissueMetadataJson[tissueSiteDetail].expressedGeneCount;
}

export function getSgeneCount(tissueSiteDetail) {
    if (typeof tissueMetadataJson[tissueSiteDetail] == 'undefined') return undefined;
    if (typeof tissueMetadataJson[tissueSiteDetail].sGeneCount == 'undefined') tissueMetadataJson[tissueSiteDetail].sGeneCount = 0;

    return tissueMetadataJson[tissueSiteDetail].sGeneCount;
}

export function getNormSgeneCount(tissueSiteDetail) {
    if (typeof tissueMetadataJson[tissueSiteDetail] == 'undefined') return undefined;
    if (typeof tissueMetadataJson[tissueSiteDetail].expressedGeneCount == 'undefined' || tissueMetadataJson[tissueSiteDetail].expressedGeneCount == 'null') return undefined;

    return getSgeneCount(tissueSiteDetail)/tissueMetadataJson[tissueSiteDetail].expressedGeneCount;
}

export function getTissueDetails(name) { // name could be tissue.name or tissue.parameter
    if (typeof tissueMetadataJson[name] != 'undefined') return tissueMetadataJson[name];
    else if (typeof tissueLookupTable[name] != 'undefined') return tissueLookupTable[name];
    return null;
}

export function getTissueDisplayName(id) { // name is tissue.parameter
    // aTissue = getTissueDetails(name);
    const tissue = tissueMetadataJson[id];
    if (tissue != null) {
        return tissue.tissueSiteDetail;
    } else if (name == 'All') {
        return 'All eQTL Tissues';
    } else {
        return name;
    }
}

export function getTissueId(name) { // name is tissue.name, id is tissue.parameter
    const aTissue = getTissueDetails(name);
    if (aTissue != null) {
        return aTissue.tissueSiteDetailId;
    } else if (name == 'All') {
        return 'All eQTL Tissues';
    } else {
        return name;
    }
}

export function sumLargeTissueCount() {
    return eqtlTissueSampleWithGenotypeSum;
}

export function getAllTissuesGroupedByMinN() {
    // first group tissues by n>=60 and n<60 and then sort tissues alphabetically
    const largeTissues = [];
    const smallTissues = [];
    const allTissues = [];

    $.each(tissueMetadataJson, (id, tissue) => {
        if (tissue.eqtlSampleSummary.totalCount >= minN) {
            largeTissues.push(tissue);
        } else {
            smallTissues.push(tissue);
        }
    });

    // sorts the name of the tissue site alphabetically
    const sortAlphabet = function(x, y) {
        return ((x.tissueSiteDetail > y.tissueSiteDetail) ? 1 : (x.tissueSiteDetail < y.tissueSiteDetail) ? -1 : 0);
    };
    largeTissues.sort(sortAlphabet);
    smallTissues.sort(sortAlphabet);

    const addTissue = (t) => allTissues.push(t.tissueSiteDetailId)

    largeTissues.forEach(addTissue);
    // this adds a divider between large and small
    allTissues.push('======');
    smallTissues.forEach(addTissue);
    return allTissues;
}

export function getAllTissueParameters() {
    allTissueParameters = Object.keys(tissueMetadataJson);
    return allTissueParameters;
}

/**
 * If tissueParameter exists in the allTissueParamsHash, this
 * returns the tissueParameter.  If the tissueParameter is not
 * found, it throws an exception.
 *
 * @param tissueParameter
 */
export function validateTissueParameter(tissueParameter) {
    if (allTissueParamsHash.length == 0) {
    // initialize params hash
        const allParams = getAllTissueParameters();
        $.each(allParams, (index, parameter) => {
            allTissueParamsHash[parameter] = parameter;
        });
    }

    if (!allTissueParamsHash[tissueParameter]) {
        throw ('Invalid tissue name: ' + tissueParameter);
    }

    return tissueParameter;
}

export function getAllTissueDropdownTags() {
    const optionTags = new Array();
    const tissues = new Array();
    $.each(tissueMetaData, (k, tissueCategory) => {
        const cat = tissueCategory;
        $.each(cat.tissue, (kk, tissue) => {
            //<option value="Adipose_Subcutaneous">Adipose Subcutaneous (n = 94)</option>
            tissues.push(tissue);
        });
    });
    tissues.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    $.each(tissues, (kk, tissue) => {
    //<option value="Adipose_Subcutaneous">Adipose Subcutaneous (n = 94)</option>
        const option = '<option value=\''+tissue.parameter+'\'>'+tissue.name+' (n = '+tissue.count+')</option>';
        optionTags.push(option);
    });

    return optionTags;
}

export function getSampleCount(tissueName) {
    let count = null;
    if (tissueName == 'All') { count = sumLargeTissueCount(); } else {
        const tissue = tissueMetadataJson[tissueName];
        count = tissue.rnaSeqAndGenotypeSampleCount;
    }
    return count;
}

