<template>
  <div :id="modalId" class="modal fade" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="close">
            <span aria-hidden="true">x</span>
          </button>
          <h3>
            About GTEx IGV Browser
          </h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="close" />
        </div>
        <div class="modal-body">
          <!-- Documentation is SVG exports from here: https://docs.google.com/presentation/d/1zS0-JU1nSce1rFt12xw6xwfD-Gk3kEFAKvbZxZCENlw -->
          <img src="@/assets/images/igv_documentation/GTEx IGV Browser Documentation.svg"><hr>
          <img src="@/assets/images/igv_documentation/GTEx IGV Browser Documentation (1).svg"><hr>
          <img src="@/assets/images/igv_documentation/GTEx IGV Browser Documentation (2).svg"><hr>
          <img src="@/assets/images/igv_documentation/GTEx IGV Browser Documentation (3).svg"><hr>
          <img src="@/assets/images/igv_documentation/GTEx IGV Browser Documentation (4).svg"><hr>
          <img src="@/assets/images/igv_documentation/GTEx IGV Browser Documentation (5).svg"><hr>
          <img src="@/assets/images/igv_documentation/GTEx IGV Browser Documentation (6).svg"><hr>
          <img src="@/assets/images/igv_documentation/GTEx IGV Browser Documentation (7).svg"><hr>
          <img src="@/assets/images/igv_documentation/GTEx IGV Browser Documentation (8).svg"><hr>
          <img src="@/assets/images/igv_documentation/GTEx IGV Browser Documentation (9).svg"><hr>
          <img src="@/assets/images/igv_documentation/GTEx IGV Browser Documentation (10).svg"><hr>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn-info footer" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data: function() {
        return {
            modalId: 'igv-help-modal-id'
        };
    }
};
</script>
