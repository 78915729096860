/*
 * Copyright 2014 The Broad Institute, Inc.
 * SOFTWARE COPYRIGHT NOTICE
 * This software and its documentation are the copyright of the
 * Broad Institute, Inc. All rights are reserved.
 *
 * This software is supplied without any warranty or guaranteed support
 * whatsoever. The Broad Institute is not responsible for its
 * use, misuse, or functionality.
 */

/**
 *
 * A directory of web services.
 *
 *
 * @author jnedzel
 *
 */

import getServerUrl from '@/utils/getServerUrl.js';

export function UrlEntry(url, needsCreds) {
    this.url = url;
    this.needsCreds = needsCreds;
}

export function Directory(initialUrl) {
    this.homeUrl = initialUrl;

    // local dev environment settings
    this.pythonGtexServerUrl = getServerUrl ? getServerUrl() : '//local.gtexportal.org/rest/'; // TODO: consider including the API version v1 in the root URL
    //this.pythonGtexServerUrl = 'http://127.0.0.1:8000/api/'; // TODO: consider including the API version v1 in the root URL
    this.urlHash = {};

    this.initialize = function(callback) {
    // histology sample search URL
        const histologySampleUrl = this.pythonGtexServerUrl + 'v2/histology/image';
        this.urlHash.histologySampleUrl = new UrlEntry(histologySampleUrl);

        // biobank sample search download URL
        const biobankDownloadUrl = this.pythonGtexServerUrl + 'v2/biobank/download';
        this.urlHash.biobankDownloadUrl = new UrlEntry(biobankDownloadUrl);

        // biobank sample search URL
        const biobankSampleUrl = this.pythonGtexServerUrl + 'v2/biobank/sample';
        this.urlHash.biobankSampleUrl = new UrlEntry(biobankSampleUrl);

        const datasetUrl = this.pythonGtexServerUrl + 'v2/metadata/dataset';
        this.urlHash.datasetUrl = new UrlEntry(datasetUrl);

        // egene URL
        const egeneUrl = this.pythonGtexServerUrl + 'v2/association/egene';
        this.urlHash.egeneUrl = new UrlEntry(egeneUrl, false);

        // feature URL
        const featureUrl = this.pythonGtexServerUrl + 'v2/reference/features';
        this.urlHash.featureUrl = new UrlEntry(featureUrl, false);

        // gene search URL
        const geneUrl = this.pythonGtexServerUrl + 'v2/reference/gene';
        this.urlHash.geneUrl = new UrlEntry(geneUrl, false);
        
        const geneSearchUrl = this.pythonGtexServerUrl + 'v2/reference/geneSearch';
        this.urlHash.geneSearchUrl = new UrlEntry(geneSearchUrl, false);

        /*
         * SNP rsId search URL
         * var snpSearchUrl = this.pythonGtexServerUrl + 'v1/reference/snp?reference=snp147&format=json&snpId=';
         * this.urlHash.snpSearchUrl = new UrlEntry(snpSearchUrl, false);
         */

        /*
         * SNP variant ID search URL
         * var snpVariantSearchUrl = this.pythonGtexServerUrl + 'v1/reference/snp?reference=snp135&format=json&release=v6p&variantId=';
         * this.urlHash.snpVariantSearchUrl = new UrlEntry(snpVariantSearchUrl, false);
         */

        /*
         * SNP position search URL
         * var snpPosSearchUrl = this.pythonGtexServerUrl + 'v1/reference/snp?release=v7'; // it requires two parameters: chrom and pos;
         * this.urlHash.snpPosSearchUrl = new UrlEntry(snpPosSearchUrl, false);
         */

        // Variant URL
        const variantUrl = this.pythonGtexServerUrl + 'v2/dataset/variant?';
        this.urlHash.variantUrl = new UrlEntry(variantUrl, false);

        // Variant by location
        const variantByLocationUrl = this.pythonGtexServerUrl + 'v2/dataset/variantByLocation';
        this.urlHash.variantByLocationUrl = new UrlEntry(variantByLocationUrl, false);


        // gene expression URL
        const geneExpressionUrl = this.pythonGtexServerUrl + 'v2/expression/geneExpression';
        this.urlHash.geneExpressionUrl = new UrlEntry(geneExpressionUrl, false);

        // eqtl boxplot URL
        const eqtlBoxplotUrl = this.pythonGtexServerUrl + 'v2/association/dyneqtl';
        this.urlHash.eqtlBoxplotUrl = new UrlEntry(eqtlBoxplotUrl, false);

        // dynsqtl URL
        const dynSqtlUrl = this.pythonGtexServerUrl + 'v2/association/dynsqtl';
        this.urlHash.dynSqtlUrl = new UrlEntry(dynSqtlUrl, false);

        // dyn ieQTL URL
        const dynIEqtlUrl = this.pythonGtexServerUrl + 'v2/association/dynieqtl';
        this.urlHash.dynIEqtlUrl = new UrlEntry(dynIEqtlUrl, false);

        // dyn isQTL URL
        const dynISqtlUrl = this.pythonGtexServerUrl + 'v2/association/dynisqtl';
        this.urlHash.dynISqtlUrl = new UrlEntry(dynISqtlUrl, false);

        // transcript expression URL
        const transcriptExpressionUrl = this.pythonGtexServerUrl + 'v2/expression/transcriptExpression';
        this.urlHash.transcriptExpressionUrl = new UrlEntry(transcriptExpressionUrl, false);

        // median transcript expression URL, a brand new API
        const medianTranscriptExpressionUrl = this.pythonGtexServerUrl + 'v2/expression/medianTranscriptExpression';
        this.urlHash.medianTranscriptExpressionUrl = new UrlEntry(medianTranscriptExpressionUrl, false);
        
        const medianTranscriptExpressionClusteredUrl = this.pythonGtexServerUrl + 'v2/expression/clusteredMedianTranscriptExpression';
        this.urlHash.medianTranscriptExpressionClusteredUrl = new UrlEntry(medianTranscriptExpressionClusteredUrl, false);

        // median junction expression URL
        const medianJunctionExpressionUrl = this.pythonGtexServerUrl + 'v2/expression/medianJunctionExpression';
        this.urlHash.medianJunctionExpressionUrl = new UrlEntry(medianJunctionExpressionUrl, false);
        
        const medianJunctionExpressionClusteredUrl = this.pythonGtexServerUrl + 'v2/expression/clusteredMedianJunctionExpression';
        this.urlHash.medianJunctionExpressionClusteredUrl = new UrlEntry(medianJunctionExpressionClusteredUrl, false);

        // median exon expression URL
        const medianExonExpressionUrl = this.pythonGtexServerUrl + 'v2/expression/medianExonExpression';
        this.urlHash.medianExonExpressionUrl = new UrlEntry(medianExonExpressionUrl, false);
        
        const medianExponExpressionClusteredUrl = this.pythonGtexServerUrl + 'v2/expression/clusteredMedianExonExpression';
        this.urlHash.medianExponExpressionClusteredUrl = new UrlEntry(medianExponExpressionClusteredUrl, false);

        // linkage disequlibrium URL
        const ldUrl = this.pythonGtexServerUrl + 'v2/dataset/ld';
        this.urlHash.ldUrl = new UrlEntry(ldUrl, false);

        const ldByVariant = this.pythonGtexServerUrl + 'v2/dataset/ldByVariant';
        this.urlHash.ldByVariantUrl = new UrlEntry(ldByVariant, false);

        // metasoft data URL
        const metasoftUrl = this.pythonGtexServerUrl + 'v2/association/metasoft';
        this.urlHash.metasoftUrl = new UrlEntry(metasoftUrl, false);

        // ptv URL
        const ptvUrl = this.pythonGtexServerUrl + 'v2/association/ptv?geneId=';
        this.urlHash.ptvUrl = new UrlEntry(ptvUrl, false);

        const aseSummaryUrl = this.pythonGtexServerUrl + 'v2/association/ptv?geneId=ALL_ASE';
        this.urlHash.aseSummaryUrl = new UrlEntry(aseSummaryUrl, false);

        const sdmSummaryUrl = this.pythonGtexServerUrl + 'v2/association/ptv?geneId=ALL_SDM';
        this.urlHash.sdmSummaryUrl = new UrlEntry(sdmSummaryUrl, false);

        // multi-tissue eQTL URL, default data version
        const multiTissueEqtlUrl = this.pythonGtexServerUrl + 'v2/association/multiTissueEqtl';
        this.urlHash.multiTissueEqtlUrl = new UrlEntry(multiTissueEqtlUrl, false);

        // multi-tissue eQTL URL, V4
        const multiTissueEqtlUrlV4 = this.pythonGtexServerUrl + 'v2/association/multiTissueEqtl?release=v4';
        this.urlHash.multiTissueEqtlUrlV4 = new UrlEntry(multiTissueEqtlUrlV4, false);

        // spliceQtl URL
        const spliceQtlUrl = this.pythonGtexServerUrl + 'v2/association/spliceQtlSeeker';
        this.urlHash.spliceQtlUrl = new UrlEntry(spliceQtlUrl, false);

        // spliceQtl Altrans URL
        const spliceQtlAltransUrl = this.pythonGtexServerUrl + 'v2/association/spliceQtlAltrans';
        this.urlHash.spliceQtlAltransUrl = new UrlEntry(spliceQtlAltransUrl, false);

        // imprinting URL
        const imprintingUrl = '/data/tmpImprintingData/imprinting.short.json';
        this.urlHash.imprintingUrl = new UrlEntry(imprintingUrl, false);

        const imprintingPartialUrl = '/data/tmpImprintingData/imprinting.filtered.json';
        this.urlHash.imprintingPartialUrl = new UrlEntry(imprintingPartialUrl, false);

        // datasets file listing URL
        const fileListUrl = this.pythonGtexServerUrl + 'v2/dataset/fileList';
        this.urlHash.fileListUrl = new UrlEntry(fileListUrl, false);

        const openAccessFilesMetadataUrl = this.pythonGtexServerUrl + 'v2/dataset/openAccessFilesMetadata';
        this.urlHash.openAccessFilesMetadataUrl = new UrlEntry(openAccessFilesMetadataUrl, false);
        
        // tissueInfoUrl
        const tissueInfoUrl = this.pythonGtexServerUrl + 'v2/dataset/tissueSiteDetail';
        this.urlHash.tissueInfoUrl = new UrlEntry(tissueInfoUrl, false);

        const tissueInfoUrlV10 = this.pythonGtexServerUrl + 'v2/dataset/tissueSiteDetail?datasetId=gtex_v10';
        this.urlHash.tissueInfoUrlV10 = new UrlEntry(tissueInfoUrlV10, false);

        //sGene Url
        const sGeneUrl = this.pythonGtexServerUrl + 'v2/association/sgene';
        this.urlHash.sGeneUrl = new UrlEntry(sGeneUrl, false);

        // singleTissue eQTL URL
        const singleTissueEqtlUrl = this.pythonGtexServerUrl + 'v2/association/singleTissueEqtl'; 
        this.urlHash.singleTissueEqtlUrl = new UrlEntry(singleTissueEqtlUrl, false);

        // single tissue eQTL by location Url
        const singleTissueEqtlByLocationUrl = this.pythonGtexServerUrl + 'v2/association/singleTissueEqtlByLocation';
        this.urlHash.singleTissueEqtlByLocationUrl = new UrlEntry(singleTissueEqtlByLocationUrl, false);

        // single tissue sQTL Url
        const singleTissueSqtlUrl = this.pythonGtexServerUrl + 'v2/association/singleTissueSqtl';
        this.urlHash.singleTissueSqtlUrl = new UrlEntry(singleTissueSqtlUrl, false);

        // interaction eQTL Url
        const singleTissueIEqtlUrl = this.pythonGtexServerUrl + 'v2/association/singleTissueIEqtl';
        this.urlHash.singleTissueIEqtlUrl = new UrlEntry(singleTissueIEqtlUrl, false);

        // interaction sQTL Url
        const singleTissueISqtlUrl = this.pythonGtexServerUrl + 'v2/association/singleTissueISqtl';
        this.urlHash.singleTissueISqtlUrl = new UrlEntry(singleTissueISqtlUrl, false);

        // top expressed gene URL
        const topExpressedGeneUrl = this.pythonGtexServerUrl + 'v2/expression/topExpressedGene';
        this.urlHash.topExpressedGeneUrl = new UrlEntry(topExpressedGeneUrl, false);

        // top expressed gene URL
        const topExpressedGeneUrlV10 = this.pythonGtexServerUrl + 'v2/expression/topExpressedGene?datasetId=gtex_v10';
        this.urlHash.topExpressedGeneUrlV10 = new UrlEntry(topExpressedGeneUrlV10, false);


        // median gene expression URL
        const medianGeneExpressionUrl = this.pythonGtexServerUrl + 'v2/expression/medianGeneExpression';
        this.urlHash.medianGeneExpressionUrl = new UrlEntry(medianGeneExpressionUrl, false);
        
        const medianGeneExpressionClusteredUrl = this.pythonGtexServerUrl + 'v2/expression/clusteredMedianGeneExpression';
        this.urlHash.medianGeneExpressionClusteredUrl = new UrlEntry(medianGeneExpressionClusteredUrl, false);

        // Forest PM plot URL
        const forestPmPlotUrl = this.pythonGtexServerUrl + 'v2/association/metasoft';
        this.urlHash.forestPmPlotUrl = new UrlEntry(forestPmPlotUrl, false);

        const newsItemUrl = this.pythonGtexServerUrl + 'v2/admin/newsItem';
        this.urlHash.newsItemUrl = new UrlEntry(newsItemUrl, false);

        const maintenanceMessageUrl = this.pythonGtexServerUrl + 'v2/admin/maintenanceMessage';
        this.urlHash.maintenanceMessageUrl = new UrlEntry(maintenanceMessageUrl, false);

        const contactUrl = this.pythonGtexServerUrl + 'v2/admin/contact';
        this.urlHash.contactUrl = new UrlEntry(contactUrl, false);

        // IGV related files
        const fastaUrl = '//s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa';
        this.urlHash.fastaUrl = new UrlEntry(fastaUrl, false);

        const fastaIndexUrl = '//s3.amazonaws.com/igv.broadinstitute.org/genomes/seq/hg38/hg38.fa.fai';
        this.urlHash.fastaIndexUrl = new UrlEntry(fastaIndexUrl, false);

        const cytobandUrl = '//s3.amazonaws.com/igv.broadinstitute.org/annotations/hg38/cytoBandIdeo.txt';
        this.urlHash.cytobandUrl = new UrlEntry(cytobandUrl, false);

        const gwasCatalogUrl = 'https://storage.googleapis.com/gtex-igv-files/GWAS_catalog_v1.0.2_signif_only_filtered_reordered_renamed.txt';
        this.urlHash.gwasCatalogUrl = new UrlEntry(gwasCatalogUrl, false);

        const gwasCatalogByLocationUrl = this.pythonGtexServerUrl + 'v2/reference/gwasCatalogByLocation';
        this.urlHash.gwasCatalogByLocationUrl = new UrlEntry(gwasCatalogByLocationUrl, false);

        const gencodeBedFileUrl = 'https://storage.googleapis.com/gtex-igv-files/references/v10/gencode.v39.GRCh38.genes.bed';
        this.urlHash.gencodeBedFileUrl = new UrlEntry(gencodeBedFileUrl, false);

        const gencodeFullBedFileUrl = 'https://storage.googleapis.com/gtex-igv-files/references/v10/gencode.v39.GRCh38.annotation.bed';
        this.urlHash.gencodeFullBedFileUrl = new UrlEntry(gencodeFullBedFileUrl, false);

        const bigWigFileUrl = 'https://storage.googleapis.com/gtex-igv-files';
        this.urlHash.bigWigFileUrl = new UrlEntry(bigWigFileUrl, false);

        // principal component analysis data URL
        const expressionPcaUrl = this.pythonGtexServerUrl + 'v2/expression/expressionPca';
        this.urlHash.expressionPcaUrl = new UrlEntry(expressionPcaUrl, false);

        // sample info URL
        const sampleUrl = this.pythonGtexServerUrl + 'v2/dataset/sample';
        this.urlHash.sampleUrl = new UrlEntry(sampleUrl, false);

        // image viewer URLs
        const gtexHistUrl = '/home/histologyPage/';
        this.urlHash.gtexHistUrl = new UrlEntry(gtexHistUrl, false);
        const brdHistUrl = 'https://brd.nci.nih.gov/brd/specimen/';
        this.urlHash.brdHistUrl = new UrlEntry(brdHistUrl, false);

        // transcript URL
        const transcriptUrl = this.pythonGtexServerUrl + 'v2/reference/transcript?datasetId=gtex_v8&gencodeId=';
        this.urlHash.transcriptUrl = new UrlEntry(transcriptUrl, false);

        // exon URL, this is a brand new API
        const exonUrl = this.pythonGtexServerUrl + 'v2/reference/exon?datasetId=gtex_v8&gencodeId=';
        this.urlHash.exonUrl = new UrlEntry(exonUrl, false);
        
        // transcript URL No Dataset Version for V10 Gene Page release
        const transcriptUrlNoDataset = this.pythonGtexServerUrl + 'v2/reference/transcript?gencodeId=';
        this.urlHash.transcriptUrlNoDataset = new UrlEntry(transcriptUrlNoDataset, false);

        // exon URL, No Dataset Version for V10 Gene Page release
        const exonUrlNoDataset = this.pythonGtexServerUrl + 'v2/reference/exon?gencodeId=';
        this.urlHash.exonUrlNoDataset = new UrlEntry(exonUrlNoDataset, false);
        

        /*
         * gene model expression URL -- expression data of a gene model (used by spliceViz), eventually this should be replaced by the redesigned web service
         * no longer in use?
         * var modelExpressionUrl = this.pythonGtexServerUrl + 'v1/dataset/featureExpression?gencode_id=';
         * this.urlHash.modelExpressionUrl = new UrlEntry(modelExpressionUrl, false);
         */

        /*
         * gene model URL, no longer used?
         * var geneModelUrl = this.pythonGtexServerUrl + 'v1/dataset/collapsedGeneModel';
         * this.urlHash.geneModelUrl = new UrlEntry(geneModelUrl, false);
         */

        // collapsed gene model exon URL
        const geneModelExonUrl = this.pythonGtexServerUrl + 'v2/dataset/collapsedGeneModelExon';
        this.urlHash.geneModelExonUrl = new UrlEntry(geneModelExonUrl, false);

        // full collapsed gene model exon URL
        const fullGeneModelExonUrl = this.pythonGtexServerUrl + 'v2/dataset/fullCollapsedGeneModelExon';
        this.urlHash.fullGeneModelExonUrl = new UrlEntry(fullGeneModelExonUrl, false);

        // Services used by LocusBrowser
        const neighborGeneUrl = this.pythonGtexServerUrl + 'v2/reference/neighborGene';
        this.urlHash.neighborGeneUrl = new UrlEntry(neighborGeneUrl, false);

        const independentEqtlUrl = this.pythonGtexServerUrl + 'v2/association/independentEqtl';
        this.urlHash.independentEqtlUrl = new UrlEntry(independentEqtlUrl, false);

        const vepUrl = this.pythonGtexServerUrl + 'v2/dataset/variantEffect';
        this.urlHash.vepUrl = new UrlEntry(vepUrl, false);

        const funcAnnoUrl = this.pythonGtexServerUrl + 'v2/dataset/functionalAnnotation?datasetId=gtex_v8';
        this.urlHash.funcAnnoUrl = new UrlEntry(funcAnnoUrl, false);

        const fineMappingUrl = this.pythonGtexServerUrl + 'v2/association/fineMapping';
        this.urlHash.fineMappingUrl = new UrlEntry(fineMappingUrl, false);
        
        const singleCellExpressionUrl = this.pythonGtexServerUrl + 'v2/expression/singleNucleusGeneExpression?datasetId=gtex_snrnaseq_pilot&gencodeId=';
        this.urlHash.singleCellExpressionUrl = new UrlEntry(singleCellExpressionUrl, false);
        
        const singleCellExpressionSummaryUrl = this.pythonGtexServerUrl + 'v2/expression/singleNucleusGeneExpressionSummary';
        this.urlHash.singleCellExpressionSummaryUrl = new UrlEntry(singleCellExpressionSummaryUrl, false);
        
    };

    this.getNeighborGeneUrl = function() {
        return this.getUrl('neighborGeneUrl');
    };

    this.getIndependentEqtlUrl = function() {
        return this.getUrl('independentEqtlUrl');
    };

    this.getVepUrl = function() {
        return this.getUrl('vepUrl');
    };

    this.getFuncAnnoUrl = function() {
        return this.getUrl('funcAnnoUrl');
    };

    this.getGwasCatalogByLocationUrl = function() {
        return this.getUrl('gwasCatalogBylocationUrl');
    };

    this.getHistologySampleUrl = function() {
        return this.getUrl('histologySampleUrl');
    };

    this.getDatasetUrl = function() {
        return this.getUrl('datasetUrl');
    };

    /*
     * the URL of this function got commented out, this function becomes invalid
     * this.getGeneModelUrl = function(){
     *     return this.getUrl('geneModelUrl');
     * };
     */

    this.getGeneModelExonUrl = function() {
        return this.getUrl('geneModelExonUrl');
    };

    this.getFullGeneModelExonUrl = function() {
        return this.getUrl('fullGeneModelExonUrl');
    };
    
    this.getTranscriptUrlNoDataset = function() {
        return this.getUrl('transcriptUrlNoDataset');
    }
    
    this.getExonUrlNoDataset = function() {
        return this.getUrl('exonUrlNoDataset');
    }

    /*
     * this.getModelExpressionUrl = function(){
     *     return this.getUrl('modelExpressionUrl');
     * };
     */

    this.getTranscriptUrl = function() {
        return this.getUrl('transcriptUrl');
    };

    this.getExonUrl = function() {
        return this.getUrl('exonUrl');
    };

    this.getBiobankDownloadUrl = function() {
        return this.getUrl('biobankDownloadUrl');
    };

    this.getBiobankSampleUrl = function() {
        return this.getUrl('biobankSampleUrl');
    };

    this.getPtvUrl = function() {
        return this.getUrl('ptvUrl');
    };

    this.getAseSummaryUrl = function() {
        return this.getUrl('aseSummaryUrl');
    };

    this.getSdmSummaryUrl = function() {
        return this.getUrl('sdmSummaryUrl');
    };

    this.getTranscriptExpressionUrl = function() {
        return this.getUrl('transcriptExpressionUrl');
    };

    this.getMedianTranscriptExpressionUrl = function() {
        return this.getUrl('medianTranscriptExpressionUrl');
    };
    
    this.getMedianTranscriptExpressionClusteredUrl = function() {
        return this.getUrl('medianTranscriptExpressionClusteredUrl');
    };

    this.getGeneExpressionUrl = function() {
        return this.getUrl('geneExpressionUrl');
    };

    this.getMedianExonExpressionUrl = function() {
        return this.getUrl('medianExonExpressionUrl');
    };
    
    this.getMedianExonExpressionClusteredUrl = function() {
        return this.getUrl('medianExponExpressionClusteredUrl');
    };

    this.getMedianJunctionExpressionUrl = function() {
        return this.getUrl('medianJunctionExpressionUrl');
    };
    
    this.getMedianJunctionExpressionClusteredUrl = function() {
        return this.getUrl('medianJunctionExpressionClusteredUrl');
    };

    this.getEqtlBoxplotUrl = function() {
        return this.getUrl('eqtlBoxplotUrl');
    };

    this.getDynSqtlUrl = function() {
        return this.getUrl('dynSqtlUrl');
    };

    this.getDynIEqtlUrl = function() {
        return this.getUrl('dynIEqtlUrl');
    };

    this.getDynISqtlUrl = function() {
        return this.getUrl('dynISqtlUrl');
    };

    this.getFeatureUrl = function() {
        return this.getUrl('featureUrl');
    };

    this.getGeneUrl = function() {
        return this.getUrl('geneUrl');
    };
    
    this.getGeneSearchUrl = function() {
        return this.getUrl('geneSearchUrl');
    };
    
    this.getSingleCellExpressionUrl = function() {
        return this.getUrl('singleCellExpressionUrl');
    };
    
    this.getSingleCellExpressionSummaryUrl = function() {
        return this.getUrl('singleCellExpressionSummaryUrl');
    }

    this.getLDUrl = function() {
        return this.getUrl('ldUrl');
    };

    this.getLDByVariantUrl = function() {
        return this.getUrl('ldByVariantUrl');
    };

    this.getMetasoftUrl = function() {
        return this.getUrl('metasoftUrl');
    };

    /*
     * this.getSnpPosSearchUrl = function(){
     *     return this.getUrl('snpPosSearchUrl');
     * };
     * this.getSnpSearchUrl = function(){
     *     return this.getUrl('snpSearchUrl');
     * };
     */

    /*
     * this.getVariantSearchUrl = function(){
     *     return this.getUrl('snpVariantSearchUrl');
     * };
     */

    this.getVariantUrl = function() {
        return this.getUrl('variantUrl');
    };

    this.getVariantByLocationUrl = function() {
        return this.getUrl('variantByLocationUrl');
    };

    this.getEgeneUrl = function() {
        return this.getUrl('egeneUrl');
    };

    this.getMultiTissueEqtlUrl = function() {
        return this.getUrl('multiTissueEqtlUrl');
    };

    this.getMultiTissueEqtlUrlV4 = function() {
        return this.getUrl('multiTissueEqtlUrlV4');
    };

    this.getSpliceQtlUrl = function() {
        return this.getUrl('spliceQtlUrl');
    };

    this.getSpliceQtlAltransUrl = function() {
        return this.getUrl('spliceQtlAltransUrl');
    };

    this.getImprintingUrl = function() {
        return this.getUrl('imprintingUrl');
    };

    this.getImprintingPartialUrl = function() {
        return this.getUrl('imprintingPartialUrl');
    };

    this.getFileListUrl = function() {
        return this.getUrl('fileListUrl');
    };
    
    this.getOpenAccessFilesMetadata = function() {
        return this.getUrl('openAccessFilesMetadataUrl');
    }

    this.getTissueInfoUrl = function() {
        return this.getUrl('tissueInfoUrl');
    };

    this.getTissueInfoUrlV10 = function() {
        return this.getUrl('tissueInfoUrlV10');
    };

    this.getMedianGeneExpressionUrl = function() {
        return this.getUrl('medianGeneExpressionUrl');
    };
    
    this.getMedianGeneExpressionClusteredUrl = function() {
        return this.getUrl('medianGeneExpressionClusteredUrl');
    }

    this.getTopExpressedGeneUrl = function() {
        return this.getUrl('topExpressedGeneUrl');
    };

    this.getTopExpressedGeneUrlV10 = function() {
        return this.getUrl('topExpressedGeneUrlV10');
    };

    this.getSGeneUrl = function() {
        return this.getUrl('sGeneUrl');
    };

    this.getSingleTissueEqtlUrl = function() {
        return this.getUrl('singleTissueEqtlUrl');
    };

    this.getSingleTissueEqtlByLocationUrl = function() {
        return this.getUrl('singleTissueEqtlByLocationUrl');
    };

    this.getSingleTissueSqtlUrl = function() {
        return this.getUrl('singleTissueSqtlUrl');
    };

    this.getSingleTissueIEqtlUrl = function() {
        return this.getUrl('singleTissueIEqtlUrl');
    };

    this.getSingleTissueISqtlUrl = function() {
        return this.getUrl('singleTissueISqtlUrl');
    };

    this.getForestPmPlotUrl = function() {
        return this.getUrl('forestPmPlotUrl');
    };

    this.getNewsItemUrl = function() {
        return this.getUrl('newsItemUrl');
    };

    this.getMaintenanceMessageUrl = function() {
        return this.getUrl('maintenanceMessageUrl');
    };

    this.getContactUrl = function() {
        return this.getUrl('contactUrl');
    };

    this.getFastaUrl = function() {
        return this.getUrl('fastaUrl');
    };

    this.getFastaIndexUrl = function() {
        return this.getUrl('fastaIndexUrl');
    };

    this.getCytobandUrl = function() {
        return this.getUrl('cytobandUrl');
    };

    this.getGwasCatalogUrl = function() {
        return this.getUrl('gwasCatalogUrl');
    };

    this.getGwasCatalogByLocationUrl = function() {
        return this.getUrl('gwasCatalogByLocationUrl');
    };

    this.getGencodeBedFileUrl = function() {
        return this.getUrl('gencodeBedFileUrl');
    };

    this.getGencodeFullBedFileUrl = function() {
        return this.getUrl('gencodeFullBedFileUrl');
    };

    this.getBigWigFileUrl = function() {
        return this.getUrl('bigWigFileUrl');
    };

    this.getExpressionPcaUrl = function() {
        return this.getUrl('expressionPcaUrl');
    };

    this.getSampleUrl = function() {
        return this.getUrl('sampleUrl');
    };

    this.getGtexHistUrl = function() {
        return this.getUrl('gtexHistUrl');
    };

    this.getBrdHistUrl = function() {
        return this.getUrl('brdHistUrl');
    };

    this.getFineMappingUrl = function() {
        return this.getUrl('fineMappingUrl');
    };
    
    this.getV10GencodeGenomeVersion = function() {
        return 'gencodeVersion=v39&genomeBuild=GRCh38/hg38'
    }
    
    this.getV10Dataset = function(){
        return 'datasetId=gtex_v10'
    }

    this.getUrl = function(key) {
        return this.urlHash[key];
    };

    this.setUrl = function(key, url) {
    //this.urlHash[key] = url;
        this.urlHash[key] = new UrlEntry(url, false);
    };

    /*
     * validating API json structures
     * checking if expected attributes exist
     */
    this.validateVariantJson = function(json) {
        const dataKeys = ['variant', 'dataset'];
        dataKeys.forEach(d => {
            if (!json.hasOwnProperty(d)) throw 'Parser Error. Attribute '  + d + 'is not found.';
        });
        // dataset specific attributes
        if (!json['dataset'].hasOwnProperty('dbSnpBuild')) throw 'Parser Error: Attribute dbSnpBuild is missing.';

        // variant specific attributes
        if (json['variant'].length > 0) {
            const variant = json['variant'][0]; // use the first element for attr error-checking
            const attr = ['variantId', 'shorthand', 'snpId', 'chromosome', 'maf01', 'ref', 'alt'];
            attr.forEach(d => {
                if (!variant.hasOwnProperty(d)) throw 'Parser Error. Attribute ' + d + ' is not found';
            });
        }
        return;
    };
}


