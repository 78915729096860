<template>
  <div class="container-fluid">
    <div id="news_list" />
  </div>
</template>
<script>

import { RetrieveAllPaginatedData } from '../utils/pagination';
import { directory } from '@/utils/portal';

export default {
    mounted: function() {
        const divId = '#news_list';
        const selectedNewsId = this.$route.query.id;
        const newsItems_url = directory.getNewsItemUrl().url;
        RetrieveAllPaginatedData(newsItems_url).then(
            newsItems => {
                newsItems.sort((x, y) => {
                    const i = x.rank;
                    const j = y.rank;
                    return ((i<j)?1:(i>j)?-1:0);
                });

                if (selectedNewsId !== undefined) {
                    //If the user got here by clicking on a new item
                    //Display that one on top
                    const entries = newsItems.filter(d => {
                        return d.id == selectedNewsId;
                    });
                    if (entries.length > 0) {
                        newsItems.unshift(entries[0]); // always display the specified news item first
                    }
                }
                $(divId).append('<h3>News</h3><hr>');
                $.each(newsItems, (i, news) => {
                    const block_class = 'news_block';
                    let title_class = 'news_title';
                    const date_class = 'news_date';
                    if (news.id == selectedNewsId && i == 0) {
                        title_class = 'panel-title';
                    } else if (news.id == selectedNewsId) {
                        return; // the specified news item has two copies in data.newsItem, ignore the 2nd copy
                    }
                    i = $('<div class="'+block_class+'">'
                           +'<span class="'+date_class+'">'
                           +news.dateCreated+'<br>'
                           +'</span>'
                           +'<span class="'+title_class+'">'
                           + news.title+'<br>'
                           +'</span>'

                           +news.newsText+'<br>'
                           +'</div><br>');
                    $(divId).append(i);
                }); // end each
            }
        );
    }
};
</script>
