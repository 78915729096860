<template>
  <div class="container-fluid">
    <h2>
      GTEx Locus Browser (Variant-centric)<img src="@/assets/images/GTEx-locus-browser-icon.svg" width="40px" style="margin: 0px 0px 0px 10px;">
    </h2>
    <hr>
    <div class="row">
      <div class="col-sm-12 col-lg-4">
        <form id="geneSearchForm" style="padding: 5px;">
          <div class="form-group">
            <!-- form-group for optimum spacing between the label and its associated form input element -->
            <b>Browse association by Variant</b>
            <!-- <small>
              <a data-toggle="modal" data-target="#helpModal" style="cursor: pointer;">
                <i class="fas fa-info-circle" /> Help
              </a>
            </small> -->
            <input id="variantInput" type="text" class="form-control" placeholder="Enter a variant ID (e.g. chr11_65592772_G_A_b38) or rs ID (e.g. rs34278912 from v151) " style="font-size:12px;" />
          </div>
        </form>
      </div>
      <gtex-browser-spinner :id="spinnerId" style="margin-top: 25px;"/>
    </div>
    <hr>
    <section>
      <div class="row">
        <!-- error message block -->
        <div class="col-sm-12 col-lg-4">
          <div id="browser-error" style="color:brown" />
        </div>
      </div>
    </section>
    <section id="toolbar" style="padding: 0 15px;">
      <div class="row">
        <div>
          <b style="margin-right: 10px;">You are currently viewing: </b> <span id="q-variant" />
        </div>
      </div>
      <br>
      <div class="row">
        <b style="margin-right: 10px; margin-left: 0px; ">Data filtering options</b>
        <div class="btn-group btn-group-sm" style="margin-right: 10px; padding-right: 10px; border-right: 1px solid black;">
          <a id="show-data-menu" class="btn btn-default" data-toggle="modal" data-target="#qtl-modal">
            <i class="fas fa-filter" /> Filter Menu
          </a>
        </div>
        <b style="margin-right: 10px;">Variant view mode</b>
        <div class="btn-group btn-group-sm">
          <a id="ld-mode" class="btn btn-default active">
            LD only
          </a>
          <a id="win-mode" class="btn btn-default">
            Genomic window
          </a>
        </div>
        <div id="ld-cutoff-ui" class="btn-group" style="padding-right: 10px; border-right: 1px solid black;">
          <em class="btn" style="margin-right: 10px; margin-left: 10px; ">LD R<sup>2</sup> cutoff</em>
          <input id="ld-slider" class="btn" type="range" min="0.1" max="1" value="0.20" step="0.01" style="width: 200px;">
          <input id="ld-cutoff" type="text" value="0.20" style="width: 45px; ">
        </div>
        <div id="win-size-ui" class="btn-group" style="display: none; padding-right: 10px; border-right: 1px solid black;">
          <em class="btn" style="margin-right: 10px; margin-left: 10px;">Window size</em>
          <input id="win-slider" class="btn" type="range" min="10" max="100" value="10" step="1" style="width: 200px;">
          <input id="win-size" type="text" value="10" style="width: 50px"> kb
        </div>
        <b style="margin-right: 10px; margin-left: 10px; ">Fine-mapping</b>
        <div class="btn-group btn-group-sm">
          <a id="fine-mapping-off" class="btn btn-default active">
            Off
          </a>
          <a id="fine-mapping-on" class="btn btn-default">
            On
          </a>
        </div>
        <b style="margin-right: 10px; margin-left: 10px;">Dim QTL bubbles</b>
        <div class="btn-group btn-group-sm">
          <a id="qtl-view-on" class="btn btn-default active">
            No
          </a>
          <a id="qtl-view-off" class="btn btn-default">
            Yes
          </a>
        </div>
      </div>
      <hr>
    </section>
    <div id="browser-toolbar" class="row" style="font-size: 0.75em; display:none;" />
    <div id="locus-browser">
    <!-- Root DOM for the SVG -->
    </div>

    <!-- QTL data filter menu modal -->
    <!-- Modal. reference: https://getbootstrap.com/docs/4.0/components/modal/ -->
    <div id="qtl-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="qtlFilterModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="qtlFilterModalTitle" class="modal-title">
              QTL Data Filter Menu
            </h5>
            <button id="modal-close-btn" type="button" class="btn btn-info" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fas fa-filter" /> Update</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-3">
                <h5>QTL Type</h5>
                <form id="data-type-menu">
                  <input type="checkbox" name="dataType" value="eQTL" checked="true">eQTL<br>
                  <input type="checkbox" name="dataType" value="sQTL">sQTL<br>
                </form>
              </div>
              <div class="col-sm-9">
                <h6>GTEx tissues with association data</h6>
                <div class="btn-group btn-group-sm">
                  <a id="modal-clear-tissues-btn" type="button" class="btn btn-sm btn-default">Deselect All</a>
                  <a id="modal-all-tissues-btn" type="button" class="btn btn-sm btn-default">Select All</a>
                </div>
                <form id="tissue-menu">
                  <!--programmatically generated GTEx tissues-->
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button id="modal-filter-btn" type="button" class="btn btn-info" data-dismiss="modal">
              <i class="fas fa-filter" /> Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { directory } from '@/utils/portal';
import router from '@/router/router';
import { RetrieveAllPaginatedData } from '../utils/pagination';
import GtexBrowserSpinner from './GtexBrowserSpinner.vue';
export default {
    components: { GtexBrowserSpinner },
    data: function() {
        return {
            urls: {
                variantByLocation: (chr, start, end) => { return `${directory.getVariantByLocationUrl().url}?sortBy=pos&sortDirection=asc&chromosome=${chr}&start=${start}&end=${end}`; },
                ld: id => { return `${directory.getLDByVariantUrl().url}?variantId=${id}`; },
                variantEqtl: id => { return `${directory.getSingleTissueEqtlUrl().url}?variantId=${id}`; },
                variantSqtl: id => { return `${directory.getSingleTissueSqtlUrl().url}?variantId=${id}`; },
                gwasCat: (chr, hExtent) => {
                    const gwasCatUrlRoot = directory.getGwasCatalogByLocationUrl().url;
                    return gwasCatUrlRoot + `?chromosome=${chr}&start=${hExtent[0]}&end=${hExtent[1]}`;
                },
                tissueInfo: directory.getTissueInfoUrl().url,
                geneEqtl: id => { return `${directory.getSingleTissueEqtlUrl().url}?gencodeId=${id}`; },
                geneSqtl: id => { return `${directory.getSingleTissueSqtlUrl().url}?gencodeId=${id}`; },
            },
            spinnerId: 'locus-browser-spinner'
        };
    },
    mounted: function() {
        this.init();
    },
    methods: {
        init() {
            const run = varId => {
                const domId = 'locus-browser';
                LocusBrowserVC.init(varId, domId, 'LD', this.urls, this.spinnerId);
                this.updateVariant(varId);
            };
            // Next implement the text search
            const queryVariantId = this.$route.params.variantId||'chr11_66545555_A_G_b38';
            run(queryVariantId);
            $('#variantInput').keypress(e => {
                if (e.keyCode == 13) {
                    $('#locus-browser').html('');
                    // bind the enter key
                    e.preventDefault(); // Note: prevent the default behavior of the enter key, which is refreshing the page
                    const queryVariant = $('#variantInput').val();
                    if (queryVariant.startsWith('chr') && queryVariant.endsWith('b38')) {
                        run(queryVariant);
                    } else {
                        let url = undefined;
                        const getVariant = link => {
                            RetrieveAllPaginatedData(url).then(data => {
                                run(data[0].variantId);
                            }).catch(err => {
                                console.error(err);
                                $('#locus-browser').html('Web service has returned an error ' + err.responseText);
                            });
                        };
                        if (queryVariant.startsWith('rs')) {
                            url = directory.getVariantUrl().url + 'snpId=' + queryVariant;
                            getVariant(url);
                        } else if (queryVariant.startsWith('chr')) {
                            const input = queryVariant.split('_');
                            url = directory.getVariantUrl().url + 'chromosome=' + input[0] + '&pos=' + input[1];
                            getVariant(url);
                        } else {
                            $('#locus-browser').text('Invalid variant ID');
                        }
                    }
                }
            });
        },
        /**
         * Update the search box and URL with the current variant gene
         * @param {String} variantId
         */
        updateVariant(variantId) {
            const routeName = this.$route.name;
            $('#variantId').prop('placeholder', variantId); // display the query variant in the search box
            router.push({ name: routeName, params: { variantId: variantId } }); // add the query gene to the page's URL
        }
    }
};
</script>
