<template>
  <div class="container-fluid">
    <h2>
      Tissue Page <img src="@/assets/images/GTEx-brain-icon.svg" width="40px" style="margin: 0px 0px 0px 10px;">
    </h2>
    <!-- QTL violin plot modal root DIVs -->
    <div :id="eqtlViolinDivId" />
    <div :id="sqtlViolinDivId" />

    <!-- ForestPMPlot documentation modal -->
    <div id="fpmplot-doc-modal" class="modal fade" aria-hidden="true" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">x</span>
            </button>
            <h4 class="modal-title">
              Multi-tissue eQTL Comparison Info
            </h4>
          </div>
          <div class="modal-body">
            <div
              id="fpmInfo"
              class="fpm-info"
            >
              <div><br><b> NES </b>:The slope of the linear regression of normalized expression data versus the three genotype categories using single-tissue eQTL analysis, representing eQTL effect size. The normalized expression values are based on quantile normalization within each tissue, followed by inverse quantile normalization for each gene across samples.</div>
              <div><br><b> p-value</b>:From a t-test that compares observed beta from single-tissue eQTL analysis to a null beta of 0. </div>
              <div><br><b> m-value</b>:The posterior probability that an eQTL effect exists in each tissue tested in the cross-tissue meta-analysis. The m-value ranges between 0 and 1 (Han and Eskin, PLoS Genetics 8(3): e1002555, 2012).</div>
              <div><br><b> m-value interpretation</b>:<br>Small m-value (e.g. &lt;0.1): The tissue is predicted to NOT have an eQTL effect. <br> Large m-value (e.g. >0.9): The tissue is predicted to HAVE an eQTL effect. <br> Otherwise: The prediction of the existence of an eQTL effect is ambiguous.</div>
              <br><br>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3 id="qTissue" />
    <div id="eVersion" />

    <!-- tissue select menu -->
    <br>

    <div id="sigEgeneSection">
      <div class="row">
        <div class="col-sm-12 col-lg-6">
          <form id="sigEgeneByTissueForm" action="">
            <select id="tissueSelect" name="tissueSelect" class="tissueSelect col-xs-8" />

            <button id="queryEgeneByTissueButton" class="submitButton">
              Change Tissue
            </button>
          </form>
        </div>
      </div>
    </div>
    <br>
    <div id="tissueMetaData" class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          Tissue Information
        </h4>
      </div>
      <div class="panel-body">
        <div class="col-xs-12 col-lg-6">
          <table class="table table-condensed table-bordered">
            <thead>
              <tr>
                <th><span id="qTissue2" /></th>
                <th>Counts</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total samples</td>
                <td><span id="rSamples" /></td>
              </tr>
              <tr>
                <td>Total samples with donor genotype</td>
                <td><span id="rSamplesGtype" /></td>
              </tr>
              <tr>
                <td>
                  Number of significant eGenes
                  <span class="gtooltip fas                                                 fa-info-circle"
                        data-toggle="tooltip" data-html="true" data-placement="auto right"
                        title="eGenes (eQTL Genes) are genes that
                                          have at least one significant <i>cis</i>-eQTL
                                          acting upon them.
                                          <br/><br/>
                                          To ensure that the reported assocations are
                                          not due to chance (e.g., due to effects of
                                          linkage disequilibrium), we employ a
                                          permutation-based approach in which
                                          the test statistic is the minimum
                                          nominal p-value from all the
                                          <i>cis</i>-variants per gene. eGenes are
                                          called at 0.05 FDR, using the q-value approach
                                          applied to the empirical p-values."
                  />
                </td>
                <td><span id="eGenes" /></td>
              </tr>
              <tr>
                <td>
                  Number of significant sGenes
                  <span
                    class="gtooltip fas                                                 fa-info-circle"
                    data-toggle="tooltip"
                    data-html="true"
                    data-placement="auto right"
                    title="sGenes (sQTL Genes) are genes that
                                          have at least one significant <i>cis</i>-sQTL
                                          acting upon them."
                  />
                </td>
                <td><span id="sGenes" /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div id="downloadTempDiv">
      <!-- do not erase this empty div, this div is in use by
                the download SVG function for cloning a copy of SVG, editing this copy by adding the missing CSS styles
                before saving to file
            -->
    </div>
    <div id="forestPmDiv" class="col-xs-12" />

    <div id="topExpress" class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-toggle="collapse" href="#topExpressBody">
            Top 100 Expressed Genes in
            <span class="queryTissue" style="color:#C86F6F;" />
          </a>
        </h4>
      </div>
      <div id="topExpressBody" class="panel-collapse collapse in">
        <div class="panel-body">
          <div id="topExpressedDiv">
            <table id="topExpressedGenes" />
          </div>
        </div>
      </div>
    </div>

    <div id="singleT" class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a data-toggle="collapse" href="#singleEqtl">
            Single-Tissue eQTLs in
            <span class="queryTissue" style="color:#C86F6F;" />
          </a>
        </h4>
      </div>
      <div id="singleEqtl" class="panel-collapse collapse in">
        <div class="panel-body">
          <div id="eqtlByIdInQueryTissueSection" class="row">
            <!--query eGene by gene ID in specified tissue-->

            <div class="col-xs-12 col-lg-6">
              <form id="eqtlByIdForm" action="">
                <input id="queryId"
                       type="text"
                       name="queryId"
                       placeholder="Gene or SNP Id..."
                       class="ui-widget-content geneId required round-corner col-xs-7"
                       style="margin:0;"
                >
                <button id="queryEqtlByIdButton" class="submitButton">
                  Search significant eQTL By ID
                  <span class="gtooltip fas fa-info-circle"
                        data-toggle="tooltip"
                        data-container="body"
                        data-placement="auto right"
                        title="This searches our precalculated eQTLs
                                            (FDR <= 0.05) for tissues having more than
                                            70 samples, using a +/-1 Mb cis-window around
                                            the transcription start site (TSS).
                                            More details are presented on the
                                            Documentation page (Analysis Methods)."
                  />
                </button>
              </form>
            </div>
          </div>
          <div class="row">
            <div id="eqtlResultDiv" />
          </div>
          <div id="tableDiv">
            <div id="tableHeaderDiv" />
            <table id="gridTable" />
          </div>

          <div>
            <hr>
            <h5 id="eGeneTitle">
              Significant eGenes
              <small style="color:#333">
                <span class="gtooltip fa fa-info-circle"
                      data-toggle="tooltip"
                      data-html="true"
                      data-placement="auto right"
                      title="This table reports significant eQTLs.
                                      <br/><br/>
                                      More details can be found on the
                                      Documentation page (Analysis Methods)."
                />
              </small>
            </h5>
          </div>


          <div id="eGeneTableDiv">
            <table id="eGeneTable" />
          </div>

          <div>
            <hr>
            <h5 id="sGeneTitle">
              Significant sGenes
              <small style="color:#333">
                <span class="gtooltip fa fa-info-circle"
                      data-toggle="tooltip"
                      data-html="true"
                      data-placement="auto right"
                      title="This table reports significant sQTLs.
                                      <br/><br/>
                                      More details can be found on the
                                      Documentation page (Analysis Methods)."
                />
              </small>
            </h5>
          </div>

          <div id="sGeneTableDiv">
            <table id="sGeneTable" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import QtlTableMaker from '@/mixins/QtlTableMaker';
import router from '@/router/router';
import { setEqtlFormVars, portalClient, directory, initialUrl, generateSnpUrl, generateGeneUrl } from '@/utils/portal';
import { getTissueDisplayName, getTissueId, getAllTissuesGroupedByMinN, eqtlTissueSampleSum, eqtlTissueSampleWithGenotypeSum, totalNumEgenes, eqtlEgeneSum, tissueMetadataJson, getSampleCount } from '@/utils/tissue';
import MessageHandler from '@/utils/message-handler';
import AutoComplete from '@/utils/autocomplete';
import EqtlTableMaker, { generateForestPmPlotUrl } from '@/utils/eqtl-table-maker';
import TableUtils from '@/utils/table-utils';
import { appendUserInfo } from '@/utils/models/gtex-webservice';
import downloadUtils from '@/utils/downloadUtil';

import details_open_path from '@/assets/images/details_open.png';
import details_close_path from '@/assets/images/details_close.png';
import { RetrieveAllPaginatedData } from '../utils/pagination';

export default {
    mixins: [QtlTableMaker],
    data: function() {
        return {
            subTableId: 'sigByGeneSubTable',
            eGeneTableId: 'eGeneTable',
            eGeneTableDivId: 'eGeneTableDiv',
            eGeneTableHeaderRaw: 'eGeneTableHeader',
            eGeneTableHeader: '#'+this.eGeneTableHeaderRaw,
            sGeneTableId: 'sGeneTable',
            sGeneTableDivId: 'sGeneTableDiv',
            sGeneTableHeaderRaw: 'sGeneTableHeader',
            sGeneTableHeader: '#'+this.sGeneTableHeaderRaw,
            sGeneSubTableId: 'sGeneSigByGeneSubTable',
            geneName: null,
            tissueName: null,
            tissueDisplayName: null,
            openRow: null,
            batchResults: null,
            eqtlViolinDivId: 'eqtl-violin-modal',
            sqtlViolinDivId: 'sqtl-violin-modal'
        };
    },
    watch: {
        '$route'(newRoute, oldRoute) {
            const tissueName = newRoute.query.tissueName;
            let tissueDisplayName = newRoute.params.tissueDisplayName;
            const count = newRoute.params.count;
            const geneId = newRoute.query.geneId;
            const type = newRoute.params.type;
            const snpId = newRoute.query.snpId;
            const variantId = newRoute.query.variantId;

            if (tissueDisplayName === undefined) tissueDisplayName = getTissueDisplayName(tissueName);
            const args = {
                tissueName: tissueName,
                tissueDisplayName: tissueDisplayName,
                count: count,
                geneId: geneId,
                type: type,
                snpId: snpId,
                variantId: variantId
            };
            $('#forestPmDiv').html('');
            setEqtlFormVars(args);
            this.goEdata(args);
        }
    },
    mounted: function() {
        $('input[placeholder], textarea[placeholder]').placeholder();

        const tissueName = this.$route.query.tissueName;
        let tissueDisplayName = this.$route.params.tissueDisplayName;
        const count = this.$route.params.count;
        const geneId = this.$route.query.geneId;
        const type = this.$route.params.type;
        const snpId = this.$route.query.snpId;
        const variantId = this.$route.query.variantId;

        if (tissueDisplayName === undefined) tissueDisplayName = getTissueDisplayName(tissueName);

        const args = {
            tissueName: tissueName,
            tissueDisplayName: tissueDisplayName,
            count: count,
            geneId: geneId,
            snpId: snpId,
            variantId: variantId,
            type: type
        };
        this.initialize();
        setEqtlFormVars(args);
        this.goEdata(args);
    },
    methods: {
        initialize() {
            this.createDialogForQtlViolinPlots(this.eqtlViolinDivId, 'eQTL Violin Plots');
            this.createDialogForQtlViolinPlots(this.sqtlViolinDivId, 'sQTL Violin Plots');
            this.populateTissueDropdown();
            this.fetchEvents();
            AutoComplete.geneAutoComplete('#queryId');
        },
        populateTissueDropdown() {
            const allTissueOptionTagsGroupedByMinN = getAllTissuesGroupedByMinN();
            $.each(allTissueOptionTagsGroupedByMinN, (k, optionTag) => {
                $('#tissueSelect').append($(optionTag));
            });
        },
        fetchEvents() {
            this.fetchEgeneByTissueButton();
            this.fetchEqtlByIdAndTissueEnterKey();
            this.fetchEqtlByIdAndTissueButton();
        },
        fetchEgeneByTissueButton() {
            const parent = this;
            $('#queryEgeneByTissueButton').button().click(e => {
                const params = { 'action': 'getEgene' };
                return fetchEdataByTissue(params);
            });
        },
        fetchEqtlByIdAndTissueEnterKey() {
            const parent = this;
            $('#queryId').keypress(event => {
                const keycode = (event.keyCode?event.keyCode:event.which);
                if (keycode == '13') {
                    const params = { 'action': 'getEqtl' };
                    return fetchEdataByTissue(params);
                }
            });
        },
        fetchEqtlByIdAndTissueButton() {
            const parent = this;
            $('#queryEqtlByIdButton').button().click(event => {
                const params = { 'action': 'getEqtl' };
                return fetchEdataByTissue(params);
            });
        },
        goEdata(params) {
            const parent = this;
            this.clearEqtlPlot();
            this.setEqtlBrowseInputVars(params.tissueName);
            this.reportMetadata(params.tissueName, params.tissueDisplayName);

            /**** call the function to build the top 100 expressed gene table ****/
            // todo: need to restructure the code. Now, we are building a tissue page. We reuse a lot of code from eqtl.js, but it starts to report more than just eqtl data for a given tissue... So, the code should be re-written for clarity.

            this.getTopExpressed(params.tissueName);
            $('#topExpress').show();

            const count = (params.count != undefined)?params.count:this.getSampleCount(params.tissueName);

            if (count < 70) { // small tissues have no precomputed data
                $('#eqtlByIdInQueryTissueSection').hide(); // hide the gene/snp in tissue search box
                $('#eGeneTitle').hide();
                $('#sGeneTitle').hide();
                // clean up the page
                this.deleteGenesTable();
                this.setEgeneAndTopExpressedGeneHeaders(params.tissueDisplayName);
                portalClient.deleteTable();
                $('#queryId').val('');

                const headerText = '<strong>' + params.tissueDisplayName + '</strong> has no precomputed eGene/eQTL or sGene/sQTL data';
                portalClient.setTableHeader(headerText);
            } else { // large tissues with precomputed eGenes and eQTLs
                $('#eqtlByIdInQueryTissueSection').show();
                $('#eGeneTitle').show();
                $('#sGeneTitle').show();
                if (params.type == 'chromPos' || params.type == 'rsId' || params.snpId !== undefined || params.variantId !== undefined) {
                    this.goEqtlBySnpV2(params);
                } else if (params.geneId != undefined) {
                    this.goEqtlByGeneV2(params);
                } else {
                    // no queryIDs
                    const headerText = '';
                    // clean up the eqtl table
                    $('#queryId').val('');
                    portalClient.deleteTable();
                    portalClient.setTableHeader(headerText);
                }
                this.deleteGenesTable();
                this.setHeaders(params.tissueDisplayName);
                // fetch eGenes
                this.generateEqtlSignificantTable(params.tissueName, params.tissueDisplayName);
                // fetch sGenes
                this.generateSGeneTable(params.tissueName, this.sGeneSubTableId, this.sqtlViolinDivId);
            }
            return false;
        },
        clearEqtlPlot() {
            $('#eqtlResultDiv').html('');
        },
        setEqtlBrowseInputVars(tissueName) {
            const tissueSelect = $('#tissueSelect');
            const selectedTissueName = tissueSelect.val();
            if (tissueName != selectedTissueName) {
                tissueSelect.val(tissueName);
            }
            tissueSelect.val(tissueName);
        },
        reportMetadata(tissueName, tissueDisplayName) {
            $('#qTissue').text(tissueDisplayName);
            $('#qTissue2').text(tissueDisplayName);
            const t = tissueMetadataJson[tissueName];
            // fill in the HTML text
            $('#rSamples').text(t.rnaSeqSampleCount);
            $('#rSamplesGtype').text(t.rnaSeqAndGenotypeSampleCount);
            t.eGeneCount = t.eGeneCount == null?'Not Available': t.eGeneCount;
            t.sGeneCount = t.sGeneCount == null?'Not Available': t.sGeneCount;
            $('#eGenes').text(t.eGeneCount);
            $('#sGenes').text(t.sGeneCount);
        },
        deleteGenesTable() {
            let blankHtml = '<div id="'+this.eGeneTableHeaderRaw+'"></div><table id="'+this.eGeneTableId+'"></table>';
            $(`#${this.eGeneTableDivId}`).html(blankHtml);
            $(`#${this.eGeneTableDivId}`).hide();

            blankHtml = '<div id="'+this.sGeneTableHeaderRaw+'"></div><table id="'+this.sGeneTableId+'"></table>';
            $(`#${this.sGeneTableDivId}`).html(blankHtml);
            $(`#${this.sGeneTableDivId}`).hide();
        },
        setEgeneAndTopExpressedGeneHeaders(displayName) {
            const versionInfo = portalClient.versionInfo.getMarkup('singleTissue');
            $('#eVersion').html(versionInfo);
            $('.queryTissue').text(displayName);
            $(this.eGeneTableHeader).show();
            $(`#${this.eGeneTableDivId}`).show();
        },
        setHeaders(displayName) {
            const versionInfo = portalClient.versionInfo.getMarkup('singleTissue');
            $('#eVersion').html(versionInfo);
            $('.queryTissue').text(displayName);
            $(this.eGeneTableHeader).show();
            $(`#${this.eGeneTableDivId}`).show();
            $(this.sGeneTableHeader).show();
            $(`#${this.sGeneTableDivId}`).show();
        },
        getSampleCount(tissueName) {
            const tissue = tissueMetadataJson[tissueName];
            const count = tissue.rnaSeqAndGenotypeSampleCount;
            return count;
        },
        generateEqtlSignificantTable(tissueSiteDetailId, tissueSiteDetail) {
            const eqtlTableMaker = new EqtlTableMaker();
            const afcInfo = '<div class="gtooltip fas fa-info-circle "' +
                        'data-toggle="tooltip" data-html="true" ' +
                        'data-placement="auto" data-container="body" ' +
                        'title="Allelic Fold-Change (in log2 scale)"' + '>'+ '</div>';
            const objectHeadings = ['', 'Gencode Id', 'Gene Symbol', 'Nominal P-Value', 'Empirical P-Value', 'Q-Value', afcInfo + ' aFC', 'Tissue', 'Actions'];
            const oTable = $(`#${this.eGeneTableId}`);

            const tableElt = oTable[0];
            TableUtils.buildTableHtml(tableElt, objectHeadings);

            const buttonProps = {
                'buttons': [{
                    extend: 'copy',
                    exportOptions: { columns: [1, 2, 3, 4, 5, 6, 7] }
                },
                {
                    extend: 'csv',
                    exportOptions: { columns: [1, 2, 3, 4, 5, 6, 7] }
                }]
            };

            // this is to prevent DataTables from putting warnings or errors in an alert box.
            $.fn.dataTableExt.sErrMode = 'throw';
            const parent = this;

            // filter the egenes by tissue
            const egeneUrl = `${directory.getEgeneUrl().url}?tissueSiteDetailId=${tissueSiteDetailId}`;

            const columnProps =
                [
                    {
                        name: 'icon', orderable: false, searchable: false, defaultContent: '',                  // open-close icon
                        'render': function(data, type, row, meta) {
                            const html = '<img class="expandCollapse" src="' + details_open_path + '">';
                            return html;
                        }
                    },

                    { name: 'gencodeId', data: 'gencodeId' },
                    {
                        name: 'geneSymbol',
                        defaultContent: '',
                        'render': function(data, type, row, meta) {
                            const url = parent.generateGeneExpressUrl(row.gencodeId, row.geneSymbol);
                            return url;
                        }
                    },
                    { name: 'pValue', data: 'pValue', type: 'scientific' }, // Nominal P-value
                    { name: 'empiricalPValue', data: 'empiricalPValue', type: 'scientific' },
                    { name: 'qValue', data: 'qValue', type: 'scientific' }, // Q-value
                    { name: 'log2afc', data: 'log2AllelicFoldChange', type: 'scientific' }, // allelic fold-change
                    {
                        name: 'tissueSiteDetailId', defaultContent: '',
                        'render': function(data, type, row) {
                            return eqtlTableMaker.generateTissueUrl(row.tissueSiteDetailId);
                        }
                    },
                    {
                        name: 'actions', searchable: false, orderable: false, defaultContent: '',
                        'render': function(data, type, row, meta) {
                            const url = parent.generateBrowserUrl(row.gencodeId, tissueSiteDetailId) + ', ' + parent.generateLocusBrowserUrl(row.gencodeId);
                            return url;
                        }
                    }
                ];

            try {
                if ($.fn.dataTable.isDataTable(oTable)) {
                    const t = oTable.DataTable();
                    t.destroy();
                }

                oTable.dataTable({
                    serverSide: true,
                    paging: true,
                    pageLength: 10,
                    ajax: {
                        url: egeneUrl,
                        data: function(d) {
                            const sort_column_index = d.order[0].column;
                            const direction = d.order[0].dir;
                            const sort_column_name = d.columns[sort_column_index].name;
                            if (sort_column_name != 'icon') { d.sortBy = sort_column_name; }
                            if (direction == 'asc') {
                                d.sortDirection = 1;
                            } else if (direction == 'desc') {
                                d.sortDirection = -1;
                            }
                            d.sortDirection = direction;
                            const search_term = d.search.value;
                            if (search_term) { d.searchTerm = search_term; }
                            if ('start' in d) {
                                d.page = d.start / d.length;
                                d.start = null;
                            }
                            if ('length' in d) {
                                d.pageSize = d.length;
                                d.length = null;
                            }
                            d.order = null;
                            d.columns = null;
                            d.search = null;
                        },
                        dataSrc: 'egene'
                    },
                    jQueryUI: true,
                    deferRender: true,
                    processing: true,
                    paginationType: 'full_numbers',
                    columns: columnProps,
                    dom: 'B<"clear">lfrtip',
                    orderClasses: false,
                    buttons: buttonProps,
                    language: {
                        'sEmptyTable': 'Please wait - Fetching records'
                    },

                    fnInitComplete: function(oSettings) {
                        oSettings.oLanguage.sEmptyTable = 'No significant eQTLs were found for tissue ' +
                            tissueSiteDetail;
                        $(oTable).find('.dataTables_empty').html('No significant eQTLs were found for tissue ' +
                                tissueSiteDetail);
                    }
                });
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err + ' Unable to create table for tissue: '
                    + tissueSiteDetail);
            }

            // this is required for row_selected styles to work.
            oTable.addClass('display');
            oTable.show(); // in case it was hidden
            //oTable.fnSort([ [3, 'asc'] ]);
            $(`#${this.eGeneTableId} tbody`).on('click', 'td img.expandCollapse', function() {
                const row = this.parentElement.parentElement;
                const collapseExpandCell = this;
                if (oTable.fnIsOpen(row)) {
                    /* This row is already open - close it */
                    collapseExpandCell.src = details_open_path;
                    oTable.fnClose(row);
                    parent.openRow = null;
                } else {
                    if (parent.openRow != null) {
                        /* only one row open at a time */
                        parent.openRow.firstChild.firstChild.src = details_open_path;
                        oTable.fnClose(parent.openRow);
                        parent.openRow = null;
                    }

                    /* Open this row */
                    collapseExpandCell.src = details_close_path;
                    const data = oTable.fnGetData(row);
                    const selectedGeneName = data.geneSymbol;
                    const tissueSiteDetailId = data.tissueSiteDetailId;
                    const tissueSiteDetail = getTissueDisplayName(tissueSiteDetailId);
                    const html = parent.buildSubTableHtml();
                    oTable.fnOpen(row, html, 'detailrow');
                    parent.openRow = row;
                    parent.retrieveSubTable(selectedGeneName, tissueSiteDetailId, tissueSiteDetail);
                }
            });
            return oTable;
        },
        generateGeneExpressUrl(geneId, displayText) {
            /*
             * a string with spaces i.e. "ENSG00001234.1 something something" passed in
             * as a geneId indicates some sort of error being
             * printed in the test your own table - don't generate a link
             */
            if (-1 != geneId.indexOf(' ') || geneId == 'N/A') return geneId;
            return `<a href="/home/gene/${geneId}">${displayText}</a>`;
        },
        generateBrowserUrl(geneId, tissueName) {
            return `<a href="/home/browseEqtls?location=${geneId}&tissueName=${tissueName}">IGV Browser</a>`;
        },
        generateLocusBrowserUrl(geneId) {
            return `<a href="/home/locusBrowserPage/${geneId}">Locus Browser</a>`;
        },
        buildSubTableHtml() {
            const subTableHeadings = ['Gencode Id', 'Gene Symbol', 'Variant ID', 'SNP', 'SNP ID', 'P-Value', 'P-Value', 'NES', 'NES', 'Tissue', 'Actions'];
            let html = '<table id="' + this.subTableId + '"><thead><tr>';
            const numHeadings = subTableHeadings.length;
            for (let i = 0; i < numHeadings; i++) {
                html += '<th>' + subTableHeadings[i] + '</th>';
            }
            html += '</tr></thead><tbody></tbody></table>';
            return html;
        },
        retrieveSubTable(geneName, tissueSiteDetailId, tissueDisplayName) {
            const urlSuffix = ('?' + ('ENSG' === geneName.substr(0, 4) ? 'gencodeId' : 'geneSymbol=') + geneName
                             + '&tissueSiteDetailId=' + tissueSiteDetailId);
            const urlEntry = directory.getSingleTissueEqtlUrl();
            const url = urlEntry.url + urlSuffix;
            const parent = this;
            const revisedUrl = appendUserInfo(url);
            $.ajax({
                url: revisedUrl,
                type: 'GET',
                dataType: 'json',
                success: function(data) {
                    parent.generateSubTable(data, geneName, tissueSiteDetailId, tissueDisplayName);
                },
                error: function(response) {
                    alert('Error: cannot generate subtable ' + response.responseText);
                }
            });
        },
        generateSubTable(data, geneName, tissueSiteDetailId, tissueDisplayName) {
            const eqtlTableMaker = new EqtlTableMaker();
            const eqtls = data['singleTissueEqtl'];

            const parent = this;
            let oTable = null;
            $.fn.dataTableExt.sErrMode = 'throw';

            // Note: the column headers are hard-coded in this method: this.buildSubTableHtml
            const columnProps =
                [
                    { data: 'gencodeId' },
                    {
                        defaultContent: '',
                        'render': function(data, type, row) {
                            return parent.generateGeneExpressUrl(row.gencodeId,
                                row.geneSymbol);
                        }
                    },
                    {
                        defaultContent: '', // variantID link
                        'render': function(data, type, row) {
                            return generateSnpUrl(row.variantId);
                        }
                    },
                    // Kat's note: do we still need these invisible columns?
                    { data: 'snpId', visible: false, searchable: false },    // SnpId
                    {
                        defaultContent: '',                        // SNP id link, visible
                        'render': function(data, type, row, meta) {
                            return generateSnpUrl(row.snpId);
                        }
                    },
                    { data: 'pValue', visible: false, searchable: false }, // P-value
                    {
                        defaultContent: '',
                        'render': function(data, type, row, meta) {
                            return Number(row.pValue).toPrecision(2);
                        }
                    },
                    { data: 'nes', visible: false, searchable: false }, // normalized effect size
                    {
                        defaultContent: '',
                        'render': function(data, type, row, meta) {
                            return Number(row.nes).toPrecision(2);
                        }
                    },
                    {
                        defaultContent: '',
                        'render': function(data, type, row, meta) {
                            return eqtlTableMaker.generateTissueUrl(row.tissueSiteDetailId);
                        }
                    },
                    {
                        defaultContent: '',
                        'render': function(data, type, row, meta) {
                            return parent.generateEqtlPlotAndBrowserUrl(row.gencodeId,
                                row.snpId,
                                row.variantId,
                                row.tissueSiteDetailId,
                                row.geneSymbol);
                        }
                    }
                ];

            const buttonProps = {
                'buttons': []
            };

            try {
                oTable = $('#' + this.subTableId).dataTable({
                    jQueryUI: true,
                    processing: true,
                    paginationType: 'full_numbers',
                    data: eqtls,
                    columns: columnProps,
                    orderClasses: false,
                    dom: 'B<"clear">lfrtip',
                    buttons: buttonProps,
                    language: {
                        'sEmptyTable': 'Please wait - Fetching records'
                    },

                    fnInitComplete: function(oSettings) {
                        oSettings.oLanguage.sEmptyTable = 'No significant eQTLs were found for gene ' + geneName + ' in tissue ' +
                          tissueDisplayName;
                        $(oTable).find('.dataTables_empty').html('No significant eQTLs were found for gene ' + geneName + ' in tissue ' +
                              tissueDisplayName);
                    }
                });
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err + ' Unable to create table for entity: eqtl ');
            }

            oTable.addClass('display');
            oTable.show();
            oTable.fnSort([[5, 'asc']]); // sort by p-value
        },
        generateEqtlPlotAndBrowserUrl(geneId, snpId, variantId, tissueName, geneSymbol) {
            let url = this.generateEqtlPlotUrl(geneId, variantId, tissueName, geneSymbol);
            url += ', ' + this.generateBrowserUrl(variantId, tissueName);
            url += ', ' + generateForestPmPlotUrl(variantId, geneId, geneSymbol);
            return url;
        },
        generateEqtlPlotUrl(geneId, snpId, tissueName, geneSymbol, divId=undefined) {
            /*
             * if the geneId or snpId is a string with spaces, it's an error message
             * coming from test your own - cannot create an eQTL box plot for it,
             * since it will not have a properly formatted geneId or snpId
             */
            if (-1 != geneId.indexOf(' ') || -1 != snpId.indexOf(' ')) return;
            if ('NA' == geneId || 'N/A' == geneId) return;
            if (divId === undefined) divId = this.eqtlViolinDivId;
            const dialogDivIdString = '\'' + divId + '-dialog\', ';
            const geneIdString     = '\'' + geneId + '\', ';
            const snpIdString      = '\'' + snpId  + '\', ';
            const tissueNameString = '\'' + tissueName + '\', ';
            const geneSymbolString = '\'' + geneSymbol + '\'';
            return '<a href="javascript:gtex.plotEqtlViolinPlot(' + dialogDivIdString + geneIdString
                + snpIdString + tissueNameString + geneSymbolString + ')">eQTL violin plot</a>';
        },

        getTopExpressed(tissueSiteDetailId, num) {
            if (num === undefined) num = 100; // by default, retrieve the top 100 genes
            const unfilteredUrl = directory.getTopExpressedGeneUrl().url + '?pageSize=100&sortBy=median&sortDirection=desc&tissueSiteDetailId=' + tissueSiteDetailId;
            const filteredUrl = directory.getTopExpressedGeneUrl().url + '?pageSize=100&sortBy=median&sortDirection=desc&filterMtGene=true&tissueSiteDetailId=' + tissueSiteDetailId;

            const parseTopExpressedList = function(unfilteredJson, filteredJson) {
                const unfilteredData = unfilteredJson.topExpressedGene;
                const filteredData = filteredJson.topExpressedGene;
                $('#expressVersion').html(portalClient.versionInfo.getMarkup('expression'));
                const columnHeadings = ['Gencode Id',
                    'Gene Symbol',
                    'Median '  + unfilteredData[0]['unit']];
                const tableId = '#topExpressedGenes';
                $(tableId).html(''); // make sure to clear the table if it has been used
                const oTable = $(tableId);
                const tableElt = oTable[0];
                TableUtils.buildTableHtml(tableElt, columnHeadings);
                $.fn.dataTableExt.sErrMode = 'throw';
                const columnProps = [
                    { data: 'gencodeId' },
                    {
                        defaultContent: '',
                        'render': function(data, type, row) {
                            return generateGeneUrl(row.gencodeId, row.geneSymbol);
                        }
                    },
                    {
                        defaultContent: '',
                        'render': function(data, type, row) { return row.median.toFixed(1); }
                    }
                ];
                const indices = columnHeadings.map((d, i) => { return i; }); // this is for mColumns, omit the 3rd column

                const buttonProps = {
                    'buttons': [{
                        extend: 'copy',
                        exportOptions: { columns: indices }
                    },
                    {
                        extend: 'csv',
                        exportOptions: { columns: indices }
                    },
                    {
                        name: 'allGene',
                        text: 'Include Mitochondrial Genes',
                        action: function(e, dt, node, config) {
                            dt.clear();
                            dt.rows.add(unfilteredData);
                            dt.draw();
                            dt.button('mtGeneFilter:name').enable();
                            this.disable();
                        },
                        enabled: false
                    },
                    {
                        name: 'mtGeneFilter',
                        text: 'Exclude Mitochondrial Genes',
                        action: function(e, dt, node, config) {
                            dt.clear();
                            dt.rows.add(filteredData);
                            dt.draw();
                            dt.button('allGene:name').enable();
                            this.disable();
                        },
                        enabled: true
                    }]
                };

                const emptyMessage = 'No Top Expressed Genes found';
                try {
                    if ($.fn.dataTable.isDataTable(oTable)) {
                        const t = oTable.DataTable();
                        t.destroy();
                    }
                    oTable.dataTable({

                        deferRender: true,
                        jQueryUI: true,
                        processing: true,
                        paginationType: 'full_numbers',
                        data: unfilteredData,
                        order: [[2, 'desc']],
                        columns: columnProps,
                        orderClasses: false,
                        dom: 'B<"clear">lfrtip',
                        buttons: buttonProps,
                        language: {
                            'sEmptyTable': 'Please wait - Fetching records'
                        },
                        fnInitComplete: function(oSettings) {
                            oSettings.oLanguage.sEmptyTable = emptyMessage;
                            $(oTable).find('.dataTables_empty').html(emptyMessage);
                        }
                    });
                } catch (err) {
                    const messageHandler = new MessageHandler();
                    messageHandler.showError('Internal client error: ' + err + ' Unable to create table for top expressed gene list. ');
                }

                // this is required for row_selected styles to work.
                oTable.addClass('display');

                oTable.show(); // in case it was hidden
                return oTable;
            };
            $('#spinner').show();
            $.ajax({
                url: unfilteredUrl,
                type: 'GET',
                dataType: 'json',
                success: function(unfilteredData) {
                    $.ajax({
                        url: filteredUrl,
                        type: 'GET',
                        dataType: 'json',
                        success: function(filteredData) {
                            parseTopExpressedList(unfilteredData, filteredData);
                            $('#spinner').hide();
                        },
                        error: function(response) {
                            console.error('Error: api call failed ' + unfilteredUrl + ' ' + response.status);
                            $('#spinner').hide();
                        }
                    });
                },
                error: function(response) {
                    console.error('Error: api call failed ' + unfilteredUrl + ' ' + response.status);
                    $('#spinner').hide();
                }
            });
        },

        /*
         * TODO: move this into a separate shared location? - Duyen 2018/10/18
         * plotEqtlBoxPlot(geneId, snpId, tissueName, geneSymbol) {
         *     let args = {
         *         'geneId'            : geneId,
         *         'snpId'             : snpId,
         *         'tissueName'        : tissueName,
         *         'tissueDisplayName' : tissueName,   // TODO find tissueDisplayName
         *         'geneSymbol'        : geneSymbol
         *     };
         *     this.goEqtlCalc(args);
         * },
         */

        /**
         * Create a dialog popup window for the eQTL violin plots
         * @param parentDivId {String} the parent Div ID to append the dialog
         * @param dialogDivId {String}
         * @param title {String} the title of the dialog window
         */
        createDialogForQtlViolinPlots(parentDivId, title) {
            const parent = $(`#${parentDivId}`);
            const dialogDivId = parentDivId + '-dialog';
            const dialog = $('<div/>')
                .attr('id', dialogDivId)
                .attr('title', title)
                .appendTo(parent);
            const clearDiv = $('<div/>')
                .text('Clear All')
                .appendTo(dialog);
            const contentDiv = $('<div/>')
                .attr('id', `${dialogDivId}-content`)
                .appendTo(dialog);
            // define the clearDiv click event
            clearDiv.click(() => {
                contentDiv.empty();
            });

            // below is jQuery UI code for the dialog properties
            dialog.dialog({
                title: title,
                autoOpen: false
            });
        },

        goEqtlCalc(urlParams) { // this is for generating the boxplot
            $('#spinner').show();
            // TODO: Kane, also implement the error below from the ajax call we are replacing. - Kane
            const plotConfig = {
                width: 500,
                height: 600,
                outlierJitter: 0.25,
                tickRotation: 0,
                tickAlign: 'middle',
                tickTranslation: 0,
                leftAxisLabel: 'Rank Normalized Gene Expression',
                buttons: true,
                viewerBottomSpacing: 0,
                viewerLeftSpacing: 0.30,
                titleContent: urlParams.tissueName + ' eQTL ' + urlParams.snpId + ' ' + urlParams.geneId
            };
            $('#eqtlResultDiv').empty();

            // add download button
            $('#eqtlResultDiv').append('<div id="eqtlDownloadTempDiv"></div>');
            const $div = $('#eqtlResultDiv').append('<div id="eqtlDownload" class="navLink"><br/><i class="fas fa-download"></i> Download </div>');
            $('#eqtlDownload').click(() => {
                const $svg = $('#eqtlResultDiv .svg-div svg');
                downloadUtils.downloadSVGByObject($svg, 'eqtlDownload.svg', 'eqtlDownloadTempDiv');
            });

            const url = directory.getEqtlBoxplotUrl().url;
            const eqtlplot = new plotviz.EqtlPlot($('#eqtlResultDiv')[0], url, plotConfig);
            eqtlplot.query(urlParams.snpId, urlParams.geneId, urlParams.tissueName, urlParams.geneSymbol, plotConfig);
            $('#spinner').hide();
        },

        setEqtlByGeneInputVars2(tissueName, geneId) {
            const tissueSelect = $('#tissueSelect');
            const selectedTissueName = tissueSelect.val();
            if (tissueName != selectedTissueName) {
                tissueSelect.val(tissueName);
                const args = { 'tissueName': tissueName };
                portalClient.navigationRouter.navigateTo('eqtlByTissue', args);
            }
            const enteredGeneId = $('#queryId').val();
            if (geneId != enteredGeneId) { $('#queryId').val(geneId); }
        },
        setHeader(feature, urlParams) {
            let queryId = '';
            if (feature == 'SNP') {
                if (urlParams.variantId !== undefined) {
                    queryId = urlParams.variantId;
                } else if (urlParams.snpId !== undefined) {
                    queryId = urlParams.snpId;
                } else {
                    queryId = urlParams.queryId;
                }
            } else {
                queryId = urlParams.geneId;
            }
            const headerText = '<hr><h5>eQTLs of ' + queryId + '</h5>';
            portalClient.setTableHeader(headerText);
        },
        goEqtlByGeneV2(args) {
            const eqtlTableMaker = new EqtlTableMaker();
            portalClient.deleteTable();
            this.setHeader('Gene', args);
            this.setEqtlByGeneInputVars2(args.tissueName, args.geneId);
            eqtlTableMaker.generateEqtlByGeneTable(this.eqtlViolinDivId, args.geneId, args.tissueName, args.tissueDisplayName);
        },
        goEqtlBySnpV2(args) {
            const eqtlTableMaker = new EqtlTableMaker();
            /*
             * when this function is called from the EqtlPage, eqtlViolinDivId var exists as the page's data element
             * when called from SnpPage, it's passed to this function in the arg object
             */
            const eqtlViolinDivId = this.eqtlViolinDivId === undefined ? args.eqtlViolinDivId : this.eqtlViolinDivId;
            portalClient.deleteTable();
            this.setHeader('SNP', args);
            eqtlTableMaker.generateEqtlBySnpTable(eqtlViolinDivId, args.snpId, args.tissueName, args.tissueDisplayName, args.variantId);
        }
    }
};

///// GLOBAL FUNCTIONS ////
// These functions have to be attached to the global window.gtex object since they are used in <a href="javascript:"> links
// Eventually, these should be moved into proper Vue template logic

function plotEqtlViolinPlot(dialogDivId, geneId, snpId, tissueName, geneSymbol) {
    $(`#${dialogDivId}`).dialog('open'); // open the jQuery UI dialog

    // create the plot DIV
    const plot = $('<div/>')
        .attr('class', 'bMap-dialog')
        .css('float', 'left')
        .css('margin', '20px')
        .appendTo($(`#${dialogDivId}-content`));

    // add a header section
    const head = $('<div/>').appendTo(plot);

    // add a window-close icon in the header
    $('<i/>').attr('class', 'fa fa-window-close')
        .css('margin-right', '2px')
        .click(() => {
            plot.remove();
        })
        .appendTo(head);

    // add the plot title
    $('<div/>')
        .attr('class', 'title')
        .css('text-align', 'center')
        .html(`<br/>${geneSymbol}<br/>${snpId}<br/>${getTissueDisplayName(tissueName)}`)

        // .html(`<br/>${geneId} (${geneSymbol})<br/>${snpId}<br/>${getTissueDisplayName(tissueName)}`)
        .appendTo(head);

    // add the violin plot
    const id = 'dEqtl' + Date.now().toString(); // random ID generator
    $('<div/>').attr('id', id).appendTo(plot);


    const vConfig = {
        id: id,
        data: undefined, // this would be assigned by the eqtl violin function
        width: 250,
        height: 200,
        margin: { left: 50, right: 20, top: 20, bottom: 70 },
        showDivider: false,
        xAxis: { show: false, angle: 0, paddingInner: 0.01, paddingOuter: 0.01, textAnchor: 'start', adjustHeight: 0, showLabels: false, showTicks: false },
        yAxis: {
            label: 'Norm. Expression'
        },
        sizeAxis: {
            show: true
        },
        showWhisker: false,
        showLegend: false,
        showSampleSize: true,
        vColor: '#a9e4cc'
    };

    const urls = {
        dyneqtl: directory.getEqtlBoxplotUrl().url,
        geneId: directory.getGeneUrl().url + "?geneId=",
        tissue: directory.getTissueInfoUrl().url,
    };
    const qtlType = 'eqtl';
    QTLViolinPlot.render(vConfig, geneId, snpId, tissueName, snpId, qtlType, urls);
}

// TODO: move this into a separate shared location? - Duyen 2018/10/18
function getForestPmPlot(snpId, geneId, geneSymbol) {
    const url = directory.getForestPmPlotUrl().url + '?variantId=' + snpId + '&gencodeId=' + geneId;
    const divId = 'forestPmDiv';
    $('body, html').animate({ scrollTop: $('#'+divId).offset().top }, 400);
    const embedPlot = function(json) {
        $('#'+divId).html(''); // delete any existing content in the div
        if (0 === json.length) {
            const imgHtml = '<span style=\'color:red\'>Forest PM Plot is not available for ' + snpId+' & '+geneId + '. Currently, the plot generation requires both single tissue and METASOFT results be statistically significant.</span><br>';
            $('#' + divId).html(imgHtml);

            return;
        }

        const config = {
            'rowH': 20,
            'divId': divId
        };

        const entry = json[0];
        const snpId = entry.variantId;
        const gencodeId = entry.gencodeId;
        const metaP = entry.metaP;

        const tissues = Object.keys(entry.tissues);


        const rawData = {
            'gene': gencodeId,
            'geneSymbol': geneSymbol,
            'metaP': metaP,
            'size': undefined,
            'snp': snpId,
            'nes': tissues.map(tissue => {
                return entry.tissues[tissue].nes;
            }),
            'm': tissues.map(tissue => {
                return entry.tissues[tissue].mValue;
            }),
            'p': tissues.map(tissue => {
                return entry.tissues[tissue].pValue;
            }),
            'se': tissues.map(tissue => {
                return entry.tissues[tissue].se;
            })
        };

        // add download button
        const $div = $('#'+divId).append('<div id="forestPmDownload" class="navLink"><br/><i class="fas fa-download"></i> Download </div>');
        $('#forestPmDownload').click(() => {
            const $svg = $('#'+divId+' svg');
            const downloadFile = snpId+'_'+geneId+'.multiTissue.svg';
            downloadUtils.downloadSVGByObject($svg, downloadFile, 'downloadTempDiv');
        });

        const fpm = new forestPm(tissues,
            rawData,
            config);
        fpm.setTissueUrl(directory.getTissueInfoUrl().url);
        fpm.init();
    };
    RetrieveAllPaginatedData(url).then(data => {
        embedPlot(data);
    });
    //apiCallNoCreds(url, embedPlot);
}

export function fetchEdataByTissue(params) {
    /*
     * TODO: review this function, it needs an update.
     * initiate required search options
     */
    let tissueName = 'All';
    let queryId = null;
    let fetchWhat = 'egene';

    // depending on how the search was initiated, obtains the search options differently
    if (params.action == 'fromDonut') {
        tissueName = params.tissueName;
    } else if (params.action == 'fromTopMenu') {
        queryId = $('#asdSearchTermEqtl').val().trim();
        fetchWhat = 'eqtl';
    } else if (params.action == 'getEqtl') {
        tissueName = $('#tissueSelect').val();
        queryId = $('#queryId').val().trim();
        fetchWhat = 'eqtl';
    } else if (params.action == 'getEgene') {
        tissueName = $('#tissueSelect').val();
    }
    /*
     * else if (params.action == 'fromNavBar'){
     *  queryId = $('#asdSearchTermGeneral').val().trim();
     *  fetchWhat = 'eqtl';
     * }
     */
    const count = getSampleCount(tissueName); // num of samples

    const args = {
        'queryId': queryId,
        'tissueName': tissueName,
        'count': count
    };

    if (count < 70 || fetchWhat == 'egene') {
        router.push({
            name: 'tissuePage',
            query: {},
            params: { tissueName }
        });
        return false;
    }

    // handling eqtl query
    if (queryId == null || queryId == '') { // evaluates query ID
        alert('Please provide a Gene or SNP ID.');
    } else {
        args.type = portalClient.parseIdType(args.queryId);
        if ('rsId' == args.type) { // query ID is a SNP
            args.snpId = queryId;
            router.push({
                name: 'eqtlsBySnpPage',
                query: { tissueName: args.tissueName, snpId: args.snpId },
                params: { tissueDisplayName: args.tissueDisplayName, count: args.count, type: 'rsId' }
            });
        } else if ('chromPos' == args.type) { // query ID is a SNP variant ID
            args.variantId = queryId;
            router.push({
                name: 'eqtlsBySnpPage',
                query: { tissueName: args.tissueName, variantId: args.variantId },
                params: { tissueDisplayName: args.tissueDisplayName, count: args.count, type: 'chromPos' }
            });
        } else { // query ID is a gene
            args.geneId = queryId;
            router.push({
                name: 'eqtlsByGenePage',
                query: { tissueName: args.tissueName, geneId: args.geneId },
                params: { tissueDisplayName: args.tissueDisplayName, count: args.count, type: 'Gene' }
            });
        }
    }

    return false;
}

export function goTissuePage(tissueName) {
    const tissueId = getTissueId(tissueName); // convert tissue name to tissue ID
    const args = {
        'tissueName': tissueId,
        'tissueDisplayName': tissueName,
        'action': 'fromDonut'
    };
    fetchEdataByTissue(args);
}
window.gtex = window.gtex ? window.gtex : {};
window.gtex.goTissuePage = goTissuePage;
window.gtex.plotEqtlViolinPlot = plotEqtlViolinPlot;
window.gtex.getForestPmPlot = getForestPmPlot;
</script>
