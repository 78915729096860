import { portalClient } from '@/utils/portal';

//var egene_url = 'egene2.json'; // when doing local testing

export function apiCall(url, needsCreds, callback) {
    if (needsCreds) {
        apiCallCreds(url, callback);
    } else {
        apiCallNoCreds(url, callback);
    }
}

/*
 * Use this method.  The urlEntry should contain to attribute:
 *
 * url: the url to call
 * needsCreds: true means it needs credentials to be sent, false otw.
 *
 * Use needsCreds: true for Java web services.
 * Use needsCreds: false for Python web services.
 */
export function apiCallHelper(urlEntry, callback) {
    apiCall(urlEntry.url, urlEntry.needsCreds, callback);
}

/*
 * NOTE THIS IS PRIVATE.  Use apiCallHelper above instead.
 *
 * Use this method when calling the Java REST services.  The Java REST services
 * require that credentials be sent, which is set up in the Ajax defaults.
 */
function apiCallCreds(url, callback) {
    const revisedUrl = appendUserInfo(url);
    $.getJSON(revisedUrl, data => {
        callback(data);
    })
        .fail(() => {
            callback(undefined);
        });
}

/*
 * NOTE THIS IS PRIVATE.  Use apiCallHelper above instead.
 *
 * Use this method when calling the Python REST services.  The Python REST services
 * require that we do not send the credentials (which are associated with the Java
 * server, so CORS intercepts the results).
 */
export function apiCallNoCreds(url, callback) {
    const revisedUrl = appendUserInfo(url);
    $('#spinner').show();
    $.ajax({
        url: revisedUrl,
        type: 'GET',
        dataType: 'json',
        xhrFields: { withCredentials: true },
        success: function(data) {
            callback(data);
            $('#spinner').hide();
        },
        error: function(response) {
            console.error('Error: api call failed ' + url + ' ' + response.status);
            $('#spinner').hide();
        }
    });
}

/**
 * Appends the user's username and email to the URL as URL parameters.
 * @param url
 */
export function appendUserInfo(url) {
    let username = portalClient.username;
    if (!username) {
        username = 'Anonymous';
    }

    if (-1 == url.indexOf('?')) {
    // url does not have a "?"
        url += '?';
    } else {
    // url already has a "?"
        url += '&';
    }
    url += 'username=' + username;

    if (portalClient.email) {
        url += '&email=' + portalClient.email;
    }

    return url;
}
