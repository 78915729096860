<template>
  <footer class="gtex-footer">
    <div class="gtex-footer-row">
      <div>
        <img class="broad-footer-logo" src="@/assets/css/images/broad-logo.png" alt="Broad Institute Logo">
        <p>&copy;{{ getCurrentYear }} Broad Institute of MIT and Harvard. All rights reserved.</p>
      </div>
      <div class="gtex-footer-links">
        <div>
          <router-link to="/">
            FAQs
          </router-link><br />
          <router-link class="navLink" to="/documentationPage#staticTextPublicationPolicy">
            Publication Policy
          </router-link><br />
          <router-link class="navLink" to="/methods">
            Lab and Analysis Methods
          </router-link><br />
          <router-link class="navLink" to="/license">
            License
          </router-link>
        </div>
        <div>
          <div>
            <div class="gtex-footer-connect">
              <strong>CONNECT</strong>
              <span>
                <i class="fab fa-twitter" />
                <a href="https://twitter.com/gtexportal" target="_blank">
                  @GTExPortal
                </a>
              </span>
              <span>
                <i class="fas fa-envelope" />
                <router-link class="navLink" to="/contact">
                  Contact Us
                </router-link>
              </span>
            </div>
            <div>
              <strong>EXTERNAL LINKS</strong>
              <span class="gtex-footer-ext-links">
                <a href="https://www.ncbi.nlm.nih.gov/projects/gap/cgi-bin/study.cgi?study_id=phs000424" target="_blank">dbGaP</a> |
                <a href="http://commonfund.nih.gov/GTEx/" target="_blank">NIH Common Fund</a> |
                <a href="http://www.genome.gov/27543767" target="_blank">NHGRI</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </footer>
</template>

<script lang="js">
export default {
  name: 'gtex-footer',
  computed: {
    getCurrentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style lang="css" scoped>
.gtex-footer {
  width: 100%;
  padding: 2vh 5vw;
  background-color: #D3D3D3;
}

a {
  color: black;
}

.broad-footer-logo {
  height: 100px;
}

.gtex-footer-row {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.gtex-footer-links {
  display: inline-flex;
  flex-flow: row wrap;
}

.gtex-footer-links > * {
  padding: 1em 0;

  @media (min-width: 1081px) {
    padding: 0 1em;
  }
}

.gtex-footer-connect > span {
  padding-left: 1em;
}

.gtex-footer-ext-links {
  padding-left: 1em;
}
</style>