<template>
  <div id="funding" class="page-content center">
    <h3>Funding</h3>
    <p>This project is funded at the
      <a href="https://www.genome.gov/" target="_blank">National Human Genome Research Institute <i class="fas fa-external-link-alt"/></a>,
      <a href="https://www.nichd.nih.gov/" target="_blank">Eunice Kennedy Shriver National Institute of Child Health and Human Development <i class="fas fa-external-link-alt"/></a>,
      <a href="https://www.nimh.nih.gov/" target="_blank">National Institute of Mental Health <i class="fas fa-external-link-alt"/></a>,
      <a href="https://www.ninds.nih.gov/" target="_blank">National Institute of Neurological Disorders and Stroke <i class="fas fa-external-link-alt"/></a>,
      by the <a href="https://www.nih.gov/" target="_blank">National Institutes of Health <i class="fas fa-external-link-alt"/></a>.
    </p>
  </div>
</template>

<style lang="css" scoped>
img {
  padding-right: 20px;
  height: 65px;
  padding-top: 20px;
}

@media (max-width: 500px) {
  img {
    height: 40px;
  }
}
</style>
<script>
</script>