<template>
  <div id="center" class="page-content center">
    <div id="logo">
      <img id="logo" :src="logoSvg"/>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logoSvg: require('@/assets/images/dgtex/dGTEx-logo-2022.05.05-07.svg')
    }
  }
}
</script>

<style scoped>
div#logo {
  margin: 0;
}

img#logo {
  width: 30vw;
  max-width: 500px;
  min-width: 300px;
  padding-top: 20px;
  padding-bottom: 20px;
}

#center {
  display: flex;
  margin: auto;
  justify-content: flex-start;
}
</style>