<template>
  <div id="organization" class="page-content center">
    <h3>Structural Organization</h3>
    <div class="gray-bg img-box">
      <img :src="structuralOrgSvg" class="size"/>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      structuralOrgSvg: require('@/assets/images/dgtex/structuralOrg.svg')
    }
  }
}
</script>

<style scoped>
#organization {
  padding: 3vh 0;
  text-align: left;
}

.img-box {
  text-align: center;
  padding-top: 20px;
}

.size {
  width: 100%;
  max-width: 700px;
}
</style>