<template>
  <section class="gtex-homepage">
    <div id="homeBanner" class="jumbotron"/>
<!--    <div class="gtex-stat-banner">-->
<!--      <div class="gtex-statistics">-->
<!--        <div v-for="(stat, index) in gtexStats" :key="index" class="gtex-stat">-->
<!--          <h2>{{ stat.qty }}</h2>-->
<!--          <p>{{ stat.label }}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="page-content">
      <p>
        The Genotype-Tissue Expression (GTEx) Portal is a comprehensive public resource for researchers studying
        tissue and cell-specific gene expression and regulation across individuals, development, and species, with
        data from 3 NIH projects.
      </p>
      <div class="gtex-featured">
        <div v-for="(item, index) in featuredItems"
             :key="index" class="featured-item col-xs-1 col-md-4 btn btn-default"
             @click="toRouterLink(item.path)"
        >
          <div class="gtex-featured-image">
            <img :src="require(`@/assets/images/${item.img}`)">
          </div>
          <p>
            {{ item.description }}
          </p>
          <div class="gtex-featured-actions" :style="{ justifyContent: item.path !== '/aboutAdultGtex' ? 'space-between' : 'flex-end' }">
            <p v-if="item.path !== '/aboutAdultGtex'"><em>*Data Not Yet Available</em></p>
            <router-link v-if="item.path" :to="item.path">
              Explore <i class="fas fa-angle-double-right"/>
            </router-link>
          </div>
        </div>
      </div>

      <div class="gtex-home-add-content">
        <!-- Explore GTEx Column -->
        <div class="gtex-home-explore">
          <div class="panel panel-default">
            <h3>Explore the Adult GTEx data</h3>
            <p>dGTEx and NHP-dGTEx will be available at a later time.</p>
            <div>
              <table>
                <tbody>
                <tr>
                  <td style="vertical-align: text-top;">
                    <h4>
                      <img class="small-icon" src="@/assets/images/GTEx-gene-icon.svg">
                      Browse
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0.5em;">
                    <router-link to="/tissue/Adipose_Subcutaneous">
                      By Tissue
                    </router-link>
                  </td>
                  <td style="padding: 0.5em;">
                    Browse and search all data by tissue
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0.5em;">
                    <router-link to="/histologyPage">
                      Histology Viewer
                    </router-link>
                  </td>
                  <td style="padding: 0.5em;">
                    Browse and search GTEx histology images
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: text-top;">
                    <h4><img class="small-icon" src="@/assets/images/GTEx-single-cell-icon.svg">Single Cell</h4>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0.5em;">
                    <router-link to="/singleCellOverviewPage">
                      Data Overview
                    </router-link>
                  </td>
                  <td style="padding: 0.5em;">
                    Learn more about available single cell data
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0.5em;">
                    <router-link to="/multiGeneSingleCellQueryPage">
                      Multi-Gene Single Cell Query
                    </router-link>
                  </td>
                  <td style="padding: 0.5em;">
                    Browse and search single cell expression by gene and tissue
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: text-top;">
                    <h4><img class="small-icon" src="@/assets/images/GTEx-heatmap-icon.svg">Expression</h4>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0.5em;">
                    <router-link to="/multiGeneQueryPage">
                      Multi-Gene Query
                    </router-link>
                  </td>
                  <td style="padding: 0.5em;">
                    Browse and search expression by gene and tissue
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0.5em;">
                    <router-link to="/transcriptPage">
                      Transcript Browser
                    </router-link>
                  </td>
                  <td style="padding: 0.5em;">
                    Visualize transcript expression and isoform structures
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: text-top;">
                    <h4>
                      <img class="small-icon" src="@/assets/images/GTEx-locus-browser-icon.svg">
                      QTL
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0.5em;">
                    <router-link to="/locusBrowserPage/RSPRY1">
                      Locus Browser (Gene-centric)
                    </router-link>
                  </td>
                  <td style="padding: 0.5em;">
                    Visualize QTLs by gene in the Locus Browser
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0.5em;">
                    <router-link to="/locusBrowserVCPage">
                      Locus Browser (Variant-centric)
                    </router-link>
                  </td>
                  <td style="padding: 0.5em;">
                    Visualize QTLs by variant in the Locus Browser VC (Variant Centric)
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0.5em;">
                    <router-link to="/browseEqtls?location=chr1%3A1-500,000">
                      IGV Browser
                    </router-link>
                  </td>
                  <td style="padding: 0.5em;">
                    Visualize tissue-specific eQTLs and coverage data in the IGV Browser
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0.5em;">
                    <router-link to="/eqtlDashboardPage">
                      eQTL Dashboard
                    </router-link>
                  </td>
                  <td style="padding: 0.5em;">
                    Batch query eQTLs by gene and tissue
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0.5em;">
                    <router-link to="/testyourown">
                      eQTL Calculator
                    </router-link>
                  </td>
                  <td style="padding: 0.5em;">
                    Test your own eQTLs
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: text-top;">
                    <h4><img class="small-icon" src="@/assets/images/GTEx-histone-icon.svg">eGTEx</h4>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0.5em;">
                    <router-link to="/browseEqtls?location=chr1%3A750000-850,000">
                      H3K27ac, m6A, WGBS
                    </router-link>
                  </td>
                  <td style="padding: 0.5em;">
                    Browse H3K27ac ChIP-seq, m6A methylation, and WGBS DNA methylation data in IGV Browser
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: text-top;">
                    <h4><img class="small-icon" src="@/assets/images/GTEx-lung-icon.svg">Biobank</h4>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0.5em;">
                    <router-link to="/samplesPage">
                      Access Biospecimens
                    </router-link>
                  </td>
                  <td style="padding: 0.5em;">
                    Search and request available GTEx biospecimens
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="gtex-home-resources">
          <div>
            <h4>Announcements</h4>
            <div style="font-size: 14px;">
              <div id="news_div"/>
              <div class="news_item">
                <span class="news_date">2017-10-18</span>
                <a href="workshop.html" target="_blank">
                  ASHG GTEx Workshop Materials <i class="fas fa-external-link-alt"/>
                </a>
              </div>
            </div>
          </div>
          <div>
            <h4>Documentation</h4>
            <div>
              <span v-for="(item, index) in docLinks"
                    :key="index"
              >
                <router-link :to="item.path"
                             class="navLink"
                >
                  {{ item.label }}
                </router-link> <br>
              </span>
            </div>
          </div>
          <div>
            <h4>Additional Data Access</h4>
            <div>
              <span v-for="(item, index) in addDataLinks"
                    :key="index"
              >
                <router-link :to="item.path"
                             class="navLink"
                >
                  {{ item.label }}
                </router-link> <br>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TissueDonutChart from '@/utils/tissueDonutChart';
import {portalClient, updateNews} from '@/utils/portal';
import router from '@/router/router';

export default {
  name: 'GtexHomepage',
  data() {
    return {
      featuredItems: [
        {
          img: 'gtex-logo.png',
          description: 'The Adult GTEx project is a comprehensive resource of WGS, RNA-Seq, and QTL data from samples collected from 54 non-diseased tissue sites across ~1000 adult individuals.',
          path: '/aboutAdultGtex',
        },
        {
          img: 'dGTEx-logo-05.png',
          description: 'The Developmental GTEx (dGTEx) project is a new effort to study development-specific genetic effects on gene expression and to establish a new data analysis and tissue biobank resource.',
          path: '/aboutdGTEx',
        },
        {
          img: 'nhp-dgtex-logo.svg',
          description: 'The Non-Human Primate Developmental GTEx (NHP-dGTEX) project is a complement to dGTEx in 2 translational non-human primate model species: the rhesus macaque and common marmoset.',
          path: '/aboutNHPdGtex',
        }
      ],
      docLinks: [
        {
          path: '/tissueSummaryPage',
          label: 'Tissue & Sample Statistics'
        },
        {
          path: '/anatomogramPage',
          label: 'Tissue Sampling Info (Anatomogram)'
        },
        {
          path: '/datasets',
          label: 'Access & Download Data'
        },
        {
          path: '/releaseInfoPage',
          label: 'Release History'
        },
        {
          path: {
            name: 'faqPage',
            hash: '#citePortal'
          },
          label: 'How to cite GTEx?'
        }
      ],
      addDataLinks: [
        {
          path: '/',
          label: 'Access biospecimens'
        },
        {
          path: '/datasets',
          label: 'Open access data download'
        },
        {
          path: '/protectedDataAccess',
          label: 'Protected access data download'
        }
      ]
    };
  },
  beforeRouteLeave(to, from, next) {
    // moving maintenance message div back
    // $('#site-messages').prepend($('#maintenanceMessageDiv'));
    next();
  },
  mounted() {
    (new TissueDonutChart()).setupTissueDonutCharts();
    updateNews();

    // moving maintenance message under home page banner
    // $('#content').prepend($('#maintenanceMessageDiv'));


    // define search events
    $('#asdSearchTermGene').keydown(e => {
      if (e.keyCode == 13) {
        portalClient.searchFunction(e);
      }
    });

    $('#asdSearchTermVariant').keydown(e => {
      if (e.keyCode == 13) {
        const searchTerm = $('#asdSearchTermVariant').val();
        const searchType = portalClient.parseIdType(searchTerm);
        router.push({name: 'snpPage', params: {snpId: searchTerm, type: searchType}});
      }
    });

    this.clearSearchTerms();
  },
  methods: {
    goToIgvBrowser() {
      router.push({
        name: 'igvEqtlBrowserPage', query: {location: 'chr1:1-500,000'}
      });
    },
    goEqtlCalculator() {
      router.push({name: 'eqtlCalculatorPage'});
    },
    clearSearchTerms() {
      $('#asdSearchTermGene').val('');
      $('#asdSearchTermVariant').val('');
      $('#asdSearchTermEqtl').val('');
      $('#asdEqtlBrowserLocation').val('');
      $('#asdSearchTermGeneral').val('');
      $('#asdSearchTermGeneExpr').val('');
    },
    /**
     * Opens the website into a new tab when the button is clicked.
     * @param url
     */
    openSite(url) {
      window.open(url, '_blank');
    },

    /**
     * Method for programmatic navigation to a Vue Router link
     * when a button is pressed.
     * @param path the router path to be directed to.
     */
    toRouterLink(path) {
      router.push({path});
    }
  }
};
</script>

<style lang="css" scoped>
.gtex-homepage {
  width: 100%;
}

.jumbotron {
  width: 100%;
  background: center / cover no-repeat url("../assets/images/home-banner.png");
  height: var(--jumbotron-size);
  margin-bottom: unset;
}

p {
  font-size: 1.025rem;
}

.gtex-stat-banner {
  background-color: rgba(70, 164, 173, 0.27);
}

.gtex-statistics {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 15px 0;

  @media (min-width: 768px) {
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.gtex-stat {
  color: #46A4AD;
  flex-basis: 25%;
  text-transform: uppercase;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-basis: 30%;
    flex-flow: column wrap;
    text-align: center;
  }
}

.gtex-stat > * {
  margin: unset;
  padding: 0 0.5em;
}

.btn {
  white-space: unset;
}

.btn:disabled {
  border: unset;
  background-color: rgba(70, 164, 173, 0.38);
}

.gtex-featured {
  margin: 3vh 0;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  @media (min-width: 768px) {
    justify-content: space-between;
  }
}

.featured-item {
  text-align: left;
  flex-basis: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;

  @media (min-width: 768px) {
    flex-basis: 30%;
  }
}

.featured-item > * {
  padding: 5px;
}

.gtex-featured-image {
  width: 100%;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.gtex-featured-image img {
  width: auto;
  max-width: 100%;
  height: 8vh;

  @media (min-width: 768px) {
    height: 5vh;
  }
}

.gtex-featured-actions {
  width: 100%;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
}

.gtex-featured-actions > p {
  margin: unset;
}

.featured-item > a:hover {
  color: black
}

.gtex-home-add-content {
  margin-top: 3vh;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  @media (min-width: 768px) {
    justify-content: space-between;
  }
}

.gtex-home-explore {
  @media (min-width: 768px) {
    flex-basis: 60%;
  }
}

.panel > h3 {
  margin-top: 10px;
}

.gtex-home-resources {
  @media (min-width: 768px) {
    margin-left: 3em;
    flex-basis: 30%;
  }
}

.gtex-home-resources > * {
  margin-bottom: 3vh;
}

table > tr {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
}

tr > td:first-child {
  flex-basis: 35%;
}

tr > td:nth-child(2) {
  flex-basis: 65%;
}

hr {
  margin: 0;
}

td > h4 {
  font-weight: 400;
}
</style>
