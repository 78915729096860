
<template>
  <div>
    <div class="container-fluid">
      <h2>
        GTEx IGV Browser<img src="@/assets/images/GTEx-igv-icon.svg" width="40px" style="margin: 0px 0px 0px 10px;">
      </h2>
      <v-menu @add-to-browser="addToBrowser" @remove-from-browser="removeFromBrowser" />
      <v-help />
      <button
        :id="menuButtonId"
        type="button"
        class="btn btn-info btn-sm"
        data-toggle="modal"
        style="margin-right: 2px;"
      >
        Show Track Menu
      </button>
      <button
        :id="helpButtonId"
        type="button"
        class="btn btn-info btn-sm"
        data-toggle="modal"
      >
        About GTEx IGV Browser
      </button>
    </div>
    <div :id="igvBrowserRootDivId" />
    <hr id="eqtlBar">
  </div>
</template>
<script>
import IGVTrackMenu from '@/components/igv_browser/IGVTrackMenu.vue';
import IGVHelpModal from '@/components/igv_browser/IGVHelpModal.vue';
import { directory } from '@/utils/portal';
import IGVFileMaps from '@/utils/igv-file-maps';
import { tissueMetadataJson } from '@/utils/tissue';
export default {
    components: {
        'v-menu': IGVTrackMenu,
        'v-help': IGVHelpModal
    },
    data: function() {
        const igvBrowserRootDivId = 'igv-browser-root-div-id';
        const menuButtonId = 'igv-menu-button-id';
        const helpButtonId = 'igv-help-button-id';
        const params = this.$route.query; // from the URL
        const tissueId = params.tissueName; // if specified from the URL params

        // Genome reference track config
        const genomeReference = {
            id: 'hg38',
            name: 'Genes',
            fastaURL: directory.getFastaUrl().url,
            indexUrl: directory.getFastaIndexUrl().url,
            cytobandURL: directory.getCytobandUrl().url
        };

        const collapsedModelTrackInfo = { dataType: 'collapsedModel' };
        const gwasCatalogTrackInfo = { dataType: 'gwasCatalog' };
        const collapsedModelTrack = this.configCollapsedModelTrack(collapsedModelTrackInfo);
        const gwasTrack = this.configGwasTrack(gwasCatalogTrackInfo);
        const preloadTracks = [gwasTrack, collapsedModelTrack];

        const initOptions = {
            type: 'GTEX',
            locus: params.location,
            flanking: 50000,
            search: {
                url: directory.getFeatureUrl().url + '/$FEATURE$',
                resultsField: 'features',
                geneField: 'geneSymbol',
                snpField: 'snpId'
            },
            reference: genomeReference,
            tracks: preloadTracks,
            showSVGButton: true,
            withCredentials: false
        };


        return {
            params: params,
            initOptions: initOptions,
            preloadTracks: preloadTracks,
            igvBrowserRootDivId: igvBrowserRootDivId,
            menuButtonId: menuButtonId,
            helpButtonId: helpButtonId,
            tissueId: tissueId
        };
    },
    mounted: function() {
        // if there's no data tracks specified, display the track menu modal
        // else show IGV
        this.initMenuButtons();
        const checkTracks = () => {
            const menuButton = $(`#${this.menuButtonId}`);
            if (this.params.tissueName == undefined) menuButton.trigger('click');
            else {
                const igvMenuModalId = IGVTrackMenu.data().modalId;
                $(`#${this.tissueId}`).trigger('click');
            }
        };
        this.initBrowser(checkTracks);
    },

    methods: {
        initBrowser(callback=undefined) {
            const rootDiv = $(`#${this.igvBrowserRootDivId}`);
            igv.createBrowser(rootDiv, this.initOptions)
                .then(browser => {
                    this.browser = browser;
                    if (callback !== undefined) {
                        callback();
                    }
                });
        },
        initMenuButtons() {
            // assign data-target of the menu button
            const igvMenuModalId = IGVTrackMenu.data().modalId;
            $(`#${this.menuButtonId}`).attr('data-target', `#${igvMenuModalId}`); // setting up the modal target by defining the attribute data-target
            const igvHelpModalId = IGVHelpModal.data().modalId;
            $(`#${this.helpButtonId}`).attr('data-target', `#${igvHelpModalId}`);
        },
        addToBrowser(track) {
            switch (track.dataType) {
                case 'eQTL':
                    this.browser.loadTrack(this.configEqtlTrack(track));
                    break;
                case 'H3K27ac':
                    this.browser.loadTrack(this.configH3K27acCoverageTrack(track));
                    this.browser.loadTrack(this.configH3K27acPeakTrack(track));
                    break;
                case 'm6A':
                    this.browser.loadTrack(this.configM6ACoverageTrack(track));
                    this.browser.loadTrack(this.configM6APeakTrack(track));
                    break;
                case 'RNA-seq':
                    this.browser.loadTrack(this.configRNASeqCoverageTrack(track));
                    break;
                case 'WGBS':
                    this.browser.loadTrack(this.configDnaWgbsTrack(track));
                    if (IGVFileMaps.WgbsVMR.hasVMR.includes(track.tissueId)) {
                        this.browser.loadTrack(this.configDnaWgbsVmrTrack(track));
                    }
                    break;
                case 'gwasCatalog':
                    this.browser.loadTrack(this.configGwasTrack(track));
                    break;
                case 'collapsedModel':
                    this.browser.loadTrack(this.configCollapsedModelTrack(track));
                    break;
                case 'fullModel':
                    this.browser.loadTrack(this.configFullModelTrack(track));
                    break;
                default:
                    throw `Unrecognized data type: ${track.dataType}. Cannot add track.`;
            }
        },
        removeFromBrowser(track) {
            const relatedTrackNames = this.getAllRelatedTrackNames(track);
            const toRemove = [];
            this.browser.trackViews.forEach(view => {
                if (relatedTrackNames.includes(view.track.name)) {
                    toRemove.push(view);
                }
            });
            toRemove.forEach(view => {
                this.browser.removeTrack(view.track);
            });
        },
        /**
         * Creates a string to be used as the IGV track name.
         */
        getTrackDisplayName(track, trackSubtype) {
            let displayName;
            switch (track.dataType) {
                case 'fullModel':
                    displayName = 'Full Gene Models';
                    break;
                case 'collapsedModel':
                    displayName = 'Collapsed Gene Models';
                    break;
                case 'gwasCatalog':
                    displayName = 'GWAS Catalog';
                    break;
                case 'eQTL':
                case 'RNA-seq':
                    displayName = `${track.dataType}: ${track.tissueName}`;
                    break;
                case 'WGBS':
                    // aggregate data tracks in WGBS don't correspond to a single tissue
                    // 'Basal_ganglia', 'Cortical', 'HC1', 'HC2'
                    const tissueDisplay = track.tissueName ? track.tissueName : track.tissueId.replace('_', ' ');
                    if (trackSubtype === undefined) displayName = `${track.dataType}: ${tissueDisplay}`;
                    else if (trackSubtype == 'VMR') displayName = `${track.dataType} VMRs: ${tissueDisplay}`;
                    break;
                case 'H3K27ac':
                case 'm6A':
                    if (trackSubtype == 'coverage') displayName = `${track.dataType} Coverage: ${track.tissueName}`;
                    else if (trackSubtype == 'peaks') displayName = `${track.dataType} Peaks: ${track.tissueName}`;
                    break;
                default:
                    throw `Unrecognized data type: ${track.dataType}`;
            }
            return displayName;
        },
        /**
         * Returns a list containing the names of all tracks related to one data type specified from the portal
         * eGTEx data often has more than one track, and we want to remove all of them if a user de-selects that tissue.
         */
        getAllRelatedTrackNames(track) {
            const trackNames = [];
            switch (track.dataType) {
                case 'eQTL':
                case 'RNA-seq':
                case 'fullModel':
                case 'collapsedModel':
                case 'gwasCatalog':
                    trackNames.push(this.getTrackDisplayName(track));
                    break;
                case 'WGBS':
                    if (IGVFileMaps.WgbsVMR.hasVMR.includes(track.tissueId)) {
                        trackNames.push(this.getTrackDisplayName(track, 'VMR'));
                    }
                    trackNames.push(this.getTrackDisplayName(track));
                    break;
                case 'H3K27ac':
                case 'm6A':
                    trackNames.push(this.getTrackDisplayName(track, 'coverage'));
                    trackNames.push(this.getTrackDisplayName(track, 'peaks'));
                    break;
                default:
                    throw `Unrecognized data type: ${track.dataType}`;
            }
            return trackNames;
        },
        configEqtlTrack(track) {
            return {
                type: 'eqtl',
                sourceType: 'gtex-ws',
                url: directory.getSingleTissueEqtlByLocationUrl().url,
                tissueSiteDetailId: track.tissueId,
                name: this.getTrackDisplayName(track),
                datasetId: 'gtex_v10',
                withCredentials: false
            };
        },
        configH3K27acCoverageTrack(track) {
            const bigwig = {
                type: 'wig',
                name: this.getTrackDisplayName(track, 'coverage'),
                url: IGVFileMaps.H3K27AcCoverage[track.tissueId],
                color: `rgb(${tissueMetadataJson[track.tissueId].colorRgb})`
            };
            return bigwig;
        },
        configH3K27acPeakTrack(track) {
            const bed = {
                type: 'annotation',
                format: 'bed',
                name: this.getTrackDisplayName(track, 'peaks'),
                url: IGVFileMaps.H3K27AcPeak[track.tissueId],
                color: `rgb(${tissueMetadataJson[track.tissueId].colorRgb})`,
                displayMode: 'SQUISHED',
                indexed: false
            };
            return bed;
        },
        configM6ACoverageTrack(track) {
            const bigwig = {
                type: 'wig',
                name: this.getTrackDisplayName(track, 'coverage'),
                url: IGVFileMaps.M6ACoverage[track.tissueId],
                color: `rgb(${tissueMetadataJson[track.tissueId].colorRgb})`
            };
            return bigwig;
        },
        configM6APeakTrack(track) {
            const bed = {
                type: 'annotation',
                format: 'bed',
                name: this.getTrackDisplayName(track, 'peaks'),
                url: IGVFileMaps.M6APeak[track.tissueId],
                color: `rgb(${tissueMetadataJson[track.tissueId].colorRgb})`,
                displayMode: 'SQUISHED',
                indexed: false
            };
            return bed;
        },
        configDnaWgbsTrack(track) {
            const getUrl = tissueId => {
                const urlRoot = directory.getBigWigFileUrl().url;
                const folder = 'dna_methylation_wgbs';
                const fileSuffix = 'mCG.small_smooth.bw';
                return `${urlRoot}/${folder}/${tissueId}.${fileSuffix}`;
            };
            const bigwig = {
                type: 'wig',
                name: this.getTrackDisplayName(track),
                url: getUrl(track.tissueId),
                // aggregate brain tissue tracks aren't in tissueMetadataJson
                color: `rgb(${tissueMetadataJson[track.tissueId] ? tissueMetadataJson[track.tissueId].colorRgb : '238,238,0'})`
            };
            return bigwig;
        },
        configDnaWgbsVmrTrack(track) {
            const getUrl = tissueId => {
                const urlRoot = directory.getBigWigFileUrl().url;
                const folder = 'dna_methylation_wgbs/VMR';
                return `${urlRoot}/${folder}/${tissueId}_VMRs.bed`;
            };
            const bed = {
                type: 'annotation',
                format: 'bed',
                name: this.getTrackDisplayName(track, 'VMR'),
                url: getUrl(track.tissueId),
                // aggregate brain tissue tracks aren't in tissueMetadataJson -- can be assigned brain tissue color
                color: `rgb(${tissueMetadataJson[track.tissueId] ? tissueMetadataJson[track.tissueId].colorRgb : '238,238,0'})`,
                displayMode: 'SQUISHED',
                indexed: false
            };
            return bed;
        },
        configRNASeqCoverageTrack(track) {
            const getUrl = tissueId => {
                const urlRoot = directory.getBigWigFileUrl().url;
                const fileSuffix = 'Aligned.sortedByCoord.out.patched.md.bigWig';
                const sampleMap = IGVFileMaps.RNASeqCoverage;
                //gs://gtex-igv-files/rna_seq_coverage/v10/GTEX-11DXY-0011-R3a-SM-GIN8U.Aligned.sortedByCoord.out.patched.md.bigWig
                return `${urlRoot}/rna_seq_coverage/v10/${sampleMap[tissueId]}.${fileSuffix}`;
            };
            const bigwig = {
                type: 'wig',
                name: this.getTrackDisplayName(track),
                url: getUrl(track.tissueId),
                color: `rgb(${tissueMetadataJson[track.tissueId].colorRgb})`
            };
            return bigwig;
        },
        configFullModelTrack(track) {
            return {
                name: this.getTrackDisplayName(track),
                type: 'annotation',
                url: directory.getGencodeFullBedFileUrl().url,
                displayMode: 'EXPANDED',
                height: 100,
                autoHeight: true,
                removable: false,
                order: Number.MAX_VALUE, // pinning track to bottom,
                color: 'rgb(100,100,100)'
            };
        },
        configCollapsedModelTrack(track) {
            return {
                name: this.getTrackDisplayName(track),
                type: 'annotation',
                url: directory.getGencodeBedFileUrl().url,
                displayMode: 'EXPANDED',
                height: 100,
                autoHeight: true,
                removable: false,
                order: Number.MAX_VALUE, // pinning track to bottom,
                color: 'rgb(100,100,100)'
            };
        },
        configGwasTrack(track) {
            return {
                name: this.getTrackDisplayName(track),
                type: 'annotation',
                format: 'gtexgwas',
                url: directory.getGwasCatalogUrl().url,
                indexed: false,
                color: 'rgb(100,200,200)',
                displayMode: 'EXPANDED',
                order: Number.MAX_SAFE_INTEGER // 2nd to last track
            };
        }
    }
};
</script>
<style scoped>
.legend {
  border:1px solid grey;
  width:600px;
  padding:5px;
}
</style>

