<template>
  <div class="container-fluid" @wheel="handleScroll">
    <h2>Download Open Access Datasets</h2>
    <div class="dataset-selector">
      <div>Select project:</div>
      <select :id="datasetSelectorId" />
    </div>

    <!--Added this other drop down selector for when screen is small-->
    <div id="type-dropdown">
      <div class="dataset-selector">
        <div>Select data type:</div>
        <select :id="dataTypeSelectorId" />
      </div>
    </div>

    <div id="type-tabs" v-show="dataAvailable()">
      <ul :id="tabsContainerId" class="nav nav-tabs" >
        <li v-if="currDataset === 'adult-gtex'" id="overview_tab" :key="currDataset"
            :class="{ active: currDataType === 'overview' || currDataType === '' }"
            class="type-tab"
            role="presentation"
            @click="handleClick('overview')"
        >
          <a aria-controls="overview" data-toggle="tab" href="#overview" role="tab">Overview</a>
        </li>

        <li v-for="(dataType, i) in currDataTypes" :id="`${dataType.id}_tab`" :key="dataType.id"
            :class="{ active: (i === 0 && currDataset !== 'adult-gtex' && currDataType === '') || dataType.id === currDataType }"
            class="type-tab"
            role="presentation"
            @click="handleClick(dataType.id)"
        >
          <a :aria-controls="dataType.id" :href="`#${dataType.id}`" data-toggle="tab" role="tab">
            {{ dataType.displayName }}</a>
        </li>
      </ul>
    </div>

    <div class="tab-content">
      <div v-if="currDataset === 'adult-gtex'" id="overview" key="overview"
           :class="{ active: currDataType === 'overview' || currDataType === ''}"
           class="tab-pane"
           role="tabpanel"
      >
        <AdultGtexDataOverview />
      </div>
      
      <div v-if="!dataAvailable()">
        <data-coming-shortly-page/>
      </div>

      <div v-for="(dataType, i) in currDataTypes" :id="dataType.id" :key="dataType.id"
           :class="{ active: (i === 0 && currDataset !== 'adult-gtex' && currDataType === '') || dataType.id === currDataType}"
           class="tab-pane"
           role="tabpanel"
      >
        <!--If the dataset is not adult-gtex, activate the first tab if not redirecting to other tab-->

        <p v-html="dataType.description" />
        <data-panel-component v-for="(dataPanel, j) in dataType.dataPanels" :key="dataPanel.id"
                              :data-panel="dataPanel" :data-type="dataType" :groups="dataPanel.dataPanelGroups"
                              :index="j"
                              :tab-id="dataType.id" :tabs-container-id="tabsContainerId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router/router';
import { directory } from '@/utils/portal';
import AdultGtexDataOverview from './public_downloads_components/AdultGtexDataOverview.vue';
import DataPanelComponent from './public_downloads_components/DataPanelComponent.vue';
import DataType from './public_downloads_components/DataType';
import { RetrieveNonPaginatedData } from '../utils/pagination';
import downloadsPageUtils from './public_downloads_components/downloads-page-utils';
import DataComingShortlyPage from "./public_downloads_components/DataComingShortlyPage";

export default {
    components: {DataComingShortlyPage, DataPanelComponent, AdultGtexDataOverview },
    data() {
        return {
            tabsContainerId: 'dataset-data-tabs',
            datasetSelectorId: 'dataset-selector',
            dataTypeSelectorId: 'dataType-selector',
            selectedDatasetId: this.$route.params.project,
            currDataset: this.$route.params.project,
            currDataType: this.$route.params.tab,
            datasetSelector: null,
            dataTypeSelector: null,
            datasetsDropdownArr: [],
            dataTypeDropdownArr: [],
            selectedDataTypeId: this.$route.params.tab,
            mapProjects: { 'adult-gtex': 'Adult GTEx', 'egtex': 'Enhancing GTEx (eGTEx)', 'dgtex': 'Developmental GTEx', "nhp-dgtex": 'Non-human Primate dGTEx' },
            datasets: ['adult-gtex', 'egtex', 'dgtex', 'nhp-dgtex'],
            currDataTypes: [],
            sticky: null
        };
    },
  created() {
      this.$watch(
      () =>this.$route.params, 
      (newParams, oldParams) => {
        if (newParams.project != oldParams.project) {
          console.log("here");
            this.setSelectedDataset(newParams.project);
            this.currDataTypes = [];
            this.getAllDataTypesInfo();
            this.scrollToTop();
        }
        if (newParams.tab != oldParams.tab) {
            this.currDataType = newParams.tab;
            this.scrollToTop();
        }
        if (newParams.hash) {
            this.scrollToTableId(newParams.hash);
        }
      }
    )
  },
    beforeMount() {
        if (this.$route.params.project === undefined || this.currDataset === undefined) this.setDefaults();
        router.push({ name: 'publicDownloadsPage', params: { project: this.currDataset, tab: this.currDataType } });
    },
    mounted() {
        this.getAllDataTypesInfo();
        this.sticky = document.getElementById('type-tabs').offsetTop;
        this.setDatasetsDropdownArr();
        this.createDatasetsDropdown();
        this.setSelectedDataset(this.$route.params.project);
    },
    updated() {
        //This function has to be called under updated() instead of mounted because tableIds aren't generated during mount
        if (this.$route.hash) {
            this.scrollToTableId(this.$route.hash);
        }
    },
    methods: {
        /**
       * Changes the current tab within the downloads page.
       * @param type
       */
        handleClick(type) {
            router.push({ name: 'publicDownloadsPage', params: { project: this.currDataset, tab: type } });
        },
        /**
         * Scrolls to the top of the page
         */
        scrollToTop() {
            window.scrollTo(0, 0);
            this.handleScroll();
        },
        handleScroll(e) {
            const typeBar = $('#type-tabs');
            if (window.scrollY >= this.sticky) {
                typeBar.addClass('sticky');
            } else {
                typeBar.removeClass('sticky');
            }
        },
        /**
         * Resets the default dataset and tab to Adult Gtex project Overview tab.
         */
        setDefaults() {           
            this.currDataset = 'adult-gtex';
            this.currDataType = 'overview';
        },
        /**
         * Loads all the data for the datasets downloads page
         */
        getAllDataTypesInfo() {
            this.currDataTypes = [];

            // Retrieves the URL of the API for datasets
            const url = directory.getOpenAccessFilesMetadata().url + '?project_id=' + this.currDataset;
            if (this.dataAvailable()){
                RetrieveNonPaginatedData(url).then(endpointReturn => {
                const data = endpointReturn['data'];
                for (const index in data) {
                    const dataType = data[index];
                    const dataTypeObject = new DataType(dataType, this.currDataset);
                    this.currDataTypes.push(dataTypeObject);
                }
                this.currDataTypes.sort(downloadsPageUtils.compareOrder);
                this.dataTypeDropdownArr = [{ id: 'overview', text: 'Overview', selected: true }];

                this.dataTypeDropdownArr = this.dataTypeDropdownArr.concat(this.currDataTypes.map((d, i) => {
                    return {
                        id: d.id,
                        text: d.displayName,
                        selected: d.id === this.selectedDataTypeId
                    };
                }));
                const dataTypeSelector = $(`#${this.dataTypeSelectorId}`).select2({
                    data: this.dataTypeDropdownArr,
                    width: '200px',
                    clearable: false
                });
                dataTypeSelector.on('change.select2', e => {
                    this.currDataType = dataTypeSelector.select2('data')[0].id;

                    $('.tab-pane').removeClass('active');

                    $('.type-tab').removeClass('active');

                    // Adding classes for the current tab
                    $(`#${this.currDataType}`).addClass('active');
                    $(`#${this.currDataType}_tab`).addClass('active');

                    router.push({
                        name: 'publicDownloadsPage',
                        params: { project: this.currDataset, tab: this.currDataType }
                    });
                });

                this.dataTypeSelector = dataTypeSelector;
                console.log(this);
            });
            }
        },
        setDatasetsDropdownArr() {
            this.datasetsDropdownArr = this.datasets.map((d, i) => {
                if (d === this.selectedDatasetId) {
                    this.currDataset = d;
                }
                return {
                    id: i,
                    text: this.mapProjects[d],
                    selected: d.id === this.selectedDatasetId
                };
            });
        },
        createDatasetsDropdown() {
            this.datasetSelector = $(`#${this.datasetSelectorId}`).select2({
                data: this.datasetsDropdownArr,
                width: '200px',
                clearable: false,
                minimumResultsForSearch: -1
            });
        },
        dataAvailable(){
          return this.currDataType != 'unavailable'
        },
        setSelectedDataset(projectId) {
            this.selectedDatasetId = projectId;
            const datasetIndex = this.datasets.indexOf(projectId);
            this.currDataset = this.datasets[datasetIndex];
            this.datasetSelector.val(datasetIndex);
            this.datasetSelector.trigger('change');

            this.datasetSelector.on('change.select2', e => {
                const datasetIndex = parseInt(this.datasetSelector.select2('data')[0].id);
                const dataset = this.datasets[datasetIndex];
                this.currDataset = dataset.toString();
                if (datasetIndex === 1){
                  this.currDataType = "methylation";
                }
                else if (datasetIndex === 0){
                  this.setDefaults();
                }
                else{
                  this.currDataType = "unavailable"
                }
                router.push({ name: 'publicDownloadsPage', params: { project: this.currDataset, tab: this.currDataType } });
            });
        },
        /**
       * Scrolls the window to the table ID
       * @param id - the id of the table
       */
        scrollToTableId(id) {
            //four levels of parent brings to the table's header element
            const tableHeader = $(id).parent().parent().parent().parent()[0];
            //The setTimeout is necessary for the page to scroll to the right position
            //Unclear why it's necessary but this is the same as the scroll behavior in router.js
            setTimeout(() => {
                tableHeader.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
            }, 100);
        }
    }
};
</script>

<style scoped>
.dataset-selector {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 20px 0;
}

@media (max-width: 1260px) {
    #type-tabs {
        display: none;
    }

    #type-dropdown {
        display: block;
    }

}

@media (min-width: 1260px) {
    #type-dropdown {
        display: none;
    }

    #type-tabs {
        display: block;
    }
}

#type-tabs {
    background-color: white;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.sticky + .tab-content {
    padding-top: 60px;
}

.tab-content {
    margin-top: 10px;
    z-index: 0;
}


</style>

