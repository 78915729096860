<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-10 col-sm-offset-1">
        <h2>
          <!-- <img src="@/assets/images/dGTEx-logo.png" width="80"> -->
          Development Genotype-Tissue Expression (dGTEx) Project
        </h2>
        <div class="row">
          <div class="col-xs-12">
            <h3>Project Overview</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div style="float:right; margin-left: 50px">
              <img src="@/assets/images/dGTEx-sample-collection-v3.png" width="500">
              <div class="img-caption">dGTEx proposed tissue sample collection design</div>
            </div>
            <p>
              The <a href="https://www.genome.gov/Funded-Programs-Projects/Developmental-Genotype-Tissue-Expression" target="_blank">Developmental Genotype-Tissue Expression (dGTEx) Project</a> is a new effort to study development-specific genetic effects on gene expression from donors at each of 4 developmental windows: postnatal, early childhood, pre-pubertal, and post-pubertal.  The goal is to establish a data resource and tissue bank to study the regulation of gene expression in multiple reference tissues across human developmental stages, building on the Genotype-Tissue Expression (GTEx) project.  

            </p>
            <p>
              This study aims to recruit at least 120 donors and will collect and bank a large set of tissues per donor. The study will characterize a subset of tissues using state-of-the-art sequencing-based molecular assays, including single nucleus RNA-seq, multiomic single cell/nucleus RNA/ATAC-seq, and spatial transcriptomic assays. Moreover, the study will also measure gene expression and chromatin modifications using bulk tissue RNA-seq and ChIP-seq, respectively. These data will be used to characterize development-specific genetic effects on gene expression from the data generated across all donors and tissues, and through integrative analysis of quantitative trait loci (QTL) from existing GTEx data.
            </p>
            <p>
              This project is funded by the National Human Genome Research Institute (NHGRI) and the Eunice Kennedy Shriver National Institute for Child Health and Human Development (NICHD).
            </p>
            
            <p>
              The project was launched in late September 2021, and is expected to consist of an initial phase designing sample and tissue collections, and optimizing molecular assays during the first year. We anticipate tissue characterization and data production to start by late 2022.
               
            </p>
            
          </div>
          
        </div>
        <div class="row">
          <div class="col-sm-12">
            <br>
            <img src="@/assets/images/dGTEx-experimental-plan.png" width=90%>
            <div class="img-caption">dGTEx experimental plan</div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.screenshot {
  box-shadow: 4px 3px 3px #a1a1a1;
}

.img-caption {
  color: #8c8c8c;
  font-size: 12px;
  padding-top: 5px;
}
</style>