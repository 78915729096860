<template>
  <div class="container-fluid">
    <div class="row">
      <div
        id="datasetspage-nav-menu"
        style="display:none;"
        class="col-xs-1 gtx-docs-sidebar"
      />
      <div class="col-xs-10">
        <div class="row">
          <div
            id="browseDataDiv"
            class="col-xs-12"
            style="display:none;"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { directory, hostUrl } from '@/utils/portal';
import Dataset from '@/utils/models/dataset';
import TableUtils from '@/utils/table-utils';
import MessageHandler from '@/utils/message-handler';
import { RetrieveNonPaginatedData } from "../utils/pagination";

export default {
    data: function() {
        return {
            datasets: [],
            scrollItems: []
        };
    },
    mounted: function() {
        this.retrieveDatasets();
    },
    methods: {
        retrieveDatasets() {
          const url = directory.getFileListUrl().url;
          RetrieveNonPaginatedData(url).then(
              data => {
                this.clearNavMenu();
                this.parseDatasetsJson(data);
                this.displayDatasets();
                this.bindNavMenu();
              }
          );
        },
        clearNavMenu() {
            const navMenu = $('#datasetspage-nav-menu');
            navMenu.html('');
            const html = '<br><br><ul id="sidebar" class="nav nav-stacked affix"></ul>';
            navMenu.append(html);
        },
        bindNavMenu() {
            const topMenu = $('#datasetspage-nav-menu');
            topMenu.show();
            let lastId;
            const topMenuHeight = topMenu.outerHeight()+15;

            // All list items
            const menuItems = topMenu.find('a');

            // Anchors corresponding to menu items
            const scrollItems = menuItems.map(function() {
                const item = $($(this).attr('href'));
                if (item.length) { return item; }
            });

            /*
             * Bind click handler to menu items
             * so we can get a fancy scroll animation
             */
            menuItems.click(function(e) {
                const href = $(this).attr('href');
                const offsetTop = href === '#' ? 0 : $(href).offset().top -topMenuHeight+1;
                $('html, body').stop().animate({
                    scrollTop: offsetTop
                }, 300);
                e.preventDefault();
            });

            // Bind to scroll
            $(window).scroll(function() {
                // Get container scroll position
                const fromTop = $(this).scrollTop()+topMenuHeight;

                // Get id of current scroll item
                let cur = scrollItems.map(function() {
                    if ($(this).offset().top < (fromTop + 200)) return this;
                });
                // Get the id of the current element
                cur = cur[cur.length-1];
                const id = cur && cur.length ? cur[0].id : '';

                if (lastId !== id) {
                    lastId = id;
                    // Set/remove active class
                    menuItems
                        .parent().removeClass('active')
                        .end().filter('[href=#'+id+']').parent().addClass('active');
                }
            });
        },
        parseDatasetsJson(datasetsJson) {
            const rootUrl = hostUrl;
            const _datasets = datasetsJson;
            // sorting datasets by order attribute
            _datasets.sort((a, b) => { return a.order - b.order; });
            const numDatasets = _datasets.length;
            for (let i = 0; i < numDatasets; i++) {
                // sorting filesets for each dataset by order attribute
                _datasets[i]['filesets'].sort((a, b) => { return a.order - b.order; });
                const dataset = new Dataset(_datasets[i]);
                this.datasets.push(dataset);
            }
        },
        displayDatasets() {
            const browseDataDiv = $('#browseDataDiv');
            const biobankDatasetId = 'biobank_inventory';
            browseDataDiv.html('');
            browseDataDiv.show();
            for (let i = 0; i < this.datasets.length; i++) {
                const dataset = this.datasets[i];
                // skips over biobank files -- we don't want to display it under datasets
                if (dataset.id != biobankDatasetId) {
                    this.displayDataset(dataset, i);
                }
            }
            this.displayDatasetRenameMessage();
        },
        displayDataset(dataset, index) {
            const datasetTitleDivName = 'datasetTitleDiv' + dataset.order;
            const datasetDivName = 'datasetDiv' + dataset.order;

            const li = $('<li/>');
            const link = $('<a/>', {
                href: '#' + datasetTitleDivName,
                text: dataset.name
            });

            $(link).click(() => {
                const $datasetTitle = $('#' + datasetTitleDivName
                                          + ' .sectionTogglers');
                if ($datasetTitle.hasClass('collapsed')) {
                    $datasetTitle.click();
                }
            });

            $(li).append(link);
            this.scrollItems.push($(link).attr('href'));
            $('#sidebar').append(li);

            let datasetTitleDiv = $('<div/>', {
                id: datasetTitleDivName,
                class: 'datasetDivOnDatasetPage'
            });

            let refTag = $('<a/>', {
                class: 'sectionTogglers collapsed',
                href: '#' + datasetDivName,
                'data-toggle': 'collapse'
            });

            const iconSpan = $('<span/>', {
                style: 'padding-top: 10px',
                class: 'pull-left far fa-plus-square'
            });

            const title = $('<h1/>', {
                text: dataset.longName()
            });

            const datasetDiv = $('<div/>', {
                class: 'panel-group collapse',
                id: datasetDivName
            });

            // most recent dataset should be expanded
            if (dataset.order == 1 || dataset.id=='additional_datasets' || dataset.order == 0) {
                refTag.removeClass('collapsed');
                iconSpan.toggleClass('fa-plus-square fa-minus-square');
                datasetDiv.addClass('in');
            }

            refTag = refTag.append(iconSpan, title);
            datasetTitleDiv = datasetTitleDiv.append(refTag);

            $('#browseDataDiv').append(datasetTitleDiv, datasetDiv);
            if (dataset.description) {
                const descriptionDiv = $('<div/>', {
                    text: dataset.description
                });
                $('#' + datasetDivName).append(descriptionDiv);
            }
            this.displayFilesets(dataset.order, dataset.filesets, datasetDivName);

            this.makeCollapseIconTogglerHandler(datasetTitleDivName, datasetDivName);
        },
        displayFilesets(datasetOrderNum, filesets, datasetDivName) {
            const numFilesets = filesets.length;
            for (let i = 0; i < numFilesets; i++) {
                const fileset = filesets[i];
                // sorting files in a fileset by order attribute
                fileset['files'].sort((a, b) => { return a.order - b.order; });
                this.displayFileset(datasetOrderNum, fileset.order, fileset, datasetDivName);
            }
        },
        displayFileset(datasetOrderNum, parentFilesetOrderNum, fileset, parentDivId) {
            const parentDiv = $('#' + parentDivId);
            const uniqueSuffix = datasetOrderNum.toString() + parentFilesetOrderNum.toString();
            const wrapperDivId = 'filesetDiv' + uniqueSuffix;
            const divId = 'filesetFilesDiv' + uniqueSuffix;
            const tableId = 'filesTable' + uniqueSuffix;
            const panelBodyId = 'panelBodyDiv' + uniqueSuffix;

            const wrapperHtml = '<div id="' + wrapperDivId + '" class="panel panel-default"></div>';
            parentDiv.append(wrapperHtml);
            const wrapperDiv = $('#' + wrapperDivId);

            const h2 = '<div class="panel-heading"><a href="#' + divId + '" data-toggle="collapse"><h2 class="panel-title">' + fileset.name + '</h2></a></div>';
            wrapperDiv.append(h2);

            const divhtml = '<div id="' + divId + '" class="filesetDiv panel-collapse collapse in"><div id="' +
                panelBodyId + '" class="panel-body"></div></div>';
            wrapperDiv.append(divhtml);
            const panelBodyDiv = $('#' + panelBodyId);
            this.addDescriptionToDiv(fileset.description, panelBodyDiv);
            if (fileset.files.length > 0) {
                panelBodyDiv.append('<table id="' + tableId + '"></table>');
                this.generateFilesetTable(tableId, fileset);
            }

            const filesets = fileset.filesets;
            if (filesets !== undefined && filesets.length > 0) {
                this.displaySubFilesets(filesets, panelBodyId, datasetOrderNum, parentFilesetOrderNum);
            }
        },
        addDescriptionToDiv(description, parentDiv) {
            if (description.length == 0) return;

            const descDiv = '<div class="expDescription">' + description + '</div>';
            parentDiv.append(descDiv);
        },
        generateFilesetTable(tableId, fileset, skipDescription) {
            const tableSelector = '#' + tableId;
            const emptyMessage = 'No files were found';
            let objectHeadings = [];
            if (skipDescription) {
                objectHeadings = ['Order', 'Name', 'Name', 'Size'];
            } else {
                objectHeadings = ['Order', 'Description', 'Name', 'Name', 'Size'];
            }

            const oTable = $(tableSelector);
            const tableElt = oTable[0];
            TableUtils.buildTableHtml(tableElt, objectHeadings);

            const buttonProps = {
                'buttons': []
            };
            const tablemaker = this;

            let columnProps = [];
            if (skipDescription) {
                columnProps = [
                    { data: 'order', 'visible': false, 'searchable': false },
                    { data: 'name', 'visible': false, 'searchable': false },
                    {
                        defaultContent: '',
                        'render': function(data, type, row, meta) {
                            return tablemaker.generateFileUrl(row.name, row.url);
                        }
                    },
                    { data: 'size' }
                ];
            } else {
                columnProps = [
                    { data: 'order', 'visible': false, 'searchable': false },
                    { data: 'description', 'width': '60%' },
                    { data: 'name', 'visible': false, 'searchable': false },
                    {
                        defaultContent: '',
                        'render': function(data, type, row, meta) {
                            //console.log(row);
                            return tablemaker.generateFileUrl(row.name, row.url);
                        }
                    },
                    { data: 'size' }
                ];
            }

            $.fn.dataTableExt.sErrMode = 'throw';
            if ($.fn.dataTable.isDataTable(oTable)) {
                const t = oTable.DataTable();
                t.destroy();
            }

            try {
                oTable.dataTable({
                    deferRender: true,
                    destroy: true,
                    retrieve: true,
                    jQueryUI: true,
                    processing: true,
                    paging: false,
                    data: fileset.files,
                    columns: columnProps,
                    dom: 'B<"clear">lrtp',
                    orderClasses: false,
                    buttons: buttonProps,
                    language: {
                        'sEmptyTable': 'Please wait - Fetching records'
                    },

                    fnInitComplete: function(oSettings) {
                        oSettings.oLanguage.sEmptyTable = emptyMessage;
                        $(oTable).find('.dataTables_empty').html(emptyMessage);
                    }
                });
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err +
                    'unable to create table for files');
                oTable.addClass('display');
                oTable.show();
                oTable.fnSort([[0, 'asc']]);
                return oTable;
            }
        },
        displaySubFilesets(filesets, parentFilesetDivId, datasetOrderNum, parentFilesetOrderNum) {
            const numFilesets = filesets.length;
            const panelGroupDivId = 'subFilesetsDiv' + datasetOrderNum + parentFilesetOrderNum;
            const parentFilesetDiv = $('#' + parentFilesetDivId);
            parentFilesetDiv.append('<div id="' + panelGroupDivId + '" class="panel-group"></div>');

            // sorting subfilesets by order attribute
            filesets.sort((a, b) => { return a.order - b.order; });
            for (let i = 0; i < numFilesets; i++) {
                const fileset = filesets[i];
                // sorting subfileset files by order attribute
                fileset['files'].sort((a, b) => { return a.order - b.order; });
                this.displaySubFileset(datasetOrderNum, parentFilesetOrderNum, fileset, panelGroupDivId);
            }
        },
        displaySubFileset(datasetOrderNum, parentFilesetOrderNum, fileset, parentDivId) {
            // TODO integrate with displayFileset function
            const parentDiv = $('#' + parentDivId);
            const uniqueSuffix = datasetOrderNum.toString() + parentFilesetOrderNum.toString() + fileset.order.toString();
            const wrapperDivId = 'filesetDiv' + uniqueSuffix;
            const divId = 'filesetFilesDiv' + uniqueSuffix;
            const tableId = 'filesTable' + uniqueSuffix;
            const panelBodyId = 'panelBodyDiv' + uniqueSuffix;

            const wrapperHtml = '<div id="' + wrapperDivId + '" class="panel panel-default"></div>';
            parentDiv.append(wrapperHtml);
            const wrapperDiv = $('#' + wrapperDivId);

            const h3 = '<div class="panel-heading"><a href="#' + divId + '" data-toggle="collapse"><h3 class="panel-title">'
                + fileset.name + '</h3></a></div>';
            wrapperDiv.append(h3);

            const divhtml = '<div id="' + divId + '" class="filesetDiv panel-collapse collapse"><div id="' +
                panelBodyId + '" class="panel-body"></div></div>';
            wrapperDiv.append(divhtml);
            const panelBodyDiv = $('#' + panelBodyId);
            this.addDescriptionToDiv(fileset.description, panelBodyDiv);
            if (fileset.files.length) panelBodyDiv.append('<table id ="' + tableId + '"></table>');

            if (fileset.subpath.startsWith('gtex_external_datasets')) {
                $('#' + divId).addClass('in');
                this.generateFilesetTable(tableId, fileset, false);
            } else this.generateFilesetTable(tableId, fileset, true);
        },
        generateFileUrl(name, path) {
            return '<a href="' + 'https://storage.googleapis.com/' + path + '">' + name + '</a>';
        },
        makeCollapseIconTogglerHandler(parentDiv, targetDiv) {
            const $parentDiv = $('#' + parentDiv + ' .sectionTogglers .far');
            $('#' + targetDiv).on('show.bs.collapse', e => {
                if (e.target.id == targetDiv) {
                    $parentDiv.removeClass('fa-plus-square')
                        .addClass('fa-minus-square');
                }
            })
                .on('hide.bs.collapse', e => {
                    if (e.target.id == targetDiv) {
                        $parentDiv.removeClass('fa-minus-square')
                            .addClass('fa-plus-square');
                    }
                });
        },
        displayDatasetRenameMessage() {
            let message = '<hr><p>Note that on May 7, 2015, we renamed the datasets and their associated data files to be more consistent with ';
            message += 'dbGaP.  The dataset "GTEx Analysis 2014-01-17" has been renamed "GTEx Analysis V4".  ';
            message += 'The dataset "GTEx Analysis Pilot Data 2013-01-31" has been renamed "GTEx Analysis Pilot V3".</p>';
            $('#browseDataDiv').append(message);
        }
    }
};
</script>
