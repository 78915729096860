<template>
  <div id="project-overview" class="page-content">
    <h3>Project Overview</h3>
    <p>The <a href="https://www.genome.gov/Funded-Programs-Projects/Developmental-Genotype-Tissue-Expression"
              target="_blank">Developmental Genotype-Tissue Expression (dGTEx) Project</a> is a new effort to study
      development-specific genetic effects on gene expression from donors at each of <b>4 developmental windows:</b></p>
    <div class="grid">
      <div class="cell">
        <div class="flex-container">
          <div class="item">
            <div class="bold">Infant</div>
            <div>0-2 yo</div>
          </div>
          <div class="item">
            <img :src="infantSvg"/>
          </div>
        </div>
      </div>
      <div class="cell">
        <div class="flex-container">
          <div class="item">
            <div class="bold">Early Childhood</div>
            <div>2-8 yo</div>
          </div>
          <div class="item">
            <img :src="earlyChildhoodSvg"/>
          </div>
        </div>
      </div>
      <div class="cell">
        <div class="flex-container">
          <div class="item">
            <div class="bold">Pre-pubertal</div>
            <div>~8-12.5 yo</div>
          </div>
          <div class="item"><img :src="prepubertalSvg"/></div>
        </div>
      </div>
      <div class="cell">
        <div class="flex-container">
          <div class="item">
            <div class="bold">Post-pubertal</div>
            <div>~12.5-18 yo</div>
          </div>
          <div class="item"><img :src="postpubertalSvg"/></div>
        </div>
      </div>
    </div>
    <p>Despite considerable variation in expression patterns across tissues and across individuals, these patterns are
      relatively stable throughout adulthood, when most human gene expression studies have been done. The changes in
      gene expression that occur during postnatal development are largely unstudied, yet lead to important milestones in
      health and disease, such as the onset or cessation of growth spurts or puberty, and the development of both
      childhood specific diseases, as well as diseases that manifest much later in life.
    </p>

  </div>
</template>
<script>

export default {
  data() {
    return {
      infantSvg: require('@/assets/images/dgtex/infant.svg'),
      earlyChildhoodSvg: require('@/assets/images/dgtex/earlyChildhood.svg'),
      prepubertalSvg: require('@/assets/images/dgtex/prepubertal.svg'),
      postpubertalSvg: require('@/assets/images/dgtex/postpubertal.svg')
    }
  }
}
</script>

<style scoped>
#project-overview {
  margin-bottom: 5vh;
  text-align: left;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 1000px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
}

.cell {
  height: 100px;
  background: #F2F2F2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-container {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.item {
  min-height: 100%;
  margin: 0px 10px;
}

img {
  padding: 0;
}

.center {
  margin: auto;
}

.bold {
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: bold;
}
</style>