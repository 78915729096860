<template>
  <div class="container-fluid">
    <h2>
      GTEx Biobank<img src="@/assets/images/GTEx-lung-icon.svg" width="40px" style="margin: 0px 0px 0px 10px;">
    </h2>

    <div>
      <div :id="navTabId">
        <ul class="nav nav-tabs">
          <li role="presentation" class="active">
            <a role="tab" data-toggle="tab" aria-controls="about" href="#about">About the biobank</a>
          </li>
          <li role="presentation">
            <a role="tab" data-toggle="tab" aria-controls="search" href="#search">Sample search</a>
          </li>
          <li role="presentation">
            <a role="tab" data-toggle="tab" aria-controls="docs" href="#docs">Forms and additional documentation</a>
          </li>
          <li role="presentation">
            <a role="tab" data-toggle="tab" aria-controls="authed-reqs" href="#authed-reqs">Previously authorized requests</a>
          </li>
        </ul>
      </div>
      <div :id="contentsId" class="tab-content">
        <div id="about" role="tabpanel" class="tab-pane active">
          <biobank-description :biobank-inventory-file-url="inventoryFileUrl" />
        </div>
        <div id="docs" role="tabpanel" class="tab-pane">
          <biobank-documents :biobank-inventory-file-url="inventoryFileUrl" />
        </div>

        <div id="authed-reqs" role="tabpanel" class="tab-pane">
          <biobank-authorized-requests />
        </div>

        <div id="search" role="tabpanel" class="tab-pane">
          <biobank-sample-search :biobank-inventory-file-url="inventoryFileUrl" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import BiobankAuthorizedRequests from './biobank_tabs/BiobankAuthorizedRequests.vue';
import BiobankDescription from './biobank_tabs/BiobankDescription.vue';
import BiobankSampleSearch from './biobank_tabs/BiobankSampleSearch.vue';
import BiobankDocuments from './biobank_tabs/BiobankDocuments.vue';
import { directory } from '@/utils/portal';

export default {
    components: {
        BiobankAuthorizedRequests,
        BiobankDescription,
        BiobankDocuments,
        BiobankSampleSearch
    },
    data: function() {
        return {
            navTabId: 'biobank-navtabs',
            contentsId: 'biobank-page-contents',
            initSectionId: 'about',
            inventoryFileUrl: ''
        };
    },
    watch: {
        /**
       * Watching the URL hash allows us to properly display tabs when a user uses the back/forward navigation arrows.
       * Setting a timeout gives the UI time to catch up and properly add classes.
       */
        '$route.hash': function(hash) {
            hash = hash || `#${this.initSectionId}`;
            setTimeout(() => {
                if ($(hash).hasClass('active')) return;
                else {
                    $(`a[href=${hash}]`).click();
                }
            }, 50);
        }
    },
    mounted: function() {
        this.initialize();
    },
    methods: {
        bindNavtabHandlers() {
            const component = this;
            const navtabs = $(`#${this.navTabId}`);
            const menuItems = navtabs.find('li');
            const sections = $(`#${this.contentsId}`).children();

            menuItems.click(function(e) {
                const menuLi = $(this);
                const href = menuLi.find('a').attr('href');
                window.location.hash = href;
            });
        },
        // finds the biobank "dataset" in a file list
        // assumes that there is only one corresponding dataset for biobank
        getBiobankDataset(fileList) {
            const biobankDatasetVersionId = 'biobank_inventory';
            const biobankDatasets = fileList.filter(d => d.id == biobankDatasetVersionId);
            return biobankDatasets[0];
        },
        // need to go into the fileset, and then grab the first file in that fileset.
        // assumes that the correct fileset and file are the first one in each respective array.
        getInventoryFilename(filesets) {
            const biobankFile = filesets[0].files[0];
            return biobankFile.name;
        },
        // determines biobank inventory file URL to pass to children components
        getBiobankInventoryFileUrl() {
            const component = this;
            const biobankInventoryUrlPrefix = 'https://storage.googleapis.com/gtex_biobank/downloads/';
            const dataFilesUrl = directory.getFileListUrl().url;
            $.ajax({
                url: dataFilesUrl,
                type: 'GET'
            }).done(data => {
                const biobankDataset = component.getBiobankDataset(data);
                const inventoryFilename = component.getInventoryFilename(biobankDataset.filesets);
                component.inventoryFileUrl = `${biobankInventoryUrlPrefix}${inventoryFilename}`;
            });
        },
        // checks if user is trying to navigate to a specific tab on page entry and navigates them there
        checkHashAndNavigate() {
            const hash = window.location.hash;
            if (hash) {
                $(`a[href=${hash}]`).tab('show');
            }
        },
        initialize() {
            this.getBiobankInventoryFileUrl();
            this.bindNavtabHandlers();
            this.checkHashAndNavigate();
        }
    }
};
</script>

<style scoped>
.tab-pane {
  margin: 25px 30px;
}

#biobank-navtabs > .nav-tabs > li {
  margin-bottom: -2px;
}
</style>
