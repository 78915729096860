<template>
  <div class="container-fluid">
    <div class="col-sm-10">
      <h2>GTEx Protected Access Data</h2>
      <p>
        Due to the nature of our donor consent agreement, raw data and attributes which might be used to identify
        the donors are not publicly available on the GTEx Portal.
      </p>
      <p>
        Available protected access data include:
      </p>
      <ul>
        <li>
          BAM files for RNA-Seq, Whole Exome Seq, and Whole Genome Seq
        </li>
        <li>
          Genotype Calls (.vcf) for WES and WGS
        </li>
        <li>
          Allele Specific Expression (ASE) tables
        </li>
        <li>
          All expression matrices from the Portal, including samples that did not pass the Analysis Freeze QC
        </li>
        <li>
          Expanded sample attributes
        </li>
        <li>
          Expanded subject phenotypes, including age and ethnicity
        </li>
      </ul>

      <h2>How to Access the GTEx Protected Access Data</h2>
      <p>
        Access to GTEx protected access data
        requires an approved dbGaP application. GTEx V8, V9, and V10 protected access data are now hosted in the <a
          href="https://anvil.terra.bio/#workspaces/anvil-datastorage/AnVIL_GTEx_V8_hg38"
          target="_blank"
        >AnVIL <i class="fas fa-external-link-alt" /></a> repository, with access granted to users who have approved
        dbGaP applications.
      </p>
      <p>To access GTEx protected data in AnVIL, please follow the steps below: </p>
      <div class="col-sm-12">
        <ol type="1">
          <li>
            You must first have, or obtain, access to GTEx protected access data on dbGaP. If you do not currently
            have access to the GTEx protected access
            data on dbGaP, you can apply for access by selecting “Request Access” at this
            <a href="https://www.ncbi.nlm.nih.gov/projects/gap/cgi-bin/study.cgi?study_id=phs000424.v8.p2"
               target="_blank"
            >link <i class="fas fa-external-link-alt" /></a>.
          </li>
          <li>
            Create an account on the <a href="https://anvil.terra.bio" target="_blank">AnVIL <i
              class="fas fa-external-link-alt"
            /></a>.
            This is the NHGRI’s new genomic data repository (anvilproject.org). More information about the site can be
            found
            <a href="https://grants.nih.gov/grants/guide/notice-files/NOT-HG-19-024.html" target="_blank">here</a>.
            Detailed instructions for creating an account can be found at this
            <a href="https://support.terra.bio/hc/en-us/articles/360028235911-How-to-register-for-a-Terra-account"
               target="_blank"
            >link <i class="fas fa-external-link-alt" /></a>. If you already have an account on another
            <a href="http://app.terra.bio" target="_blank">Terra <i class="fas fa-external-link-alt" /></a> platform, you
            can simply log in.
          </li>
          <li>
            You will need to link your eRA Commons ID to your Terra account. On the Terra platform, open the menu in
            the upper left, then click your name, followed by profile. On the right hand side of the profile page,
            you will see an option to link your eRA Commons ID. Click there, and follow the prompts. Once complete,
            you will see the datasets you have been authorized to access. If your
            dbGAP authorization has been approved for GTEx, you’ll also see that in this list. Terra should
            synchronize quickly with dbGaP but there can be a delay after you synchronize your eRA Commons ID.
          </li>
          <li>
            The data for GTEx V8 is found in a workspace located
            <a href="https://app.terra.bio/#workspaces/anvil-datastorage/AnVIL_GTEx_V8_hg38" target="_blank">here <i
              class="fas fa-external-link-alt"
            /></a>.
            The data for GTEx V9 is found in a workspace located
            <a href="https://app.terra.bio/#workspaces/anvil-datastorage/AnVIL_GTEx_V9_hg38" target="_blank">here <i
              class="fas fa-external-link-alt"
            /></a>.
            The data for GTEx V10 is found in a workspace located
            <a href="https://app.terra.bio/#workspaces/anvil-datastorage/AnVIL_GTEx_V10_hg38" target="_blank">here <i
              class="fas fa-external-link-alt"
            /></a>.

            If you are a new user of Terra, there are a number of helpful tutorials and walkthroughs in the
            <a href="https://support.terra.bio/hc/en-us" target="_blank">help section <i
              class="fas fa-external-link-alt"
            /></a>. Some particularly helpful articles are listed below:
            <ul>
              <li>
                <a href="https://support.terra.bio/hc/en-us/sections/360006866192-New-users-overview" target="_blank">New
                  Users Overview <i class="fas fa-external-link-alt" /></a>
              </li>
              <li>
                <a href="https://support.terra.bio/hc/en-us/articles/360028444332-Terra-workshop-at-BroadE-May-22-2019"
                   target="_blank"
                >In-Depth Terra Workshop <i class="fas fa-external-link-alt" /></a>
              </li>
              <li>
                <a href="https://support.terra.bio/hc/en-us/articles/360026182251-How-to-set-up-billing-in-Terra"
                   target="_blank"
                >How to Setup Billing in Terra <i class="fas fa-external-link-alt" /></a>
              </li>
            </ul>
            For additional Terra-specific help, please see the Terra <a
              href="https://support.terra.bio/hc/en-us/community/topics/360000500432" target="_blank"
            >community forum <i
              class="fas fa-external-link-alt"
            /></a> or submit a request to
            <a href="https://support.terra.bio/hc/en-us/requests/new" target="_blank">Terra Support <i
              class="fas fa-external-link-alt"
            /></a>.
          </li>
        </ol>
      </div>

      <p>
        <span style="font-weight: 600;">Downloading Protected Access Data</span>:
        Please note that downloading GTEx protected access data from Terra will incur egress fees from Google.
      </p>

      <br>
      <span style="font-weight: 600;">GTEx Open Access Data</span>: The GTEx Portal displays V10 data.
      GTEx open access data can be downloaded
      <router-link class="navLink" to="/datasets">
        here
      </router-link>
      .
      <p>&nbsp;</p>
    </div>
  </div>
</template>
<script lang="ts">
</script>