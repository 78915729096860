/*
 * Copyright � 2013 The Broad Institute, Inc.
 * SOFTWARE COPYRIGHT NOTICE
 * This software and its documentation are the copyright of the
 * Broad Institute, Inc. All rights are reserved.
 *
 * This software is supplied without any warranty or guaranteed support
 * whatsoever. The Broad Institute is not responsible for its
 * use, misuse, or functionality.
 */

/**
 * Contains utility methods for tables.
 *
 * @author jnedzel
 */
export default function TableUtils() {

};

/**
 * Adds single quotes around the string and a comma, for use as
 * parameters to an URL.
 * @param theString the string to quote
 * @param isLast if true, add a comma.
 */
TableUtils.asUrlString = function(theString, isLast) {
    let result = '\'' + theString;
    if (isLast) {
        result += '\'';
    } else {
        result += '\',';
    }
    return result;
};

TableUtils.buildTableHtml = function(tableElement, headings) {
    let newContent = '<thead><tr>';
    const numHeadings = headings.length;
    for (let i = 0; i < numHeadings; i++) {
        const heading = headings[i];
        newContent += '<th>' + heading + '</th>';
    }
    newContent += '</tr></thead><tbody></tbody>';
    $(tableElement).children().remove();
    $(tableElement).prepend(newContent);
};

TableUtils.contains = function(array, element) {
    let i;
    for (i = 0; i < array.length; i++) {
        if (element == array[i]) {
            return true;
        };
    };
    return false;
};

TableUtils.generateDownloadButton = function(url) {
    return `<a href="${url}"><i class="fas fa-download"></i></a>`;
};
