<template>
  <div>
    <p>
      The Adult Genotype-Tissue Expression (GTEx) project is a comprehensive public resource for the study of tissue-specific gene expression and regulation. Samples were collected from 54 non-diseased tissue sites across nearly 1000 individuals, primarily for molecular assays including WGS, WES, and RNA-Seq.
    </p>
    <p>
      All data files generated in the GTEx project are available for download.
      Only open access data files are available here on the GTEx portal.
      All raw sequence data files (DNA and RNA), and the full donor metadata files, are protected access data.
      Please see our <router-link to="/protectedDataAccess">Protected Data Downloads page</router-link> for instructions on how to request access to those data.
      </p>
    <p>
      <b>
      Note that GTEx protected access data files are housed in <a href="https://anvil.terra.bio/" target="_blank">AnVIL <i class="fas fa-external-link-alt"/></a>,
      NOT in the Sequence Read Archive (SRA)</b>.
    </p>

    <div class="row">
      <div class="col-lg-offset-1 col-xl-offset-2 col-sm-12 col-lg-10 col-xl-8">
        <img src="@/assets/images/adult_gtex_data_overview.svg" focusable="true" role="img" class="img-responsive">
      </div>
    </div>

    <h4>Downloading Adult GTEx data</h4>
    <p>Explore and download open-access Adult GTEx data by clicking on the subsequent tabs on this page. Users interested in accessing protected data must apply for access separately and can learn how to do so by visiting our protected data access page.</p>
    <p>Additional assays were run on Adult GTEx samples as part of the Enhancing GTEx (eGTEx) project. Select the eGTEx dataset at the top of the page to learn more and to download available data.</p>

    <h4>Citing/acknowledging the GTEx project</h4>
    <p>
      GTEx data, visualizations, and tools can all be used with acknowledgement. An example acknowledgment statement follows:
      </p>
    <p>
      The data used for the analyses described in this manuscript were obtained from: [insert, where appropriate] the GTEx Portal on MM/DD/YY and/or dbGaP accession number phs000424.vN.pN on MM/DD/YYYY.
    </p>
    <h4>Related pages</h4>
    <ul>
      <li>The <router-link to="/samplingSitePage">Tissue Sampling Sites page</router-link> shows more information about our tissue sampling procedures.</li>
      <li>The <router-link to="/tissue/Adipose_Subcutaneous"> Tissue Summary page</router-link> shows summary statistics about the current release.</li>
      <li>The <router-link to="/releaseInfoPage">Release History page</router-link> shows the history of our releases.</li>
      <li>The <router-link to="/singleCellOverviewPage">Single Cell Dataset Overview page</router-link> shows an overview of our single cell data.</li>
      <li>The <router-link to="/methods">Lab and Analysis Methods page</router-link> shows information about our methods.</li>
    </ul>
    <div />
  </div>
</template>

<script>
export default {
  name: 'AdultGTExDataOverview'
}
</script>
