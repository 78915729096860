<template>
  <div>
    <div>
      <p>
        Use the search form below to find biobank samples according to your criteria of interest through the GTEx Portal.
        Sample ID searches will take precedence over searches using the dropdown selections.
        By adding samples to the cart, you will be able to view all your selected samples together and download a TSV file of your samples of interest to send in with your sample request.
      </p>

<!--      <p>
        If you would prefer to manually select samples, the most up-to-date inventory file can be <a :href="inventoryFileUrl" target="_blank">downloaded here</a>. -->
      <p>A sample request can also be submitted without selecting samples by clicking on the "Checkout" button below.
      </p>
      <p>
        Please note: the sample search currently returns tissues and all derived samples, such as RNA and DNA.
        Many of these samples, however, have not undergone primary molecular analyses for use in the GTEx resource.
        As a result, sample availability and amount are subject to change without notice.
      </p>
      <p>
        Images for the samples used in the analyses performed by the GTEx Consortium can be viewed using
        <router-link class="navLink" :to="{name: 'histologyViewerPage'}">
          the GTEx Histology Image Viewer.
        </router-link>
        <br>
        Images for all samples can be viewed in the
        <a href="https://brd.nci.nih.gov/brd/" target="_blank">
          NCI Biospecimen Research Database <i class="fas fa-external-link-alt" />
        </a>.
        <br>
        Additional brain samples that are not shown here may be available from the residual brains stored at the
        <a href="http://miamibrainbank.org/" target="_blank">Miami Brain Bank <i class="fas fa-external-link-alt" /></a>.
      </p>
      <br>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-8" style="border-right:2px solid #333">
            <div class="row">
              <div class="col-xs-8">
                <select id="materialType" name="materialType" class="form-control">
                  <option />
                  <template v-for="option in materialTypeSearchOptions" :key="option.value">
                    <option :value="option.value">
                      {{ option.display }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="col-xs-4">
                <select id="hasExpressionData" name="hasExpressionData" class="form-control">
                  <option />
                  <template v-for="option in expressionSearchOptions"  :key="option.value">
                    <option :value="option.value">
                      {{ option.display }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-xs-4">
                <select id="tissueSiteDetail" name="tissueSiteDetail" class="form-control">
                  <option />
                </select>
              </div>
              <div class="col-xs-8">
                <select id="pathNotesCategories" name="pathNotesCategories" class="form-control" multiple>
                  <template v-for="option in pathCategoriesSearchOptions"  :key="option.value">
                    <option :value="option.value">
                      {{ option.display }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-xs-2">
                <select id="sex" name="sex" class="form-control">
                  <option />
                  <template v-for="option in sexSearchOptions"  :key="option.value">
                    <option :value="option.value">
                      {{ option.display }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="col-xs-2">
                <select id="ageBracket" name="ageBracket" class="form-control">
                  <option />
                  <template v-for="option in ageSearchOptions"  :key="option.value" >
                    <option :value="option.value">
                      {{ option.display }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="col-xs-4">
                <select id="hardyScale" name="hardyScale" class="form-control">
                  <option />
                  <template v-for="option in hardyScaleSearchOptions"  :key="option.value">
                    <option :value="option.value">
                      {{ option.display }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="col-xs-4">
                <select id="hasGenotype" name="hasGenotype" class="form-control">
                  <option />
                  <template v-for="option in genotypeSearchOptions"  :key="option.value" >
                    <option :value="option.value">
                      {{ option.display }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
          </div>
          <div class="col-xs-4">
            <textarea id="sampleId" class="form-control" rows="6" placeholder="Enter one or more comma-separated sample IDs. Please note that this search occurs on the tissue sample ID to display all related samples." />
          </div>
        </div>
        <br>
        <div class="row text-center">
          <br>
          <div class="col-xs-12 text-center">
            <button id="sampleSearch" class="text-center">
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div id="sampleSearchMessageDiv" class="col-xs-12" />
      <div id="searchResultDiv" class="col-xs-12">
        <button id="biobankCartButton" type="submit" class="biobankRequest btn btn-md btn-info cart-btn-margin" style="display:none;" role="button">
          <span class="glyphicon glyphicon-shopping-cart" /> View Sample Cart <span class="badge">0</span>
        </button>
        <button id="clearCartButton" type="submit" class="btn btn-md btn-primary" style="display:none;" role="button">
          Clear Cart
        </button>
        <br><br>
        <span id="searchResultTableHeaderDiv" />
        <table id="searchResultTable" />
      </div>
    </div>
  </div>
</template>

<script>
import { tissueLookupTable, getTissueId } from '@/utils/tissue';
import BiobankSampleRequest from '@/utils/biobank-sample-request';
import { portalClient, directory } from '@/utils/portal';
import TableUtils from '@/utils/table-utils';
import MessageHandler from '@/utils/message-handler';
import downloadUtils from '@/utils/downloadUtil';

export default {
    props: {
        biobankInventoryFileUrl: {
            type: String,
            default: ''
        }
    },
    data: function() {
        return {
            inventoryFileUrl: this.biobankInventoryFileUrl,
            materialTypeSearchOptions: [
                { display: 'Cells:Cell Line Viable', value: 'Cells:Cell Line Viable' },
                { display: 'DNA:DNA Genomic', value: 'DNA:DNA Genomic' },
                { display: 'DNA:DNA Somatic', value: 'DNA:DNA Somatic' },
                { display: 'RNA:Total RNA', value: 'RNA:Total RNA' },
                { display: 'Tissue:PAXgene Preserved', value: 'Tissue:PAXgene Preserved' },
                { display: 'Tissue:PAXgene Preserved Paraffin-embedded', value: 'Tissue:PAXgene Preserved Paraffin-embedded' },
                { display: 'Tissue:Fresh Frozen Tissue', value: 'Tissue:Fresh Frozen Tissue' }
            ],
            expressionSearchOptions: [
                { display: 'True', value: 'true' },
                { display: 'False', value: 'false' }
            ],
            pathCategoriesSearchOptions: [
                { display: 'Adenoma', value: 'adenoma' },
                { display: 'Amylacea', value: 'amylacea' },
                { display: 'Atelectasis', value: 'atelectasis' },
                { display: 'Atherosclerosis', value: 'atherosclerosis' },
                { display: 'Atherosis', value: 'atherosis' },
                { display: 'Atrophy', value: 'atrophy' },
                { display: 'Calcification', value: 'calcification' },
                { display: 'Cirrhosis', value: 'cirrhosis' },
                { display: 'Clean specimens', value: 'clean_specimens' },
                { display: 'Congestion', value: 'congestion' },
                { display: 'Corpora Albicantia', value: 'corpora_albicantia' },
                { display: 'Cyst', value: 'cyst' },
                { display: 'Desquamation', value: 'desquamation' },
                { display: 'Diabetic', value: 'diabetic' },
                { display: 'Dysplasia', value: 'dysplasia' },
                { display: 'Edema', value: 'edema' },
                { display: 'Emphysema', value: 'emphysema' },
                { display: 'Esophagitis', value: 'esophagitis' },
                { display: 'Fibrosis', value: 'fibrosis' },
                { display: 'Gastritis', value: 'gastritis' },
                { display: 'Glomerulosclerosis', value: 'glomerulosclerosis' },
                { display: 'Goiter', value: 'goiter' },
                { display: 'Gynecomastoid', value: 'gynecomastoid' },
                { display: 'Hashimoto', value: 'hashimoto' },
                { display: 'Heart failure cells', value: 'heart_failure_cells' },
                { display: 'Hemorrhage', value: 'hemorrhage' },
                { display: 'Hepatitis', value: 'hepatitis' },
                { display: 'Hyalinization', value: 'hyalinization' },
                { display: 'Hypereosinophilia', value: 'hypereosinophilia' },
                { display: 'Hyperplasia', value: 'hyperplasia' },
                { display: 'Hypertrophy', value: 'hypertrophy' },
                { display: 'Hypoxic', value: 'hypoxic' },
                { display: 'Infarction', value: 'infarction' },
                { display: 'Inflammation', value: 'inflammation' },
                { display: 'Ischemic changes', value: 'ischemic_changes' },
                { display: 'Macrophages', value: 'macrophages' },
                { display: 'Mastopathy', value: 'mastopathy' },
                { display: 'Metaplasia', value: 'metaplasia' },
                { display: 'Monckeberg', value: 'monckeberg' },
                { display: 'Necrosis', value: 'necrosis' },
                { display: 'Nephritis', value: 'nephritis' },
                { display: 'Nephrosclerosis', value: 'nephrosclerosis' },
                { display: 'No abnormalities', value: 'no_abnormalities' },
                { display: 'Nodularity', value: 'nodularity' },
                { display: 'Pancreatitis', value: 'pancreatitis' },
                { display: 'Pigment', value: 'pigment' },
                { display: 'Pneumonia', value: 'pneumonia' },
                { display: 'Post menopausal', value: 'post_menopausal' },
                { display: 'Prostatitis', value: 'prostatitis' },
                { display: 'Saponification', value: 'saponification' },
                { display: 'Scarring', value: 'scarring' },
                { display: 'Sclerotic', value: 'sclerotic' },
                { display: 'Solar elastosis', value: 'solar_elastosis' },
                { display: 'Spermatogenesis', value: 'spermatogenesis' },
                { display: 'Steatosis', value: 'steatosis' },
                { display: 'Sweat glands', value: 'sweat_glands' },
                { display: 'Tma', value: 'tma' }
            ],
            sexSearchOptions: [
                { display: 'Female', value: 'female' },
                { display: 'Male', value: 'male' }
            ],
            ageSearchOptions: [
                { display: '20-29', value: '20-29' },
                { display: '30-39', value: '30-39' },
                { display: '40-49', value: '40-49' },
                { display: '50-59', value: '50-59' },
                { display: '60-69', value: '60-69' },
                { display: '70-79', value: '70-79' }
            ],
            hardyScaleSearchOptions: [
                { display: 'Fast death - natural causes', value: 'Fast death - natural causes' },
                { display: 'Fast death - violent', value: 'Fast death - violent' },
                { display: 'Intermediate death', value: 'Intermediate death' },
                { display: 'Slow death', value: 'Slow death' },
                { display: 'Ventilator case', value: 'Ventilator case' }
            ],
            genotypeSearchOptions: [
                { display: 'True', value: 'true' },
                { display: 'False', value: 'false' }
            ]
        };
    },
    watch: {
        biobankInventoryFileUrl() {
            this.inventoryFileUrl = this.biobankInventoryFileUrl;
        }
    },
    mounted: function() {
        this.initialize();
    },
    methods: {
        loadTissueList() {
            const tissueNames = [];
            for (const name in tissueLookupTable) {
                if (tissueLookupTable.hasOwnProperty(name)) {
                    tissueNames.push(name);
                }
            }
            tissueNames.sort();

            const tsdObject = $('#tissueSiteDetail');

            for (const i in tissueNames) {
                tsdObject.append($('<option>', {
                    value: getTissueId(tissueNames[i]),
                    text: tissueNames[i]
                }));
            }
        },
        initializeSearchForm() {
            $.fn.select2.defaults.set('allowClear', true);
            $.fn.select2.defaults.set('minimumResultsForSearch', 8);
            $.fn.select2.defaults.set('width', '100%');

            $('select#materialType').select2({
                placeholder: 'Material Type'
            });

            $('select#hasExpressionData').select2({
                placeholder: 'Has Expression Data'
            });

            $('select#tissueSiteDetail').select2({
                placeholder: 'Select Tissue...'
            });

            $('select#pathNotesCategories').select2({
                placeholder: 'Pathology Categories',
                closeOnSelect: false
            });

            $('select#sex').select2({
                placeholder: 'Sex'
            });

            $('select#ageBracket').select2({
                placeholder: 'Age'
            });

            $('select#hardyScale').select2({
                placeholder: 'Hardy Scale'
            });

            $('select#hasGenotype').select2({
                placeholder: 'Donor Has Genotype Data'
            });

            // prevent dropdown box from opening again on clearAll
            $('select').on('select2:unselecting', function() {
                $(this).data('unselecting', true);
            }).on('select2:opening', function(e) {
                if ($(this).data('unselecting')) {
                    $(this).removeData('unselecting');
                    e.preventDefault();
                }
            });
        },
        toUrlSuffix(queryParamDict) {
            /*
             * Translates a dict of query params to an url suffix.
             * The key is the query parameter name, the value is parameter value.
             * Only supports a single value per parameter
             */
            let firstParamAdded = false;
            let urlSuffix = '';
            for (const key in queryParamDict) {
                const value = queryParamDict[key];
                if (value) {
                    if (firstParamAdded) {
                        urlSuffix += '&';
                    } else {
                        urlSuffix += '?';
                        firstParamAdded = true;
                    }
                    urlSuffix += key + '=' + value;
                }
            }
            return urlSuffix;
        },
        urlSuffixFromSelects() {
            const materialType = $('#materialType').val();
            const tissueSiteDetail = $('#tissueSiteDetail').val();
            const sex = $('#sex').val();
            const ageBracket = $('#ageBracket').val();
            const hardyScale = $('#hardyScale').val();
            const hasExpressionData = $('#hasExpressionData').val();
            const hasGenotype = $('#hasGenotype').val();


            const urlSuffix = this.toUrlSuffix({
                'materialType': materialType,
                'tissueSiteDetailId': tissueSiteDetail,
                'sex': sex,
                'ageBracket': ageBracket,
                'hardyScale': hardyScale,
                'hasExpressionData': hasExpressionData,
                'hasGenotype': hasGenotype
            });

            return urlSuffix;
        },
        roundValue(value, numDecimals) {
            if (value) return +(value.toFixed(numDecimals));
            else return value;
        },
        determineSampleDepletion(amount, mass, materialType) {
            if (materialType != 'Cells:Cell Line Viable' && materialType != 'Tissue:PAXgene Preserved Paraffin-embedded' && (amount == 0 || mass == 0)) return 'Y';
            else return 'N';
        },
        generatePathCategoriesString(pathCategoriesHash) {
            let pathCategoriesString = '';
            let firstParamAdded = false;

            for (const category in pathCategoriesHash) {
                if (pathCategoriesHash[category]) {
                    if (firstParamAdded) {
                        pathCategoriesString += (', ' + category.replace(/_/g, ' '));
                    } else {
                        pathCategoriesString += category.replace(/_/g, ' ');
                        firstParamAdded = true;
                    }
                }
            }

            return pathCategoriesString;
        },
        generateImageLinks(tissueSampleId, hasGTExImageBool, hasBRDImageBool) {
            const gtexSuffix = directory.getGtexHistUrl().url;
            const gtexUrl = gtexSuffix + tissueSampleId + '/'; // somehow in production, the link only works with a /
            let gtexHtml = '';
            if (hasGTExImageBool) {
                gtexHtml = '<a href="' + gtexUrl + '" target="_blank">'
                + 'GTEx</a>';
            }

            const brdSuffix = directory.getBrdHistUrl().url;
            const brdUrl = brdSuffix + tissueSampleId;
            let brdHtml = '';
            if (hasBRDImageBool) {
                brdHtml = '<a href="' + brdUrl + '" target="_blank">'
                + 'BRD <i class="fas fa-external-link-alt"></i></a>';
            }
            return gtexHtml + ' ' + brdHtml;
        },
        generateSampleTable(selector, url, sampleIds) {
            const emptyMessage = 'No samples were found.';
            const samplesSearchedHash = {};
            for (let i = 0; i < sampleIds.length; i++) {
                samplesSearchedHash[sampleIds[i]] = false;
            }
            const brainInfo = '<div class="gtooltip fas fa-info-circle "' +
                            'data-toggle="tooltip" data-html="true" ' +
                            'data-placement="auto" data-container="body" ' +
                            'title="May have brain materials stored in the ' +
                            'Miami Brain Bank"' + '>'+ '</div>';
            const objectHeadings = [
                '<input id="checkAllForBiobank" type="checkbox" onclick="javascript:gtex.BiobankSampleRequest.checkAll(this, \''+ selector + '\')"></input>',
                'Sample Id',
                'Tissue Sample Id',
                'Subject Id',
                'Material Type',
                'Original Material Type',
                'Tissue Site Detail',
                'Sex',
                'Age Bracket',
                'Hardy Scale',
                'Has Expression Data',
                'From Genotyped Subject',
                brainInfo+' Brain Materials',
                'Autolysis Score',
                'RIN',
                'Amount (ng)',
                'Volume (uL)',
                'Concentration (ng/uL)',
                'Mass (mg)',
                'Sample Depleted',
                'Pathology Categories',
                'Image Links'
            ];

            const tableSelector = selector;


            const oTable = $(tableSelector);
            const tableElt = oTable[0];
            TableUtils.buildTableHtml(tableElt, objectHeadings);
            const tableMaker = this;
            const onSearchPage = window.location.href.indexOf('biobankRequestCart') == -1;
            const downloadButton = {
                name: 'download',
                text: onSearchPage ? 'Download Search Results' : 'Download Samples in Cart',
                action: function(e, dt) {
                    const queryParams = url.split('?')[1];
                    const downloadUrl = directory.getBiobankDownloadUrl().url + '?' + queryParams;
                    $('#spinner').show(); // show the spinner
                    $.ajax({
                        url: downloadUrl,
                        success: function(data) {
                            const biobankDownloadType = onSearchPage ? 'search' : 'cart';
                            downloadUtils.downloadTSV(data, `biobank.${biobankDownloadType}.${Date.now()}.tsv`);
                            $('#spinner').hide();
                        },
                        error: function(jqXHR, textStatus, thrownError) {
                            portalClient.messageHandler.showErrorFromJqXHR('Request failed', jqXHR);
                            $('#spinner').hide();
                        }
                    });
                },
                enabled: true
            };
            const buttonProps = { 'buttons': [] };
            if (url.includes('?')) {
                buttonProps['buttons'].push(downloadButton);
            }
            const columnProps = [
                {
                    defaultContent: '', orderable: false, render: function(data, type, row) {
                        return '<input type="checkbox" onclick="javascript:gtex.BiobankSampleRequest.checkboxOnClick(this)" id="' + row.sampleId + '" value="' + row.materialType + '|' + row.tissueSiteDetail + '"></input>';
                    }
                },
                {
                    name: 'sampleId', data: 'sampleId',
                    render: function(data, type, row) {
                        if (type === 'display') {
                            let sampleIdLabel = row.sampleId;
                            if (sampleIds.indexOf(row.sampleId)>-1) {
                                samplesSearchedHash[row.sampleId] = true;
                                sampleIdLabel = '<b>'+row.sampleId+'</b>'; // use boldface on the matched sample IDs when user searches samples by sample IDs
                            }
                            return sampleIdLabel;
                        } else {
                            return data;
                        }
                    }
                },
                {
                    name: 'tissueSampleId', data: 'tissueSampleId',
                    render: function(data, type, row) {
                        if (type === 'display') {
                            if (sampleIds.indexOf(row.tissueSampleId)>-1) samplesSearchedHash[row.tissueSampleId] = true;
                            return row.tissueSampleId;
                        } else {
                            return data;
                        }
                    }
                },
                {
                    name: 'subjectId', data: 'subjectId',
                    render: function(data, type, row) {
                        if (type === 'display') {
                            if (sampleIds.indexOf(row.subjectId)>-1) samplesSearchedHash[row.subjectId] = true;
                            return row.subjectId;
                        } else {
                            return data;
                        }
                    }
                },
                { name: 'materialType', data: 'materialType' },
                { name: 'originalMaterialType', data: 'originalMaterialType' },
                { name: 'tissueSiteDetail', data: 'tissueSiteDetail' },
                { name: 'sex', data: 'sex' },
                { name: 'ageBracket', data: 'ageBracket' },
                { name: 'hardyScale', data: 'hardyScale' },
                {
                    name: 'hasExpressionData', data: 'hasExpressionData',
                    render: function(data) {
                        const bool = data?'Y':'N';
                        return bool;
                    }
                },
                {
                    name: 'hasGenotype', data: 'hasGenotype',
                    render: function(data) {
                        const bool = data?'Y':'N';
                        return bool;
                    }
                },
                {
                    name: 'brainTissueDonor', data: 'brainTissueDonor',
                    render: function(data) {
                        const bool = data?'Y':'N';
                        return bool;
                    }
                },
                { name: 'autolysisScore', data: 'autolysisScore' },
                {
                    name: 'rin', data: 'rin',
                    render: function(data, type, row) {
                        return tableMaker.roundValue(data, 2);
                    }
                },
                {
                    name: 'amount', data: 'amount',
                    defaultContent: 'N/A',
                    render: function(data, type, row) {
                        return tableMaker.roundValue(data, 2);
                    }
                },
                {
                    name: 'volume', data: 'volume',
                    defaultContent: 'N/A',
                    render: function(data, type, row) {
                        return tableMaker.roundValue(data, 2);
                    }
                },
                {
                    name: 'concentration', data: 'concentration',
                    defaultContent: 'N/A',
                    render: function(data, type, row) {
                        return tableMaker.roundValue(data, 2);
                    }
                },
                {
                    name: 'mass', data: 'mass',
                    defaultContent: 'N/A',
                    render: function(data, type, row) {
                        return tableMaker.roundValue(data, 2);
                    }
                },
                // these 3 columns are created on the front-end and can't be processed server side for sorting
                {
                    defaultContent: '', orderable: false,
                    'render': function(data, type, row) {
                        return tableMaker.determineSampleDepletion(row.amount, row.mass, row.materialType);
                    }
                },
                {
                    defaultContent: '', orderable: false,
                    'render': function(data, type, row) {
                        return tableMaker.generatePathCategoriesString(row.pathologyNotesCategories);
                    }
                },
                {
                    defaultContent: '', orderable: false,
                    'render': function(data, type, row) {
                        return tableMaker.generateImageLinks(row.tissueSampleId, row.hasGTExImage, row.hasBRDImage);
                    }
                }
            ];
            // this is to prevent DataTables from putting warnings or errors in an alert box.
            $.fn.dataTableExt.sErrMode = 'none';
            if ($.fn.dataTable.isDataTable(oTable)) {
                const t = oTable.DataTable();
                t.destroy();
            }

            try {
                oTable
                    .on('error.dt', (e, settings, techNote, message) => {
                        const messageHandler = new MessageHandler();
                        messageHandler.showError(message);
                    })
                    .dataTable({
                        deferRender: true,
                        serverSide: true,
                        destroy: true,
                        retrieve: true,
                        jQueryUI: true,
                        processing: true,
                        paginationType: 'full_numbers',
                        columns: columnProps,
                        scrollX: true,
                        dom: 'B<"clear">lfrtip',
                        orderClasses: false,
                        buttons: buttonProps,
                        language: {
                            emptyTable: 'Please wait - Fetching records'
                        },
                        ajax: {
                            url: url,
                            data: function(d) {
                                const sort_column_index = d.order[0].column;
                                const direction = d.order[0].dir;
                                let sort_column_name = d.columns[sort_column_index].name;
                                if (sort_column_name != 'icon') {
                                    if (!sort_column_name) {
                                        sort_column_name = 'sampleId';
                                    }
                                    if (sort_column_name == 'tissueSiteDetail') {
                                        sort_column_name = 'tissueSiteDetailId';
                                    }
                                    d.sortBy = sort_column_name;
                                }

                                d.sortDirection = direction;

                                const search_term = d.search.value;
                                if (search_term) {
                                    d.searchTerm = search_term;
                                }
                                if ('start' in d) {
                                    d.page = Math.floor(d.start / d.length);
                                    delete d.start;
                                }
                                if ('length' in d) {
                                    d.itemsPerPage = d.length;
                                    delete d.length;
                                }

                                delete d.order;
                                delete d.columns;
                                delete d.search;
                            },
                            dataSrc: 'sample'
                        },
                        fnInitComplete: function(oSettings) {
                            oSettings.oLanguage.sEmptyTable = emptyMessage;

                            $(oTable).find('.dataTables_empty').html(emptyMessage);
                            /*
                             * check for samples found, display message if any
                             * samples searched by user were not found
                             */
                            const samplesNotFound = [];
                            for (const sample in samplesSearchedHash) {
                                if (!samplesSearchedHash[sample]) {
                                    samplesNotFound.push(sample);
                                }
                            }
                            if (samplesNotFound.length) {
                                let text;
                                if (samplesNotFound.length == 1) {
                                    text = 'Sorry, the following ID was not found in our inventory: ';
                                } else {
                                    text = 'Sorry, the following IDs were not found in our inventory: ';
                                }
                                $('#sampleSearchMessageDiv').append($('<span>', {
                                    html: text + samplesNotFound.join(', '),
                                    style: 'color:firebrick'
                                }));
                            }
                        },
                        createdRow: function(row, data, dataIndex) {
                            /*
                             * before the table is rendered on the html
                             * check to see if samples are selected in the biobank sample cart
                             * if so, the checkbox should be checked and row should be color-highlighted
                             * note: BiobankCart is a global variable...
                             */
                            if (sessionStorage[data.sampleId]) {
                                $(row).addClass('selected');
                                $(row).find('input:checkbox').prop('checked', true);
                            }
                        },
                        headerCallback: function(thead, data, start, end, display) {
                            // implement the logic of the check-all box:
                            $('#checkAllForBiobank').prop('checked', false); // first reset the check box
                            let counter = 0;
                            data.forEach(d => {
                                if (sessionStorage[d.sampleId]) counter++;
                            });
                            if (counter == data.length) {
                                // checkAll box should be checked if all rows are selected
                                $('#checkAllForBiobank').prop('checked', true);
                            }
                        }
                    });
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err + ' Unable to create table for samples');
            }
            oTable.addClass('display');
            oTable.show(); // in case it was hidden
            return oTable;
        },
        bindSearchButton() {
            const self = this;
            $('#sampleSearch').button().click(event => {
                $('#sampleSearchMessageDiv').empty();

                let urlSuffix = null;
                const sampleId = $('#sampleId').val().replace(/\s+/g, ''); // getting rid of all whitespace from sampleId search
                let sampleIds = [];
                if (sampleId) {
                    // by default, change ID search to tissueSample IDs -- this returns more samples
                    sampleIds = sampleId.split(','); // split the IDs on commas
                    const tissueSampleIds = sampleIds.map(id => {
                        return id.split('-SM-')[0];
                    });
                    urlSuffix = self.toUrlSuffix({ 'tissueSampleId': tissueSampleIds.join('&tissueSampleId='), 'sortBy': 'sampleId' });
                } else {
                    urlSuffix = self.urlSuffixFromSelects();
                    const pathCategories = $('#pathNotesCategories').val();
                    if (pathCategories) {
                        if (urlSuffix.includes('?')) {
                            urlSuffix += '&' + self.toUrlSuffix({
                                'pathCategory': pathCategories.join('&pathCategory='),
                                'sortBy': 'pathCategory'
                            }).substring(1);
                        } else {
                            urlSuffix += '?' + self.toUrlSuffix({
                                'pathCategory': pathCategories.join('&pathCategory='),
                                'sortBy': 'pathCategory'
                            }).substring(1);
                        }
                    }
                }


                const urlEntry = directory.getBiobankSampleUrl();
                let url = urlEntry.url;
                url += urlSuffix;
                self.generateSampleTable('#searchResultTable', url, sampleIds);
                BiobankSampleRequest.bindCartButtons();
                return false;
            });
        },
        initialize() {
            this.loadTissueList();
            this.initializeSearchForm();
            this.bindSearchButton();

            // initializing the sample search
            const url = directory.getBiobankSampleUrl().url;;
            const sampleIds = [];
            this.generateSampleTable('#searchResultTable', url, sampleIds);
            BiobankSampleRequest.bindCartButtons();
        }
    }
};
</script>

<style scoped>
.cart-btn-margin {
  margin-right: 5px;
}
</style>

<style>
/* this fixes an issue where the Path Categories placeholder text doesn't take up 100% of width */
.select2-search__field {
    width: 100% !important;
}
</style>
