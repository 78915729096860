<template>
  <div id="timeline" class="page-content center">
    <h3>Timeline</h3>
    <div>
      <p>
        The project was launched in late September 2021. In the initial phase of the project, the Laboratory Data
        Analysis and Coordination Center (LDACC) and the Biospecimen Procurement Center (BPC) have been working
        together to design sample and tissue collections, molecular assays, data sharing, and the Ethical, Legal, and
        Social Implications (ELSI) research. Donor recruitment began in August of 2023 and remains ongoing. Tissue
        characterization and data production are underway.
      </p>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            timelineSvg: require('@/assets/images/dgtex/timeline.svg')
        };
    }
};
</script>

<style scoped>
#timeline {
  padding: 2vh 0;
  text-align: left;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 50px;
}

.size {
  width: 100%;
  max-width: 500px;
}

#image {
  justify-self: center;
}

/*min-width: for when screen is bigger than 1000px (desktop)*/
@media (min-width: 1000px) {
  .grid {
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
  }

  .size {
    width: 100%;
  }
}

.cell {
  justify-content: center;
  align-items: center;
}
</style>
