<template>
  <div class="container-fluid">
    <h2>Contact GTEx</h2>
    <h5>
      Please review the <a href="https://gtexportal.org/home/methods"
                           target="_blank"
      >Lab and Analysis Methods page <i class="fas fa-external-link-alt" /></a>
      and the <a
        href="https://gtexportal.org/home/faq"
        target="_blank"
      >FAQs page <i class="fas fa-external-link-alt" /></a> for answers to your questions.
      <br>
      If those resources do not answer your questions or if you have any additional comments, contact the GTEx team using the form below.
      <br><br>
      If you are experiencing a problem with the GTEx Portal, please clear your browser cache, reload the page, and try again.
      <br>
      If the problem persists after clearing your cache, please include the following information:
      <ul>
        <li>the browser you are using</li>
        <li>the version of that browser</li>
        <li>any error message you are seeing</li>
        <li>detailed instructions for reproducing the problem</li>
      </ul>
    </h5>

    <div id="contactDiv" class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          Contact GTEx Portal Team
        </h4>
      </div>
      <div class="panel-body">
        <form id="contactForm" class="detailForm">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <div class="form-group">
                <label for="requestType" class="col-form-label">Request Type</label>

                <span>
                  <select id="requestType" name="requestType" class="form-control">
                    <option value="Question" selected>Question</option>
                    <option value="Bug">Bug</option>
                    <option value="Feature_Request">Feature Request</option>
                  </select>
                </span>
              </div>

              <div class="form-group">
                <label for="name" class="col-form-label">Name</label>
                <input id="name" type="text" class="form-control" placeholder="Enter Name" required>
              </div>
              <div class="form-group">
                <label for="email" class="col-form-label">Email</label>
                <input id="email" type="email" class="form-control" placeholder="Enter email" required>
              </div>
              <div class="form-group">
                <label for="subject" class="col-form-label">Subject</label>
                <input id="subject" type="text" class="form-control" placeholder="Enter subject" required>
              </div>
              <div class="form-group">
                <label for="message" class="col-form-label">Message</label>
                <textarea id="message" class="form-control" rows="10" placeholder="Enter message" required />
              </div>
              <div>
                <button id="send" type="button" class="btn btn-info">
                  Send
                </button>

                <button id="cancel" type="button" class="btn btn-info">
                  Clear
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .col-form-label {
    font-size: medium;
  }
  .ui-button-text-only {
    padding: 0;
  }
</style>
<style>
  /*
  Unscoped style because label.error is generated in runtime. 
  */
  #contactForm label.error {
    color: red;
    padding-left: 10px;
  }
</style>
<script>
import router from '@/router/router';
import { portalClient, directory } from '@/utils/portal';

export default {
    mounted: function() {
        this.initialize();
    },
    methods: {
        initialize() {
            this.bindButtons();
            $('#contactForm').validate({
                messages: {
                    requestType: { required: 'Please select a request type' },
                    name: { required: 'Please enter your name' },
                    email: { required: 'Please enter your email address' },
                    subject: { required: 'Please enter a subject' },
                    message: { required: 'Please enter your message' }
                },
                errorPlacement: function(error, element) {
                    error.insertBefore(element);
                    $('<br>').insertBefore(element);
                }
            });
        },
        bindButtons() {
            this.bindSendButton();
            this.bindCancelButton();
        },
        bindSendButton() {
            const parent = this;
            $('#send').button()
                .click(event => {
                    let valid = true;

                    $('#contactForm').each(function() {
                        if (!$(this).valid()) {
                            valid = false;
                        }
                    });

                    if (!valid) {
                        event.preventDefault();
                        return false;
                    }

                    parent.contact({
                        requestType: $('#requestType').val(),
                        name: $('#name').val(),
                        email: $('#email').val(),
                        subject: $('#subject').val(),
                        message: $('#message').val()
                    });

                    return false;
                });
        },
        contact(object) {
            $('#spinner').show();
            $.ajax({
                url: directory.getContactUrl().url,
                type: 'POST',
                contentType: 'application/json; charset=utf-8',
                data: JSON.stringify(object),
                traditional: 'true',
                success: function(data) {
                    $('#spinner').hide();
                    portalClient.messageHandler.showDialogMessage('Your message has been sent to the GTEx Portal team');
                    router.push({ name: 'homePage' });
                },
                error: function(jqXHR, textStatus, thrownError) {
                    $('#spinner').hide();
                    portalClient.messageHandler.showMessageFromJqXHR(jqXHR, 'Internal error, message not sent');
                }

            });
        },
        bindCancelButton() {
            $('#cancel').button().click(event => {
                $('#contactForm input').val('');
                $('#contactForm textarea').val('');
                return false;
            });
        }
    }
};
</script>
