/*
 * Copyright � 2012 The Broad Institute, Inc.
 * SOFTWARE COPYRIGHT NOTICE
 * This software and its documentation are the copyright of the
 * Broad Institute, Inc. All rights are reserved.
 *
 * This software is supplied without any warranty or guaranteed support
 * whatsoever. The Broad Institute is not responsible for its
 * use, misuse, or functionality.
 */

/**
 * Centralizes the display of messages to the user.
 *
 * Status messages messages are shown at the top of the content.
 * Error messages are shown in an alert.
 *
 * @author jnedzel
 */
import { portalClient } from '@/utils/portal';

export default function MessageHandler() {
    this.messageDiv = '#messageDiv';

    this.showDialogMessage = function(message, aTitle, callback) {
        if (aTitle) {
            $('#messageDialog').dialog('option', 'title', aTitle);
        }
        if (callback) {
            $('#messageDialog').dialog('option', 'close', callback);
        } else {
            $('#messageDialog').dialog('option', 'close', null);
        }

        $('#messageText').html(message);
        $('#messageDialog').dialog('open');
    };

    this.showMessage = function(message) {
        $(this.messageDiv).html(message);
        $(this.messageDiv).show();
        const parent = this;
        setTimeout(() => {
            $(parent.messageDiv).slideUp(400);
            $(parent.messageDiv).html('');
        }, 10000);
    };

    this.showMessageFromJqXHR = function(jqXHR, title) {
        let response;
        if (jqXHR.responseText && (jqXHR.responseText.indexOf('<html>') == -1)) {
            response = jqXHR.responseText;
        } else {
            response = jqXHR.statusText;
        }
        this.showDialogMessage(response, title);
    };

    /**
     * The title is optional and is put onto title of dialog.
     */
    this.showError = function(message, title, callback) {
        this.showDialogMessage(message, title, callback);
    };

    /**
     * Message is optional.  If it is not true, then only the jqXHR response will be displayed.
     */
    this.showErrorFromJqXHR = function(message, jqXHR, title) {
        let response;
        if (jqXHR.responseText && (jqXHR.responseText.indexOf('<html>') == -1)) {
            response = jqXHR.responseText;
        } else {
            response = jqXHR.statusText;
        }

        let fullMessage;
        if (message) {
            fullMessage = message + ': ' + response;
        } else {
            fullMessage = response;
        }

        this.showError(fullMessage, title);
    };

    this.showJqXHRInternalServerError = function(message, jqXHR) {
        this.showErrorFromJqXHR(message, jqXHR, 'Internal Server Error');
    };

    this.showInternalError = function(message) {
        this.showError(message, 'Internal Error');
    };

    this.showDefaultAjaxErrorMessage = function(jqXHR, exception) {
        if (jqXHR.status === 0) {
            portalClient.messageHandler.showError('Could not connect to the GTEx server.\n The server may be down or you may have been disconnected from the network.', 'Server unavailable');
        } else if (jqXHR.status == 404) {
            portalClient.messageHandler.showError('The data you requested was not found. [404]', 'Requested data not found');
        } else if (jqXHR.status == 500) {
            portalClient.messageHandler.showError('There was an error in the GTex server.\nPlease notify the administrators if this persists.', 'Error');
        } else if (exception === 'parsererror') {
            portalClient.messageHandler.showError('An error occurred reading the data.\nPlease notify the administrators if this persists.', 'Data format error');
        } else if (exception === 'timeout') {
            portalClient.messageHandler.showError('A request to the server timed out.\nThe server may be down or their may be a\nproblem connecting to the GTEx server. ', 'Server unavailable');
        } else if (exception === 'abort') {
            portalClient.messageHandler.showError('An ajax request was aborted.', 'Request aborted');
        } else {
            portalClient.messageHandler.showError('Uncaught Error.\n' + jqXHR.responseText);
        }
    };
}
