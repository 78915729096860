<template>
  <div class="container-fluid">
    <h2>Frequently Asked Questions</h2>

    <h4>About data and analysis</h4>
    <ul>
      <li v-for="item in group1" :key="item.id">
        <a :href="getHash(item)">{{ item.question }}</a>
      </li>
    </ul>
    <hr>
    <h4>About the GTEx Portal</h4>
    <ul>
      <li v-for="item in group2" :key="item.id">
        <a :href="getHash(item)">{{ item.question }}</a>
      </li>
    </ul>
    <hr>
    <!-- eslint-disable vue/no-v-html -->
    <div v-for="item in group1" :key="item.id">
      <h4 :id="item.id" style="color: #376487">
        {{ item.question }}
      </h4>
      <div>
        <span v-html="item.answer" />
      </div>
      <hr>
    </div>
    <div v-for="item in group2" :key="item.id">
      <h4 :id="item.id" style="color: #376487">
        {{ item.question }}
      </h4>
      <div>
        <span v-html="item.answer" />
      </div>
      <hr>
    </div>
    <!-- eslint-enable -->
  </div>
</template>
<script>
import tpmImage from '@/assets/images/tpm.equation.png';
export default {
    data: function() {
        const group1 = [
            {
                id: 'accessRawData',
                question: 'How can I access the RNA sequence data?',
                answer: '<p>For privacy reasons, NIH policy prevents us from releasing the raw sequence data via the GTEx \
                portal. You must request access via: \
                <a href="https://www.ncbi.nlm.nih.gov/projects/gap/cgi-bin/study.cgi?study_id=phs000424" target="_blank">dbGAP</a>.  \
                Protected data are available in the AnVIL repository.</p>'
            },
            {
                id: 'ageOfDonors',
                question: 'Where can I find the exact age of GTEx donors?',
                answer: '<p>Due to the nature of our donor consent, the exact age of GTEx donors is protected data. \
                you can find age in 10-year brackets in the public sample annotation file, available on the \
                <a href="https://gtexportal.org/home/downloads/adult-gtex/metadata">Open Access Data page</a>. \
                To access exact age, you must request access via \
                <a href="https://www.ncbi.nlm.nih.gov/projects/gap/cgi-bin/study.cgi?study_id=phs000424" target="_blank">dbGAP</a>. \
                Protected data are available in the AnVIL repository as described on the \
                <a href="https://gtexportal.org/home/protectedDataAccess"> Protected Access Data page.</p>'
            },
            {
                id: 'raceOfDonors',
                question: 'Where can I find the race or ethnicity of GTEx donors?',
                answer: '<p>Due to the nature of our donor consent, the race and ethnicity of GTEx donors is protected \
                data. To access exact race or ethnicity, you must request access via \
                <a href="https://www.ncbi.nlm.nih.gov/projects/gap/cgi-bin/study.cgi?study_id=phs000424" target="_blank">dbGAP</a>. \
                Protected data are available in the AnVIL repository as described on the \
                <a href="https://gtexportal.org/home/protectedDataAccess"> Protected Access Data page.</p>'
            },
            {
                id: 'obtainFastq',
                question: 'I have access to the GTEx protected data on dbGaP/AnVIL, but I need FASTQ files. How can I obtain these?',
                answer: '<p>Only BAM files are available in the AnVIL repository, but these files contain all unmapped reads ' +
                'and it is easy to generate FASTQs from them using Picard tools.  We provide a wrapper <a target="_blank" href="https://github.com/broadinstitute/gtex-pipeline/blob/master/rnaseq/src/run_SamToFastq.py">here</a>.</p>'
            },
            {
                id: 'rnaSeqProtocol',
                question: 'Was the GTEx RNA-seq protocol strand-specific?',
                answer: '<p>No.  RNA-seq was performed using the Illumina TruSeq library construction protocol. This is ' +
                'a non-strand specific polyA+ selected library.  For more details, please visit our ' +
                '<a href="https://gtexportal.org/home/documentationPage">Documentation page.</p>'
            },
            {
                id: 'donorInfo',
                question: 'Where can I find clinical information about the donors?',
                answer: '<p>The publicly available donor phenotypes and sample annotations can be downloaded from the ' +
                'GTEx Portal in the Annotation section of each release <a href="https://gtexportal.org/home/datasets">here</a></p>' +
                '<p>Due to the nature of our donor consent, the public phenotypes and annotations are limited.  If you ' +
                'need access to protected phenotypes and annotations, you can apply for access via dbGaP <a target="_blank" href="https://www.ncbi.nlm.nih.gov/projects/gap/cgi-bin/study.cgi?study_id=phs000424">here</a></p>'
            },
            {
                id: 'missingDonorGenotypes',
                question: 'Why are there samples in dbGaP for donors that do not have genotypes in the VCF file?',
                answer: '<p>The RNA-Seq and genotyping processes are run separately.  All donors will eventually be ' +
                'genotyped, but their genotypes may not have been produced and QC\'d in time for the current release.  ' +
                'Some donors have been excluded from the VCF due to relatedness or for biological or clinical reasons ' +
                '(see also <a href="#A8">here</a>).</p>'
            },
            {
                id: 'sampleExclusion',
                question: 'If a donor is found to have a pre-existing condition, are any targeted organs excluded from the specimen collection process?',
                answer: '<p>GTEx sample exclusion is done at the donor level. Donors were excluded from the analysis ' +
                'freeze if the donor was a "biological outlier", e.g. had a large chromosomal duplication or deletion ' +
                '-- duplication of a whole chromosome or chromosome arm or a large CNV (>1Mb) associated with a known ' +
                'syndrome based on literature search, such as OMIM -- or underwent transgender surgery. We have also ' +
                'removed all but one individual from related donor subsets (primarily pairs). To date, we have not ' +
                'excluded specific donors from specific tissues based on their cause of death or medical history.</p>'
            },

            {
                id: 'brainCortexAndCerebellum',
                question: 'What is the difference between the tissue sites Brain - Frontal Cortex and Brain - Cortex, ' +
                'Brain - Cerebellum and Brain - Cerebellar Hemisphere?',
                answer: '<p>The Brain - Frontal Cortex and Brain- cortex, and the Brain - Cerebellum and Brain - Cerebellar ' +
                'Hemisphere samples should be considered as sample duplicates.  One set of each pair (the Brain - Cortex ' +
                'and Brain - Cerebellum) were sampled at the same time as the remaining donor non-brain tissue samples, ' +
                'and were preserved in PAXgene tissue fixative solution.</p>' +
                '<p>The remaining whole brain was then shipped to the University of Miami Brain Endowment Bank, where 8-11 ' +
                'brain sub-regions were sampled.  The Brain - Frontal Cortex and Brain - Cerebellar Hemisphere were ' +
                're-sampled at this time, as close as possible to the original sampling sites. All brain sub-regions ' +
                'sampled at the Miami Brain bank were preserved by snap freezing. Hence the paired brain regions differ ' +
                'in the time of sampling (those re-sampled at the Brain Bank, have a longer ischemic time) and in the ' +
                'manner in which the sample was preserved.</p>'
            },
            {
                id: 'negativeIschemicTime',
                question: 'Why are some ischemic times less than zero?',
                answer: '<p>In the sample annotation file, the samples have a SMTSISCH value which indicates minutes of ischemia time.  ' +
                'Some of these values are less than zero.  Is this time calculated from the time the patient is pronounced dead or when the ' +
                'heart is no longer pumping or when the ventilator is stopped, or all of the above? </p>' +
                '<p>Sample-specific ischemic time is defined as the time from death or withdrawal of life-support until ' +
                'the time the sample is placed in a  fixative solution or frozen. So it\'s all of those scenarios, ' +
                'depending on the particular patient, although NOT from when the person is pronounced death, but rather ' +
                'the actual time of death (or as close to it as possible for rapid autopsy patients). The negative times ' +
                'should appear only for blood samples and represent samples that were collected pre-mortem. Those were ' +
                'all from organ donor ventilator cases where life support was about to be shut off, and the patient ' +
                'would have been perfused prior to organ harvest, so blood was taken prior to that happening in those cases.</p> '
            },
            {
                id: 'autoLysisScore',
                question: 'How was the autolysis score measured?',
                answer: '<p>The autolysis score was assigned by a pathologist during a visual inspection of the histology image.  ' +
                'The assigned values ranged from 0 to 3 (None, Mild, Moderate, and Severe).</p>'
            },
            {
                id: 'variantId2rsId',
                question: 'How can I map GTEx variant IDs to dbSNP rs IDs?',
                answer: '<p>A lookup table is available on the <a href="https://gtexportal.org/home/datasets">Datasets Page</a> \
                (for release V7, the file is GTEx_Analysis_2016-01-15_v7_WholeGenomeSeq_635Ind_PASS_AB02_GQ20_HETX_MISS15_PLINKQC.lookup_table.txt.gz).</p>'
            },
            {
                id: 'sampleIdFormat',
                question: 'What does the sample ID for an RNA-seq or genotype sample stand for, such as GTEX-14753-1626-SM-5NQ9L?',
                answer: '<p>The format of the sample ID is <b>GTEX-[donor ID]-[tissue site ID]-SM-[aliquot ID]</b>. </p> \
                <p>The donor ID (e.g. GTEX-14753) should be used to link between the various RNA-seq and genotype samples that come from the same donor. </p> \
                <p>The tissue site ID (e.g. 1626) mostly refers to the tissue site. BUT we do not recommend using it for \
                tissue site designation. Sometimes, sample mix-ups occur, and will be corrected, however this part of \
                the ID will not change when that happens. The accurate tissue site info for all samples can be \
                obtained from the "Tissue Site Detail field" (encoded as "SMTSD") in the Sample Attribute file, \
                available on the <a href="https://gtexportal.org/home/datasets"> Download </a> page. \
                <p>The aliquot ID (e.g. SM-5NQ9L) is the RNA or DNA aliquot ID used for sequencing. </p>'
            },
            {
                id: 'sampleAnnotation',
                question: 'Where can I find the sample annotations for GTEX-111CU-1826-SM-5GZYN? I searched in the biobank, but I could not find that sample identifier.',
                answer: '<p>To find the sample and subject annotations for samples used in an analysis release, please ' +
                'use the sample and subject annotation files from the <a href="/home/datasets">Download</a> page.</p>' +
                '<p>The GTEx biobank inventory contains information about samples that are currently in our freezers.  ' +
                'The sample aliquots that were used for genotyping and RNA-seq were used up during processing, so they ' +
                'will not appear in the biobank inventory.  The biobank inventory should contain related parent samples, ' +
                'and searching for the sample identifier GTEX-111CU-1826-SM-5GZYN should return those related samples ' +
                '(providing they have not been depleted).</p>'
            },
            {
                id: 'releaseDifference',
                question: 'Why are the results in release v7 different from those in release v6p?  My SNP of interest had a significant eQTL in v6P, but in v7 it no longer has a significant eQTL?',
                answer: '<p>There may be multiple reasons for the observed differences. V7 has a larger sample size than v6p. ' +
                'The eQTL calls in v7 are based on whole genome data and the RNA-Seq data have been reprocessed. eQTLs that ' +
                'lose significance from one release to the next were likely weakly significant. You can verify this in ' +
                'the *egenes.txt.gz files from v6p, by comparing pval_nominal to pval_nominal_threshold. Since the v7 ' +
                'data is better powered and of higher quality, we recommend using the v7 results.</p>'
            },
            {
                id: 'releaseInfo',
                question: 'Where can I learn more about the releases?',
                answer: 'You can learn more about the releases <a href="/home/releases">here</a>.'
            },
            {
                id: 'expressionAnalysisMethods',
                question: ' Why are isoform and gene expression estimated separately using two different tools (RNA-SeQC for gene expression and RSEM for isoform expression)?',
                answer: '<p>We found that eQTL discovery is better powered using the gene-level expression estimates generated with RNA-SeQC.  The RSEM estimates are based on combining isoform-level estimates, which adds uncertainty to the resulting gene-level values (the isoform-level estimates are highly inaccurate in some cases).</p>'
            },
            {
                id: 'effectSizeDirection',
                question: 'Why is the effect size direction of some eQTLs opposite to that reported elsewhere?',
                answer: '<p>The effect sizes of eQTLs are defined as the effect of the alternative allele (ALT) relative ' +
                'to the reference (REF) allele in the human genome reference.  In other words, the eQTL effect allele is ' +
                'the ALT allele, not the minor allele.</p>'
            },
            {
                id: 'interpretEffectSize',
                question: 'How should eQTL effect sizes be interpreted?',
                answer: '<p>The portal displays two values that are related to the eQTL effect sizes:</p>' +
                '<p><b>Allelic fold-change (aFC)</b>, a measure of cis-eQTL effect size, is defined as the log-ratio ' +
                'between the expression of the haplotype carrying the alternative eVariant allele to the one carrying ' +
                'the reference allele in log2 scale. Currently, due to computational limitation, only the aFC of the ' +
                'most significant variant of each eGene is available.</p>' +
                '<p><b>Normalized effect size (NES)</b>, previously known as the effect size on the portal, is defined ' +
                'as the slope of the linear regression, and is computed as the effect of the alternative allele (ALT) ' +
                'relative to the reference allele (REF) in the human genome reference (i.e., the eQTL effect ' +
                'allele is the ALT allele). NES are computed in a normalized space where magnitude has no direct biological ' +
                'interpretation.</p>' +
                '<p><b>eVariant</b>: a significant variant associated with an eGene.</p>'
            },
            {
                id: 'tpm',
                question: 'What are TPM units? How were these calculated?',
                answer: '<p>Gene and transcript expression on the GTEx Portal are shown in Transcripts Per Million (TPM), calculated as</p>' +
                '<p><img width="200" src="' + tpmImage + '"/></p>' +
                '<p>where n<sub>t</sub> is the number of reads for transcript/gene t, l<sub>t</sub> is the normalized ' +
                'transcript/gene length, and T is the set of all transcripts or genes depending on whether the ' +
                'quantification is at the gene level (e.g. by collapsing transcripts). For additional information, ' +
                'see <a href="https://academic.oup.com/bioinformatics/article/26/4/493/243395" target="_blank">' +
                'https://academic.oup.com/bioinformatics/article/26/4/493/243395</a> </p>'
            },
            {
                id: 'whyTpm',
                question: 'Why does GTEx use TPM units rather than RPKM?',
                answer: '<p>We no longer provide expression quantifications in RPKM. TPM is a better unit for ' +
                        'comparison of RNA-seq samples.  You can convert RPKM as follows (for each sample/column): ' +
                        'TPM = RPKM / sum(RPKM) * 1e6.  For additional information, please see: ' +
                        '<a href="https://academic.oup.com/bioinformatics/article/26/4/493/243395" target="_blank">' +
                        'https://academic.oup.com/bioinformatics/article/26/4/493/243395</a></p>'
            },
            {
                id: 'geneExpressionByTissue',
                question: 'How can I obtain gene expression data for my tissue of interest?',
                answer: '<p>Derived gene expression data can be downloaded from our ' +
                        '<a href="https://gtexportal.org/home/datasets" target="_blank">Download Page</a>.' +
                        'This data is available at the gene, transcript, and exon levels.  ' +
                        'We have released tissue-specific gene expression files in read count and TPM units.' +
                        'We also have combined files that contain data from all tissues.  The expression ' +
                        'matrices contain one row per gene, exon, or transcript, and one column per sample.  The ' +
                        'column header contains the sample identifier.  The sample annotation file, ' +
                        'GTEx_Analysis_v8_Annotations_SampleAttributesDS.txt, contains the tissue site detail ' +
                        '(in the SMTSD column) for every sample.'
            },
            {
                id: 'geneModel',
                question: 'Which GENCODE 26 GTF file was used for creating the GTEx V8 gene model?',
                answer: '<p>The GTEx V8 gene model was creating using the GENCODE \'CHR\' file: ' +
                        '<a href="ftp://ftp.ebi.ac.uk/pub/databases/gencode/Gencode_human/release_26/gencode.v26.annotation.gtf.gz" target="_blank">' +
                        'ftp://ftp.ebi.ac.uk/pub/databases/gencode/Gencode_human/release_26/gencode.v26.annotation.gtf.gz</a></p>' +
                        '<p>This was used to create the GTEx collapsed gene model available here: ' +
                        '<a href="https://storage.googleapis.com/gtex_analysis_v8/reference/gencode.v26.GRCh38.genes.gtf" target="_blank">' +
                        'gencode.v26.GRCh38.genes.gtf</a></p>' +
                        '<p>The script used to create the collapsed gene model is available here: ' +
                        '<a href="https://github.com/broadinstitute/gtex-pipeline/tree/master/gene_model" target="_blank">' +
                        'https://github.com/broadinstitute/gtex-pipeline/tree/master/gene_model</a></p>'
            },
            {
                id: 'splicingPhenotypeId',
                question: 'How are splicing phenotype IDs created?  The sQTL phenotype identifiers appear to be ' +
                          'in the format: chr:bp_first:bp_end:clu_XXXX:ensg_geneID.  Does the "clu_XXXX" id map ' +
                          'to specific types of splicing events?',
                answer: '<p>The splicing phenotype IDs are constructed by LeafCutter, and indicate the intron ' +
                        '(chr:start:end) and cluster of connected components (clu_) the intron belongs to. ' +
                        'Please see the LeafCutter paper <a href="https://www.nature.com/articles/s41588-017-0004-9" target="_blank">' +
                        'https://www.nature.com/articles/s41588-017-0004-9</a> and documentation for additional details.</p>'
            },
            {
                id: 'mitochondrial',
                question: 'How can I find genotype data which has the mitochondrial germline dbSNPs?',
                answer: '<p>GTEx has not released an official set of mitochondrial genotypes, since there remain ' +
                        'challenges in calling these due to heteroplasmy of mitochondrial genomes. The whole genome ' +
                        'sequence alignments ' +
                        '(available on <a href="https://anvil.terra.bio/" target="_blank">AnVIL</a>) did include ' +
                        'the mitochondrial reference, however, and can be used to produce such calls</p>.'
            },
            {
                id: 'diseased',
                question: 'My understanding is that the tissues collected for GTEx are generally non-diseased and ' +
                          'free of pathology. However the pathology notes in the sample annotations contain many ' +
                          'words that suggest a quite diseased tissue, for example "congestion", "emphysema", ' +
                          '"pneumonia", etc. How can these tissues be considered "non-diseased"?',
                answer: '<p>The GTEx tissues are to be considered a population control that is as "normal" as can ' +
                        'be relative to the age of the individual.  Much is described in: '+
                        '<a href="https://doi.org/10.1089/bio.2015.0032" target="_blank">doi: 10.1089/bio.2015.0032</a>' +
                        '</p>' +
                        '<p>All GTEx individuals are deceased organ donors, hence all individuals had to die from ' +
                        'some cause or other. Most older individuals had underlying disease ' +
                        'conditions at the time of their death. The GTEx study had certain conditions that were ' +
                        'considered exclusions outright for being enrolled in the study. If individuals met ' +
                        'inclusion criteria for enrollment on the study, however, each tissue collected from them ' +
                        'was reviewed by a panel of 2-3 pathologists. Any tissue with an incidental finding (e.g. ' +
                        'cancer) was "excluded" from the GTEx "normal" database (although we could produce data ' +
                        'from it at the end of the study), while other tissues that were mildly diseased but ' +
                        'considered "normal" for the age of the individual (e.g. benign prostate hyperplasia is ' +
                        '"normal" for most males above the age of 30), were considered acceptable to be included ' +
                        'in the study. Many older individuals had mild evidence of disease in some tissues, but ' +
                        'other tissues obtained from them were quite healthy.</p>'
            },
            {
                id: 'downloadFromSra',
                question: 'Can I download GTEx V8 protected data from SRA?',
                answer: '<p>Protected data for GTEx V8 and future releases are only available in '+
                        '<a href="https://anvil.terra.bio/" target="_blank">AnVIL</a>.  Due to the large size of ' +
                        'these data, we recommend against downloading the data, as that will incur significant ' +
                        'egress charges. Instead, we recommend that you consider performing your computations in ' +
                        'the AnVIL/Terra environment.  That will incur compute charges, but those might be ' +
                        'significantly less than the egress charges (depending upon your computation).</p>' +
                        '<p>Access to protected data is described ' +
                        '<a href="https://gtexportal.org/home/protectedDataAccess">here</a></p>'
            },
            {
                id: 'rnaSeqProtocol2',
                question: 'What protocol was used to generate the GTEx cDNA for RNA sequencing?',
                answer: '<p>The protocol is described on Page 11 of these supplementary materials: ' +
                        '<a href="https://www.biorxiv.org/content/10.1101/787903v1.supplementary-material" target="_blank">https://www.biorxiv.org/content/10.1101/787903v1.supplementary-material</a></p>'
            },
            {
                id: 'compareExpression',
                question: 'I\'m trying to determine if a gene is higher expressed in the brain vs. other ' +
                          'tissues. In the documentation, it states that the TPM values have not been normalized in ' +
                          'any way. Therefore, I was wondering if it was appropriate to directly compare the ' +
                          'provided TPM values of a gene between different tissues.',
                answer: '<p>It is not possible to directly compare these values across tissues. Unfortunately, no ' +
                        'computational method can be applied for proper between-sample normalization across the ' +
                        'diverse set of tissues represented in GTEx. For further details, please see this paper: ' +
                        '<a href="https://doi.org/10.1016/j.cell.2012.10.012" target="_blank">https://doi.org/10.1016/j.cell.2012.10.012</a>.</p> ' +
                        '<p>The gene expression visualizations therefore provide a qualitative measure of relative ' +
                        'expression.</p>'
            },
            {
                id: 'normalization',
                question: 'Is there a detailed description of how the normalized expression data is generated?',
                answer: '<p>The normalization steps are described on the ' +
                        '<a href="https://gtexportal.org/home/documentationPage#staticTextAnalysisMethods">Documentation Page</a> ' +
                        'and in the Supplementary Materials, Section 3.4.2 of ' +
                        '<a href="https://www.science.org/doi/10.1126/science.aaz1776" target="_blank">https://www.science.org/doi/10.1126/science.aaz1776a</a>. ' +
                        'The code for this is available on ' +
                        '<a href="https://github.com/broadinstitute/gtex-pipeline/blob/master/qtl/src/eqtl_prepare_expression.py" target="_blank">GitHub</a></p>'
            },
            {
                id: 'histology',
                question: 'Why are some samples (e.g., GTEX-11GSO-0011-R3b) missing from the Histology Image Viewer?',
                answer: '<p>Histology images are only available for tissues that were PAXgene preserved. ' +
                        'For brain, there are two tissues types (Brain - Cortex and Brain - Cerebellum) that were ' +
                        'PAXgene preserved.  The remaining tissue types were preserved frozen and we do not have  ' +
                        'histology images for those samples. </p> ' +
                        '<p>The sample in question, GTEX-11GSO-0011-R3b, is from ' +
                        'Brain - Anterior cingulate cortex (BA24), which is one of the frozen tissues for which ' +
                        'we do not have histology images.</p>'
            },
            {
                id: 'downloadHistology',
                question: 'How can I download GTEx histology images?',
                answer: '<p>The GTEx API histology web service only provides metadata about histology images. ' +
                        'The images themselves are not available for download via the API.  The images can be ' +
                        'downloaded from the AnVIL_GTEx_public_data workspace in AnVIL here: ' +
                        '<a href="https://app.terra.bio/" target="_blank">https://app.terra.bio</a></p>' +
                        '<p>Note that the AnVIL_GTEx_public_data workspace is stored in a requester-pays Google Cloud Storage (GCS) ' +
                        'bucket.  Consequently, you will incur egress charges from Google when you download ' +
                        'these images.  The image dataset is about 8T, so downloading the entire dataset will ' +
                        'take a significant amount of time.</p>' +
                        'If you are going to download the entire dataset, we recommend that you use Google\'s ' +
                        'gcloud command line utility.  Specifically, we suggest that you use \'gcloud storage rsync\' ' +
                        'to perform the download.  You can find Google egress pricing here: ' +
                        '<a href="https://cloud.google.com/storage/pricing" target="_blank">https://cloud.google.com/storage/pricing</a></p>'
            },
            {
                id: 'unmapped-reads',
                question: 'Do the RNA-seq BAM files available on AnVIL contain all the sequenced reads, including the unmapped ones?',
                answer: '<p>Yes.</p>'
            },
            {
                id: 'trimmed',
                question: 'Were the RNA-seq reads trimmed prior to alignment?',
                answer: '<p>No, trimming is not necessary with the STAR aligner.  See: ' +
                    '<a target="_blank" href=https://github.com/alexdobin/STAR/issues/455>' +
                    'https://github.com/alexdobin/STAR/issues/455</a> for additional details.</p>'
            },
            {
                id: 'irb',
                question: 'Is an IRB required to analyze raw sequence data from GTEx?',
                answer: '<p>An IRB is not required to analyze raw sequence data from GTEx.  Please review ' +
                        'the Consenting Donors section in the Materials and Methods section of this paper: ' +
                        '<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4675181" target="_blank"> ' +
                        'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4675181</a>.</p>'
            },
            {
                id: 'nes_beta',
                question: 'What is the difference between normalized effect size (NES), beta coefficient, and slope?',
                answer: '<p>On the GTEx project we use the term Normalized Effect Size (NES), which is the same as ' +
                        'the beta coefficient (that is, the regression slope).  You can find the NES in our Open ' +
                        'Access Data eQTL file ' +
                        '<a href="https://storage.googleapis.com/gtex_analysis_v8/single_tissue_qtl_data/GTEx_Analysis_v8_eQTL.tar" target="_blank"> ' +
                        'GTEx_Analysis_v8_eQTL.tar</a> in the column labeled "slope", ' +
                        'as described in the associated README file ' +
                        '<a href="https://storage.googleapis.com/gtex_analysis_v8/single_tissue_qtl_data/README_eQTL_v8.txt" target="_blank"> ' +
                        'README_eQTL_v8.txt</a>.</p>'
            },
            {
                id: 'wes',
                question: 'What exome capture array version was used for each WES sample?',
                answer: '<p>The sample annotation file here: ' +
                        '<a href="https://storage.googleapis.com/gtex_analysis_v8/annotations/GTEx_Analysis_v8_Annotations_SampleAttributesDS.txt" target="_blank"> ' +
                        'GTEx_Analysis_v8_Annotations_SampleAttributesDS.txt</a> contains our internal product names ' +
                        'for our WES protocols in the column labeled SMGEBTCHT. We used four different WES protocls ' +
                        'and these map to WES kits as follows: </p>' +
                        '<ul>' +
                        '<li>SMGEBTCHT = "Exome Express (Agilent SureSelect)" --> Agilent Sure-Select Human All Exon v2.0, 44Mb baited target</li>' +
                        '<li>SMGEBTCHT = "Standard Exome Sequencing v2 (ICE)" --> Agilent Sure-Select Human All Exon v2.0, 44Mb baited target</li>' +
                        '<li>SMGEBTCHT = "Standard Exome Sequencing v3 (ICE)" --> Illumina Rapid Capture Exome enrichment kit with 38Mb target territory (29Mb baited) WES v8.1</li>' +
                        '<li>SMGEBTCHT = "Standard Exome Sequencing v5 (ICE)" --> Illumina Rapid Capture Enrichment 37Mb WES v8.2</li>' +
                         '</ul>'
            }, {
                id: 'fibroblast',
                question: 'From what location/organ were the cells - cultured fibroblasts derived?',
                answer: '<p>The fibroblasts were cultured from a skin sample taken from the exact same location as ' +
                'the sun-exposed skin (left or right leg 2 cm below patella on medial side).</p>'
            }, {
                id: 'gen3',
                question: 'Can I download the GTEx V8 protected data from Gen3?',
                answer: '<p>AnVIL has decommissioned the ability to download GTEx V8 protected data from Gen3. ' +
                'Please use the AnVIL Terra environment to access GTEx protected data.  For more information, please see ' +
                '<a href="https://anvilproject.org/news/2024/09/16/sunsetting-gen3-in-anvil" ' +
                    'target="_blank">https://anvilproject.org/news/2024/09/16/sunsetting-gen3-in-anvil</a></p>'
            }, {
                id: 'sampleSwap',
                question: 'Tissue type swaps in the V10 release',
                answer: '<p>The V10 release represents the final release to include new RNA sequencing data. As ' +
                    'part of our QC, some samples from V8 have been removed and replaced in V10 with re-sequenced ' +
                    'samples from the same tissue (where quality was improved). A number of tissue type swaps were ' +
                    'also identified. For any such swaps that were unambiguous, we have retained those samples and ' +
                    'corrected their tissue type in the V10 sample annotation file.  A file containing the list of ' +
                    'samples for which the tissue site, and tissue site detail, was changed from v8 ' +
                    'to v10 will shortly be available on the Open Access Download page.</p>'
           }, {
                id: 'caudateNucleus',
                question: 'Can you please clarify which part of the caudate nucleus was sampled from GTEx donor brains?',
                answer: 'The body region of the caudate nucleus was the region always sampled.'
          }
        ];
        const group2 = [
            {
                id: 'citePortal',
                question: 'How to cite or acknowledge the GTEx project?',
                answer: '<p><b>An example acknowledgment statement follows:</b>' +
                    'The Genotype-Tissue Expression (GTEx) Project was supported by the <a target="_blank" href="https://commonfund.nih.gov/GTEx">Common Fund</a>  of the Office of ' +
                    'the Director of the National Institutes of Health, and by NCI, NHGRI, NHLBI, NIDA, NIMH, and NINDS. ' +
                    'The data used for the analyses described in this manuscript were obtained from: ' +
                    '[insert, where appropriate] the GTEx Portal on MM/DD/YY and/or dbGaP  accession number ' +
                    '<a href="https://www.ncbi.nlm.nih.gov/projects/gap/cgi-bin/study.cgi?study_id=phs000424" ' +
                    'target="_blank">phs000424.vN.pN</a>  on MM/DD/YYYY.</p>'
            },
            {
                id: 'tissuesOfeQTL',
                question: 'Why are there different number of tissues available on the Single-tissue eQTL page?',
                answer: '<p>The <a href="https://gtexportal.org/home/eqtls/tissue?tissueName=Adipose_Subcutaneous">"Single-Tissue eQTLs"</a> section allows you to look up cis eQTLs which have ' +
                'been precomputed in a +/- 1Mb cis window around the transcription start site (TSS). Significance was determined ' +
                'using a Q-value threshold. At least 70 samples per tissue are necessary to achieve the statistical power needed ' +
                'for this type of analysis.</p>' +
                '<p>In contrast, the <a href="https://gtexportal.org/home/testyourown">"GTEx eQTL Calculator"</a> and <a href="https://gtexportal.org/home/eqtlDashboardPage">GTEx eQTL Dashboard</a> allow you to compute, on demand, an association ' +
                'between a SNP and gene of your choice. The association may be cis or trans. This calculation may be performed in ' +
                'tissues for which we have more than 10 samples. No Q-value filtering is performed and the user is left to interpret ' +
                'the significance of the p-value.</p>'
            },
            {
                id: 'refAllele',
                question: 'What does Ref Allele mean on the eQTL plot?',
                answer: '<p>REF stands for reference allele in the human genome reference. ALT stands for ' +
                'alleles that are alternate relative to this reference. The variant IDs are in the format ' +
                'chromosome_position_ref_alt_build. A file mapping these variant IDs to rsIDs for all variants used ' +
                'in GTEx eQTL analyses can be downloaded from the <a href="https://gtexportal.org/home/datasets"> ' +
                'Datasets page</a></p>'
            },
            {
                id: 'browserSupport',
                question: 'What browsers does the GTEx portal support?',
                answer: '<p>The GTEx Portal is tested on the latest versions of Chrome and Firefox, and Safari version 7+. ' +
                'Please note that the GTEx Portal will not work properly with Internet Explorer.</p>'

            },
            {
                id: 'portalNotWorking',
                question: 'Why is the GTEx portal not working for me after the latest update?',
                answer: '<p>While we have taken steps to reduce the chance of this happening, sometimes browsers cache ' +
                'important portal files and do not recognized that they have changed. If you are having problems with the ' +
                'GTEx Portal, please try clearing your cache first. If that doesn\'t solve the problem, then please contact us.</p>'
            },
            {
                id: 'usePortalFigures',
                question: 'Can I use one of the figures in the GTEx Portal in my paper?',
                answer: '<p>Yes, you are free to use figures in the GTEx Portal in your publications.  Most of the figures' +
                ' on the GTEx Portal now have a Download button above and to the left of the figure.  This will download ' +
                'the figure in .svg format, which is a vector-based format.</p>' +
                '<p>Please acknowledge the GTEx Project and/or Portal. An example acknowledgement statement follows:<br/>' +
                'The Genotype-Tissue Expression (GTEx) Project was supported by the <a href="https://commonfund.nih.gov/GTEx" target="_blank">Common Fund</a> of the Office of the ' +
                'Director of the National Institutes of Health, and by NCI, NHGRI, NHLBI, NIDA, NIMH, and NINDS. The data ' +
                'used for the analyses described in this manuscript were obtained from: [insert, where appropriate] ' +
                'the GTEx Portal on MM/DD/YY and/or dbGaP accession number ' +
                '<a href="https://www.ncbi.nlm.nih.gov/projects/gap/cgi-bin/study.cgi?study_id=phs000424" target="_blank">' +
                'phs000424.vN.pN </a>on MM/DD/YYYY.</p>'
            },
            {
                id: 'requesterPays',
                question: 'Why do I see the error message ' +
                          '"Bucket is requester pays bucket but no user project provided" while trying to download a file?',
                answer: '<p>We have placed some large, seldom used files in requester pays buckets.  In order to ' +
                         'download these files you will need to create a paid Google Cloud Platform account.  ' +
                         'Google will charge you egress fees for downloading these files.  You can avoid egress ' +
                         'fees by computing on these files in the Google Cloud Platform in the US-Central1 region.</p>' +
                         '<p>Unfortunately, there is a bug in the Google Cloud Console which prevents you from ' +
                         'downloading files in requester-pays buckets using the Google Cloud Console.  Instead, ' +
                         'you will need to use ' +
                         'Google\'s command-line tool, gsutil.  You can find instructions for installing gsutil '+
                         '<a href="https://cloud.google.com/storage/docs/gsutil_install" target="_blank">here.</a></p>' +
                         '<p>Once you have installed gsutil, you can download a file as follows: </br></br>' +
                         '<p><pre style="margin-left: 25px;">gsutil -u PROJECT_IDENTIFIER cp gs://BUCKET_NAME/OBJECT_NAME OBJECT_DESTINATION</pre></br>' +
                         'where: <ul style="margin-left: 25px;">' +
                             '<li>PROJECT_IDENTIFIER is the ID of your Google Cloud Platform project.</li>' +
                             '<li>BUCKET_NAME is the name of the GTEx Google Cloud Storage bucket.</li>' +
                             '<li>OBJECT_NAME is the name of the GTEx file that you want to download.</li>' +
                             '<li>OBJECT_DESTINATION is the location where you want to save the file.</li>' +
                         '</ul><p></p>' +
                         '<p>For example, the following command will copy the Artery_Aorta.allpairs.txt.gz file to ' +
                         'your current directory, provided that you replace PROJECT_IDENTIFIER with the ' +
                         'project identifier for your Google project. </p>' +
                         '<p><pre style="margin-left: 25px;">gsutil -u PROJECT_IDENTIFIER cp gs://gtex-resources/GTEx_Analysis_v8_QTLs/GTEx_Analysis_v8_eQTL_all_associations/Artery_Aorta.allpairs.txt.gz .' +
                         '</pre></p>' +
                         '<p>You can find complete documentation for accessing requester pays buckets ' +
                         '<a href="https://cloud.google.com/storage/docs/using-requester-pays#gsutil" target="_blank">here</a>. ' +
                         'You can find detailed information on Google egress pricing ' +
                         '<a href="https://cloud.google.com/storage/pricing" target="_blank">here.</a></p>' +
                     '</p>'

            }
        ];

        return {
            group1: group1,
            group2: group2
        };
    },
    methods: {
        getHash: function(item) {
            return `#${item.id}`;
        }
    }
};
</script>
