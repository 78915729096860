/*
 * Copyright � 2017 The Broad Institute, Inc.
 * SOFTWARE COPYRIGHT NOTICE
 * This software and its documentation are the copyright of the
 * Broad Institute, Inc. All rights are reserved.
 *
 * This software is supplied without any warranty or guaranteed support
 * whatsoever. The Broad Institute is not responsible for its
 * use, misuse, or functionality.
 */

/**
 * This modules handles the biobank sample requests.
 * @author Katherine Huang
 */
import router from '@/router/router';
import { directory } from '@/utils/portal';
import BiobankSampleSearch from '@/components/biobank_tabs/BiobankSampleSearch.vue';

// this modules handles biobank sample request actions
if (typeof(Storage) !== 'undefined') {
    console.info('Biobank Cart will be stored in sessionStorage.');
} else {
    console.error('Biobank Cart Error: Your browser doesn\'t support web storage.');
}

const BiobankSampleRequest = (function() {
    // Methods related to managing samples in the cart
    function addSampleToCart(id, value) {
        sessionStorage[id] = value; // sessionStorage stores Strings
    }

    function deleteSampleFromCart(id) {
        if (sessionStorage[id] !== undefined) {
            delete sessionStorage[id];
        }
    }

    function getSampleIds() {
        return Object.keys(sessionStorage).filter(id => {
            return sessionStorage[id] !== undefined && id.startsWith('GTEX');
        });
    }

    function getCartSize() {
        const ids = getSampleIds();
        return ids.length;
    }

    function getSessionStorageData(id) {
        return sessionStorage[id];
    }

    // UI methods
    function checkAll(checkbox, selector) { // selector is a table ID in this format #ID
        const samplesInView = $(selector).children('tbody').find(':input'); // find all the samples in view
        if (checkbox.checked) {
            samplesInView.each(function() {
                this.checked = true;
                checkboxOnClick(this);
            });
        } else {
            samplesInView.each(function() {
                this.checked = false;
                checkboxOnClick(this);
            });
        }
    }

    function checkboxOnClick(checkbox) {
    // the click event of the checkboxes when user selects/deselects one from the biobank sample data table
        const $checkboxJ = $('#' + checkbox.id); // note: $checkboxJ is a jQuery object
        if (checkbox.checked) {
            $checkboxJ.parent().parent().addClass('selected'); // highlight the row
            addSampleToCart(checkbox.id, checkbox.value);
        } else {
            $checkboxJ.parent().parent().removeClass('selected'); // remove highlight row color
            $checkboxJ.prop('checked', false); // when clear button is clicked, and it unchecks the checkbox
            deleteSampleFromCart(checkbox.id);
            $('#checkAllForBiobank').prop('checked', false);
        }
        updateCartButton();
    }

    function showSampleCart() {
        updateSampleCart();
    }

    function updateSampleCart() {
    /*
     * This function manages the sample cart page
     * Fetch Biobank data of the samples in the Cart
     * And display in a data table again
     * the checkboxes in data table allow users to deselect samples in the cart
     */
        $('#biobankCartTableDiv').html('<table id="biobankCartTable"></table>'); // clear the cart sample data table
        if (getCartSize() > 0) {
            const bss = BiobankSampleSearch.methods;
            const url = directory.getBiobankSampleUrl().url + bss.toUrlSuffix({ sampleId: getSampleIds().join(',') });
            bss.generateSampleTable('#biobankCartTable', url, getSampleIds());
        }
    }

    function bindNavCartIcon(cartId) {
        $(cartId).click(() => {
            router.push({ name: 'biobankRequestPage' });
        });
    }

    function bindCartButtons() {
    /*
     * TODO: avoid hard-coded DOM IDs
     * defines click events for the navigation bar and the related buttons on the sample search page
     */
        bindNavCartIcon();
        $('#biobankCartButton').click(() => { router.push({ name: 'biobankRequestPage' }); });
        $('#refreshCartButton').click(() => { updateSampleCart(); });

        // the clear button on both pages
        $('#clearCartButton').click(() => {
            const ids = getSampleIds(); // clear the samples in the BiobankCart
            // clear the data table checkboxes and row highlights
            ids.forEach(id => {
                $('#' + id).parent().parent().removeClass('selected');
                $('#' + id).prop('checked', false); // un-checks the checkbox
                deleteSampleFromCart(id);
            });
            // update the buttons -- number of samples in the cart
            updateCartButton();
            updateSampleCart();
            $('#detailedRequestSection').hide();
            $('#requestFormSubTitle').hide();

            return false;
        });

        /*
         * display the buttons if hidden.
         * cart-related buttons are, by default, hidden when the cart is empty and the Biobank sample search is not in use
         * show the buttons when a sample search is initiated
         */
        if ($('#clearCartButton').css('display') == 'none')$('#clearCartButton').show();
        if ($('#biobankCartButton').css('display') == 'none') $('#biobankCartButton').show();
        updateCartButton();
    }

    function updateCartButton() {
        const count = getCartSize();
        // update the sample counts in the cart
        $('#biobankCartButton .badge').html(count);
        $('#biobankCartCount').html(count);
    }

    return {
        checkAll: checkAll,
        checkboxOnClick: checkboxOnClick,
        bindCartButtons: bindCartButtons,
        bindNavCartIcon: bindNavCartIcon,
        getCartSize: getCartSize,
        showSampleCart: showSampleCart,
        updateCartButton: updateCartButton
    };
})();

export default BiobankSampleRequest;
window.gtex = window.gtex ? window.gtex : {};
// We need to attach this to global namespace due to javascript links used in BiobankSamplesPage
window.gtex.BiobankSampleRequest = BiobankSampleRequest;
