<template>
  <div class="container-fluid">
    <div class="col-sm-10">
      <h2>
        GTEx Portal Data License
      </h2>
      <p>
        The GTEx Portal data is made available under the following license:
      </p>
      <h4>
        INTRODUCTION
      </h4>
      <p>
        Downloading data from the GTEx Portal servers indicates your acceptance of the following Terms and Conditions: 
        No charges, usage fees or royalties are paid to the GTEx Portal for this data.
      </p>
      <h4>GENERAL TERMS AND CONDITIONS</h4>
      <ul>
        <li>Users of the data agree to:</li>
        <ul>
          <li>acknowledge the GTEx Portal as the source of the data by including the phrase "The data used for the 
            analyses described in this manuscript were obtained from: [insert, where appropriate] the GTEx Portal on 
            MM/DD/YY and/or dbGaP accession number 
            <a href="https://www.ncbi.nlm.nih.gov/projects/gap/cgi-bin/study.cgi?study_id=phs000424" target="_blank">
              phs000424.vN.pN
            <i class="fas fa-external-link-alt" /></a>  
            on MM/DD/YYYY." in a clear and conspicuous manner,
          </li>
          <li>
            not indicate or imply that the GTEx Portal has endorsed its products/services/applications. 
          </li>
        </ul>
        <li>Users who republish or redistribute the data (services, products or raw data) agree to:</li>
        <ul>
          <li>
            maintain the most current version of all distributed data, or
          </li>
          <li>
            make known in a clear and conspicuous manner that the products/services/applications do not reflect the 
            most current/accurate data available from the GTEx Portal.
          </li>
        </ul>
        <li>
          These data are produced with a reasonable standard of care, but the GTEx Project makes no warranties express 
          or implied, including no warranty of merchantability or fitness for particular purpose, regarding the 
          accuracy or completeness of the data. Users agree to hold the GTEx Project and the Broad Institute and the 
          U.S. Government harmless from any liability resulting from errors in the data. The GTEx Project disclaims 
          any liability for any consequences due to use, misuse, or interpretation of information contained or 
          not contained in the data.
        </li>
        <li>
          The GTEx Project does not provide legal advice regarding copyright, fair use, or other aspects of 
          intellectual property rights. 
        </li>
        <li>
          The GTEx Project reserves the right to change the type and format of its machine-readable data. The GTEx 
          Project will take reasonable steps to inform users of any changes to the format of the data via the GTEx 
          Portal.
        </li>
      </ul>
    </div>
  </div>
</template>
