import DataPanelGroup from "./DataPanelGroup";
import downloadsPageUtils from "./downloads-page-utils";
export default class DataPanel {
    constructor(panel, parentName) {
        this.dataTab = parentName;
        this.name = panel.name;
        this.displayName = panel.displayName;
        this.description = panel.description;
        this.order = panel.order;
        const groups = panel.children.folders;
        this.id = panel.displayName.replaceAll(" ", "_").toLowerCase();
        //If there are files at this level, put them in a folder 
        if (panel.children.files.length > 0){
            const noNameGroup = {};
            noNameGroup["name"] = this.id + "_group";
            noNameGroup["displayName"] = "";
            noNameGroup["description"] = ""; 
            noNameGroup["order"] = 0;
            noNameGroup["children"] = {"files": panel.children.files, "folders": []};
            groups.push(noNameGroup);
        }
        this.dataPanelGroups = groups.map(group => {
            return new DataPanelGroup(group, this.id)
        })
        this.dataPanelGroups.sort(downloadsPageUtils.compareOrder)
    }
};
