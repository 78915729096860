import DataPanel from './DataPanel';
import downloadsPageUtils from "./downloads-page-utils";

export default class DataType {
    constructor(dataTypeObject, parentName) {
        this.dataset = parentName; // Dataset this data belongs to
        this.displayName = dataTypeObject.displayName;
        this.id = dataTypeObject.displayName.replaceAll(' ', '_').toLowerCase();
        this.name = dataTypeObject.name;
        this.description = dataTypeObject.description;
        this.order = dataTypeObject.order;

        const panels = dataTypeObject.children.folders;
        //If there are files at this level, put them in a folder
        if (dataTypeObject.children.files.length > 0) {
            const noNamePanel = {};
            noNamePanel['name'] = this.id + '_panel';
            noNamePanel['displayName'] = '';
            noNamePanel['description'] = '';
            noNamePanel['order'] = 0;
            noNamePanel['children'] = { 'files': dataTypeObject.children.files, 'folders': [] };
            panels.push(noNamePanel);
        }
        this.dataPanels = panels.map(panel => {
            return new DataPanel(panel, this.id);
        });
        this.dataPanels.sort(downloadsPageUtils.compareOrder);
    }
};
