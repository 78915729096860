<template>
  <div class="gtex-panel-data-grp">
    <div class="gtex-p-data-grp-header">
      <span class="gtex-p-data-grp-title" title="Copy URL to clipboard" @click="copyTableUrl(tableId)" >
          {{ dataPanelGroup.displayName }}
        <i v-if="dataPanelGroup.displayName !== ''" class="far fa-copy" title="Copy URL to clipboard" />
      </span>
      <p v-html="dataPanelGroup.description" />
    </div>
    <table :id="tableId" class="stripe hover" />

    <data-panel-subgroup-component v-for="subgroup in dataPanelGroup.subGroups"
                                   :key="subgroup.displayName"
                                   :parent-id="tableId"
                                   :fileset="subgroup"
                                   :tabs-container-id="tabsContainerId" :tab-id="tabId"
    />
  </div>
</template>

<script>
import TableUtils from '@/utils/table-utils';
import MessageHandler from '@/utils/message-handler';
import DataPanelSubgroupComponent from './DataPanelSubgroupComponent';

export default {
    name: 'DataPanelGroupComponent', 
    components: { DataPanelSubgroupComponent },
    props: {
        dataPanel: {
            type: Object,
            required: true
        },
        dataPanelGroup: {
            type: Object,
            required: true
        },
        fileset: {
            type: Array,
            required: true
        },
        tabsContainerId: {
            type: String,
            required: true
        },
        tabId: {
            type: String,
            required: true
        }
    },
    data() {
        const dataPanelId = this.dataPanelGroup.dataPanel;
        const dataTabId = this.dataPanel.dataTab;
        const dataPanelGroupId = this.dataPanelGroup.id;
        return {
            tableId: `${dataTabId}-${dataPanelId}-${dataPanelGroupId}`
        };
    },
  
      created() {
      this.$watch(
      () =>this.$route.params.tab, 
      (newParam, oldParam) => {
        if (newParam !== oldParam) {
          $(`#${this.tableId}`).DataTable().columns.adjust();
        }
      }
    )
  },
    mounted() {
        this.generateFilesTable();

        const tabsContainerId = this.tabsContainerId;
        const tabId = this.tabId;
        const tableId = this.tableId;

        // this fixes an issue where the datatable header is misasligned from the table because "scrollX" was enabled
        $(`#${tabsContainerId} a[data-toggle="tab"][aria-controls="${tabId}"]`).on('shown.bs.tab', () => {
            $(`#${tableId}`).DataTable().columns.adjust();
        });
    },
    methods: {
        generateFilesTable() {
           console.log("Generate Data Panel Group components")
            const emptyMessage = 'No files were found';

            // we are using an older version of data tables that requires us to generate the table header HTML
            let objectHeadings = [];

            objectHeadings = ['Order', 'Name', 'Description', 'Size', ''];

            const oTable = $(`#${this.tableId}`);
            const tableElt = oTable[0];
            TableUtils.buildTableHtml(tableElt, objectHeadings);
            const buttonProps = {
                'buttons': []
            };
            let columnProps = [];

            columnProps = [
                { data: 'order', 'visible': false },
                { data: 'displayName' },
                { data: 'description', width: '60%' },
                { data: 'fileSize', width: 60, className: 'dt-body-right' },
                {
                    defaultContent: '',
                    sortable: false,
                    data: 'url',
                    width: 15,
                    render: function(data) {
                        const fileUrl = data;
                        return TableUtils.generateDownloadButton(fileUrl);
                    }
                }
            ];

            $.fn.dataTableExt.sErrMode = 'throw';
            try {
                oTable.dataTable({
                    deferRender: true,
                    destroy: true,
                    retrieve: true,
                    processing: true,
                    paging: false,
                    data: this.fileset,
                    columns: columnProps,
                    dom: 't',
                    orderClasses: false,
                    buttons: buttonProps,
                    scrollX: true,
                    language: { sEmptyTable: 'Please wait - Fetching records' },
                    fnInitComplete: function(oSettings) {
                        oSettings.oLanguage.sEmptyTable = emptyMessage;
                        $(oTable).find('.dataTables_empty').html(emptyMessage);
                    }
                });
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err +
                    'unable to create table for files');
                oTable.addClass('display');
                oTable.show();
                oTable.fnSort([[0, 'asc']]);
                return oTable;
            }
        },
      /**
       * Copies the URL to the specified table when clicking the title.
       */
      copyTableUrl(tableId) {
        const url = window.location.href.indexOf('#') > -1 ? window.location.href.substring(0, window.location.href.indexOf('#')) : window.location.href ;
        if (navigator.clipboard)
          navigator.clipboard.writeText(`${url}#${tableId}`);
      }
    }
};
</script>

<style scoped>
.gtex-p-data-grp-header {
    display: flex;
    flex-direction: column;
}

.gtex-p-data-grp-title {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    line-height: 2;
}
</style>
