/*
 * Copyright 2015 The Broad Institute, Inc.
 * SOFTWARE COPYRIGHT NOTICE
 * This software and its documentation are the copyright of the
 * Broad Institute, Inc. All rights are reserved.
 *
 * This software is supplied without any warranty or guaranteed support
 * whatsoever. The Broad Institute is not responsible for its
 * use, misuse, or functionality.
 */

/**
 * Represents a downloadable file.
 * @author jnedzel
 */
export default function File(rootUrl, json) {
    this.name = null;
    this.description = null;
    this.size = null;
    this.order = null;
    this.url = null;

    this.initialize = function(rootUrl, json) {
        this.name = json.name;
        this.description = json.description;
        this.size = json.size;
        this.order = json.order;
        this.url = rootUrl + '/' + this.name;
    };

    this.initialize(rootUrl, json);
}
