<template>
  <HomePage/>
</template>

<script>
import HomePage from './dgtex/Page.vue';

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>
