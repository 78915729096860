<template>
  <div class="container-fluid">
    <div class="row" style="min-height: 800px;">
      <div class="col-xs-12 col-sm-2" role="complementary" style="margin-top: 40px;">
        <nav class="gtx-docs-sidebar hidden-print hidden-xs hidden-sm hidden-md affix">
          <ul id="sidebar" class="nav">
            <li>
              <a href="#visTutorials">Training Videos</a>
              <ul class="nav">
                <li v-for="(video, vId) in videos" :key="vId">
                  <a :href="'#'+vId">{{ video.navText }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-xs-12 col-sm-10">
        <section id="visTutorials">
          <h2>GTEx Training Videos</h2>
          <div v-for="(video, vId) in videos" :id="vId" :key="vId">
            <h4>{{ video.title }}</h4>
            <iframe width="560" height="315" :src="video.link" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen />
            <br><br>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data: function() {
        return {
            // key is used as the id for the video to link between the navbar items and div items
            //
            // NOTE: in order for the video embedding to work, you MUST use the embed form of the link
            //  to YouTube.  To get the correct link, on YouTube select "Share" -> "Embed".  Then remove
            //  the link from the iframe HTML and paste below.
            //
            //  If you see an embedding error in the browser, that indicates that you did not use the correct
            //  embed version of the URL.
            videos: {
                'gtex-intro': {
                    navText: 'GTEx Intro',
                    title: 'Introduction to the GTEx Project',
                    link: 'https://www.youtube.com/embed/PhK186A7Ryo'
                },
                'gtex-download': {
                    navText: 'GTEx Download',
                    title: 'Downloading GTEx Data from the Portal',
                    link: 'https://www.youtube.com/embed/XcwwBzYEOUs?si=Rfj7Qj-7J_TaaDd9'
                },
                'gene-expr': {
                    navText: 'Gene Expr. Tutorial',
                    title: 'Visualizing Bulk RNA-Seq Gene Expression on the GTEx Portal',
                    link: 'https://www.youtube.com/embed/WjHkb_y_yFk'
                },
                'gene-snuc': {
                    navText: 'Single Cell Tutorial',
                    title: 'Accessing Single Cell Gene Expression Data on the GTEx Portal',
                    link: 'https://www.youtube.com/embed/VTgMgJk1Cjw'
                },
                'gtex-eui': {
                    navText: 'GTEx EUI',
                    title: 'Using the Exploration User Interface on the GTEx Portal',
                    link: 'https://www.youtube.com/embed/CWCGORb2zZ4'
                },
                'gtex-api': {
                    navText: 'GTEx API',
                    title: 'Retrieving GTEx Gene Expression Data via the API',
                    link: 'https://www.youtube.com/embed/NFyO0Pi0QJo?si=qMbV4GOkJGruRp0h'
                },
            }
        };
    }
};
</script>
