/*
 * Copyright 2015 The Broad Institute, Inc.
 * SOFTWARE COPYRIGHT NOTICE
 * This software and its documentation are the copyright of the
 * Broad Institute, Inc. All rights are reserved.
 *
 * This software is supplied without any warranty or guaranteed support
 * whatsoever. The Broad Institute is not responsible for its
 * use, misuse, or functionality.
 */

/**
 * Represents a fileset -- a collection of files available for download.
 *
 * @author jnedzel
 */
import File from '@/utils/models/file.js';

export default function Fileset(rootUrl, json) {
    this.name = null;
    this.description = null;
    this.subpath = null;
    this.order = null;
    this.files = [];
    this.filesets = [];

    this.initialize = function(rootUrl, json) {
        this.name = json.name;
        this.description = json.description;
        this.subpath = rootUrl + '/' + json.subpath;
        this.order = json.order;
        const filesJson = json.files;
        this.parseFiles(this.subpath, filesJson);
        this.parseFilesets(this.subpath, json.filesets);
    };

    this.parseFiles = function(subpath, filesJson) {
        const numFiles = filesJson.length;
        for (let i = 0; i < numFiles; i++) {
            const fileJson = filesJson[i];
            const file = new File(subpath, fileJson);
            this.files.push(file);
        }
    };

    this.parseFilesets = function(subpath, filesetsJson) {
        if (!filesetsJson) {
            return;
        }
        const numFilesets = filesetsJson.length;
        for (let i = 0; i < numFilesets; i++) {
            const filesetJson = filesetsJson[i];
            const fileset = new Fileset(subpath, filesetJson);
            this.filesets.push(fileset);
        }
    };

    this.initialize(rootUrl, json);
}
