<template>
  <div class="container-fluid">
    <!-- tissue selector -->
    <div class="row" style="padding: 10px 0px 10px 15px">
      Select a tissue:
      <select id="pca-tissue-selector" />
    </div>
    <br>
    <!-- PCA Plots Container -->
    <div id="pca-plots" />
  </div>
</template>
<script>
import { directory } from '@/utils/portal';
import { tissueLookupTable } from '@/utils/tissue';

export default {
    data: function() {
        const queryDatasetV10 = directory.getV10Dataset();
        return {
            containerDiv: 'pca-plots',
            urls: {
                pcaUrl: directory.getExpressionPcaUrl().url + '?' + queryDatasetV10,
                sampleUrl: directory.getSampleUrl().url + '?' + queryDatasetV10
            },
            tissueId: undefined,
            tissueName: undefined,
            pcaPlot: undefined
        };
    },
    mounted: function() {
        this.init();
    },
    methods: {
        init() {
            this.tissueName = 'Adipose - Subcutaneous';
            this.tissueId = tissueLookupTable[this.tissueName].tissueSiteDetailId;
            this.createTissueSelectorMenu();
            $('#pcaQueryTissue').text(this.tissueName);
            this.bindTissueSelector();

            this.pcaPlot = new PcaPlot(this.containerDiv, this.urls.pcaUrl, this.urls.sampleUrl, this.tissueId);
            this.pcaPlot.launch();
        },
        createTissueSelectorMenu() {
            const menu = $('#pca-tissue-selector');
            const tissues = [];

            for (const tissueName in tissueLookupTable) {
                tissues.push({
                    id: tissueLookupTable[tissueName].tissueSiteDetailId,
                    text: tissueName
                });
            }

            menu.select2({
                placeholder: 'Select a tissue',
                data: tissues
            });
        },
        bindTissueSelector() {
            const component = this;
            const menuId = 'pca-tissue-selector';
            $('#' + menuId).on('change.select2', e => {
                const selectedTissue = $(`#${menuId}`).select2('data')[0];
                component.tissueId = selectedTissue.id;
                component.tissueName = selectedTissue.text;
                const currCategory = component.pcaPlot.getCurrCategory();
                component.pcaPlot.destroy();
                component.pcaPlot = new PcaPlot(component.containerDiv,
                    component.urls.pcaUrl,
                    component.urls.sampleUrl,
                    component.tissueId);
                component.pcaPlot.launch(currCategory);
            });
        }
    }
};
</script>
