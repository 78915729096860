<template>
  <div id="tissue-page-container" class="container-fluid">
    <div id="tissue-page-header">
      <div id="tissue-page-header-content">
          <div>
            <select id="tissueSelect" name="tissueSelect" />
            <br>
            <small id="eVersion" />
          </div>
      </div>
    </div>
    <div id="tissue-page-body">
      <div class="row">
        <div id="bodyBox" class="col-sm-3 card">
          <div class="em-text">
            UBERON <span id="uberon"><!--Uberon ID--></span><br><br>
          </div>
          <div>Main Sampling Site</div>
          <span id="sampling-site"><!--sampling site--></span>
          <div id="body-map-svg">
            <v-body-map />
          </div>
          <div>
            <span><router-link to="/histologyPage">Histology Viewer</router-link> | <a
              href="#topExpressedVisualization"
            >Expression</a> | <router-link
              to="/browseEqtls?location=chr1%3A1-500,000"
            >
              IGV</router-link> | <router-link to="/anatomogramPage">
              Anatomagram</router-link><nobr v-show="isHubmap"> | </nobr><router-link v-show="isHubmap" to="/eui">Tissue Sites Mapped to HuBMAP Human Reference Atlas</router-link></span><br>
            <span id="hubmap-link" style="display:none">Information about this GTEx tissue is available to view at <a
              href="https://portal.hubmapconsortium.org/ccf-eui" target="_blank"
            >HuBMAP </a></span>
          </div>
        </div>
        <div class="card col-sm-4">
          <span class="card-title">Bulk Tissue RNA-Seq Sample Info</span>
          <div class="row">
            <br><br>
            <div class="col-sm-12">
              <h5>Table: Sample count in {{ tissueDisplayName }}</h5>
              <table width="90%">
                <tbody>
                <tr>
                  <th>Samples</th>
                  <th>All</th>
                  <th>Female</th>
                  <th>Male</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <td id="rna-seq-count">
                    <!--RNA-Seq sample count-->
                  </td>
                  <td id="rna-seq-female-count">
                    <!--RNA-Seq female sample count-->
                  </td>
                  <td id="rna-seq-male-count">
                    <!--RNA-seq male sample count-->
                  </td>
                </tr>
                <tr>
                  <th>With Genotype</th>
                  <td id="rna-seq-w-genotype-count">
                    <!--RNA-Seq sample count-->
                  </td>
                  <td id="rna-seq-w-genotype-count-female">
                    <!--RNA-Seq female sample count-->
                  </td>
                  <td id="rna-seq-w-genotype-count-male">
                    <!--RNA-seq male sample count-->
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row">
            <br><br>
            <div class="col-sm-12">
              <h5>Chart: GTEx samples by tissue</h5>
              <small>QTL analysis results are available for tissues with >70 samples with genotype data</small>
              <figure id="tissue-sample-chart">
                <!--sample bar chart-->
              </figure>
            </div>
          </div>
        </div>
<!--        <div class="card col-sm-4">-->
<!--          Donor Info-->
<!--          <figure id="donors-by-age">-->
<!--            &lt;!&ndash;donors by age&ndash;&gt;-->
<!--          </figure>-->
<!--          <figure id="tissue-donors-by-age">-->
<!--            &lt;!&ndash;donors of the query tissue by age&ndash;&gt;-->
<!--          </figure>-->
<!--        </div>-->
      </div>
      <div class="row">
        <div id="topExpressedVisualization" class="card">
          Gene Expression
          <v-top-expressed-heat-map :tissue-display-name="tissueDisplayName" :tissue-site-detail-id="tissueName" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { getAllTissuesGroupedByMinN, getTissueDisplayName, tissueMetadataJson, mappedInHubmap } from '@/utils/tissue';
import { directory, generateGeneUrl, portalClient } from '@/utils/portal';
import BodyMap from '@/components/BodyMap.vue';
import TopExpressedGenesPage from '@/components/TopExpressedGenesPage.vue';
import TableUtils from '@/utils/table-utils';
import MessageHandler from '@/utils/message-handler';
import EqtlTableMaker from '@/utils/eqtl-table-maker';
import details_open_path from '@/assets/images/details_open.png';
import details_close_path from '@/assets/images/details_close.png';
import router from '@/router/router';
import { RetrieveOnePage } from '../utils/pagination';

$(document).ready(() => {
    $(window).bind('scroll', () => {
        if ($(window).scrollTop() > 150) {
            $('#tissue-page-header').addClass('scrollFix');
        } else {
            $('#tissue-page-header').removeClass('scrollFix');
        }
    });
});

export default {
    components: {
        'v-body-map': BodyMap,
        'v-top-expressed-heat-map': TopExpressedGenesPage
    },
    data() {
        return {
            tissueName: this.$route.params.tissueName,
            tissueDisplayName: getTissueDisplayName(this.tissueName),
            tissueUrl: null,
            metaData: null,
            heatmap: null,
            isHubmap: null,
            tissueDropdownArr: [],
            tissueSelectorId: 'tissueSelect',
            allTissueNames: getAllTissuesGroupedByMinN(),
            tissueDatasetSelector: null
        };
    },
    watch: {
        // Watches the `tissueName` param within the router
        '$route.params.tissueName'(newParams) {
            this.setTissueDropdownArr();
            this.updateTissue(newParams);
            this.loadTissueData();
        }
    },
    mounted() {
        // gets the data source version
        const versionInfo = portalClient.versionInfo.getMarkup('singleTissue');
        $('#eVersion').html(versionInfo);

        this.setTissueDropdownArr();
        this.populateTissueDropdown();

        // if the `tissueName` param in the router is undefined, then set the default tissue to display.
        const currTissueId = this.$route.params.tissueName === undefined ? 'Adipose_Subcutaneous' : this.$route.params.tissueName;

        // sets the current tissue based on the route
        this.updateTissue(currTissueId);
        this.loadTissueData();
    },
    methods: {
        /**
       * Creates an array with `id`, `text`, and `selected` keys tailed to dropdown.
         * and assigns it to `tissueDropdownArr` variable
       */
        setTissueDropdownArr() {
            this.tissueDropdownArr = this.allTissueNames.map((d, i) => {
                return {
                    id: i,
                    text: d.replaceAll('_', ' '),
                    selected: d.id === this.tissueName
                };
            });
        },
        /**
       * Fills the tissue dropdown with values
       */
        populateTissueDropdown() {
        // const tissueLabelNames = [];
        // const addSpace = (t) => tissueLabelNames.push(t.split('_').join(' '));
        // this.allTissueNames.forEach(addSpace);

            this.tissueDatasetSelector = $(`#${this.tissueSelectorId}`).select2({
                data: this.tissueDropdownArr,
                width: '300px',
                clearable: false,
                minimumResultsForSearch: -1,
            });
        },

        /**
       * Updates the current tissue on the page based on the tissueName selected
       * @param tissueId
       */
        updateTissue(tissueId) {
            this.tissueName = tissueId;
            this.tissueDisplayName = getTissueDisplayName(tissueId);
            const tissueIndex = this.allTissueNames.indexOf(tissueId);
            this.tissueDatasetSelector.val(tissueIndex);
            this.tissueDatasetSelector.trigger('change');

            // if the value within the tissue dropdown changes
            this.tissueDatasetSelector.on('change.select2', e => {
                const tissueIndex = parseInt(this.tissueDatasetSelector.select2('data')[0].id);
                const tissue = this.allTissueNames[tissueIndex];
                this.tissueName = tissue.toString();

                router.push({
                    params: {
                        name: 'tissuePage',
                        tissueName: this.tissueName
                    }
                });
            });
        },

        /**
       * Loads data based from the current `tissueName` selected from dropdown
       * into various visualizations
       */
        loadTissueData() {
            const urlMaker = new EqtlTableMaker();
            this.tissueUrl = urlMaker.generateTissueUrl(this.tissueName);
            this.metaData = tissueMetadataJson[this.tissueName];
            $('#qTissue').text(this.tissueDisplayName);
            $('#uberon').text(this.metaData.ontologyId);
            $('#sampling-site').text(this.metaData.samplingSite);
            $('.query-tissue').text(this.tissueDisplayName);

            // check HubMap availability
            if (tissueMetadataJson[this.tissueName].mappedInHubmap) {
                $('#hubmap-link').show();
            }

            // Body map
            this.customizeBodyMap();

            // RNA-seq
            $('#rna-seq-count').text(this.metaData.rnaSeqSampleSummary.totalCount);
            $('#rna-seq-female-count').text(this.metaData.rnaSeqSampleSummary.female.count);
            $('#rna-seq-male-count').text(this.metaData.rnaSeqSampleSummary.male.count);
            $('#rna-seq-w-genotype-count-female').text(this.metaData.eqtlSampleSummary.female.count);
            $('#rna-seq-w-genotype-count-male').text(this.metaData.eqtlSampleSummary.male.count);
            $('#rna-seq-w-genotype-count').text(this.metaData.eqtlSampleSummary.totalCount);
            this.plotSampleChart();
            // this.getDonorAgeData(this.plotDonorAgeChart);

            // Expression
            $('#expressed-genes').text(this.metaData.expressedGeneCount);
            this.getTopExpressed();

            // Association
            // $('#egenes').text(this.metaData.eGeneCount)
            // $('#sgenes').text(this.metaData.sGeneCount)
            // this.getEGenes();
            // this.getSGenes();
            this.isHubmap = tissueMetadataJson[this.tissueName].mappedInHubmap;
        },

        customizeBodyMap() {
            d3.select('#Labels').selectAll('g').style('opacity', 0.05);
            d3.select('#Hovers').selectAll('g').style('display', 'none');
            d3.select(`#${this.tissueName}_label`).style('opacity', 1);
            d3.select(`#${this.tissueName}_hover`).style('display', 'block');
        },

        plotSampleChart() {
            const tissues = Object.values(tissueMetadataJson);
            $('#tissue-sample-chart').highcharts({
                chart: {
                    type: 'column',
                    height: 350
                    // marginLeft: 130 // so that the x-axis labels don't get cut off
                    //marginRight: 30
                },
                credits: { enabled: false },
                plotOptions: {
                    column: {
                        stacking: 'normal'
                    }
                },
                title: { text: '' },
                dataLabels: {
                    enabled: true
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    formatter: function() {
                        return '<b>' + this.point.display + '</b><br/>' +
                  'with genotype : ' + this.point.rnaSeqAndGenotypeSampleCount + '<br/>' +
                  'without genotype: ' + (this.point.rnaSeqSampleCount - this.point.rnaSeqAndGenotypeSampleCount) + '<br/>' +
                  'Total: ' + this.point.rnaSeqSampleCount;
                    }
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        rotation: 60,
                        style: {
                            fontSize: '6px',
                            whiteSpace: 'nowrap',
                            textOverflow: 'none'
                        },
                        step: 1
                        // padding: 0
                    }
                },
                yAxis: {
                    title: { text: 'Number of Samples' },
                    opposite: true,
                    plotLines: [{
                        color: '#FF0000',
                        width: 1,
                        value: 70,
                        zIndex: 5,
                        dashStyle: 'dash',
                        label: {
                            text: 'n>=70',
                            align: 'left',
                            x: 1,
                            style: {
                                color: '#FF0000'
                            }
                        }
                    }]
                },
                series: [
                    {
                        name: 'Number RNAseq samples',
                        data: tissues.map(t => {
                            return {
                                name: t.tissueSiteDetailAbbr,
                                y: t.rnaSeqSampleSummary.totalCount - t.eqtlSampleSummary.totalCount,
                                color: t.tissueSiteDetailId == this.tissueName ? `#${t.colorHex}` : '#dddddd',
                                display: t.tissueSiteDetail,
                                rnaSeqSampleCount: t.rnaSeqSampleSummary.totalCount,
                                rnaSeqAndGenotypeSampleCount: t.eqtlSampleSummary.totalCount
                            };
                        }),
                        cursor: 'pointer',
                        stack: 'stack1'
                    },
                    {
                        name: 'Number RNAseq samples with genotype',
                        data: tissues.map(t => {
                            return {
                                name: t.tissueSiteDetailAbbr,
                                y: t.eqtlSampleSummary.totalCount,
                                color: t.tissueSiteDetailId == this.tissueName ? `#${t.colorHex}` : '#aaaaaa',
                                display: t.tissueSiteDetail,
                                rnaSeqSampleCount: t.rnaSeqSampleSummary.totalCount,
                                rnaSeqAndGenotypeSampleCount: t.eqtlSampleSummary.totalCount
                            };
                        }),
                        cursor: 'pointer',
                        stack: 'stack1'
                    }

                ]
            });
        },

        // getDonorAgeData(callback) {
        // // get data from json and draw
        //     const file = '/data/tmpSummaryData/v8/v8_age_by_tissue.json';
        //     d3.json(file, data => {
        //         callback(data);
        //     });
        // },

        // plotDonorAgeChart(data) {
        //     const tissues = Object.values(tissueMetadataJson);
        //     Highcharts.chart('donors-by-age', {
        //
        //         chart: {
        //             type: 'area',
        //             marginBottom: 30,
        //             height: $('#bodyBox').height() - 220
        //         },
        //
        //         title: {
        //             text: 'Donors by age'
        //         },
        //
        //         xAxis: {
        //             maxPadding: 0,
        //             type: 'category',
        //             crosshair: true,
        //             categories: [
        //                 '',
        //                 '20-29',
        //                 '30-39',
        //                 '40-49',
        //                 '50-59',
        //                 '60-70'
        //             ],
        //             labels: {
        //                 align: 'center',
        //                 reserveSpace: false,
        //                 rotation: 0
        //             },
        //             lineWidth: 0,
        //             margin: 20,
        //             tickWidth: 0
        //         },
        //
        //
        //         yAxis: {
        //             min: 0,
        //             title: {
        //                 text: ''
        //             }
        //         },
        //
        //         legend: {
        //             enabled: false
        //         },
        //
        //         credits: {
        //             enabled: false
        //         },
        //
        //         plotOptions: {
        //             area: {
        //                 stacking: 'normal',
        //                 marker: {
        //                     lineWidth: 0
        //                 }
        //             },
        //             series: {
        //                 marker: {
        //                     enabled: false
        //                 },
        //                 line: {
        //                     lineWidth: 0.2
        //                 }
        //             }
        //         },
        //
        //         series: data.map(d => {
        //             const info = tissues.filter(t => {
        //                 return t.tissueSiteDetail == d.anno;
        //             });
        //             const color = `#` + info[0].colorHex;
        //
        //             return {
        //                 name: d.anno,
        //                 data: [
        //                     '',
        //                     '20-29',
        //                     '30-39',
        //                     '40-49',
        //                     '50-59',
        //                     '60-70'
        //                 ].map(a => {
        //                     if (a != '') return d.AGE[a];
        //                     return 0;
        //                 }),
        //                 color: color,
        //                 opacity: d.anno === this.tissueDisplayName ? 1 : 0.1
        //             };
        //         }),
        //
        //         exporting: {
        //             enabled: false,
        //             sourceWidth: 800,
        //             sourceHeight: 600
        //         }
        //
        //     });
        //
        //
        //     Highcharts.chart('tissue-donors-by-age', {
        //         chart: {
        //             type: 'column',
        //             height: 200
        //         },
        //         title: {
        //             text: ''
        //         },
        //         subtitle: {
        //             text: ''
        //         },
        //         xAxis: {
        //             categories: [
        //                 '',
        //                 '20-29',
        //                 '30-39',
        //                 '40-49',
        //                 '50-59',
        //                 '60-70'
        //             ],
        //             crosshair: true
        //         },
        //         yAxis: {
        //             min: 0,
        //             title: {
        //                 text: ''
        //             }
        //         },
        //         tooltip: {
        //             headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        //             pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        //         '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        //             footerFormat: '</table>',
        //             shared: true,
        //             useHTML: true
        //         },
        //         plotOptions: {
        //             column: {
        //                 pointPadding: 0.2,
        //                 borderWidth: 0
        //             }
        //         },
        //         series: data.filter(d => {
        //             return d.anno == this.tissueDisplayName;
        //         }).map(d => {
        //             const info = tissues.filter(t => {
        //                 return t.tissueSiteDetail === d.anno;
        //             });
        //             const color = Highcharts.color(`#` + info[0].colorHex).get();
        //
        //             return {
        //                 name: d.anno,
        //                 data: [
        //                     '',
        //                     '20-29',
        //                     '30-39',
        //                     '40-49',
        //                     '50-59',
        //                     '60-70'
        //                 ].map(a => {
        //                     if (a != '') return d.AGE[a];
        //                     return 0;
        //                 }),
        //                 color: color
        //             };
        //         }),
        //         exporting: {
        //             enabled: false
        //         },
        //         credits: {
        //             enabled: false
        //         }
        //     });
        // },

        getTopExpressed(num = 50) {
        // all genes
            const unfilteredUrl = directory.getTopExpressedGeneUrlV10().url + `&sortBy=median&sortDirection=desc&tissueSiteDetailId=${this.tissueName}`;
            // exclude mitochondrial genes
            const filteredUrl = directory.getTopExpressedGeneUrlV10().url + `&sortBy=median&sortDirection=desc&filterMtGene=true&tissueSiteDetailId=${this.tissueName}`;

            $('#spinner').show();
            const promises = [
                RetrieveOnePage(unfilteredUrl, num, 0),
                RetrieveOnePage(filteredUrl, num, 0)
            ];
            Promise.all(promises).then(args => {
                const unfilteredData = args[0];
                const filteredData = args[1];
                this.createTopExpressedTable(unfilteredData, filteredData);
                $('#spinner').hide();
            });
        },

        createTopExpressedTable(unfilteredJson, filteredJson, tableId = 'topExpressedGenes') {
            const genes = filteredJson;
            const unit = genes[0]['unit'];

            // data table
            const headings = ['Gencode Id', 'Gene Symbol', `Median ${unit}`];

            const columnProps = [
                { data: 'gencodeId' },
                {
                    defaultContent: '',
                    'render': function(data, type, row) {
                        return generateGeneUrl(row.gencodeId, row.geneSymbol);
                    }
                },
                {
                    defaultContent: '',
                    'render': function(data, type, row) {
                        return row.median.toFixed(1);
                    }
                }
            ];
            const indices = headings.map((d, i) => {
                return i;
            }); // this is for mColumns, omit the 3rd column

            const buttonProps = {
                'buttons': [{
                    extend: 'copy',
                    exportOptions: { columns: indices }
                },
                {
                    extend: 'csv',
                    exportOptions: { columns: indices }
                },
                {
                    name: 'allGene',
                    text: 'Include Mitochondrial Genes',
                    action: function(e, dt, node, config) {
                        dt.clear();
                        dt.rows.add(unfilteredJson);
                        dt.draw();
                        dt.button('mtGeneFilter:name').enable();
                        this.disable();
                    },
                    enabled: true
                },
                {
                    name: 'mtGeneFilter',
                    text: 'Exclude Mitochondrial Genes',
                    action: function(e, dt, node, config) {
                        dt.clear();
                        dt.rows.add(filteredJson);
                        dt.draw();
                        dt.button('allGene:name').enable();
                        this.disable();
                    },
                    enabled: false
                }]
            };

            const customParams = {
                data: genes,
                order: [[2, 'desc']],
                columns: columnProps,
                buttons: buttonProps,
                pageLength: 25
            };

            const oTable = this.buildDataTable(tableId, headings, customParams);
            return oTable;
        },

        getEGenes(tableId = 'eGeneTable', dataSrc = 'egene') {
            const egeneUrl = `${directory.getEgeneUrl().url}?pageSize=30000&tissueSiteDetailId=${this.tissueName}`;
            // define headings
            const afcInfo = '<div class="gtooltip fas fa-info-circle data-toggle="tooltip" data-html="true" data-placement="auto" data-container="body" ' +
            'title="Allelic Fold-Change (in log2 scale)"' + '>' + '</div>';
            const headings = ['', 'Gencode Id', 'Gene Symbol', 'Nominal P-Value', 'Empirical P-Value', 'Q-Value', afcInfo + ' aFC', 'Actions'];

            const sortColumnIndex = headings.indexOf('Q-Value');

            const columns = [
                {
                    name: 'icon', orderable: false, searchable: false, defaultContent: '',                  // open-close icon
                    'render': function() {
                        const html = '<img class="expandCollapse" src="' + details_open_path + '" width="10px">';
                        return html;
                    }
                },
                { name: 'gencodeId', data: 'gencodeId' },
                {
                    name: 'geneSymbol',
                    defaultContent: '',
                    'render': function(data, type, row) {
                        return generateGeneUrl(row.gencodeId, row.geneSymbol);
                    }
                },
                {
                    name: 'pValue',
                    'render': function(data, type, row) {
                        return Number(row.pValue).toPrecision(3);
                    }
                }, // Nominal P-value
                {
                    name: 'empiricalPValue',
                    render: function(data, type, row) {
                        return Number(row.empiricalPValue).toPrecision(3);
                    }
                },
                {
                    name: 'qValue',
                    render: function(data, type, row) {
                        return Number(Number(row.qValue).toPrecision(3)); // convert back to a number for numerical sorting
                    }
                },
                {
                    name: 'log2afc',
                    render: function(data, type, row) {
                        return Number(Number(row.log2AllelicFoldChange).toPrecision(3)); // convert back to a number for numerical sorting, but the datatable sorting seems to have a bug
                    }
                },
                {
                    name: 'actions', searchable: false, orderable: false, defaultContent: '',
                    'render': function(data, type, row) {
                        const gevUrl = `<a href="/home/locusBrowserPage/${row.gencodeId}">LocusBrowser</a>`;
                        const igvUrl = `<a href="/home/browseEqtls?location=${row.geneId}&tissueName=${row.tissueSiteDetailId}">IGV</a>`;
                        return igvUrl + ', ' + gevUrl;
                    }
                }
            ];
            this.createAssoGeneTable(egeneUrl, dataSrc, tableId, columns, headings, sortColumnIndex, this.getEqtls);
        },

        getSGenes(tableId = 'sGeneTable', dataSrc = 'sgene') {
            const sgeneUrl = `${directory.getSGeneUrl().url}?pageSize=30000&tissueSiteDetailId=${this.tissueName}`;
            const headings = ['', 'Gencode Id', 'Gene Symbol', 'PhenotypeId', 'Nominal P-Value', 'Empirical P-Value', 'Q-Value', 'nPhenotypes', 'Actions'];
            const sortColumnIndex = headings.indexOf('Q-Value');
            const columns = [
                {
                    name: 'icon', orderable: false, searchable: false,
                    defaultContent: '', // open-close icon
                    render: function(data, type, row, meta) {
                        const html = '<img class="expandCollapse" src="' + details_open_path + '" width="10px">';
                        return html;
                    }
                },
                { name: 'gencodeId', data: 'gencodeId' },
                {
                    name: 'geneSymbol', defaultContent: '',
                    render: function(data, type, row) {
                        return generateGeneUrl(row.gencodeId, row.geneSymbol);
                    }
                },
                {
                    name: 'phenotypeId',
                    data: 'phenotypeId'
                },
                {
                    name: 'pValue',
                    'render': function(data, type, row) {
                        return Number(row.pValue).toPrecision(3);
                    }
                }, // Nominal P-value
                {
                    name: 'empiricalPValue',
                    render: function(data, type, row) {
                        return Number(row.empiricalPValue).toPrecision(3);
                    }
                },
                {
                    name: 'qValue',
                    render: function(data, type, row) {
                        return Number(Number(row.qValue).toPrecision(3)); // convert back to a number for numerical sorting
                    }
                },
                { name: 'nPhenotypes', data: 'nPhenotypes' },
                {
                    name: 'actions', searchable: false, orderable: false, defaultContent: '',
                    'render': function(data, type, row) {
                        const gevUrl = `<a href="/home/locusBrowserPage/${row.gencodeId}">LocusBrowser</a>`;
                        // const igvUrl = `<a href="/home/browseEqtls?location=${row.geneId}&tissueName=${row.tissueSiteDetailId}">IGV</a>`;
                        return gevUrl;
                    }
                }
            ];
            this.createAssoGeneTable(sgeneUrl, dataSrc, tableId, columns, headings, sortColumnIndex, this.getSqtls);
        },

        createAssoGeneTable(url, dataSrc, tableId, columnProps, objectHeadings, sortColumnIndex, callback) {
        // export columns and buttons
            const defineButtons = () => {
                const exportColumns = objectHeadings.map((d, i) => {
                    return i;
                }).filter(i => {
                    return i > 0 && i < objectHeadings.length - 1;
                });
                return {
                    'buttons': [{
                        extend: 'copy',
                        exportOptions: { columns: exportColumns }
                    },
                    {
                        extend: 'csv',
                        exportOptions: { columns: exportColumns }
                    }]
                };
            };
            const buttonProps = defineButtons();
            const customParams = {
                ajax: {
                    url: url,
                    dataSrc: dataSrc
                },
                buttons: buttonProps,
                columns: columnProps,
                order: [[sortColumnIndex, 'asc']]
            };
            const oTable = this.buildDataTable(tableId, objectHeadings, customParams);

            // define row click event: expand and create a sub-table for eQTLs given the gene and tissue
            const subTableId = tableId == 'eGeneTable' ? 'eqtlTable' : 'sqtlTable';
            const parent = this;
            $(`#${tableId} tbody`).on('click', 'td img.expandCollapse', function() {
                const row = this.parentElement.parentElement; // this refers to the clicked table cell
                const cell = row.firstChild.firstChild;
                const closeRow = (activeRow = row, activeCell = cell) => {
                    oTable.fnClose(activeRow);
                    activeCell.src = details_open_path;
                    parent.openRow = null;
                };

                const openRow = () => {
                    parent.openRow = row;
                    cell.src = details_close_path;
                    const html = `<table id=${subTableId} class="compact"></table>`;
                    oTable.fnOpen(row, html, 'detailrow'); // open the row in the parent table
                    const geneName = oTable.fnGetData(row).gencodeId;
                    const phenotypeId = oTable.fnGetData(row).phenotypeId;
                    callback(geneName, subTableId, phenotypeId); // additional custom functions: getEqtls or getSqtls
                };
                if (oTable.fnIsOpen(row)) { // if the row is already open, close it.
                    closeRow();
                } else {
                    if (parent.openRow != null) {
                        /* this assures only one row is open at a time */
                        closeRow(parent.openRow, parent.openRow.firstChild.firstChild);
                    }
                    openRow();
                }
            });
            return oTable;
        },

        buildDataTable(tableId, objectHeadings, customParams) {
            const oTable = $(`#${tableId}`);
            TableUtils.buildTableHtml(oTable[0], objectHeadings);
            $.fn.dataTableExt.sErrMode = 'throw'; // this is to prevent DataTables from putting warnings or errors in an alert box.
            const defaultParams = {
                processing: true,
                deferRender: true,
                paginationType: 'full_numbers',
                dom: 'B<"clear">lfrtip',
                orderClasses: false,
                language: {
                    'sEmptyTable': 'Please wait - Fetching records'
                },

                fnInitComplete: function(oSettings) {
                    oSettings.oLanguage.sEmptyTable = 'No data found';
                    $(oTable).find('.dataTables_empty').html('No data');
                }
            };

            try {
                if ($.fn.dataTable.isDataTable(oTable)) {
                    const t = oTable.DataTable();
                    t.destroy();
                }
                oTable.dataTable({
                    ...customParams,
                    ...defaultParams
                });
                oTable.addClass('display');// this is required for odd/even row styling to work.
                oTable.show();
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err);
            }

            return oTable;
        }

    }


};

</script>
<style lang="css" scoped>

h5 {
  margin: 0;
}

h3 {
  margin-top: 10px;
}

p {
  margin-bottom: 0;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

#tissue-page-container {
  background-color: #F2F6F8;
  max-width: 100%
}

#tissue-page-header-content {
  width: 100%;
  padding: 1.5vh 2vw;
  font-size: 1.35em;
}

#tissue-page-header {
  left: 0;
  max-width: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
      center / cover no-repeat url('~@/assets/images/tissue-bg.png');
  z-index: 1;
}

div .scrollFix {
  top: 0;
  left: 0;
  width: 100%;
  /* padding-left: 20px;
  margin-right: 20px; */

  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.05);
  min-height: 20px;
  position: fixed;
}

#tissue-page-body {
  max-width: 100%;
  min-height: 1500px;
}

div .card {
  min-height: 200px;
  background-color: #ffffff;
  margin: 4px;
  padding: 10px 10px 20px 10px;
}

div .big-number-box {
  width: 160px;
  height: 160px;
  background-color: #c5dbec;
  float: left;
  margin: 10px;
  padding: 10px;
  font-size: 0.9rem;
  font-weight: bold;
}

div .very-big-number-box {
  width: 250px;
  height: 160px;
  background-color: #c5dbec;
  float: left;
  margin: 10px;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
}

div .three-digit-number {
  font-size: 8rem;
  color: #2e6e9e;
  text-align: center;
}

div .five-digit-number {
  font-size: 8rem;
  color: #2e6e9e;
  text-align: center;
}

div .four-digit-number {
  font-size: 7rem;
  color: #2e6e9e;
  text-align: center;
}

.row {
  margin: 0;
}

.card-title {
  font-size: 1.5rem;
}

.em-text {
  font-size: 1rem;
  font-weight: bold;
}

/* expression */
#topExpressedDiv, #eGeneTableDiv, #sGeneTableDiv {
  font-size: 1rem;
}

/* table.dataTable.compact {
  font-size: 1rem;
}
table.dataTable.compact thead th,table.dataTable.compact thead td{
  padding:4px 17px;
  font-size: 1rem;
}
table.dataTable.compact tfoot th,table.dataTable.compact tfoot td{
  padding:4px;
  font-size: 1rem;
}
table.dataTable.compact tbody th,table.dataTable.compact tbody td{
  padding:4px;
  font-size: 1rem;
} */

td, th {
  padding: 5px;
  border: 2px solid white;
}

td {
  background-color: #eeffff;
  padding: 5px;
}

th {
  background-color: #c5dbec;
  padding: 5px;
}

</style>
