<template>
  <div class="container-fluid">
    <div class="col-sm-10">
      <h2>Programmatic Access to GTEx Public Data</h2>
      <p>
        The GTEx Portal provides two different Application Programming Interfaces (APIs) to access GTEx public data: the
        <a href="https://www.ga4gh.org/" target="_blank">GA4GH <i class="fas fa-external-link-alt" /></a>
        <a href="https://github.com/ga4gh-rnaseq/schema" target="_blank"> RNAget API <i class="fas fa-external-link-alt" /></a>
        and our own GTEx API.  The GA4GH RNAget API provides a standard API to query RNA-seq expression data, whereas the
        GTEx API provides the ability to query RNA-seq expression data as well as other GTEx data, as described below
        in more detail.
      </p>
      <p>
        As an alternative to using our APIs, you may prefer to
        <router-link class="navLink" to="/datasets">
          download our public data files
        </router-link> and write your scripts
        processing those files. You might find that processing a local file is more performant than
        repeatedly calling one of our APIs.
      </p>
      <p />
      <H3>GTEx API</H3>
      <p>
        The GTEx Portal provides access to GTEx open access data via the GTEx API.  The GTEx API is more extensive
        than the GA4GH RNAget API, providing access to QTL data, sample annotations, subject phenotypes, and reference
        data, in addition to expression data.
      </p>
      <p>
        The GTEx API is suitable for low-throughput querying – please do not send queries in parallel to this API.
        Instead, send one query and wait until it completes before sending your next query.
      </p>

      <H4>GTEx API V2</h4>
      <p>
        GTEx API V2 has been released and is documented
        <a href="https://gtexportal.org/api/v2/redoc#tag/GTEx-Portal-API-Info" target="_blank">
          here <i class="fas fa-external-link-alt" /></a>.

        The V2 API provides improved documentation, improved query parameter validation, improved support for
        querying by tissue ontology identifiers, and support for paginated queries.
      </p>
      <p>
        Our upcoming V10 data release will only be supported by GTEx V2 API
      </p>
      <H4>GTEx API V1 Discontinued</H4>
      <p>
        The GTEx API V1 has been discontinued and is no longer operational.  Please update
        your code to use the GTEx API V2. Our upcoming V10 data release will not be available via the GTEx API V1.
      </p>
      <p /><H3>GA4GH RNAget API</H3>
      <p>
        The GTEx Portal RNAget API provides the ability to retrieve GTEx RNA-seq data using the
        <a href="https://www.ga4gh.org/" target="_blank">GA4GH <i class="fas fa-external-link-alt" /></a>
        standard
        <a href="https://github.com/ga4gh-rnaseq/schema" target="_blank">RNAget API <i class="fas fa-external-link-alt" /></a>.
        This API currently supports the retrieval of data for the GTEx V8 release.
        You can find documentation for our implementation of RNAget
        <a href="https://gtexportal.org/rnaget/docs" target="_blank">here <i class="fas fa-external-link-alt" /></a>.
      </p>
    </div>
  </div>
</template>
