<template>
  <div>
    <div class="row">
      <div id="h3k27ac" class="col-xs-12 col-sm-4">
        <h5>H3K27ac</h5>
        <div>
          <label><input type="checkbox" value="Brain_Frontal_Cortex_BA9" class="h3k27ac-item"> Brain - Frontal Cortex (BA9)</label>
        </div>
        <div>
          <label><input type="checkbox" value="Heart_Left_Ventricle" class="h3k27ac-item"> Heart - Left Ventricle</label>
        </div>
        <div>
          <label><input type="checkbox" value="Lung" class="h3k27ac-item"> Lung</label>
        </div>
        <div>
          <label><input type="checkbox" value="Muscle_Skeletal" class="h3k27ac-item"> Muscle - Skeletal</label>
        </div>
      </div>
      <div id="m6a" class="col-xs-12 col-sm-4">
        <h5>m6A RNA methylation</h5>
        <div>
          <label><input type="checkbox" value="Brain_Cortex" class="m6a-item"> Brain - Cortex</label>
        </div>
        <div>
          <label><input type="checkbox" value="Heart_Left_Ventricle" class="m6a-item"> Heart - Left Ventricle</label>
        </div>
        <div>
          <label><input type="checkbox" value="Lung" class="m6a-item"> Lung</label>
        </div>
        <div>
          <label><input type="checkbox" value="Muscle_Skeletal" class="m6a-item"> Muscle - Skeletal</label>
        </div>
      </div>
    </div>

    <br>
    <div class="row">
      <div class="col-xs-12">
        <h5>
          WGBS DNA methylation
          <span> (<a href="https://www.biorxiv.org/content/10.1101/2021.01.02.425010v1.full" target="_blank">Rizzardi <em>et al. </em><i class="fas fa-external-link-alt" /></a>) </span>
          <span class="fas fa-info-circle gtooltip"
                data-toggle="tooltip"
                data-placement="auto right"
                data-html="true"
                data-container="body"
                title="For this dataset, all brain regions and groupings are NeuN+ sorted.
                      Neuronal nuclei were isolated from brain tissues based on positive NeuN (<em>RBFOX3</em>) staining via fluorescence activated nuclei sorting.
                      <br/><br/>
                      Some tracks are specific to the eGTEx WGBS study and aggregate or disaggregate NeuN+ sorted samples from GTEx brain tissues as noted below.
                      <br/>
                      <ul style='margin-bottom:0;'>
                        <li>Basal ganglia: all samples from Brain - Caudate (basal ganglia), Brain - Nucleus accumbens (basal ganglia), Brain - Putamen (basal ganglia)</li>
                        <li>Cortical: all samples from Brain - Anterior cingulate cortex (BA24) and Brain - Frontal Cortex (BA9)</li>
                        <li>Hippocampus - Dentate gyrus (HC1): Brain - Hippocampus samples that are likely from the dentate gyrus region of the hippocampus (based off PCA groupings and further investigation of promoter methylation at marker genes of pyramidal neurons and granule neurons)</li>
                        <li>Hippocampus - Others (HC2): remaining Brian - Hippocampus samples not in the HC1 grouping</li>
                      </ul>"
          />
        </h5>
      </div>

      <div class="col-xs-12 col-sm-4">
        <h6 class="data-subtype">
          Study specific NeuN+ sorted groupings
        </h6>
        <div id="wgbs-1">
          <div>
            <label><input type="checkbox" value="Basal_ganglia" class="wgbs-item"> Basal ganglia</label>
          </div>
          <div>
            <label><input type="checkbox" value="Cortical" class="wgbs-item"> Cortical</label>
          </div>
          <div>
            <label><input type="checkbox" value="HC1" class="wgbs-item"> Hippocampus - Dentate gyrus (HC1)*</label>
          </div>
          <div>
            <label><input type="checkbox" value="HC2" class="wgbs-item"> Hippocampus - Others (HC2)</label>
          </div>
        </div>
      </div>
      <div id="wgbs-2" class="col-xs-12 col-sm-4">
        <h6 class="data-subtype">
          NeuN+ sorted brain regions
        </h6>
        <div>
          <label><input type="checkbox" value="Brain_Amygdala" class="wgbs-item"> Brain - Amygdala*</label>
        </div>
        <div>
          <label><input type="checkbox" value="Brain_Anterior_cingulate_cortex_BA24" class="wgbs-item"> Brain - Anterior cingulate cortex (BA24)*</label>
        </div>
        <div>
          <label><input type="checkbox" value="Brain_Caudate_basal_ganglia" class="wgbs-item"> Brain - Caudate (basal ganglia)*</label>
        </div>
        <div>
          <label><input type="checkbox" value="Brain_Frontal_Cortex_BA9" class="wgbs-item"> Brain - Frontal Cortex (BA9)*</label>
        </div>
        <div>
          <label><input type="checkbox" value="Brain_Hippocampus" class="wgbs-item"> Brain - Hippocampus*</label>
        </div>
        <div>
          <label><input type="checkbox" value="Brain_Hypothalamus" class="wgbs-item"> Brain - Hypothalamus*</label>
        </div>
        <div>
          <label><input type="checkbox" value="Brain_Nucleus_accumbens_basal_ganglia" class="wgbs-item"> Brain - Nucleus accumbens (basal ganglia)*</label>
        </div>
        <div>
          <label><input type="checkbox" value="Brain_Putamen_basal_ganglia" class="wgbs-item"> Brain - Putamen (basal ganglia)*</label>
        </div>
      </div>
      <div id="wgbs-3" class="col-xs-12 col-sm-4">
        <h6 class="data-subtype">
          Bulk GTEx tissues
        </h6>
        <div>
          <label><input type="checkbox" value="Breast_Mammary_Tissue" class="wgbs-item"> Breast - Mammary Tissue</label>
        </div>
        <div>
          <label><input type="checkbox" value="Esophagus_Muscularis" class="wgbs-item"> Esophagus - Muscularis</label>
        </div>
        <div>
          <label><input type="checkbox" value="Heart_Left_Ventricle" class="wgbs-item"> Heart - Left Ventricle</label>
        </div>
        <div>
          <label><input type="checkbox" value="Lung" class="wgbs-item"> Lung*</label>
        </div>
        <div>
          <label><input type="checkbox" value="Muscle_Skeletal" class="wgbs-item"> Muscle - Skeletal</label>
        </div>
        <div>
          <label><input type="checkbox" value="Pituitary" class="wgbs-item"> Pituitary</label>
        </div>
        <div>
          <label><input type="checkbox" value="Skin_Sun_Exposed_Lower_leg" class="wgbs-item"> Skin - Sun Exposed (Lower leg)</label>
        </div>
        <div>
          <label><input type="checkbox" value="Thyroid" class="wgbs-item"> Thyroid*</label>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="note">
          * These marked selections will load with an additional VMR track, noting regions of inter-individual methylation variability among samples in that group.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    mounted: function() {
        const self = this;
        const submit = function(dom, type) {
            // visual response
            dom.parent().toggleClass('checked-egtex-item');

            // add to or remove from IGV
            const tissueId = dom.val();
            const item = {
                status: dom.is(':checked'),
                id: tissueId,
                type: type
            };
            self.$emit('check-item', item);
        };
        $('.h3k27ac-item').click(function() {
            const dom = $(this);
            const type = 'H3K27ac';
            submit(dom, type);
        });
        $('.m6a-item').click(function() {
            const dom = $(this);
            const type = 'm6A';
            submit(dom, type);
        });
        $('.wgbs-item').click(function() {
            const dom = $(this);
            const type = 'WGBS';
            submit(dom, type);
        });
    }
};
</script>
<style scoped>
.data-subtype {
  margin-bottom: 5px;
}

.checked-egtex-item {
  background: aliceblue;
}

.note {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
}

label {
  cursor: pointer;
  font-weight: normal;
  margin: 0;
  border-bottom: 1px solid transparent;
}

label:hover {
  border-bottom-color: lightblue;
}
</style>
