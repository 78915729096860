<template>
  <div class="col-xs-12 col-md-4" style="width: 100%">
    <table class="table table-striped table-bordered">
      <thead>
        <tr>
          <th>GTEx dbGaP release</th>
          <th>V10</th>
          <th>V8</th>
          <th>V7</th>
          <th style="width:15%;">V6p</th>
          <th style="width:15%;">V3 (Pilot Phase)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>RNA-Seq samples (used in eQTL analysis)</th>
          <td>19788 (19466)</td>
          <td>17382 (15201)</td>
          <td>11688 (10294)</td>
          <td>8555 (7051)</td>
          <td>1641 (991)</td>
        </tr>
        <tr>
          <th>Donors (used in eQTL analysis)</th>
          <td>946 (943)</td>
          <td>948 (838)</td>
          <td>714 (620)</td>
          <td>544 (449)</td>
          <td>185 (175)</td>
        </tr>
        <tr>
          <th>Tissues (used in eQTL analysis)</th>
          <td>54 (50)</td>
          <td>54 (49)</td>
          <td>53 (48)</td>
          <td>53 (44)</td>
          <td>43 (9)</td>
        </tr>
        <tr>
          <th>GENCODE Version</th>
          <td><a href="https://www.gencodegenes.org/human/release_39.html" target="_blank">v39 <i class="fas fa-external-link-alt" /></a></td>
          <td><a href="https://www.gencodegenes.org/human/release_26.html" target="_blank">v26 <i class="fas fa-external-link-alt" /></a></td>
          <td><a href="https://www.gencodegenes.org/human/release_19.html" target="_blank">v19 <i class="fas fa-external-link-alt" /></a></td>
          <td><a href="https://www.gencodegenes.org/human/release_19.html" target="_blank">v19 <i class="fas fa-external-link-alt" /></a></td>
          <td><a href="https://www.gencodegenes.org/human/release_12.html" target="_blank">v12 <i class="fas fa-external-link-alt" /></a></td>
        </tr>
        <tr>
          <th>Genome Build</th>
          <td>GRCh38/hg38</td>
          <td>GRCh38/hg38</td>
          <td>GRCh37/hg19</td>
          <td>GRCh37/hg19</td>
          <td>GRCh37/hg19</td>
        </tr>
        <tr>
          <th>RNA-seq alignment</th>
          <td>STAR v2.7.10a</td>
          <td>STAR v2.5.3a</td>
          <td>STAR v2.4.2a</td>
          <td>TopHat v1.4.1</td>
          <td>TopHat v1.4.1</td>
        </tr>
        <tr>
          <th>Transcript quantification</th>
          <td>RSEM v1.3.3</td>
          <td>RSEM v1.3.0</td>
          <td>RSEM v.1.2.22</td>
          <td>Flux Capacitor v1.2.3</td>
          <td>Flux Capacitor v1.2.3</td>
        </tr>
        <tr>
          <th>Genotype used for eQTL analysis</th>
          <td>WGS</td>
          <td>WGS</td>
          <td>WGS</td>
          <td>Illumina's OMNI 5M + imputation to 1000 Genomes Project Phase I v3</td>
          <td>Illumina's OMNI 5M + imputation to 1000 Genomes Project Phase I v3</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script lang="ts">
</script>