

function sizeToReadableSize(size) {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(1) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];

}

function compareOrder(a, b){
    return a.order - b.order;
}


export default {
    sizeToReadableSize,
    compareOrder
};
