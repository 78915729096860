<template>
  <div id="publications-page" class="container-fluid">
<!--    <h2>GTEx Consortium Preprints</h2>
    <p class="details">
    </p>
    <hr>
    </>-->

    <h2>GTEx Consortium Publications</h2>
    <h4>2025</h4>
    <p class="article-heading">
      <a href="https://www.nature.com/articles/s41586-024-08244-9/" target="_blank">
        <em>
          The human and non-human primate developmental GTEx projects
        </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>

    <p class="details">
      Coorens, T.H.H., Guillaumet-Adkins, A., Kovner, R. <em>et al</em>.<br>
      <span class="jrnl" title="Nature">Nature </span><b>637</b>, 557–564 (2025). doi: 10.1038/s41586-024-08244-9
      <br>
    </p>
    
    
    <h4>2024</h4>
    <p class="article-heading">
      <a href="https://pubmed.ncbi.nlm.nih.gov/38797720/" target="_blank">
        <em>
          Histologic and Quality Assessment of Genotype-Tissue Expression (GTEx) Research Samples: A Large Postmortem Tissue Collection
        </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>

    <p class="details">
      Sobin L, Barcus M, Branton PA, <em>et al</em>.<br>
      <span class="jrnl" title="Arch Pathol Lab Med">Arch Pathol Lab Med</span>. 
      Published online May 27, 2024. doi:10.5858/arpa.2023-0467-OA<br>
    </p>
    
    <h4>2023</h4>

    <p class="article-heading">
      <a href="https://www.science.org/doi/10.1126/science.abn7113" target="_blank">
        <em>The origins and functional effects of postzygotic mutations throughout the human life span </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>

    <p class="details">
      Nicole B. Rockweiler, <em>et al</em>.<br>
      <span class="jrnl" title="Science">Science</span>. 14 Apr 2023. Vol 380, Issue 6641. doi: 10.1126/science.abn7113<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1016/j.xgen.2022.100245" target="_blank">
        <em>Clinical traits impacting human tissue transcriptomes </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Kaur Alasoo <br>
      <span class="jrnl" title="Cell Genomics">Cell Genomics</span>. Volume 3, Issue 1, 100245, January 11, 2023. doi:10.1016/j.xgen.2022.100245<br>
    </p>
    <h4>2022</h4>
    <p class="article-heading">
      <a href="https://pubmed.ncbi.nlm.nih.gov/36777183/" target="_blank">
        <em>Population-scale tissue transcriptomics maps long non-coding RNAs to complex disease </em>
        <i class="fas fa-external-link-alt" />
      </a>
    </p>
    <p class="details">
      García-Pérez R, Ramirez JM, Ripoll-Cladellas A, <em>et al</em>.<br>
      <span class="jrnl" title="Cell Genomics">Cell Genomics </span> vol. 3,1 100244. 30 Dec. 2022, doi:10.1016/j.xgen.2022.100244
    </p>
    
    <p class="article-heading">
      <a href="" target="_blank">
        <em>Transcriptome variation in human tissues revealed by long-read sequencing </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Glinos, Dafni A <em>et al</em>. <br>
      <span class="jrnl" title="Nature">Nature </span> vol. 608,7922 (2022): 353-359. doi:10.1038/s41586-022-05035-y
    </p>

    <p class="article-heading">
      <a href="https://www.science.org/doi/10.1126/science.abl4290" target="_blank">
        <em>Single-nucleus cross-tissue molecular reference maps toward understanding disease gene </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Eraslan G, <em>et al</em>. <br>
      <span class="jrnl" title="Science">Science</span>. 376 (abl4290), 13 May 2022. doi:10.1126/science.abl4290<br>
    </p>
    <h4>2021</h4>
    <p class="article-heading">
      <a href="https://pmc.ncbi.nlm.nih.gov/articles/PMC8651477/" target="_blank">
        <em>The landscape of expression and alternative splicing variation across human traits </em>
        <i class="fas fa-external-link-alt" />
      </a>
    </p>
    <p class="details">
      Olivia M de Goede, <em>et al</em>.<br>
      <span class="jrnl" title="Cell">Cell</span>. 2021. Apr 16;184(10):2633–2648.e19. doi: 10.1016
    </p>
    <h4>2020</h4>
    <p class="article-heading">
      <a href="https://doi.org/10.1126/science.aaz1776" target="_blank">
        <em>The GTEx Consortium atlas of genetic regulatory effects across human tissues </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      The GTEx Consortium.<br>
      <span class="jrnl" title="Science">Science</span>. 369 (1318-1330), 10 Sep 2020. doi:10.1126/science.aaz1776<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1126/science.aaz8528" target="_blank">
        <em>Cell type specific genetic regulation of gene expression across human tissues </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Kim-Hellmuth* S, Aguet* F, Oliva M, Muñoz-Aguirre M, Kasela S, <em>et al</em>.<br>
      <span class="jrnl" title="Science">Science</span>. 369 (eaaz8528), 10 Sep 2020. doi:10.1126/science.aaz8528<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1126/science.aaz5900" target="_blank">
        <em>Transcriptomic signatures across human tissues identify functional rare genetic variation </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Ferraro* NM, Strober* BJ, Einson J, Abell NS, Aguet F, <em>et al</em>.<br>
      <span class="jrnl" title="Science">Science</span>. 369 (aaz5900), 10 Sep 2020. doi:10.1126/science.aaz5900<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1126/science.aaz6876" target="_blank">
        <em>Determinants of telomere length across human tissues </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Demanelis K, Jasmine F, Chen LS, Chernoff M, Tong L, <em>et al</em>.<br>
      <span class="jrnl" title="Science">Science</span>. 369 (aaz6876), 10 Sep 2020. doi:10.1126/science.aaz6876<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1126/science.aba3066" target="_blank">
        <em>The impact of sex on gene expression across human tissues </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Oliva* M, Muñoz-Aguirre* M, Kim-Hellmuth* S, Wucher V, Gewirtz ADH, <em>et al</em>.<br>
      <span class="jrnl" title="Science">Science</span>. 369 (aba3066), 10 Sep 2020. doi:10.1126/science.aba3066<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1126/sciadv.abb6242" target="_blank">
        <em>Tissue-specific genetic features inform prediction of drug side effects in clinical trials </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Duffy A, Verbanck M, Dobbyn A, Won H-H, Rein JL, <em>et al</em>.<br>
      <span class="jrnl" title="Science Advances">Science Advances</span>. 6(37), eabb6242, 10 Sep 2020. doi:10.1126/sciadv.abb6242<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1126/sciadv.aba2083" target="_blank">
        <em>PhenomeXcan: Mapping the genome to the phenome through the transcriptome </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Pividori M, Rajagopal PS, Barbeira A, Liang Y, Melia O, <em>et al</em>.<br>
      <span class="jrnl" title="Science Advances">Science Advances</span>. 6(37), eaba2083, 10 Sep 2020. doi:10.1126/sciadv.aba2083<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1186/s13059-020-02026-y" target="_blank">
        <em>PTWAS: investigating tissue-relevant causal molecular mechanisms of complex traits using probabilistic TWAS analysis </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Zhang Y, Quick C, Yu K, Barbeira A, The GTEx Consortium, <em>et al</em>.<br>
      <span class="jrnl" title="Genome Biology">Genome Biology</span>. 21(232), 10 Sep 2020. doi:10.1186/s13059-020-02026-y<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1186/s13059-020-02113-0" target="_blank">
        <em>Impact of admixture and ancestry on eQTL analysis and GWAS colocalization in GTEx </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Gay NR, Gloudemans M, Antonio ML, Abell NS, Balliu B, <em>et al</em>.<br>
      <span class="jrnl" title="Genome Biology">Genome Biology</span>. 21(233), 10 Sep 2020. doi:10.1186/s13059-020-02113-0<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1186/s13059-020-02122-z" target="_blank">
        <em>A vast resource of allelic expression data spanning human tissues </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Castel SE, Aguet F, Mohammadi P, GTEx Consortium, Ardlie KG, Lappalainen T<br>
      <span class="jrnl" title="Genome Biology">Genome Biology</span>. 21(234), 10 Sep 2020. doi:10.1186/s13059-020-02122-z<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1186/s13059-020-02129-6" target="_blank">
        <em>sn-spMF: matrix factorization informs tissue-specific genetic regulation of gene expression </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      He Y, Chhetri SB, Arvanitis M, Srinivasan K, Aguet F, <em>et al</em>.<br>
      <span class="jrnl" title="Genome Biology">Genome Biology</span>. 21(235), 10 Sep 2020. doi:10.1186/s13059-020-02129-6<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1186/s13059-020-02125-w" target="_blank">
        <em>Primo: integration of multiple GWAS and omics QTL summary statistics for elucidation of molecular mechanisms of trait-associated SNPs and detection of pleiotropy in complex traits </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Gleason KJ, Yang F, Pierce BL, He X, Chen LS<br>
      <span class="jrnl" title="Genome Biology">Genome Biology</span>. 21(236), 10 Sep 2020. doi:10.1186/s13059-020-02125-w<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1186/s13073-020-00777-8" target="_blank">
        <em>A polyclonal allelic expression assay for detecting regulatory effects of transcript variants </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Brandt MK, Gokden A, Ziosi M, Lappalainen T<br>
      <span class="jrnl" title="Genome Medicine">Genome Medicine</span>. 12(79), 10 Sep 2020. doi:10.1186/s13073-020-00777-8<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1016/j.cell.2020.08.036" target="_blank">
        <em>A Quantitative Proteome Map of the Human Body </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Jiang L, Wang M, Lin S, Jian R, Li X, <em>et al</em>.<br>
      <span class="jrnl" title="Cell">Cell</span>. 10 Sep 2020. doi:10.1016/j.cell.2020.08.036<br>
    </p>

    <p class="article-heading">
      <a href="https://doi.org/10.1002/gepi.22346" target="_blank">
        <em>Fine‐mapping and QTL tissue‐sharing information improves the reliability of causal gene identification </em>
        <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Barbeira AN, Melia OJ, Liang Y, Bonazzola R, Wang G, <em>et al</em>.<br>
      <span class="jrnl" title="Genetic Epidemiology">Genetic Epidemiology</span>. 10 Sep 2020. doi:10.1002/gepi.22346<br>
    </p>

    <h4>2019</h4>

    <p class="article-heading">
      <a href="http://dx.doi.org/10.1126/science.aaw0726" target="_blank">
        <em>RNA sequence analysis reveals macroscopic somatic clonal expansion across normal tissues </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Yizhak K, Aguet F, Kim J, Hess JM, Kübler K <em>et al</em>.<br>
      <span class="jrnl" title="Science">Science</span>. 07 June 2019. 364(6444). doi:10.1126/science.aaw0726<br>
    </p>

    <hr>
    <h4>2018</h4>
    <p class="article-heading">
      <a href="http://dx.doi.org/10.1038/s41588-018-0154-4" target="_blank">
        <em>Using an atlas of gene regulation across 44 human tissues to inform complex disease- and trait-associated variation </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Gamazon ER, Segr&egrave; AV, van de Bunt M, Wen X, Xi HS <em>et al</em>.<br>
      <span class="jrnl" title="Nature Genetics">Nature Genetics</span>. July 2018. 50(7): 956-967. Epub 28 Jun 2018. doi:10.1038/s41588-018-0154-4<br>
    </p>

    <p class="article-heading">
      <a href="http://dx.doi.org/10.1038/s41467-017-02772-x" target="_blank">
        <em>The effects of death and post-mortem cold ischemia on human tissue transcriptomes </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Ferreira PG, Mu&ntilde;oz-Aguirre M, Reverter F, S&aacute; Godinho CP, Sousa A <em>et al</em>.<br>
      <span class="jrnl" title="Nature Communications">Nature Communications</span>. 2018. 9: 490. Epub 13 Feb 2018. doi:10.1038/s41467-017-02772-x<br>
    </p>

    <hr>
    <h4>2017</h4>
    <p class="article-heading">
      <a href="http://dx.doi.org/10.1038/ng.3981" target="_blank">
        <em>Estimating the causal tissues for complex traits and diseases </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Ongen H, Brown AA, Delaneau O, Panousis NI, Nica AC <em>et al</em>.<br>
      <span class="jrnl" title="Nature Genetics">Nature Genetics</span>. 2017. 49: 1676-1683. Epub 23 Oct 2017. doi:10.1038/ng.3981<br>
    </p>

    <p class="article-heading">
      <a href="http://dx.doi.org/10.1038/ng.3979" target="_blank">
        <em>Predicting causal variants affecting expression by using whole-genome sequencing and RNA-seq from multiple human tissues </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Brown AA, Vi&#241;uela A, Delaneau O, Spector TD, Small KS <em>et al</em>.<br>
      <span class="jrnl" title="Nature Genetics">Nature Genetics</span>. 2017. 49: 1747-1751. Epub 23 Oct 2017. doi:10.1038/ng.3979<br>
    </p>

    <p class="article-heading">
      <a href="http://dx.doi.org/10.1038/nature24277" target="_blank">
        <em>Genetic effects on gene expression across human tissues </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      The GTEx Consortium.<br>
      <span class="jrnl" title="Nature">Nature</span>. 12 Oct 2017. 550: 204-213. Epub 11 Oct 2017. doi:10.1038/nature24277<br>
    </p>

    <p class="article-heading">
      <a href="http://genome.cshlp.org/content/early/2017/10/06/gr.216721.116" target="_blank">
        <em>Co-expression networks reveal the tissue-specific regulation of transcription and splicing </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Saha A, Kim Y, Gewirtz ADH, Jo B, Gao C <em>et al</em>.<br>
      <span class="jrnl" title="Genome Research">Genome Research</span>. 2017. Epub 11 Oct 2017. doi:10.1101/gr.216721.116<br>
    </p>

    <p class="article-heading">
      <a href="http://genome.cshlp.org/content/early/2017/10/04/gr.216747.116" target="_blank">
        <em>Quantifying the regulatory effect size of <i>cis</i>-acting genetic variation using allelic fold change </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Mohammadi P, Castel SE, Brown AA and Lappalainen T.<br>
      <span class="jrnl" title="Genome Research">Genome Research</span>. 2017. Epub 11 Oct 2017. doi:10.1101/gr.216747.116<br>
    </p>

    <p class="article-heading">
      <a href="http://genome.cshlp.org/content/early/2017/10/04/gr.216754.116" target="_blank">
        <em>Identifying <i>cis</i>-mediators for <i>trans</i>-eQTLs across many human tissues using genomic mediation analysis </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Yang F, Wang J, GTEx Consortium, Pierce BL and Chen LS.<br>
      <span class="jrnl" title="Genome Research">Genome Research</span>. 2017. Epub 11 Oct 2017. doi:10.1101/gr.216754.116<br>
    </p>

    <p class="article-heading">
      <a href="http://dx.doi.org/10.1038/nature24267" target="_blank">
        <em>The impact of rare variation on gene expression across tissues </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Li X, Kim Y, Tsang EK, Davis JR, Damani FN <em>et al</em>.<br>
      <span class="jrnl" title="Nature">Nature</span>. 12 Oct 2017. 550: 239-243. Epub 11 Oct 2017. doi:10.1038/nature24267<br>
    </p>

    <p class="article-heading">
      <a href="http://dx.doi.org/10.1038/nature24265" target="_blank">
        <em>Landscape of X chromosome inactivation across human tissues </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Tukiainen T, Villani AC, Yen A, Rivas MA, Marshall JL <em>et al</em>.<br>
      <span class="jrnl" title="Nature">Nature</span>. 12 Oct 2017. 550: 244-248. Epub 11 Oct 2017. doi:10.1038/nature24265<br>
    </p>

    <p class="article-heading">
      <a href="http://dx.doi.org/10.1038/nature24041" target="_blank">
        <em>Dynamic landscape and regulation of RNA editing in mammals </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Tan MH, Li Q, Shanmugam R, Piskol R, Kohler J <em>et al</em>.<br>
      <span class="jrnl" title="Nature">Nature</span>. 12 Oct 2017. 550:249-254. Epub 11 Oct 2017. doi:10.1038/nature24041<br>
    </p>

    <p class="article-heading">
      <a href="http://dx.doi.org/10.1038/ng.3969" target="_blank">
        <em>Enhancing GTEx by bridging the gaps between genotype, gene expression, and disease </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      eGTEx Project.<br>
      <span class="jrnl" title="Nature Genetics">Nature Genetics</span>. 2017. Epub 11 Oct 2017. doi:10.1038/ng.3969<br>
    </p>

    <p class="article-heading">
      <a href="http://dx.doi.org/10.1038/nmeth.4407" target="_blank">
        <em>Massively parallel single-nucleus RNA-seq with DroNc-seq </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Habib N, Avraham-Davidi I, Basu A, Burks T, Shekhar K <em>et al</em>.<br>
      <span class="jrnl" title="Nature Methods">Nature Methods</span>. 2017. 14:955-958. Epub 28 Aug 2017. doi:10.1038/nmeth.4407<br>
    </p>

    <p class="article-heading">
      <a href="http://dx.doi.org/10.1038/ng.3834" target="_blank">
        <em>The impact of structural variation on human gene expression </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Chiang C, Scott AJ, Davis JR, Tsang EK, Li X <em>et al</em>.<br>
      <span class="jrnl" title="Nature Genetics">Nature Genetics</span>. 2017. 49: 692-699. Epub 3 Apr 2017. doi:10.1038/ng.3834<br>
    </p>

    <p class="article-heading">
      <a href="http://dx.doi.org/10.1126/scitranslmed.aal5209" target="_blank">
        <em>Improving genetic diagnosis in Mendelian disease with transcriptome sequencing </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Cummings BB, Marshall JL, Tukiainen T, Lek M, Donkervoort S <em>et al</em>.<br>
      <span class="jrnl" title="Science Translational Medicine">Science Translational Medicine</span>. 19 Apr 2017. 9(386). Epub 31 Mar 2017. doi:10.1126/scitranslmed.aal5209<br>
    </p>

    <hr>

    <h4>2016</h4>
    <p class="article-heading">
      <a href="http://dx.doi.org/10.1371/journal.pgen.1006423" target="_blank">
        <em>Survey of the Heritability and Sparse Architecture of Gene Expression Traits across Human Tissues </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Wheeler HE, Shah KP, Brenner J, Garcia T, Aquino-Michaels K <em>et al</em>.<br>
      <span class="jrnl" title="PLOS Genetics">PLOS Genetics</span>. 11 Nov 2016. 12(11): e1006423. doi:10.1371/journal.pgen.1006423<br>
    </p>

    <p class="article-heading">
      <a href="http://dx.doi.org/10.1016/j.ajhg.2016.02.020" target="_blank"><em>Imputing gene expression in uncollected tissues within and beyond GTEx.</em><i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Wang J, Gamazon ER, Pierce BL, Stranger BE, Im HK <em>et al</em>.<br>
      <span class="jrnl" title="American Journal of Human Genetics">American Journal of Human Genetics</span>. 7 April 2016. 98(4):697-708. doi:10.1016/j.ajhg.2016.02.020<br>
      PMID 27040689
    </p>

    <hr>

    <h4>2015</h4>
    <p class="article-heading">
      <a href="http://online.liebertpub.com/doi/full/10.1089/bio.2015.0032" target="_blank">
        <em>A Novel Approach to High-Quality Postmortem Tissue Procurement: The GTEx Project </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Carithers LJ, Ardlie K, Barcus M, Branton PA, Britton A <em>et al</em>.<br>
      <span class="jrnl" title="Biopreservation and Biobanking">Biopreservation and Biobanking</span>. October 2015. 13(5): 311-319. doi:10.1089/bio.2015.0032<br>
      PMID: 26484571
      <br>
      The paper describes how a successful infrastructure for biospecimen procurement was developed and implemented
      by multiple research partners to support the prospective collection, annotation, and distribution of blood,
      tissues, and cell lines for the GTEx project.
    </p>

    <p class="article-heading">
      <a href="http://www.nature.com/ng/journal/v47/n9/full/ng.3367.html" target="_blank">
        <em>A gene-based association method for mapping traits using reference transcriptome data </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Gamazon ER, Wheeler HE, Shah KP, Mozaffari SV, Aquino-Michaels K <em>et al</em>.<br>
      <span class="jrnl" title="Nature Genetics">Nature Genetics</span>. Sep 2015. 47(9):1091-8. Epub 10 Aug 2015. doi: 10.1038/ng.3367<br>
      PMID:  26258848<br>
      This paper presents a gene-based association method called PrediXcan that directly tests the molecular mechanisms through which genetic variation affects phenotype.
    </p>

    <p class="article-heading">
      <a href="http://bioinformatics.oxfordjournals.org/content/early/2015/03/27/bioinformatics.btv074.abstract" target="_blank">
        <em>Assessing allele-specific expression across multiple tissues from RNA-seq read data </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Pirinen M, Lappalainen T, Zaitlen NA, GTEx Consortium, Dermitzakis ET <em>et al</em>.<br>
      <span class="jrnl" title="Bioinformatics">Bioinformatics</span>. 1 August 2015. 31(15):2497-2504. doi: 10.1093/bioinformatics/btv074<br>
      The paper presents a statistical method to compare different patterns of allele specific expression across
      tissues and to classify genetic variants according to their impact on the tissue-wide expression profile. The
      study adopts a Bayesian model comparison framework to allow a simultaneous comparison between several cross-tissue
      models for the observed data.
    </p>

    <p class="article-heading">
      <a href="http://www.sciencemag.org/content/348/6235/648.short" target="_blank">
        <em>The Genotype-Tissue Expression (GTEx) pilot analysis: Multitissue gene regulation in humans </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      The GTEx Consortium.<br>
      <span class="jrnl" title="Science">Science</span>. 8 May 2015. 348(6235):648-660. doi:10.1126/science<br>
      PMID: 25954001
      <br>
      The paper presents an analysis of RNA seq data from 1641 samples across 43 tissues and 175 individuals to provide
      an understanding of the cellular and biological consequences of genetic variation and the heterogeneity of its
      effects across diverse human tissues. The paper catalogs thousands of tissue-specific and shared regulatory
      eQTL variants, describes complex cross-tissue and individual networks, and identifies signals from GWAS studies
      explained by eQTLs.
    </p>

    <p class="article-heading">
      <a href="http://www.sciencemag.org/content/348/6235/660.short" target="_blank">
        <em>The human transcriptome across tissues and individuals </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Melé M, Ferreira PG, Reverter F, DeLuca DS, Monlong J <em>et al</em>.<br>
      <span class="jrnl" title="Science">Science</span>. 8 May 2015. 348(6235):660-665. doi: 10.1126/science.aaa0355<br>
      The paper used RNA sequence data generated by the GTEx project to investigate the patterns of transcriptome
      variation across individuals and tissues. Gene expression varied much more across tissues than individuals,
      but genes exhibiting relatively high inter-individual variation in expression include candidates for diseases
      associated with sex, ethnicity, and age.
    </p>

    <p class="article-heading">
      <a href="http://www.sciencemag.org/content/348/6235/666.short" target="_blank">
        <em>Effect of predicted protein-truncating genetic variants on the human transcriptome </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Rivas MA, Pirinen M, Conrad DF, Lek M, Tsang EK <em>et al</em>.<br>
      <span class="jrnl" title="Science">Science</span>. 8 May 2015. 348(6235):666-669. doi:10.1126/science.1261877.<br>
      This study examines the impact of variants, that have a high probability of causing proteins to be missing
      or incomplete (Protein-truncating variants), on gene expression levels. Tissue-specific and positional effects
      on nonsense-mediated transcript decay were quantified and the paper presents an improved predictive model for
      this decay. The results illustrate the value of transcriptome data in the functional interpretation of genetic
      variants.
    </p>

    <p class="article-heading">
      <a href="http://genome.cshlp.org/content/early/2015/05/07/gr.192278.115.abstract" target="_blank">
        <em>The landscape of genomic imprinting across diverse adult human tissues </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Baran Y, Subramaniam M, Biton A, Tukiainen T, Tsang EK, Rivas MA <em>et al</em>.<br>
      <span class="jrnl" title="Genome Research">Genome Research</span>. 8 May 2015. DOI: 10.1101/gr.192278.115<br>
      This study uses allele-specific expression in the GTEx pilot data to detect parental expression
      by genomic imprinting and characterize the imprinting map across a diverse set of human tissues.
    </p>

    <p class="article-heading">
      <a href="http://dx.doi.org/10.1371/journal.pcbi.1004220" target="blank">
        <em>Sharing and specificity of co-expression networks across 35 human tissues </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      Pierson E, GTEx Consortium, Koller D, Battle A and Mostafavi S<br>
      <span class="jrnl" title="PLOS Computational Biology">PLOS Computational Biology</span>. 8 May 2015. 11(5):e1004220. DOI:10.1371/journal.pcbi.1004220<br>
      Co-expression networks provide insight into gene function, and are widely used in interpreting disease-associated
      genes and loci. This paper presents tissue-specific co-expression networks learned using expression data for 35
      human tissues from the GTEx project, enabling a refined understanding of tissue-specificity of gene function and
      regulation.  The study describes a novel method for jointly learning a set of related networks, improving accuracy
      and utility of the resulting networks.
    </p>

    <hr>

    <h4>2013</h4>
    <p class="article-heading">
      <a href="http://www.nature.com/ng/journal/v45/n6/full/ng.2653.html" target="_blank">
        <em>The Genotype-Tissue Expression (GTEx) project </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      The GTEx Consortium.<br>
      <span class="jrnl" title="Nature Genetics">Nature Genetics</span>. 29 May 2013. 45(6):580-5. doi: 10.1038/ng.2653<br>
      PMID: 23715323
    </p>

    <hr>

    <h4>2012</h4>
    <p class="article-heading">
      <a href="http://www.ncbi.nlm.nih.gov/pubmed/22539670" target="_blank">
        <em>RNA-SeQC: RNA-seq metrics for quality control and process optimization </em> <i class="fas fa-external-link-alt" /></a>
    </p>
    <p class="details">
      DeLuca DS, Levin JZ, Sivachenko A, Fennell T, Nazaire MD <em>et al</em>.<br>
      <span class="jrnl" title="Bioinformatics">Bioinformatics</span>. 1 June 2012. 28(11):1530-2. Epub 25 Apr 2012. doi: 10.1093/bioinformatics/bts196<br>
      PMID: 22539670
    </p>
  </div>
</template>
<script lang="ts">
</script>