<template>
  <div :id="tissueMenuDivId" class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <span><label><input id="check-all-tissues" name="tissueStatus" type="radio" value="checkAll"> Select all tissues</label></span>

      <span style="margin-left: 20px;"><label><input id="uncheck-all-tissues" name="tissueStatus" type="radio" value="checkNone"> Deselect all tissues</label></span>
      <hr>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <div v-for="site in tissueSites" :key="site">
        <label><input type="checkbox" class="menu-group" :value="site"> {{ site }}</label><br>
        <div v-for="subsite in tissueSubSiteDict[site]" :key="subsite">
          <label class="label-sub-level"><input :id="tissueName2Id[subsite]" type="checkbox" class="menu-item menu-sub-level" :value="subsite"> {{ subsite }}</label><br>
        </div>
        <br>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <div v-for="site in tissueSites2" :key="site">
        <label><input type="checkbox" class="menu-group" :value="site"> {{ site }}</label><br>
        <div v-for="subsite in tissueSubSiteDict[site]" :key="subsite">
          <label class="label-sub-level"><input :id="tissueName2Id[subsite]" type="checkbox" class="menu-item menu-sub-level" :value="subsite"> {{ subsite }}</label><br>
        </div>
        <br>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <div v-for="single in tissueSingleSites" :key="single">
        <label><input :id="tissueName2Id[single]" type="checkbox" class="menu-item menu-top-level" :value="single"> {{ single }} </label><br>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import {tissueMetadataJson, getTissueId} from '@/utils/tissue';
export default {
  data: function() {
    // these are the sites with subsites
    // split into two groups for the column layout
    const tissueSites = new Set(["Adipose Tissue", "Blood Vessel", "Brain"])
    const tissueSites2 = new Set(["Cervix Uteri", "Colon", "Esophagus", "Heart", "Kidney", "Skin"]); 

    const tissueMenuDivId = "tissue-menu-div-id";
    const tissueObjects = Object.values(tissueMetadataJson);
    const tissueSubSiteDict = {}; // indexed by main site
    const tissueSingleSites = [];
    const tissueName2Id = {};
    tissueObjects.forEach((o)=>{
      tissueName2Id[o.tissueSiteDetail] = o.tissueSiteDetailId
      if (tissueSites.has(o.tissueSite)){ // this is a site with subsites
        if (!tissueSubSiteDict.hasOwnProperty(o.tissueSite)) tissueSubSiteDict[o.tissueSite] = [];
        tissueSubSiteDict[o.tissueSite].push(o.tissueSiteDetail);
      } else if (tissueSites2.has(o.tissueSite)){ // this is a site with subsites
        if (!tissueSubSiteDict.hasOwnProperty(o.tissueSite)) tissueSubSiteDict[o.tissueSite] = [];
        tissueSubSiteDict[o.tissueSite].push(o.tissueSiteDetail);
      } else {
        tissueSingleSites.push(o.tissueSiteDetail);
      }
    })
    return {
      tissueSites: tissueSites,
      tissueSites2: tissueSites2,
      tissueMenuDivId: tissueMenuDivId,
      tissueObjects: tissueObjects,
      tissueSubSiteDict: tissueSubSiteDict,
      tissueSingleSites: tissueSingleSites,
      tissueName2Id
    }
  },
  mounted: function() {
    const self = this;
    $('.menu-group').click(function(){
      const parentDom = $(this).parent().parent();
      const isChecked = $(this).is(':checked');
      parentDom.find(".menu-item").each(function(){
        let item = $(this);
        if(item.is(':checked') !== isChecked) item.click(); // click will negate the current checked status
      })
    });
    $('.menu-item').click(function() {
      const tissueId = getTissueId($(this).val());
      const status = $(this).is(':checked');
      $(this).parent().toggleClass('checked-menu-item');

      if (!status) {
          $(this).parent().parent().find('.menu-group').prop('checked', false);
      } else {
          $('#uncheck-all-tissues').prop('checked', false); // if any tissue item is checked, then "uncheck-all-tissues" would be false;
      }
      const tissue = {
        status: status,
        id: tissueId
      };
      self.$emit('check-tissue', tissue);
    });
    $('input[type=radio][name=tissueStatus]').change(function(){
      if ($(this).val()=="checkAll"){
        $(`#${self.tissueMenuDivId}`).find(".menu-group").each(function(){
          const tissueGroupDom = $(this);
          console.log($(this).val());
          const isChecked = $(this).is(':checked');
          if (!isChecked) $(this).click();
        });
        $(`#${self.tissueMenuDivId}`).find(".menu-item").each(function(){
          const tissueItemDom = $(this);
          const isChecked = $(this).is(':checked');
          if (!isChecked) $(this).click();
        });
      } else {
        $(`#${self.tissueMenuDivId}`).find(".menu-group").each(function(){
          const tissueGroupDom = $(this);
          const isChecked = $(this).is(':checked');
          if (isChecked) $(this).click();
        });
        $(`#${self.tissueMenuDivId}`).find(".menu-item").each(function(){
          const tissueItemDom = $(this);
          const isChecked = $(this).is(':checked');
          if (isChecked) $(this).click();
        });
      }
    })
  }
}
</script>
<style scoped>
.checked-menu-item {
  background: aliceblue;
}

label {
  cursor: pointer;
  font-weight: normal;
  margin: 0;
  border-bottom: 1px solid transparent;
}
label:hover {
  border-bottom-color: lightblue;
}
label.label-sub-level {
  font-size: 10px;
  margin-left: 20px;
  border-bottom: 1px solid transparent;
}
label.label-sub-level:hover {
  font-size: 12px;
  border-bottom-color: lightblue;
}
</style>