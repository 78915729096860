<template>
  <div class="container-fluid">
    <h2>
      GTEx Histology Viewer <img src="@/assets/images/GTEx-histology-icon.svg" width="40px" style="margin: 0px 0px 0px 10px;">
    </h2>
    <h4>
      Find histology image by tissue sample ID
      <small><a :id="moreOptions" style="margin-left: 10px; text-decoration: underline; cursor: pointer">More search options</a></small>
      <small><a :id="hideOptions" style="margin-left: 10px; text-decoration: underline; display: none; cursor: pointer">Hide search options</a></small>
    </h4>
    <form>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <textarea :id="sampleIdInput" class="form-control" rows="2" placeholder="For example: GTEX-1117F-2426, GTEX-1117F-2526, GTEX-1117F-0126-SM-4DLZ8" />
        </div>
        <div class="col-sm-12 col-md-6">
          <button :id="searchButton" class="btn btn-md btn-info">
            Submit
          </button>
          <button :id="clearButton" class="btn btn-md btn-info">
            Clear
          </button>
          <gtex-browser-spinner :id="spinnerId" />
        </div>
      </div>

      <p />
      <div :id="advancedSearch" style="background: #f5f9ff;  padding: 5px;">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <h4>Additional search options</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-3">
            <div class="form-group">
              <label style="margin-right: 20px;">Sex</label>
              <input type="radio" name="sex" value="both" checked> both
              <input type="radio" name="sex" value="male"> male
              <input type="radio" name="sex" value="female"> female
              <br><br>
            </div>
            <div class="form-group">
              <label style="margin-right: 20px;">Age</label>
              <input type="radio" name="allAges" value="all" checked> all
              <input type="radio" name="allAges" value="reset"> reset
              <br>
              <div style="margin-left: 50px">
                <input type="checkbox" name="age20" value="20-29" class="ageBox" checked> 20-29
                <input type="checkbox" name="age30" value="30-39" class="ageBox" checked> 30-39
                <input type="checkbox" name="age40" value="40-49" class="ageBox" checked> 40-49 <br>
                <input type="checkbox" name="age50" value="50-59" class="ageBox" checked> 50-59
                <input type="checkbox" name="age60" value="60-69" class="ageBox" checked> 60-69
                <input type="checkbox" name="age70" value="70-79" class="ageBox" checked> 70-79
                <br><br>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-4">
            <div class="form-group">
              <label style="margin-right: 20px;">Hardy Scale</label>
              <input type="radio" name="allHardyScale" value="all" checked> all
              <input type="radio" name="allHardyScale" value="reset"> reset
              <br>
              <div style="margin-left: 104px;">
                <input type="checkbox" name="hs2" value="Fast death.*violent" class="hardyScale" checked> Fast death - violent <br>
                <input type="checkbox" name="hs1" value="Fast death.*natural" class="hardyScale" checked> Fast death - natural causes <br>
                <input type="checkbox" name="hs3" value="Intermediate" class="hardyScale" checked> Intermediate death <br>
                <input type="checkbox" name="hs4" value="Slow" class="hardyScale" checked> Slow death <br>
                <input type="checkbox" name="hs5" value="Ventilator" class="hardyScale" checked> Ventilator case <br>
              </div>
            </div>
            <p />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4">
            <div class="form-group">
              <label>Tissue</label><br>
              <select :id="tissueInput" class="form-control" multiple>
                <option v-for="(t, i) in tissueNames" :key="i" :value="i">
                  {{ t }}
                </option>
              </select>
            </div>

            <p />
            <div class="form-group">
              <label>Pathology Category</label><br>
              <select :id="pathologyInput" class="form-control" multiple>
                <option v-for="(p, i) in pathologyCats" :key="i" :value="i">
                  {{ p.charAt(0).toUpperCase() + p.slice(1).replace(/_/g, ' ') }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
    <hr>
    <h4>
      All Histology Samples <small id="tableMessage" style="color:black">(Click on a sample to view its histology image)</small>
    </h4>
    <table :id="sampleTableId" class="stripe nowrap compact hover order-column row-border" style="cursor: pointer; font-size: 12px;">
      <!-- the histology sample table-->
    </table>
    <hr>
    <div :id="imageMenu" style="display:none;">
      <button :id="singleImageMode" class="btn btn-sm btn-dark" style="margin-right: 5px;">
        Single Image Mode
      </button>
      <button :id="multiImageMode" class="btn btn-sm btn-inactive">
        Mutli-Image Mode
      </button>
      <small>(When in Multi-Image Mode, multiple rows from the sample table may be selected and viewed.)</small>
    </div>

    <div :id="seadragonViewport" class="row" />
  </div>
</template>
<script>
import { directory } from '@/utils/portal';
import { tissueLookupTable } from '@/utils/tissue';
import MessageHandler from '@/utils/message-handler';
import { RetrieveAllPaginatedData } from '../utils/pagination';
import GtexBrowserSpinner from './GtexBrowserSpinner.vue';
export default {
  components: { GtexBrowserSpinner },
    data: function() {
        const tissueNames = Object.keys(tissueLookupTable).sort();
        const pathologyCats = [
            'adenoma',
            'amylacea',
            'atelectasis',
            'atherosclerosis',
            'atherosis',
            'atrophy',
            'calcification',
            'cirrhosis',
            'clean_specimens',
            'congestion',
            'corpora_albicantia',
            'cyst',
            'desquamation',
            'diabetic',
            'dysplasia',
            'edema',
            'emphysema',
            'esophagitis',
            'fibrosis',
            'gastritis',
            'glomerulosclerosis',
            'goiter',
            'gynecomastoid',
            'hashimoto',
            'heart_failure_cells',
            'hemorrhage',
            'hepatitis',
            'hyalinization',
            'hypereosinophilia',
            'hyperplasia',
            'hypertrophy',
            'hypoxic',
            'infarction',
            'inflammation',
            'ischemic_changes',
            'macrophages',
            'mastopathy',
            'metaplasia',
            'monckeberg',
            'necrosis',
            'nephritis',
            'nephrosclerosis',
            'no_abnormalities',
            'nodularity',
            'pancreatitis',
            'pigment',
            'pneumonia',
            'post_menopausal',
            'prostatitis',
            'saponification',
            'scarring',
            'sclerotic',
            'solar_elastosis',
            'spermatogenesis',
            'steatosis',
            'sweat_glands',
            'tma'
        ];
        return {
            spinnerId: 'histology-spinner',
            // DOM IDs
            searchButton: 'searchButton',
            clearButton: 'clearButton',
            sampleIdInput: 'sampleIdInput',
            tissueInput: 'tissueInput',
            pathologyInput: 'pathologyInput',
            searchExample: 'searchExample',
            moreOptions: 'moreOptions',
            hideOptions: 'hideOptions',
            advancedSearch: 'advancedSearch',
            seadragonViewport: 'seadragon',
            tissues: 'tissues',
            sampleTableId: 'sampleDataTable',
            imageMenu: 'imageMenu',
            singleImageMode: 'singleImageMode',
            multiImageMode: 'multiImageMode',

            // Data
            imageMode: 1,
            tissueNames: tissueNames,
            pathologyCats: pathologyCats,
            prefixUrl: 'images/openSeadragon/', // the image directory of OpenSeadragon's menu buttons
            dziUrlRoot: process.env.VUE_APP_API_SERVER === 'production'?
                'https://gtexportal.org/openslide/gtexhip/' : 'https://test.gtexportal.org/openslide/gtexhip/',
            dataFilters: {},

            // URL params
            tissueSampleId: this.$route.params.tissueSampleId||undefined
        };
    },
    mounted: function() {
    // initialize search form
        $('select#' + this.tissues).select2({
            placeholder: 'Select Tissue...'
        });
        this.bindEvents();
        this.initSampleTable();
        console.log(this.tissueSampleId);
    },
    methods: {
        // displays the spinner
        showSpinner() {
            document.getElementById(this.spinnerId).style.opacity = 1;
        },
        // hides the spinner
        hideSpinner() {
            document.getElementById(this.spinnerId).style.opacity = 0;
        },
        bindEvents() {
            const self = this;

            // tissue selector
            $('select#' + this.tissueInput).select2({
                placeholder: 'Select Tissues...',
                closeOnSelect: false
            });

            // pathology category selector
            $('select#' + this.pathologyInput).select2({
                placeholder: 'Select Pathology Categories...',
                closeOnSelect: false
            });

            // advanced search filter
            const __filter = event => {
                event.preventDefault();

                // sample ID input

                const input = $('#' + self.sampleIdInput).val().replace(/\s+/g, ',').replace(/,+/g, '|');
                const terms = input.split('|').map(d => {
                    const temp = d.split('-');
                    return temp.slice(0, 3).join('-');
                }).join('|');
                self.dataFilters[1] = terms==''?undefined:terms;
                if (self.dataFilters[1] !== undefined) { // when searching images by sample IDs, clear all other search criteria
                    [2, 3, 4, 5, 6, 7].forEach(i => {
                        self.dataFilters[i] = ''; // clear all other data filters when searching tissue sample IDs
                    });
                    self.filterTable();
                    return; // sample IDs are the most specific search terms, no need to process the other terms
                }
                self.dataFilters[1] = ''; // erase the ID search.

                // filter by sex
                const sex = $('input[name="sex"]:checked').val();
                switch (sex) {
                    case 'both':
                        self.dataFilters[4] = ''; // in regex, male would match both female and male
                        break;
                    case 'male':
                        self.dataFilters[4] = '^male';
                        break;
                    case 'female':
                        self.dataFilters[4] = 'female';
                        break;
                    default:
                }

                // filter by age
                const ages = [];
                $('.ageBox').each(function() {
                    if ($(this).is(':checked')) ages.push($(this).val());
                    if (ages.length < 6) $('input[name="allAges"]').prop('checked', false);
                    if (ages.length == 6) $('input[name="allAges"][value="all"]').prop('checked', true);
                });
                self.dataFilters[5] = ages.length == 6?'':ages.join('|');

                // filter by hardy scale
                const hardyScales = [];
                $('.hardyScale').each(function() {
                    if ($(this).is(':checked')) hardyScales.push($(this).val());
                    if (hardyScales.length < 5) $('input[name="allHardyScale"]').prop('checked', false);
                    if (hardyScales.length == 5) $('input[name="allHardyScale"][value="all"]').prop('checked', true);
                });
                self.dataFilters[6] = hardyScales.length == 5? '':hardyScales.join('|');

                // filter by tissue
                if ($('#' + self.tissueInput).val() == null) {
                    self.dataFilters[2] = '';
                } else {
                    const tissues = $('#' + self.tissueInput).val().map(i => self.tissueNames[parseInt(i)]);
                    self.dataFilters[2] = tissues.join('|');
                }

                // filter by pathology note
                if ($('#' + self.pathologyInput).val() == null) {
                    self.dataFilters[7] = '';
                } else {
                    const cats = $('#' + self.pathologyInput).val().map(i => self.pathologyCats[parseInt(i)]);
                    self.dataFilters[7] = cats.join('|');
                }

                self.filterTable();
            };

            $('#' + this.searchButton).click(__filter);
            $('#' + this.clearButton).click((e => {
                e.preventDefault();
                $('#' + self.sampleIdInput).val('');
                __filter(e);
            }));
            $('#' + this.sampleIdInput).keydown(e => {
                if (e.keyCode == 13) __filter(e);
            });

            $('input[name="sex"]').change(__filter);
            $('.ageBox').each(function() {
                $(this).change(__filter);
            });
            $('input[name="allAges"]').change(function(e) {
                const val = $(this).val();
                switch (val) {
                    case 'all': {
                        $('.ageBox').prop('checked', true);
                        __filter(e);
                        break;
                    }
                    case 'reset': {
                        $('.ageBox').prop('checked', false);
                        break;
                    }
                    default:
                        // do nothing
                }
            });
            $('.hardyScale').each(function() {
                $(this).change(__filter);
            });
            $('input[name="allHardyScale"]').change(function(e) {
                const val = $(this).val();
                switch (val) {
                    case 'all': {
                        $('.hardyScale').prop('checked', true);
                        __filter(e);
                        break;
                    }
                    case 'reset': {
                        $('.hardyScale').prop('checked', false);
                        break;
                    }
                    default:
                        // do nothing
                }
            });

            $('#' + this.tissueInput).on('select2:select', e => {
                __filter(e);
            });
            $('#' + this.tissueInput).on('select2:unselect', e => {
                __filter(e);
            });
            $('#' + this.pathologyInput).on('select2:select', e => {
                __filter(e);
            });
            $('#' + this.pathologyInput).on('select2:unselect', e => {
                __filter(e);
            });


            // control the display of the search with more options
            $('#' + this.moreOptions).click(() => {
                $('#' + this.advancedSearch).show();
                $('#' + this.hideOptions).show();
                $('#' + this.moreOptions).hide();
            });

            $('#' + this.hideOptions).click(() => {
                $('#' + this.advancedSearch).hide();
                $('#' + this.moreOptions).show();
                $('#' + this.hideOptions).hide();
            });
            $('#' + this.advancedSearch).hide();

            /*
             * control the image display mode
             * $("<div/>").attr("id", "warning").appendTo("#" + this.seadragonViewport)
             */

            $('#' + this.singleImageMode).click(() => {
                $('#' + this.multiImageMode).removeClass('btn-dark');
                $('#' + this.multiImageMode).addClass('btn-inactive');
                $('#' + this.singleImageMode).addClass('btn-dark');
                $('#' + this.singleImageMode).removeClass('btn-inactive');
                this.imageMode = 1;
                $('#viewport').show();
                $('.multi').hide();

                const row = $('#'+this.sampleTableId + ' .selected');
                const rowData = $('#'+this.sampleTableId).DataTable().row('.selected').data();
                this.render(row, rowData); // if there is no image rendered in single image mode, then render the last seen image in multi-image mode.
            });
            $('#' + this.multiImageMode).click(() => {
                $('#' + this.singleImageMode).removeClass('btn-dark');
                $('#' + this.singleImageMode).addClass('btn-inactive');
                $('#' + this.multiImageMode).addClass('btn-dark');
                $('#' + this.multiImageMode).removeClass('btn-inactive');
                this.imageMode = 2;
                $('#viewport').hide();
                $('.multi').show();
                const row = $('#'+this.sampleTableId + ' .selected');
                const rowData = $('#'+this.sampleTableId).DataTable().row('.selected').data();
                this.render(row, rowData); //if there is no images in the multi-image mode, render the last seen image in single image mode
            });
        },

        filterTable() {
            const self = this;
            console.log(this.dataFilters);
            Object.keys(this.dataFilters).forEach(c => {
                const terms = self.dataFilters[c];
                if (terms === undefined) return;
                $('#' + self.sampleTableId).DataTable().column(parseInt(c)).search(
                    terms,
                    true, // regex
                    false // smart search
                ).draw();
            });
        },

        render(row, rowData) {
            // update main data table--mark this sample as viewed
            const table = $('#' + this.sampleTableId).DataTable();
            const data = table.row(row).data();
            data.viewed = '<i class="far fa-check-circle"></i>';
            table.row(row).data(data);

            // allow user to pick single or multi-image mode
            $('#' + this.imageMenu).show();

            const tsId = rowData.histologyImageId;
            const subject = rowData.subjectId;
            const dziPath = this.dziUrlRoot + subject + '/' + tsId + '.dzi';
            const imageDivId = this.imageMode == 1? 'viewport':tsId;

            if ($('#'+imageDivId).length == 0) {
                /*
                 * if the DOM has not been created
                 * create the DOM and its child elements
                 */
                const myDiv = $('<div/>')
                    .attr('id', imageDivId)
                    .attr('class', this.imageMode == 1 ? 'col-sm-12 col-md-12 col-lg-11' : 'col-sm-12 col-md-5 multi')
                    .css('margin', this.imageMode==1?'5px 200px 20px 15px':'5px')
                    .css('border', '1px solid #d5d5d5');


                if (this.imageMode == 1) myDiv.appendTo($('#' + this.seadragonViewport));
                else $('#' + this.seadragonViewport).prepend(myDiv); // always add to the front of the image list

                const scale = 0.95;
                const innerWindowWidth = myDiv.innerWidth() * scale;

                const infoDiv = $('<div/>')
                    .attr('id', imageDivId + '-info')
                    .css('font-size', this.imageMode==1?'14px':'12px')
                    .css('border-bottom', '1px solid #d1d1d1')
                    .css('padding-bottom', '5px')
                    .css('margin-bottom', '5px')
                    .appendTo(myDiv);

                const toolbarDiv = $('<div/>')
                    .attr('id', imageDivId + '-toolbar')
                    .css('font-size', this.imageMode==1?'14px':'12px')
                    .appendTo(myDiv);

                const imageDiv = $('<div/>')
                    .attr('id', imageDivId + '-osd')
                    .css('height', innerWindowWidth*0.75)
                    .css('width', innerWindowWidth)
                    .css('background-color', 'black')
                    .css('border', '1px solid white')
                    .appendTo(myDiv);
            }

            // clear all previous content
            $('#' + imageDivId + '-info').empty();
            $('#' + imageDivId+ '-osd').empty();
            $('#' + imageDivId + '-toolbar').empty();

            // create the information table
            if (this.imageMode == 2) {
                const closeButton = $('<div/>')
                    .html('<i class="far fa-times-circle"></i> close<br/>')
                    .css('cursor', 'pointer')
                    .css('float', 'right')
                    .css('margin-left', '20px')
                    .appendTo('#' + imageDivId + '-info'); // add the image close icon

                // bind the close button click event
                closeButton.unbind('click'); // unbind previously defined click events
                closeButton.click(() => {
                    $('#'+imageDivId).remove();
                });
            }

            // customize OpenSeadragon toolbar
            const homeButton = $('<a/>')
                .attr('id', imageDivId + '-home')
                .html('<i class="fas fa-home"></i> Home | ')
                .css('cursor', 'pointer')
                .css('margin-right', '5px')
                .appendTo('#' + imageDivId + '-toolbar');

            const fullpageButton = $('<a/>')
                .attr('id', imageDivId + '-fullpage')
                .html('<i class="fas fa-expand-arrows-alt"></i> Full Screen | ')
                .css('cursor', 'pointer')
                .css('margin-right', '5px')
                .appendTo('#' + imageDivId + '-toolbar');

            const zoominButton = $('<a/>')
                .attr('id', imageDivId + '-zoomin')
                .html('<i class="fas fa-search-plus"></i> Zoom In | ')
                .css('cursor', 'pointer')
                .css('margin-right', '5px')
                .appendTo('#' + imageDivId + '-toolbar');

            const zoomoutButton = $('<a/>')
                .attr('id', imageDivId + '-zoomout')
                .html('<i class="fas fa-search-minus"></i> Zoom Out | ')
                .css('cursor', 'pointer')
                .css('margin-right', '5px')
                .appendTo('#' + imageDivId + '-toolbar');

            const downloadButton = $('<a/>')
                .attr('id', imageDivId + '-download')
                .html('<i class="fas fa-download"></i> Current Zoom |')
                .css('cursor', 'pointer')
                .css('margin-right', '5px')
                .appendTo('#' + imageDivId + '-toolbar'); // add the download icon

            const downloadOriginalButton = $('<a/>')
                .attr('id', imageDivId + '-aperio')
                .html('<a href="https://brd.nci.nih.gov/brd/imagedownload/' + tsId + '"><i class="fas fa-download"></i> Aperio Image </a>')
                .css('cursor', 'pointer')
                .css('margin-right', '5px')
                .appendTo('#' + imageDivId + '-toolbar'); // add the download icon

            const sampleInfo = '<b>Sample</b>: ' + tsId;
            const donorInfo = '<b>Donor</b>: ' + (rowData.sex=='female'?'Female':'Male') + ', ' + rowData.ageBracket;
            const tissueInfo = '<b>Tissue</b>: ' + rowData.tissueSiteDetail;
            const pathInfo = '<b>Pathology Categories</b>: ' + (Object.keys(rowData.pathologyNotesCategories).join(', '));
            const pathNote = '<b>Pathology Notes</b>: ' + rowData.pathologyNotes;
            [sampleInfo, tissueInfo, donorInfo, pathInfo, pathNote].forEach(text => {
                $('<div/>')
                    .html(text)
                    .appendTo('#' + imageDivId + '-info');
            }); // add sample info
            //

            // run openSeadragon
            $('#' + imageDivId).show();
            const viewer = OpenSeadragon({
                id: imageDivId + '-osd',
                prefixUrl: this.prefixUrl,
                tileSources: dziPath,
                showNavigator: true,
                crossOriginPolicy: 'Anonymous',
                toolbar: imageDivId + '-toolbar',
                zoomOutButton: imageDivId + '-zoomout',
                zoomInButton: imageDivId + '-zoomin',
                fullPageButton: imageDivId + '-fullpage',
                homeButton: imageDivId + '-home'
            });

            /*
             * viewer.buttons.buttons.push(downloadButton);
             * viewer.buttons.element.appendChild(downloadButton.element);
             */

            // bind the download button click event
            downloadButton.unbind('click'); // unbind any previously defined click events
            downloadButton.click(() => {
                const callback = blob => { saveAs(blob, tsId + '.png'); };
                const blob = viewer.drawer.canvas.toBlob(callback);
            });
        },

        initSampleTable() {
            // initialize the sample table
            const self = this;
            const columnProps= [
                {
                    title: 'Viewed',
                    defaultContent: '',
                    data: 'viewed'
                },
                {
                    title: 'Tissue Sample ID',
                    defaultContent: '',
                    render: function(data, type, row) {
                        return row.tissueSampleId||row.histologyImageId;
                    }
                },
                {
                    title: 'Tissue',
                    data: 'tissueSiteDetail'
                },
                {
                    title: 'Subject ID',
                    data: 'subjectId'
                },
                {
                    title: 'Sex',
                    data: 'sex'
                },
                {
                    title: 'Age Bracket',
                    data: 'ageBracket'
                },
                {
                    title: 'Hardy Scale',
                    data: 'hardyScale'
                },
                {
                    title: 'Pathology Categories',
                    defaultContent: '',
                    render: function(data, type, row) {
                        return Object.keys(row.pathologyNotesCategories).filter(c => row.pathologyNotesCategories[c]).join(', ');
                    }
                },
                {
                    title: 'Pathology Notes',
                    data: 'pathologyNotes'
                }
            ];
            const buttonProps = {
                'buttons': [{
                    extend: 'copy',
                    exportOptions: { columns: [1, 2, 3, 4, 5, 6, 7, 8] }
                },
                {
                    extend: 'csv',
                    exportOptions: { columns: [1, 2, 3, 4, 5, 6, 7, 8] }
                }]
            };

            try {
                self.showSpinner();
                RetrieveAllPaginatedData(directory.getHistologySampleUrl().url, 10000).then(
                    retrieved_data => {
                        $('#' + self.sampleTableId).dataTable({
                            // searching: false,
                            deferRender: true,
                            destroy: true,
                            retrieve: true,
                            jQueryUI: true,
                            paginationType: 'full_numbers',
                            columns: columnProps,
                            dom: 'B<"clear">lfrtip',
                            orderClasses: false,
                            buttons: buttonProps,
                            language: {
                                emptyTable: 'Please wait - Fetching records'
                            },
                            data: retrieved_data,
                            fnInitComplete: function() {
                                self.hideSpinner();
                                // tasks to execute after the data table is loaded
                                if (self.tissueSampleId !== undefined) {
                                    $('#' +self.sampleIdInput).val(self.tissueSampleId);
                                    $('#' + self.searchButton).trigger('click');
                                    $('#' + self.sampleTableId+ ' tbody tr:first').trigger('click');
                                }
                            },
                            sorting: [[1, 'asc']], // sort by gene symbol,
                            'aLengthMenu': [[5, 10, 15, 20], [5, 10, 15, 20]], // customize options of show entries
                            'pageLength': 5 // define default page length

                        });
                        $('#' + self.sampleTableId + ' tbody').on('mouseover', 'tr', () => {
                            $('#tableMessage').css('color', '#ef0807');
                        });
                        $('#' + self.sampleTableId + ' tbody').on('mouseout', 'tr', () => {
                            $('#tableMessage').css('color', '#000000');
                        });

                        $('#' + self.sampleTableId + ' tbody').on('click', 'tr', (event) => {
                            const $tr = $(event.target).closest('tr');
                            if ($tr.hasClass('selected')) {
                                $tr.removeClass('selected');
                            } else {
                                $('#' + self.sampleTableId).DataTable().$('tr.selected').removeClass('selected');
                                $tr.addClass('selected');
                                const rowData = $('#'+this.sampleTableId).DataTable().row('.selected').data();
                                self.render($tr, rowData);
                            }
                        });
                        $('#sampleDataTable_filter').hide(); // hide the Data Table's search box
                    }
                );
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err + ' Unable to create table for Histology Samples');
            }
        }
    }
};
</script>
