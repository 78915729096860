<template>
  <div class="container-fluid">
    <h2>GTEx Workshop at ASHG 2017</h2>
    <p>
      The GTEx Portal team hosted a workshop at the American Society of Human Genetics (ASHG) 2017 Annual Meeting
      in Orlando, FL.
    </p>
    <b>Workshop Title:</b> GTEx Portal: A Resource for Human Gene Expression and Regulation
    <br>
    <b>Date:</b> Thursday, October 19, 2017
    <br>
    <b>Time:</b> 12:45 PM - 1:45 PM
    <br><br>
    <b>Workshop Description:</b> The Genotype-Tissue Expression (GTEx) project is a comprehensive public resource to
    study tissue-specific gene expression and regulation. The GTEx Portal provides open access to RNA-Seq and
    genotyping data from 53 tissues on 714 postmortem donors. The Portal also provides analysis results, such as
    eQTLs, across all tissues. This workshop will provide an overview of the GTEx project and a demonstration of the
    GTEx Portal. The workshop will also include a discussion of the GTEx data release policy and the process for
    requesting controlled-access data.
    <br><br>
    <b>Workshop Agenda:</b>
    <ul>
      <li>Introduction to the GTEx Project</li>
      <li>Overview of the GTEx Portal</li>
      <li>GTEx Data Release Policy</li>
      <li>Requesting Controlled-Access Data</li>
      <li>Questions and Discussion</li>
    </ul>
    <b>Workshop Materials</b>
    <ul>
      <li><a href="https://storage.googleapis.com/gtex-public-data/ashg-workshop-files/GTEx_ASHG_workshop_101817_final.pdf"
             target="_blank"> Workshop Presentation <i class="fas fa-external-link-alt"/></a>
      </li>
      <li><a href="https://storage.googleapis.com/gtex-public-data/ashg-workshop-files/GTEx_ASHG17_workshop.ipynb" target="_blank">
        Workshop Jupyter Notebook <i class="fas fa-external-link-alt"/></a>
      </li>
      <li><a href="https://storage.googleapis.com/gtex-public-data/ashg-workshop-files/data.zip" target="_blank">
        Workshop Data <i class="fas fa-external-link-alt"/></a>
      </li>
    </ul>
    <b>Resources:</b>
    <ul>
      <li><router-link to="/documentationPage">GTEx Documentation</router-link></li>
      <li><router-link to="/datasets">GTEx Data</router-link></li>
      <li><router-link to="/faq">GTEx FAQs</router-link></li>
      <li><router-link to="/methods">GTEx Lab and Analysis Methods</router-link></li>
    </ul>
  </div>
</template>
