import {createMemoryHistory, createRouter, createWebHistory} from 'vue-router'
import HomePage from '@/components/HomePage.vue';
import MultiGeneQueryPage from '@/components/MultiGeneQueryPage.vue';
import MultiGeneSingleCellQueryPage from '@/components/MultiGeneSingleCellQueryPage.vue';
import BiobankRequestPage from '@/components/BiobankRequestPage.vue';
import BiobankSamplesPage from '@/components/BiobankSamplesPage.vue';
import ContactPage from '@/components/ContactPage.vue';
import DatasetsDownloadPage from '@/components/DatasetsDownloadPage.vue';
import DatasetSummaryPage from '@/components/DatasetSummaryPage.vue';
import dGTExInfoPage from '@/components/dGTExInfoPage.vue';
import DocumentationPage from '@/components/DocumentationPage.vue';
import DocumentationAPIPage from '@/components/DocumentationAPIPage.vue';
import EqtlCalculatorPage from '@/components/EqtlCalculatorPage.vue';
import EqtlDashboardPage from '@/components/EqtlDashboardPage.vue';
import EqtlsPage from '@/components/EqtlsPage.vue';
import ExpressionPcaPage from '@/components/ExpressionPcaPage.vue';
import FaqPage from '@/components/FaqPage.vue';
import GenePage from '@/components/GenePage.vue';
import TissuePage from '@/components/TissuePage.vue';
import HistologyViewerPage from '@/components/HistologyViewerPage.vue';
import IgvEqtlBrowserPage from '@/components/IgvEqtlBrowserPage.vue';
import ImprintingPage from '@/components/ImprintingPage.vue';
import LocusBrowserPage from '@/components/LocusBrowserPage.vue';
import LocusBrowserVCPage from '@/components/LocusBrowserVCPage.vue';
import NewsPage from '@/components/NewsPage.vue';
import ProtectedDataAccessPage from '@/components/ProtectedDataAccessPage.vue';
import PtvSummaryPage from '@/components/PtvSummaryPage.vue';
import PublicDownloadsPage from '@/components/PublicDownloadsPage.vue';
import PublicationsPage from '@/components/PublicationsPage.vue';
import ReleaseInfoPage from '@/components/ReleaseInfoPage.vue';
import SamplingSitePage from '@/components/SamplingSitePage.vue';
import SingleCellOverviewPage from '@/components/SingleCellOverviewPage.vue';
import SnpPage from '@/components/SnpPage.vue';
//import SpliceQtlPage from '@/components/SpliceQtlPage.vue';
import TopExpressedGenesPage from '@/components/TopExpressedGenesPage.vue';
import TranscriptPage from '@/components/TranscriptPage.vue';
//import V8ReleasePage from '@/components/V8ReleasePage.vue';
import VideoTutorialsPage from '@/components/VideoTutorialsPage.vue';
import MethodsPage from '@/components/MethodsPage.vue';
import EuiPage from '@/components/EuiPage.vue';
import PrivacyPolicyPage from '@/components/PrivacyPolicyPage.vue';
import LicensePage from '@/components/LicensePage.vue';
import WorkshopPage from '@/components/WorkshopPage.vue';
import dGtexAboutUs from '@/components/dGtexAboutUs.vue';
import nhpDGtexAboutUs from '@/components/nhpDGtexAboutUs.vue';


import { portalClient } from '../utils/portal.js';
import { tissueMetadataJson } from '../utils/tissue.js';
import AboutAdultGTExPage from "../components/AboutAdultGTExPage.vue";





const routes = [
    { name: 'homePage', component: HomePage, path: '/' },
        { name: 'samplingSitePage', component: SamplingSitePage, path: '/samplingSitePage' },
        {
            name: 'multiGeneQueryPage', component: MultiGeneQueryPage, path: '/multiGeneQueryPage/:genes?',
            params: {
                genes: undefined
            }

        },
        {
            name: 'multiGeneSingleCellQueryPage', component: MultiGeneSingleCellQueryPage, path: '/multiGeneSingleCellQueryPage/:genes?',
            params: {
                genes: undefined
            }

        },
        { name: 'aboutAdultGTExPage', component: AboutAdultGTExPage, path: '/aboutAdultGtex' },
        { path: '/aboutGtex', redirect: '/aboutAdultGtex' },
        { name: 'biobankRequestPage', component: BiobankRequestPage, path: '/biobankRequestCart' },
        { name: 'biobankSamplesPage', component: BiobankSamplesPage, path: '/biobank' },
        { name: 'contactPage', component: ContactPage, path: '/contact' },
        { name: 'datasetsDownloadPage', component: DatasetsDownloadPage, path: '/datasets', redirect: '/downloads/:project?' },
        { name: 'publicDownloadsPage',
            component: PublicDownloadsPage,
            path: '/downloads/:project?/:tab?',
            props: true,
        },
        { name: 'datasetSummaryPage', component: DatasetSummaryPage, path: '/tissueSummaryPage' },
        { name: 'dGTExPage', component: dGTExInfoPage, path: '/dGTExPage' },

        { name: 'documentationPage', component: DocumentationPage, path: '/documentationPage', redirect: '/aboutAdultGtex'},
        { name: 'documentationAPIPage', component: DocumentationAPIPage, path: '/apiPage' },

        { name: 'eqtlCalculatorPage', component: EqtlCalculatorPage, path: '/testyourown' },
        { name: 'eqtlDashboardPage', component: EqtlDashboardPage, path: '/eqtlDashboardPage' },
        {
            // TODO: condense this into one? -Duyen 10/02/18
            name: 'eqtlsByGenePage', component: EqtlsPage, path: '/eqtls/byGene',
            query: {
                tissueName: '',
                geneId: ''
            },
            params: {
                tissueDisplayName: undefined,
                count: undefined,
                type: 'Gene'
            }
        },
        {
            // TODO: condense this into one? -Duyen 10/02/18
            name: 'eqtlsBySnpPage', component: EqtlsPage, path: '/eqtls/bySnp',
            query: {
                tissueName: '',
                snpId: '',
                variantId: ''
            },
            params: {
                tissueDisplayName: undefined,
                count: undefined,
                //type: 'rsId' || 'chromPos'
            }
        },
        { name: 'expressionPcaPage', component: ExpressionPcaPage, path: '/expressionPca' },
        { name: 'faqPage', component: FaqPage, path: '/faq' },
        {
            name: 'locusBrowserPage', component: LocusBrowserPage, path: '/locusBrowserPage/:geneId?',
            params: { geneId: '' }
        },
        {
            name: 'locusBrowserVCPage', component: LocusBrowserVCPage, path: '/locusBrowserVCPage/:variantId?',
            //params: { variantId: '' }
            redirect: { name: 'homePage' } // redirect to home page since this page is depracated
        },
        {
            name: 'methodsPage', component: MethodsPage, path: '/methods'
        },
        {
            name: 'genePage', component: GenePage, path: '/gene/:geneId',
            params: { geneId: undefined }
        },
        {
            name: 'tissuePage', component: TissuePage, path: '/tissue/:tissueName?', props: true
        },
        {
            name: 'histologyViewerPage', component: HistologyViewerPage, path: '/histologyPage/:tissueSampleId?',
            query: { tissueSampleId: undefined }
        },
        {
            name: 'EuiPage', component: EuiPage, path: '/eui'
        },
        {
            name: 'igvEqtlBrowserPage', component: IgvEqtlBrowserPage, path: '/browseEqtls',
            query: {
                // location can be chromosomal location, snpId, or geneId
                location: undefined,
                tissueSiteDetailId: undefined
            }
        },
        { name: 'imprintingPage', component: ImprintingPage, path: '/imprintingPage' },
        {
            name: 'newsPage', component: NewsPage, path: '/home/news',
            query: { id: '' }
        },
        { name: 'protectedDataAccessPage', component: ProtectedDataAccessPage, path: '/protectedDataAccess' },
        { name: 'ptvSummaryPage', component: PtvSummaryPage, path: '/ptvSummary' },
        { name: 'publicationsPage', component: PublicationsPage, path: '/publicationsPage' },
        { name: 'releaseInfoPage', component: ReleaseInfoPage, path: '/releaseInfoPage' },
        {
            name: 'snpPage', component: SnpPage, path: '/snp/:snpId',
            params: { snpId: undefined, type: undefined }
        },
        { name: 'singleCellOverviewPage', component: SingleCellOverviewPage, path: '/singleCellOverviewPage' },
        { name: 'topExpressedGenesPage', component: TopExpressedGenesPage, path: '/topExpressedGenePage' },
        { name: 'transcriptPage', component: TranscriptPage, path: '/transcriptPage' },
        { name: 'videoTutorialsPage', component: VideoTutorialsPage, path: '/videos' },
        /* REDIRECTS LISTED BELOW */
        { path: '/:pathMatch(.*)*', redirect: { name: 'homePage' } }, // in lieu of 404s
        { path: '/v8ReleasePage', redirect: { name: 'protectedDataAccessPage' } },
        { path: '/anatomogramPage', redirect: { name: 'samplingSitePage' } },
        { path: '/samplesPage', redirect: { name: 'biobankSamplesPage' } },
        {
            /*
             * in case someone references the GEV page externally, it can just be redirected to the locus browser,
             * which now replaces GEV
             */
            path: '/bubbleHeatmapPage/:geneId?', redirect: '/locusBrowserPage/:geneId?',
            params: { geneId: '' }
        },
        {
            name: 'privacyPolicyPage', component: PrivacyPolicyPage, path: '/privacy'
        },
        {
            name: 'licensePage', component: LicensePage, path: '/license'
        },
        {
            name: 'workshopPage', component: WorkshopPage, path: '/workshopPage'
        },
        {
            name: 'dGtexAboutUs',
            component: dGtexAboutUs,
            path: '/aboutDGtex'
        },
        {
            name: 'nhpDGtexAboutUs',
            component: nhpDGtexAboutUs,
            path: '/aboutNhpDGtex'
        }
]

const router = createRouter({
  history: createWebHistory("/home/"),
  routes,
        scrollBehavior: function(to, from, savedPosition) {
        /*
         * this allows us to deal with page anchors properly
         * NOTE: this function is not triggered on initial load
         */
        if (to.name === 'publicDownloadsPage' && to.hash == "" || !to.hash) {
            return { x: 0, y: 0 };
        }
        else if (to.hash) {
            return { selector: to.hash };
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
})

router.beforeEach((to, from, next) => {
    const wait = function() {
        /*
         * TODO: remove all these global variable dependencies if possible?
         * TODO: figure out how to allow direct user navigation to pages requiring sign-in w/ AJAX sign in process -Duyen 10/03/18
         */
        if (portalClient === undefined || tissueMetadataJson === undefined) setTimeout(wait, 100);
        else next();
    };
    wait();

    // if the directed route is only the adult-gtex or egtex project datasets
    switch (to.path) {
        case '/downloads/adult-gtex':
            next('/downloads/adult-gtex/overview');
            break;
        case '/downloads/egtex':
            next('/downloads/egtex/methylation');
            break;
    }
});

router.beforeEach((to, from, next) => {
    /*
     * cleaning up tooltips added to body by visualizations
     * -- the components are rendered within a div in the body
     * so page routing will not remove these itself
     */
    $('.heatmap-tooltip').remove();
    $('.violin-tooltip').remove();

    // Remove all HTML <head> injections from EUI page
    if (from.path === '/eui') {
        document.head.removeChild(document.head.getElementsByTagName('base')[0]);
    }
    next();
});



router.afterEach((to, from) => {
    /*
     * to.name === 'homePage'? $('#logo').show() : $('#logo').hide();
     * to.name === 'homePage'||to.name.startsWith('newHome')? $('#footer-links').show() : $('#footer-links').hide();
     * dealing with page anchors on direct navigation, since scrollBehavior isn't triggered
     */
    if (!from.name && to.hash) {
        setTimeout(() => { location.href = to.hash; }, 100);
    }
    const trackPathName = to.name;
    let trackPath;
    if (trackPathName == 'homePage') {
        trackPath = to.path;
    } else {
        trackPath = to.path.replace('/home', '');
    }
    trackPath = removeExcessPageLocationURL(trackPath);
    trackPath = removeTrailingSlash(trackPath);
    if (!(from.name === 'publicDownloadsPage' && to.name === 'publicDownloadsPage')) {
        //sendGoogleAnalyticsPageview(trackPath, trackPathName);
    }

});


function removeTrailingSlash(inputString) {
    /*
    Removes a trailing slash from the input string, but not if the input string is just '/'.
     */
    let returnVal = inputString;
    if (inputString !== '/' && inputString.charAt(inputString.length - 1) === '/') {
        returnVal = inputString.substring(0, inputString.length - 1);
    }
    return returnVal;
}

function removeExcessPageLocationURL(inputString) {
    /*
    If the input string does not start with "/", the url starts with "https" and will mess up
    GA4's analytics. So we will remove everything from the beginning of the URL to the end of
    "/home"
     */
    if (inputString.charAt(0) !== '/') {
        const substringLocation = inputString.lastIndexOf('/home') + '/home'.length;
        return inputString.substring(substringLocation);
    }
    return inputString;
}

export default router;

