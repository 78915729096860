/*
 * Copyright � 2013 The Broad Institute, Inc.
 * SOFTWARE COPYRIGHT NOTICE
 * This software and its documentation are the copyright of the
 * Broad Institute, Inc. All rights are reserved.
 *
 * This software is supplied without any warranty or guaranteed support
 * whatsoever. The Broad Institute is not responsible for its
 * use, misuse, or functionality.
 */

/**
 * Utilities for handling autocompletion of fields, which may be on different pages.
 */
import { directory } from '@/utils/portal';
import { RetrieveOnePage } from './pagination';

export default function AutoComplete() {
}

/**
 * Static method for autocompletion of genes.
 */
AutoComplete.geneAutoComplete = function(selector) {
    $(selector).autocomplete({
        source: function(request, response) {
            const theUrl = directory.getGeneSearchUrl().url + '?format=json&geneId=' + request.term;
            RetrieveOnePage(theUrl, 10, 0).then(
                data => {
                    response($.map(data, item => {
                        return {
                            label: item.geneSymbol,
                            value: item.geneSymbol
                        };
                    }));
                }
            ).catch(
                response => {
                    console.error('Error: autocomplete ' + theUrl + ' ' + response.status);
                    $('#spinner').hide();
                }
            );
        },
        minLength: 2,
        open: function() {
            $(this).removeClass('ui-corner-all').addClass('ui-corner-top');
        },
        close: function() {
            $(this).removeClass('ui-corner-top').addClass('ui-corner-all');
        }
    });
};
