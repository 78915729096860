<template>
  <div class="dgtex-about-us">
    <Banner/>
    <ProjectOverview/>
    <div class="blue-bg">
      <Goals/>
    </div>
    <Analysis/>
    <div class="gray-bg">
      <Timeline/>
    </div>
    <Funding/>
    <div class="gray-bg">
      <Organization/>
    </div>
  </div>
</template>

<script>
import Logo from './Logo.vue';
import Banner from './Banner.vue';
import ProjectOverview from './ProjectOverview.vue';
import Goals from './Goals.vue';
import Analysis from './Analysis.vue';
import Funding from './Funding.vue';
import Organization from './Organization.vue';
import Timeline from './Timeline.vue';

export default {
  components: {
    Timeline,
    Logo,
    Banner,
    ProjectOverview,
    Goals,
    Analysis,
    Funding,
    Organization,
  },
  data() {
    return {
      logoSvg: require('@/assets/images/dgtex/dGTExLogo_withtext.svg')
    }
  }
}
</script>

<style lang="css" scoped>
.dgtex-about-us {
  font-family: Ubuntu, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #4A5364;
}

.dgtex-about-us .blue-bg{
  background-color: #d3eaff;
}

.dgtex-about-us .gray-bg{
  background-color: #F4F4F4;
}


/* viewport div classes*/

</style>
