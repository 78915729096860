<template>
  <!-- the navigation bar-->
  <nav class="navbar-inverse inner-shadow">
    <!-- the responsive button of the collasped nav bar when the screen is too small -->
    <div class="navbar-header">
      <button type="button"
              class="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#gtex-navbar"
              aria-expanded="false"
              aria-controls="navbar"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar" />
        <span class="icon-bar" />
        <span class="icon-bar" />
      </button>
    </div>

    <!-- the nav bar-->
    <div id="gtex-navbar" class="navbar-collapse collapse " style="padding:0px; margin: 0px;">
      <!--top bar -->
      <div class="gtex-top-nav">
        <div class="gtex-nav-logos">
          <router-link to="/">
            <img id="gtexLogo" class="gtex-nav-logo" src="@/assets/images/gtex-portal-logo.png">
          </router-link>
          <div>
            <router-link v-for="(item, index) in navLogos" id="logoLink" :key="index" :to="item.path">
              <img :src="require(`@/assets/images/${item.img}`)">
            </router-link>
          </div>
        </div>
        <ul class="nav">
          <router-link v-for="(item, index) in topNavItems"
                       :key="index"
                       class="navItem"
                       :to="item.path"
                       style="color: black; float:right;"
                       custom v-slot="{ navigate, isActive }"
          ><li :class="{ active: isActive }" @click="navigate">
            <i :class="`fas fa-${item.icon}`" /> {{ item.label }}
          </li>
          </router-link>
          <br>
        </ul>
      </div>

      <!-- THE MAIN BAR -->
      <div class="gtex-main-bar">
        <ul id="gtexmenu" class="gtex-navbar-menu nav navbar-nav">
          <!--          if the list item has children, then add the dropdown class-->
          <li v-for="(item, index) in menuItems" :key="index"
              :class="item.children ? 'dropdown' : ''"
          >
            <router-link v-if="item.path" :to="item.path">
              {{ item.name }}
            </router-link>

            <!--            the toggle to expand the dropdown-->
            <a v-if="item.children"
               class="dropdown-toggle"
               data-toggle="dropdown"
               aria-haspopup="true"
               aria-expanded="false"
            >
              {{ item.name }} <b class="caret" />
            </a>

            <!--            the dropdown menu when the toggle is triggered-->
            <ul v-if="item.children" class="dropdown-menu">

              <li v-if="item.children[0].name === 'Search Gene/Transcript Expr.'" id="nav-submenu" class="dropdown multi-level">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                    Search Gene/Transcript Expr.
                  <i class="fas fa-caret-right" style="vertical-align: middle;" /></a>
                <ul class="dropdown-menu dropdown-submenu">
                  <li>
                    <div class="input-group input-group-sm" style="width:100%">
                      <input id="asdSearchTermGeneExpr" type="text" class="expr-search form-control" placeholder="Search Gene ID">
                    </div>
                  </li>
                </ul>
              </li>

              <router-link v-for="(child, i) in item.children"
                           :key="i"
                           :to= "child.path != undefined ? child.path :'/'"
                           custom v-slot = "{ navigate, isActive }"
              >
                <li :class = "{active: isActive}" @click="navigate" 
                      v-if = "child.name !== 'Search Gene/Transcript Expr.'">
                {{ child.name }}
                </li>
              </router-link>
            </ul>
          </li>
        </ul>

        <ul class="nav navbar-nav navbar-right">
          <!-- menu aligned on the right-->
          <li>
            <div class="site-search">
              <input id="asdSearchTermGeneral"
                     type="text"
                     placeholder="Search Gene or SNP ID..."
                     class="searchInput search-query outer-shadow ui-autocomplete-input"
                     autocomplete="off"
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="js">
import { portalClient } from '@/utils/portal';
export default {
    name: 'GtexNavigationBar',
    data() {
        return {
            navLogos: [
                {
                    path: '/aboutAdultGtex',
                    img: 'gtex-logo-no-text.png'
                },
                {
                    path: '/aboutdGTEx',
                    img: 'dGTEx-logo-no-text.png'
                },
                {
                    path: '/aboutNHPdGTEx',
                    img: 'nhp-dgtex-logo-no-text.png'
                }
            ],
            topNavItems: [
                {
                    path: '/contact',
                    icon: 'envelope',
                    label: 'Contact'
                },
                {
                    path: '/faq',
                    icon: 'question-circle',
                    label: 'FAQs'
                },
                {
                    path: '/samplesPage',
                    icon: 'archive',
                    label: 'Access Biospecimens'
                },
                {
                    path: '/publicationsPage',
                    icon: 'newspaper',
                    label: 'Publications'
                },
                {
                    path: '/documentationPage',
                    icon: 'info-circle',
                    label: 'About Adult GTEx'
                }
            ],
            menuItems: [
                {
                    name: 'Home',
                    path: '/'
                },
                {
                    name: 'Downloads',
                    children: [
                        {
                            name: 'Open Access Data',
                            path: '/downloads/adult-gtex'
                        },
                        {
                            name: 'Protected Access Data',
                            path: '/protectedDataAccess'
                        }
                    ]
                },
                {
                    name: 'Expression',
                    children: [
                        {
                            name: 'Search Gene/Transcript Expr.'
                        },
                        {
                            name: 'Transcript Browser',
                            path: '/transcriptPage'
                        },
                        {
                            name: 'Multi-Gene Query',
                            path: '/multiGeneQueryPage'
                        },
                        {
                            name: 'Expression PCA',
                            path: '/expressionPca'
                        }
                    ]
                },
                {
                    name: 'Single Cell',
                    children: [
                        {
                            name: 'Dataset Overview',
                            path: '/singleCellOverviewPage'
                        },
                        {
                            name: 'Multi-Gene Single Cell Query',
                            path: '/multiGeneSingleCellQueryPage'
                        }
                    ]
                },
                {
                    name: 'QTL',
                    children: [
                        {
                            name: 'Locus Browser (Gene-centric)',
                            path: '/locusBrowserPage/RSPRY1'
                        },
                        /*
                        {
                            name: 'Locus Browser (Variant-centric)',
                            path: '/locusBrowserVCPage'
                        },*/
                        {
                            name: 'eQTL Dashboard',
                            path: '/eqtlDashboardPage'
                        },
                        {
                            name: 'eQTL Calculator',
                            path: '/testyourown'
                        }
                    ]
                },
                {
                    name: 'IGV Browser',
                    path: '/browseEqtls?location=chr1%3A1-500,000'
                },
                {
                    name: 'Tissues & Histology',
                    children: [
                        {
                            name: 'Tissue Sampling Sites',
                            path: '/samplingSitePage'
                        },
                        {
                            name: 'Tissue Sites Mapped to HuBMAP CCF',
                            path: '/eui'
                        },
                        {
                            name: 'Tissue Dashboard',
                            path: '/tissue/Adipose_Subcutaneous'
                        },
                        {
                            name: 'Histology Viewer',
                            path: '/histologyPage'
                        }
                    ]
                },
                {
                    name: 'Documentation',
                    children: [
                        {
                            name: 'Current Release',
                            path: '/tissueSummaryPage'
                        },
                        {
                            name: 'Release History',
                            path: '/releaseInfoPage'
                        },
                        {
                            name: 'Lab and Analysis Methods',
                            path: '/methods'
                        },
                        {
                            name: 'How To Videos',
                            path: '/videos'
                        },
                        {
                            name: 'API for Data Access',
                            path: '/apiPage'
                        },
                        {
                            name: 'Privacy Policy',
                            path: '/privacy'
                        },
                        {
                            name: 'License',
                            path: '/license'
                        }
                    ]
                },
                {
                  name: 'About',
                  children: [
                    {
                      name: 'About Adult GTEx',
                      path: '/documentationPage'
                    },
                    {
                      name: 'About dGTEx',
                      path: '/aboutDGtex'
                    },
                    {
                      name: 'About NHP dGTEx',
                      path: '/aboutNhpDGtex'
                    }
                  ]
                }
            ]
        };
    },
    methods: {
    // TODO: check if this is still being used NH 6/20/2023
        signout() {
            portalClient.gLoginManager.signOut();
        }
    }
};
</script>

<style lang="css" scoped>
#gtexLogo {
  width: 250px;
}

.gtex-top-nav {
  padding: 1vh 1.25vw;
  width: 100%;
  background-color: white;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.gtex-nav-logos {
  display: inline-flex;
  flex-flow: row wrap;
}

.gtex-nav-logos img {
  height: 40px;
  margin-right: 1em;
}

.gtex-main-bar {
  width: 100%;
  padding: 0 1vw;
}

.gtex-main-bar ul {
  padding-bottom: 0.5em;
}
</style>

