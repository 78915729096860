<template>
  <div class="container-fluid">
    <h3>Protein Truncating Variants Page Has Been Removed</h3>
    <p>
      The Protein Truncating Variants page was based on data from GTEx Release V3.
      This data is out of date and we are no longer providing visualizations based on this data.
      The Protein Truncating Variants data can still be downloaded from <a href="/datasets">here</a>.
    </p>
  </div>
</template>
<script>
</script>
