/*
 * Copyright 2014 The Broad Institute, Inc.
 * SOFTWARE COPYRIGHT NOTICE
 * This software and its documentation are the copyright of the
 * Broad Institute, Inc. All rights are reserved.
 *
 * This software is supplied without any warranty or guaranteed support
 * whatsoever. The Broad Institute is not responsible for its
 * use, misuse, or functionality.
 */

/**
 * Generates tables for eQTLs and eGenes.
 *
 * @author jnedzel
 */
import { tissueMetadataJson, getTissueDisplayName } from '@/utils/tissue';
import { directory, generateSnpUrl, generateGeneUrl } from '@/utils/portal';
import TableUtils from '@/utils/table-utils';
// import downloadUtils from '@/utils/downloadUtil';
import MessageHandler from '@/utils/message-handler';
import {RetrieveAllPaginatedData} from "./pagination";
// import EqtlsPage from '@/components/EqtlsPage.vue';

export default function EqtlTableMaker() {
    this.emptyMessage = 'No data available in table';
    this.tissueName = null;
    this.selector = null;
    this.isGenePage = null;

    this.generateEqtlByGeneTable = function(violinDivId, geneId, tissueSiteDetailId,
        tissueSiteDetail, selector,
        isGenePage) {
    // TODO: check if this code works for other pages too. works for gene page so far. -Duyen 2019/03/06
        const urlSuffixGene =  ('ENSG' === geneId.substring(0, 4) ?
            'gencodeId=' : 'geneSymbol=') + geneId;
        const urlSuffixTissue = tissueSiteDetailId == 'All' ? undefined : `&tissueSiteDetailId=${tissueSiteDetailId}`;
        const urlSuffix = `?${urlSuffixGene}${urlSuffixTissue===undefined?'':urlSuffixTissue}`;
        const urlEntry = directory.getSingleTissueEqtlUrl();
        const url = urlEntry.url + urlSuffix + '&' + directory.getV10Dataset();
        this.emptyMessage = 'No significant eQTLs were found for gene ' + geneId + ' in tissue ' + tissueSiteDetail;
        this.selector = selector;
        this.isGenePage = isGenePage;

        const parent = this;
        //const revisedUrl = appendUserInfo(url);
        RetrieveAllPaginatedData(url).then(
            data => {
                parent.generateEqtlTablesHelper(data, violinDivId);
            }
        ).catch(
            response => {
                alert('Error: Cannot retrieve eQTL data ' + response.responseText);
            }
        )
    };

    this.generateEqtlBySnpTable = function(violinDivId, snpId, tissueSiteDetailId,
        tissueSiteDetail, variantId) {
        const tissues = 'All' === tissueSiteDetailId ?
            Object.keys(tissueMetadataJson).reduce((a, b) => {
                return a+'&tissueSiteDetailId='+b;
            }) : tissueSiteDetailId;
        let urlSuffix = variantId === undefined? '?snpId=' + snpId + '&tissueSiteDetailId=':'?variantId=' + variantId + '&tissueSiteDetailId=';
        urlSuffix += tissues;
        //TODO - This still needs to swtich over to Querying V10 like the funciton above it for the snp page
        const urlEntry = directory.getSingleTissueEqtlUrl();
        const url = urlEntry.url + urlSuffix + '&' + directory.getV10Dataset();
        
        this.emptyMessage = 'No significant eQTLs were found for SNP ' + snpId + ' in tissue ' + tissueSiteDetail;
        this.selector = null;
        this.isGenePage = null;

        const parent = this;
        RetrieveAllPaginatedData(url).then(
            data => {
                parent.generateEqtlTablesHelper(data, violinDivId);
            }
        ).catch(
            response => {
                alert('Error: Cannot retrieve eQTL table ' + response.responseText);
            }
        );
    };

    this.generateEqtlTablesHelper = function(data, violinDivId) {
        const eqtls = data;
        const nesInfo = '<div class="gtooltip fas fa-info-circle "' +
                        'data-toggle="tooltip" data-html="true" ' +
                        'data-placement="auto" data-container="body" ' +
                        'title="Normalized Effect Size"' + '>'+ '</div>';
        const objectHeadings = ['Gencode Id', 'Gene Symbol', 'Variant Id', 'SNP Id', 'SNP', 'P-Value', 'P-Value', ' NES', 'NES ' + nesInfo, 'Tissue', 'Actions'];

        let tableSelector = '#gridTable';
        if (this.selector != null) tableSelector = this.selector;

        const oTable = $(tableSelector);

        const tableElt = oTable[0];
        TableUtils.buildTableHtml(tableElt, objectHeadings);

        const buttonProps = {
            'buttons': [{
                extend: 'copy',
                exportOptions: { columns: [0, 1, 2, 3, 6, 8, 9] }
            },
            {
                extend: 'csv',
                exportOptions: { columns: [0, 1, 2, 3, 6, 8, 9] }
            }]
        };

        const tableMaker = this;
        const columnProps =
            [{ data: 'gencodeId' },
                {
                    defaultContent: '',
                    'render': function(data, type, row) {
                        return generateGeneUrl(row.gencodeId, row.geneSymbol);
                    }
                },
                {
                    defaultContent: '',
                    'render': function(data, type, row) {
                        const url = generateSnpUrl(row.variantId);
                        return url;
                    }
                },
                { data: 'snpId', visible: false, searchable: false }, // hidden
                {
                    defaultContent: '',
                    'render': function(data, type, row, meta) {
                        const url = generateSnpUrl(row.snpId);
                        return url;
                    }
                },
                { data: 'pValue', visible: false, searchable: false }, //Kat's comment: do we still need these invisible columns?!
                {
                    defaultContent: '',
                    'render': function(data, type, row, meta) {
                        return Number(row.pValue).toPrecision(2);
                    }
                },
                { data: 'nes', visible: false, searchable: false },
                {
                    defaultContent: '',
                    'render': function(data, type, row, meta) {
                        return Number(row.nes).toPrecision(2);
                    }
                },
                {
                    defaultContent: '',
                    'render': function(data, type, row, meta) {
                        if (tableMaker.isGenePage) {
                            return tableMaker.generateTissueUrlFromGenePage(row.gencodeId, row.tissueSiteDetailId);
                        } else {
                            return tableMaker.generateTissueUrl(row.tissueSiteDetailId);
                        }
                    }
                },
                {
                    defaultContent: '',
                    'render': function(data, type, row, meta) {
                        return generatePlotUrl(violinDivId + '-dialog', row.gencodeId, row.snpId, row.variantId, row.tissueSiteDetailId, tableMaker.isGenePage, row.geneSymbol);
                    }
                }];

        // this is to prevent DataTables from putting warnings or errors in an alert box.
        $.fn.dataTableExt.sErrMode = 'throw';
        if ($.fn.dataTable.isDataTable(oTable)) {
            const t = oTable.DataTable();
            t.destroy();
        }

        try {
            oTable.dataTable({
                deferRender: true,
                destroy: true,
                retrieve: true,
                jQueryUI: true,
                processing: true,
                paginationType: 'full_numbers',
                data: eqtls,
                columns: columnProps,
                dom: 'B<"clear">lfrtip',
                orderClasses: false,
                buttons: buttonProps,
                language: {
                    emptyTable: 'Please wait - Fetching records'
                },

                fnInitComplete: function(oSettings) {
                    oSettings.oLanguage.sEmptyTable = tableMaker.emptyMessage;
                    $(oTable).find('.dataTables_empty').html(tableMaker.emptyMessage);
                }
            });
        } catch (err) {
            const messageHandler = new MessageHandler();
            messageHandler.showError('Internal client error: ' + err + ' Unable to create table for eQTLs');
        }

        // this is required for row_selected styles to work.
        oTable.addClass('display');
        oTable.show(); // in case it was hidden
        oTable.fnSort([[5, 'asc']]); // sort by p-value
        // ensures only the gene page eQTL table is collapsed
        if (this.isGenePage) {
            makeCollapseIconTogglerHandler('geneEQTLSearchResultTableDivTitle', 'geneEQTLSearchResultTableDiv');
        }
        return oTable;
    };

    function generatePlotUrl(violinDivId, geneId, snpId, variantId, tissueSiteDetailId, isGenePage, geneSymbol) {
        const divIdString = '\'' + violinDivId + '\',';
        const geneIdString     = '\'' + geneId + '\',';
        const snpIdString      = '\'' + variantId  + '\',';
        const tissueSiteDetailIdString = '\'' + tissueSiteDetailId +'\',';
        const geneSymbolString = '\'' + geneSymbol + '\'';
        const eqtlPagePrefix = '<a href="javascript:gtex.plotEqtlViolinPlot(';
        const genePagePrefix = '<a href="javascript:gtex.plotEqtlViolinPlot(';

        const urlSuffix = divIdString + geneIdString + snpIdString + tissueSiteDetailIdString + geneSymbolString + ')">eQTL violin plot</a>';
        let url;

        if (isGenePage) {
            url = genePagePrefix + urlSuffix;
        } else {
            url = eqtlPagePrefix + urlSuffix;
        }
        if (snpId == null) {
            //TODO: add this IGV browser link back in after V10 full release
            //url += ', ' + generateBrowserUrl(variantId, tissueSiteDetailId);
        } else {
            //TODO: add this IGV browser link back in after V10 full release
            //url += ', ' + generateBrowserUrl(snpId, tissueSiteDetailId);
        }
        
        //This is the multi-tissue eQTL plot link in the Genes page table. It has been removed for V10 since 
        //We do not have metasoft data for V10
        //url += ', ' + generateForestPmPlotUrl(variantId, geneId, geneSymbol);
        return url;
    }

    this.generateTissueUrlFromGenePage = function(geneId, tissueName) {
        return `<a href="/home/tissue/${tissueName}">${getTissueDisplayName(tissueName)}</a>`;
    };

    // TODO: remove this?  I think this is only used in tables on the old eQTL page, which is no longer
    // in use.  JLN
    this.generateTissueUrl = function(tissueName) {
        const featureIdString = '\'' + tissueName + '\'';
        return '<a href="javascript:gtex.goTissuePage(' + featureIdString +
            ')">' + getTissueDisplayName(tissueName) + '</a>';
    };

    function generateBrowserUrl(featureId, tissueName) {
        return `<a href="/home/browseEqtls?location=${featureId}&tissueName=${tissueName}">IGV Browser</a>`;
    }
}

export function generateForestPmPlotUrl(snpId, geneId, geneSymbol) {
    const snpIdString      = '\'' + snpId  + '\', ';
    const geneIdString     = '\'' + geneId + '\', ';
    let geneSymbolString = undefined;
    if (undefined !== geneSymbol) {
        geneSymbolString = '\'' + geneSymbol + '\'';
    }

    return '<a href="javascript:gtex.getForestPmPlot('
        + snpIdString + geneIdString + geneSymbolString + ')">Multi-tissue eQTL Plot</a>';
}

export function makeCollapseIconTogglerHandler(parentDiv, targetDiv) {
    const $parentDiv = $('#' + parentDiv + ' .sectionTogglers .far');
    const $targetDiv = $('#' + targetDiv);
    $targetDiv.on('show.bs.collapse', () => {
        $parentDiv.removeClass('fa-plus-square')
            .addClass('fa-minus-square');
    })
        .on('hide.bs.collapse', () => {
            $parentDiv.removeClass('fa-minus-square')
                .addClass('fa-plus-square');
        });
}
