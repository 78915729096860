<template>
  <div class="container-fluid">
    <div class="col-sm-10">
      <h2>GTEx Project Privacy Policy</h2>
      <p>Last Updated: September 1, 2022</p>
      <p>
        The GTEx Project has created this Privacy Policy to demonstrate its commitment to privacy and transparency 
        of practices. This Privacy Policy explains the GTEx Project’s information-gathering and dissemination 
        practices for the GTEx Portal Website (the “Services”). 
        By accessing the Services, you consent to the practices and terms of this Privacy Policy. 
        Limited data is collected about your visit to any of our Services in order to help us better 
        understand public use of the sites and to improve and continue to make our Services more useful to visitors. 
        This page describes the information that is automatically collected and stored.
      </p>
      <h3>
        Information the GTEx Project Collects
      </h3>
      <p>
        “Personal Information” means any information that can be used to identify you, directly or indirectly, 
        as an individual person. The GTEx Project collects Personal Information when you visit any of the Services, 
        send GTEx Project an email, respond to a survey, provide online feedback, otherwise communicate with us. 
        The GTEx Project also collects information, such as anonymous usage statistics, by using cookies, server logs, 
        and other similar technology as you use the Services.
      </p>
      <h3>
        Information Provided by You
      </h3>
      <p>The GTEx Project collects Personal Information that you enter through the Services or give to the GTEx Project in another way. For example:
        When you access the Services, the GTEx Project may collect: </p>
      <ul>
       <li>contact information, such as your name, email address, mailing address, and telephone number</li>
      </ul>
      <p>When you fill out a survey, the GTEx Project collects, depending on the survey:</p>
      <ul>
        <li>satisfaction with an event or course, product usage, and product satisfaction</li>
        <li>other information in response to solicited questions, as you voluntarily contribute</li>
      </ul>
      <h3>
        Information the GTEx Project Obtains from Third Party Sources
      </h3>
      <p>The GTEx Project collects Personal Information about individuals from a number of third-party sources.</p>
      <ul>
        <li>
          Business Partners and Service Providers: Our business partners and service providers, 
          such as contacts database and website hosting providers, 
          collect Personal Information and share some or all of this information with us.
        </li>
      </ul>
      <h3>
        Information the GTEx Project Collects Automatically
      </h3>
      <h4>Log File Data</h4>
      <p>
        The Services collect domain names of site visitors, as well as aggregate information about site traffic and 
        server load. Additional processes store IP addresses, browser information, location, and device statistics of 
        visitors for auditing and security purposes. 
        This information is used strictly to maintain and improve the Services.
      </p>
      <h4>Cookies</h4>
      <p>
        The Services use “cookies” during site visits. The “cookie,” which is a small text file stored in temporary 
        memory, is used by our Web applications for usability purposes and to comply with subscription and settings 
        preferences that our visitors have given us. The Services also uses “cookies” on a limited basis to measure 
        user interactions with the Services through Google Analytics. You can turn off cookies in your browser 
        preferences at any time without affecting site performance, if you wish to do so.
      </p>
      <h3>
        Online Comment Forms
      </h3>
      <p>
        Please note that the GTEx Project will not intentionally share the contents of any email feedback or comment 
        form with any party. However, due to the nature of electronic communications, the GTEx Project cannot and does 
        not provide any assurances that the contents of your e-mail will not become known or accessible to third parties.
      </p>
      <p>
        You are urged not to provide any confidential information about you, your health, or other patients’ health via 
        electronic communication. If you do so, it is at your own risk.
      </p>
      <h3>
        How the GTEx Project Uses Your Information
      </h3>
      <p>
        The GTEx Project uses information, including Personal Information, for internal and service-related purposes. 
        The GTEx Project may use and retain any data that it collects to provide and improve any of the Services. 
        The GTEx Project may send email to the email address you provide to it for sending you email for informational 
        and operational purposes, such as account management, customer service, system maintenance, and other 
        Services-related purposes. The GTEx Project may use information, including Personal Information, to show or 
        send you information that it thinks may be useful or relevant to you. The GTEx Project may anonymize or 
        aggregate data collected through the Services and use and disclose it for any purpose.
      </p>
      <h3>
        How the GTEx Project Shares Your Information
      </h3>
      <p>
        Only members of the GTEx Project staff, its approved contractors, and its approved vendors have access to the 
        data collected by the Services. The GTEx Project may share information with its third-party vendors and service 
        providers, to comply with legal obligations, to protect and defend our rights and property, and with your 
        permission. The GTEx Project may share any information it receives with vendors and service providers it uses 
        to help provide and improve the Services. For example, the GTEx Project may disclose information to the service 
        providers that help host the Services or send its emails.
      </p>
      <p>
        The GTEx Project may allow access to data collected by the Services to send you information the GTEx Project 
        thinks may be useful or relevant to you.
      </p>
      <p>
        The GTEx Project may access, preserve, and disclose collected information, if it believes doing so is required 
        or appropriate to: comply with law enforcement requests and legal process, such as a court order or subpoena; 
        respond to your requests; comply with the law; or protect your, our, or others’ rights, property, or safety.
      </p>
      <p>
        If the GTEx Project is involved in a transition of service to another provider, your information may be 
        disclosed in connection with the negotiation of such transaction, and/or transferred as part of such a 
        transaction as permitted by law and/or contract. The GTEx Project cannot control how the recipient entities 
        may use or disclose such information.
      </p>
      <h3>Other Sites</h3>
      <p>
        Our privacy policies apply only to your use of the Services. The Services contain links and integrations to 
        other sites, including sites that may indicate relationships or collaborations with the GTEx Project. At times, 
        in support of technical integrations, you may be required to provide your email address and name to third 
        parties to fully use and/or access all Services. The GTEx Project is not responsible for the privacy practices 
        of such other sites. You should read and review the privacy policies of each site you visit to determine what 
        information that site may be collecting about you. Below are the privacy policies for the third-party websites 
        & services that the Services currently integrate with:
      </p>
      <ul>
        <li>
          Google Analytics: The Services use Google Analytics to monitor user interaction and use of the sites. 
          See Log File Data above for information on what is collected with Google Analytics. 
          You may visit Google’s privacy policy here: 
          <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy
          <i class="fas fa-external-link-alt"/></a>
        </li>
      </ul>
      <h3>Changes to Our Privacy Policy</h3>
      <p>
        Just as with the other terms and conditions associated with the Services, the GTEx Project may change the 
        terms and conditions of its Privacy Policy at any time by posting revisions on the Services. 
        You agree to review the Privacy Policy each time you use the Services so that you are aware of any 
        modifications made to these policies. By accessing or using the Services, you agree to be bound by all of the 
        terms and conditions of the Agreement as posted on the Services at the time of your access or use, including 
        the Privacy Policy then in effect.
      </p>
      <h2>Disclaimer of Liability</h2>
      <p>
        Last Updated: September 1, 2022
      </p>
      <p>
        For documents and/or data available from or provided by any Services, the GTEx Project does not warrant or 
        assume any legal liability or responsibility for the accuracy, completeness, or usefulness of any information, 
        apparatus, product, or process provided. Furthermore, no specific medical advice is intended or provided by 
        any of the Services, and where applicable users are urged to consult with a qualified physician for diagnosis 
        and answers to any personal questions.
      </p>
      <h3>
        Contact Information
      </h3>
      <p>
        If you have any questions about these privacy policies or the practices of this site, 
        please contact us at <a href="mailto:support@gtex.zendesk.com" target="_blank">
        support@gtex.zendesk.com<i class="fas fa-external-link-alt"/></a>
      </p>
    </div>
  </div>
</template>
