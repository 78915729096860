<template>
  <section class="nhp-dgtex-about-us">
    <div class="nhp-dgtex-image-container">
      <img src="../assets/images/nhp-dgtex-logo.svg"/>
    </div>

    <div class="page-content">
      <h3>Project Overview</h3>
      <p>
        The Non-Human Primate Developmental GTEx (NHP-dGTEx) project is a new effort to complement the
        <router-link to="/aboutdGTEx">Developmental GTEx (dGTEx) Project</router-link>
        by studying
        development-specific genetic effects on gene expression in non-human primates.
        NHP-dGTEx
        will catalog and analyze transcriptional profiles across a wide variety of tissues at multiple developmental
        stages
        from two species of non-human primates: Macaca mulatta (rhesus macaque), an old world monkey, and Callithrix
        jacchus (common marmoset), a new world monkey. Data and samples will be made available to the research
        community.
      </p>

      <div class="nhp-dgtex-image-container">
        <img src="@/assets/images/nhp-dgtex/dGTEx_timeline_121624.svg"/>
      </div>
    </div>

    <div class="red-bg">
      <div id="goals" class="page-content">
        <h3>Goals</h3>
        <p>
          The main goal of the project is to establish a molecular and data analysis resource as well as a tissue bank
          across developmental stages in two non-human primates. The datasets produced from the non-human primates will
          be complementary to those from the
          <router-link to="/aboutdGTEx">Developmental GTEx Project</router-link>
          .
          NHP-dGTEx will consist of tissues
          from 24 organs from 12 rhesus macaque and 12 common marmoset individuals at each of 6-8
          developmental stages, including prenatal developmental stages. This project will allow cross-species,
          temporal comparisons of data including WGS, RNA seq data, and cell-type specific gene and epigenetic
          data across different stages of primate development.
        </p>

        <div class="nhp-dgtex-image-container">
          <img src="@/assets/images/nhp-dgtex/dGTEx website_exp design poster.png"/>
        </div>
      </div>
    </div>

    <div class="gray-bg">
      <div class="page-content">
        <h3>Analyses</h3>
        <p>
          We will generate full-transcript genome sequencing from a panel of tissues of each species, to annotate gene
          models and form a robust basis for the analysis of RNA-seq. For each species we will generate bulk
          RNA-sequencing multiple tissues each from 12 individuals, sampled at 6 timepoints. We will also use single cell
          10X multiome snRNA+ATAC sequencing to generate a first generation single-cell atlas for each species, covering
          ~20 total tissues, with an emphasis on the brain and on matching selected tissues with the human dGTEx resource.
          These resources will be complemented with strategic epigenetic profiling by CHIP-seq and Hi-C for annotation
          of functional elements. Spatial transcriptomics will be used to enhance data from the smallest prenatal stages,
          and from a selection of additional tissues and timepoints.
        </p>
      </div>
    </div>

    <div class="page-content">
      <h3>Project Organization and Timeline</h3>
      <p>
        The project was launched in May 2022. Four institutes are involved in the study:
        <a href="https://www.ohsu.edu/" target="_blank">the Oregon Health & Science University (OHSU)</a>,
        <a href="https://broadinstitute.org" target="/_blank">the Broad Institute</a>,
        <a href="https://yale.edu" target="_blank">Yale University</a>, and
        <a href="https://mit.edu" target="_blank">Massachusetts Institute of Technology (MIT)</a>.
        Overall
        leadership is being provided by OHSU and tissues are being contributed to the study from the
        <a href="https://www.ohsu.edu/onprc" target="_blank"> Oregon National Primate Research Center</a> (Macaques)
        and MIT (Marmosets). All core molecular assays will be conducted at the Broad
        Institute (non-brain samples) and at Yale University (brain samples). These two institutes are also conducting
        the same matched assays for the Developmental GTEx Project’s non-brain and brain samples respectively.
      </p>

      <div class="nhp-dgtex-image-container">
        <img src="../assets/images/nhp-dgtex/dGTEx website_flow chart v2.png"/>
      </div>

      <p>
        In the initial phase of the project, OHSU, the Broad Institute, Yale University, and MIT have been working
        together with the
        <router-link to="/aboutdGTEx">Developmental GTEx Project</router-link>
        , to carefully map
        tissue and sample collections, translating from the common coordinate sampling framework (CCF) design set up
        in human project to both non-human primate species. All core molecular assays, and data analysis pipelines will
        be harmonized across both projects and laboratories. Data sharing for the NHP-dGTEx project will parallel that
        used for the
        <router-link to="/aboutAdultGTEx">Adult GTEx Project</router-link>
        , with data from both projects
        being made jointly available. Animal recruitment is ongoing for both Macaques and Marmosets. Tissue
        characterization, and data production are underway.
        being made jointly available. Animal recruitment is ongoing for both Macaques and Marmosets. Tissue
        characterization, and data production are underway.
      </p>
    </div>

    <div class="gray-bg">
      <div id="funding" class="page-content">
        <h3>Funding</h3>
        <p>This project is funded by the <a
            href="https://www.genome.gov/Funded-Programs-Projects/Non-Human-Primate-Developmental-Genotype-Tissue-Expression-NHP-dGTEx"
            target="_blank">
          National Human Genome Research Institute</a> <i class="fas fa-external-link-alt"/> of the National Institutes
          of
          Health, as described at the <a href="">NIH project website</a> <i class="fas fa-external-link-alt"/>.</p>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "nhp-dgtex-about-us",
}
</script>

<style lang="css" scoped>
.nhp-dgtex-about-us {
  text-align: left;
  color: #4A5364;
  width: 100%;
  margin-top: 3vh;
}

.page-content {
  padding: 3vh 0;
  margin: 0 6vw;
}

a:link, a:visited {
  text-decoration: underline;
  color: #4A5364;
}

a:hover, a:visited:hover {
  color: #006CCF;
}

.red-bg {
  color: black;
  background-color: #f2e6e7;
}

.gray-bg {
  background-color: #F4F4F4;
}

.nhp-dgtex-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.nhp-dgtex-about-us .fa-external-link-alt {
  margin-left: 0.5em;
}
</style>
