<template>
  <div>
    <header v-if="isNHP">
      Data generation for the Non-Human Primate Developmental project has just started. 
      The availability of this data will be announced here and on our Twitter feed 
                    <span>
                <i class="fab fa-twitter" />
                <a href="https://twitter.com/gtexportal" target="_blank">
                  @GTExPortal
                </a>
              </span>
      .
    </header>
    <header v-if="!isNHP">
            Data generation for the Developmental GTEx project has just started. 
      The availability of this data will be announced here and on our Twitter feed 
                    <span>
                <i class="fab fa-twitter" />
                <a href="https://twitter.com/gtexportal" target="_blank">
                  @GTExPortal
                </a>
              </span>
      .
    </header>
  </div>
</template>

<script>
export default {
    name: 'DataComingShortlyPage',
    data() {
        return {
            isNHP: this.$route.params.project==='nhp-dgtex',
        };
    },
    watch: {
      '$route.params.project'(newParams) {
          this.isNHP = newParams==='nhp-dgtex';
        },
    },
};
</script>

<style scoped>

</style>
