export default function gtexVersion() {
    this.vlist = {
        v3: 'GTEx Analysis Pilot V3 (dbGaP Accession phs000424.v3.p1)',
        v4: 'GTEx Analysis Release V4 (dbGaP Accession phs000424.v4.p1)',
        v6: 'GTEx Analysis Release V6 (dbGaP Accession phs000424.v6.p1)',
        v6p: 'GTEx Analysis Release V6p (dbGaP Accession phs000424.v6.p1)',
        v7: 'GTEx Analysis Release V7 (dbGaP Accession phs000424.v7.p2)',
        v8: 'GTEx Analysis Release V8 (dbGaP Accession phs000424.v8.p2)',
        v10: 'GTEx Analysis Release V10 (dbGaP Accession phs000424.v10.p2)'
    };

    this.datasets = {
        summary: 'v10',
        singleTissue: 'v10',
        testYourOwn: 'v8',
        expression: 'v8',
        genePageV10: 'v10',

        multiTissue: 'v3',
        multiTissueV4: 'v4',
        ptv: 'v3',
        imprint: 'v3'
    };

    this.V3 = this.v3;
    this.V4 = this.v4;
    this.V6 = this.v6;
    this.V6p = this.v6p;
    this.V7 = this.v7;
    this.V8 = this.v8;

    this.getVersionInfo = function(dataset) {
        if (this.datasets[dataset] !== undefined) {
            const v = this.datasets[dataset];
            return this.vlist[v];
        }
        throw 'Version info of ' + dataset + ' is not found';
        return undefined;
    };

    this.getMarkup = function(dataset) {
        const versionInfo = this.getVersionInfo(dataset);
        if (versionInfo !== undefined) {
            if (dataset == 'ptv' || dataset == 'imprint') return `<h5>Data Source: ${versionInfo}<br/><span style="color: rgb(178, 34, 34); font-weight: 500;">Please note -- data on this page comes from our pilot release.</span></h5>`;
            else return `Data Source: ${versionInfo}`;
        }
        return undefined;
    };
}
