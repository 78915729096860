<template>
  <section class="gtex-about-page">

    <div class="gtex-about-page-banner"/>

    <div class="page-content">
      <h1>Adult GTEx</h1>

      <br />

      <h3>Project Overview</h3>

      <p>
        The Adult Genotype Tissue Expression (GTEx) Project is a comprehensive public resource to study human gene
        expression and regulation, and its relationship to genetic variation across multiple diverse tissues and
        individuals.  GTEx donor recruitment and molecular data generation are complete for core molecular assays
        including WGS, WES, and RNA-seq.
      </p>
    </div>

    <div class="page-content">
      <h3>Goals</h3>

      <p>
        The project collected samples from up to 54 non-diseased tissue sites across nearly 1,000 deceased individuals.
        All individuals were densely genotyped to assess genetic variation within their genomes by Whole Genome
        Sequencing (WGS).  Gene expression of each tissue was assessed by RNA sequencing (bulk RNA-seq). Expression
        quantitative trait loci (eQTLs) were identified as genetic variants that were significantly correlated with
        changes in the expression of nearby genes. The project provides a comprehensive identification of tissue-shared
        and tissue-specific human eQTLs, as well as a valuable basis for the mechanistic interpretation of the many
        non-coding genetic variants that have been associated with common human diseases, such as heart disease,
        cancer, diabetes, asthma, and stroke.
      </p>

      <ul>
        <li>
          <p>
            The project's goals are described in: <a href="https://www.nature.com/articles/ng.2653"
                                                     target="_blank">The GTEx marker paper. <i class="fas fa-external-link-alt"/></a>
          </p>
        </li>
        <li>
          <p>
            Details about sample collections, exclusion/inclusion criteria, and pathology are described in: <a
              href="https://www.liebertpub.com/doi/full/10.1089/bio.2015.0032" target="_blank">Carithers
            et al. (2015) <i class="fas fa-external-link-alt"/></a>.
          </p>
        </li>
        <li>
          <p>
            Several studies associated with donor enrollment for the study are described in:
          </p>
          <p>
            <a href="https://pubmed.ncbi.nlm.nih.gov/33386465" target="_blank">
              Siminoff, et al. (2021) <i class="fas fa-external-link-alt"/></a>,
            <a href="https://pubmed.ncbi.nlm.nih.gov/29204889" target="_blank">
              Wilson-Genderson, et al. (2018) <i class="fas fa-external-link-alt"/></a>,
            <a href="https://pubmed.ncbi.nlm.nih.gov/29565794" target="_blank">
              Siminoff, et al. (2018) <i class="fas fa-external-link-alt"/></a>,
            <a href="https://pubmed.ncbi.nlm.nih.gov/25856669" target="_blank">
              Siminoff, et al. (2016) <i class="fas fa-external-link-alt"/></a>,
            <a href="https://pubmed.ncbi.nlm.nih.gov/25856669" target="_blank">
              Mosavel, et al. (2019) <i class="fas fa-external-link-alt"/></a>.
          </p>
        </li>
        <li>
          <p>
            The main Consortium analyses and associated detailed methods are described in a series of papers:
          </p>
          <ul>
            <li>
              <p>
                An initial pilot study: <a href="http://www.sciencemag.org/content/348/6235/648.short" target="_blank">
                  The GTEx Consortium (2015) <i class="fas fa-external-link-alt"/></a>
              </p>
            </li>
            <li>
              <p>
                A set of analyses performed at the mid-point of the study:
                <a href="http://dx.doi.org/10.1038/nature24277" target="_blank">The GTEx Consortium (2017)
                  <i class="fas fa-external-link-alt"/></a>
              </p>
            </li>
            <li>
              <p>
                A final set of analyses performed on the near complete data:
                <a href="https://doi.org/10.1126/science.aaz1776" target="_blank">The GTEx Consortium (2020)
                  <i class="fas fa-external-link-alt"/></a>
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            Additional data generation efforts that were funded to enhance the core GTEx data are described in:
            <a href="http://dx.doi.org/10.1038/ng.3969" target="_blank">eGTEx Project (2017) <i
              class="fas fa-external-link-alt"/></a>.
          </p>
        </li>
        <li>
          <p>
            A collaborative study between ENCODE and GTEx is described in:
            <a href="https://pubmed.ncbi.nlm.nih.gov/37001506/" target="_blank">Rozowksy, et al. (2023)
              <i class="fas fa-external-link-alt"/></a>
          </p>
        </li>
        <li>
          <p>
            A complete list of all consortia published papers can be found
            <router-link to="/publicationsPage">here</router-link>.
          </p>
        </li>
      </ul>
    </div>

    <div class="page-content">
      <h3> Analyses and Data </h3>

      <p>
        Data generation, QC, and analysis methods are described in the Supplementary Material for
        <a href="https://doi.org/10.1126/science.aaz1776" target="_blank">The GTEx Consortium (2020)
                  <i class="fas fa-external-link-alt"/></a>
        and also  <router-link to="/methods">here</router-link>.
      </p>

      <p>
        Much of the GTEx data and metadata are protected access (e.g., sequence data and complete donor metadata,
        etc.).  Access to these data requires users to complete a data access request in
        <a href="https://www.ncbi.nlm.nih.gov/projects/gap/cgi-bin/study.cgi?study_id=phs000424" target="_blank">
          dbGaP <i class="fas fa-external-link-alt"/></a>
        and then access the data in
        <a href="https://anvil.terra.bio/" target="_blank">
          AnVIL <i class="fas fa-external-link-alt"/></a>.
      </p>

      <p>
        Open access GTEx data including expression counts and eQTLs  are available for visual exploration and download
        on <router-link to="/">the GTEx Portal</router-link>.
      </p>

      <p>
        Protocols used for tissue sampling are available here:
        <a href="https://biospecimens.cancer.gov/resources/sops/docs/GTEx_SOPs/BBRB-PR-0004-W1%20GTEx%20Tissue%20Harvesting%20Work%20Instruction.pdf" 
           target="_blank"> GTEx Tissue Harvesting Work Instruction <i class="fas fa-external-link-alt" /></a>.
        Histology images and pathology QC notes are available <router-link to="/histologyPage">here</router-link>.
      </p>

      <p>
        A subset of tissues from 16 donors were also characterized using single-cell RNA sequencing, as described in:
        <a href="https://www.science.org/doi/10.1126/science.abl4290" target="_blank">Eraslen, et al. (2022)
          <i class="fas fa-external-link-alt"/></a>.
      </p>
    </div>

    <div class="page-content">
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th><a href="https://www.science.org/doi/10.1126/science.aaz1776" target="_blank"> V10 Release</a></th>
          <th># Tissues</th>
          <th># Donors</th>
          <th># Samples</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(s, i) in sampleData" :key="i">
          <td>{{ s.release }}</td>
          <td>{{ s.numTissues }}</td>
          <td>{{ s.numDonors }}</td>
          <td>{{ s.numSamples }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="page-content">
      <h3>Biobank</h3>

      <p>
        All remaining collected tissue samples and derived nucleic acids are in a
        <router-link to="/biobank">biobank</router-link>
        and are available to be requested for future studies.
      </p>
    </div>

    <div class="page-content">
      <h3>Funding</h3>

      <p>
        The Genotype-Tissue Expression (GTEx) Project was supported by the <a
          href="http://commonfund.nih.gov/GTEx/index" target="_blank">Common Fund <i
          class="fas fa-external-link-alt"/></a> of the
        <a href="https://www.nih.gov/institutes-nih/nih-office-director" target="_blank">Office of the Director of the National Institutes of Health
          <i class="fas fa-external-link-alt"/></a>,
          and by
        <a href="https://www.cancer.gov/" target="_blank">the National Cancer Institute
          <i class="fas fa-external-link-alt"/></a>,
        <a href="https://www.genome.gov/" target="_blank">the National Human Genome Research Institute
          <i class="fas fa-external-link-alt"/></a>,
        <a href="https://www.nhlbi.nih.gov/" target="_blank">the National Heart, Lung, and Blood Institute
          <i class="fas fa-external-link-alt"/> </a>,
        <a href="https://www.drugabuse.gov/" target="_blank">the National Institute on Drug Abuse
          <i class="fas fa-external-link-alt"/> </a>,
        <a href="https://www.nimh.nih.gov/" target="_blank">the National Institute of Mental Health
          <i class="fas fa-external-link-alt"/> </a>,
        <a href="https://www.ninds.nih.gov/" target="_blank">the National Institute of Neurological Disorders and
          Stroke <i class="fas fa-external-link-alt"/> </a>.
      </p>
    </div>

    <div class="page-content">
      <h3>Citing GTEx</h3>

      <p>Please cite usage of GTEx data as follows:</p>

      <p>
        The data used for the analyses described in this manuscript [or presentation] were obtained from:
        [insert, where appropriate] the
        <router-link to="/">GTEx Portal</router-link>
        on MM/DD/YY and/or <a href="http://www.ncbi.nlm.nih.gov/gap" target="_blank">dbGaP</a> accession number
        <a href="http://www.ncbi.nlm.nih.gov/projects/gap/cgi-bin/study.cgi?study_id=phs000424.v2.p1"
           target="_blank">phs000424.vN.pN <i class="fas fa-external-link-alt"/></a> on
        MM/DD/YYYY.
      </p>
    </div>

    <br v-for="i in 3" :key="i" />
  </section>
</template>

<script>

export default {
  name: 'gtex-about-page',
  data() {
    return {
      sampleData: [
        {
          release: "RNA-seq Total",
          numTissues: 54,
          numDonors: 946,
          numSamples: 19788
        },
        {
          release: "RNA-seq with matched donor Genotype",
          numTissues: 54,
          numDonors: 946,
          numSamples: 19788
        },
        {
          release: "Has eQTL Analysis*",
          numTissues: 50,
          numDonors: 943,
          numSamples: 19466
        }
      ],
    }
  }
};
</script>

<style lang="css" scoped>
.gtex-about-page {
  width: 100%;
}

.gtex-about-page-banner {
  width: 100%;
  height: var(--jumbotron-size);
  background: center / cover no-repeat url("../assets/images/gtexhome.png");
}

.gtex-about-page p {
  font-size: 1.025rem;
  line-height: 1.5rem;
}

.gtex-about-page li > p {
  padding: unset;
}

.gtex-about-page h4 {
  font-weight: bold;
}
</style>
