<template>
  <div id="analysis" class="page-content center">
    <h3>Analysis</h3>
    <p>The study will genotype each individual and characterize all tissues via RNA sequencing. A subset of tissues will
      also be characterized using state-of-the-art single-cell RNA, open chromatin, and spatial transcriptomic assays.
    </p>
    <p>These data will be used to characterize development-specific genetic effects on gene expression from the data
      generated across all donors and tissues, and through integrative analysis of quantitative trait loci (QTL) from
      existing GTEx data.
    </p>
    <h2>Molecular Profiling</h2>
    <div class="grid">
      <div class="cell">
        <img :src="wgsSvg"/>
      </div>
      <div class="cell">
        <p>A blood sample from each donor will be genotyped by <b>whole genome sequencing.</b></p>
      </div>
      <div class="cell">
        <img :src="bulkRnaSeqSvg"/>
      </div>
      <div class="cell">
        <p>Every sampled tissue per donor will be sequenced with <b>bulk RNA-seq.</b></p>
      </div>
      <div class="cell">
        <img :src="singleCellSvg"/>
      </div>
      <div class="cell">
        <p>A subset of samples from both the brain and non-brain tissues will undergo deeper <b>single-cell and spatial
          multiomic profiling.</b></p>
      </div>
    </div>
    <h2>Histology</h2>
    <div class="grid">
      <div class="cell">
        <img :src="histSvg"/>
      </div>
      <div class="cell">
        <p>Every tissue sample will have a pathology review performed on <b>histological images</b>.
        </p>
      </div>
    </div>
    <h2>Biobank</h2>
    <div class="grid">
      <div class="cell">
        <img :src="biobankSvg"/>
      </div>
      <div class="cell">
        <p>All collected tissue samples and derived nucleic acids will be deposited in a biobank to be made available
          for future studies.
        </p>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      wgsSvg: require('@/assets/images/dgtex/WGS.svg'),
      bulkRnaSeqSvg: require('@/assets/images/dgtex/bulk-RNA-seq.svg'),
      singleCellSvg: require('@/assets/images/dgtex/single-cell.svg'),
      histSvg: require('@/assets/images/dgtex/histological.svg'),
      biobankSvg: require('@/assets/images/dgtex/biobank.svg')
    }
  }
}
</script>

<style lang="css" scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 7fr;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  align-items: center;
}

.cell {
  justify-content: center;
}

img {
  align-self: center;
}

#sample-table {
  overflow-x: scroll;
}

table {
  width: 100%;
  border-spacing: 5px;
}

tr {
  height: 80px;
  font-size: 16px;
}

thead > tr {
  background-color: #ffffff;
}

tbody > tr {
  font-weight: bold;
  background-color: #F4FAFF;
}

th, td {
  vertical-align: center;
  align-content: center;
  text-align: center;
}

td {
  font-weight: normal;
}

tbody > tr > th:first-of-type {
  text-align: center;
  width: 30%;
  padding-right: 20px;
  padding-left: 20px;
}

tbody > tr:nth-of-type(even) {
  background-color: #edf6ff;
}

</style>
