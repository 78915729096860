<template>
  <div class="container-fluid">
    <h2>Dataset Summary of Analysis Samples</h2>
    <span id="summary-version" />
    <br>
    <div>
      <div
        class="col-lg-2"
        role="complementary"
      >
        <nav class="gtx-docs-sidebar hidden-print hidden-xs hidden-sm hidden-md affix">
          <ul
            id="sidebar"
            class="nav"
          >
            <li>
              <a href="#analysisSummary">V10 analysis summary</a>
            </li>
            <li>
              <a href="#sampleInfo">V10 Sample Info</a>
              <ul class="nav">
                <li><a href="#sampleCountsPerTissue">Sample Counts by Tissues</a></li>
                <li><a href="#esgeneCountsPerTissue">e/sGenes vs Sample Size</a></li>
              </ul>
            </li>
            <li>
              <a href="#donorInfo">V10 Donor Info</a>
              <ul class="nav">
                <li><a href="#sex">Sex &amp; Age</a></li>
                <li><a href="#cause">Cause of Death</a></li>
                <li><a href="#tissuesPerDonor">Tissue Counts Per Donor</a></li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>

      <div class="col-xs-12 col-lg-10">
        <hr>
        <section id="analysisSummary">
          <h4>V10 analysis summary</h4>
          <ul>
            <li>Gencode version: <a href="https://www.gencodegenes.org/human/release_39.html" target="_blank">v39 <i class="fas fa-external-link-alt" /></a></li>
            <li>RNA-seq alignment: STAR v2.7.10a</li>
            <li>Transcript quantification: RSEM v1.3.3</li>
            <li>Genotype used for eQTL analysis: WGS</li>
          </ul>
        </section>
        <hr>
        <section id="sampleInfo">
          <h4>V10 Sample Info</h4>
          <div class="row">
            <div class="col-xs-12 col-md-4">
              <!-- Data summary table -->
              <!-- hard-coded... -->
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>V10 Release</th>
                    <th># Tissues</th>
                    <th># Donors</th>
                    <th># Samples</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total</td>
                    <td>54</td>
                    <td>946</td>
                    <td>19788</td>
                  </tr>
                  <tr>
                    <td>With Genotype</td>
                    <td>54</td>
                    <td>946</td>
                    <td>19788</td>
                  </tr>
                  <tr>
                    <td>Has eQTL Analysis*</td>
                    <td>50</td>
                    <td>943</td>
                    <td>19466</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="4">
                      * Number of samples with genotype >= 70
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="col-xs-12 col-md-4 col-md-offset-1">
              <h5>Sample RIN Histogram</h5>
              <div id="sampleRinChart" />
            </div>
          </div>
          <hr>
          <section id="sampleCountsPerTissue">
            <div class="row">
              <h5>V10 Sample Counts by Tissues</h5>
              <div class="TDS-view-options">
                <label class="TDS-view-title">View as: </label>
                <span
                  id="tdsPlotTableSwitch1Plot"
                  href="#option1"
                  class="TDS-view-option TDS-view-option-selected "
                >Plot</span>
                <span
                  id="tdsPlotTableSwitch1Table"
                  href="#option2"
                  class="TDS-view-option "
                >Table</span>
                <br><br><br>
              </div>


              <div id="tdsNumSamplesByTissuesBarChartWrapper">
                Sort tissues by:
                <select id="tdsTissueSortSelection">
                  <option value="type">
                    Tissue alphabetical
                  </option>
                  <option value="count">
                    Count
                  </option>
                </select>
                <div id="downloadTemp">
                  <!-- this is in use for downloading plots, do not delete it-->
                </div>
                <div class="row">
                  <div
                    id="tdsNumSamplesByTissuesDownload"
                    class="navLink col-xs-12 col-md-2"
                  >
                    <i class="fas fa-download" /> Download
                  </div>
                  <div
                    id="tdsNumSamplesByTissuesBarChart"
                    class="col-xs-12 col-md-10"
                  />
                </div>
              </div>
              <div
                id="tdsNumSamplesByTissuesTable"
                style="display:block; max-height: 450px;"
              />
              <div style="color: firebrick;">
                Note: <span style="font-weight:500;">Cells - Transformed fibroblasts</span> from previous releases has been corrected to <span style="font-weight:500;">Cells - Cultured fibroblasts</span>.
              </div>
            </div>
          </section>
          <hr>
          <div class="row">
            <section id="esgeneCountsPerTissue">
              <h5>
                V10 e/sGenes vs Sample Size
                <span class="fas fa-info-circle gtooltip" style="font-size:smaller" data-toggle="tooltip" data-placement="auto right"
                      title="This searches our precalculated eQTLs and sQTLs
                                        (FDR <= 0.05) for tissues having more than
                                        70 samples, using a +/-1 Mb cis-window around
                                        the transcription start site (TSS).
                                        More details can be found on the
                                        Documentation page (Analysis Methods)."
                />
              </h5>
              <div>e/sGenes were calculated for tissues with >= 70 samples with genotype data.</div>
              <div class="row">
                <div id="tdsNumSamplesByESGenesDownload" class="navLink col-xs-12 col-md-2">
                  <i class="fas fa-download" /> Download
                </div>

                <div class="col-xs-12 col-md-5">
                  <div>
                    <div id="tdsNumSamplesByESGenesChart" />
                    <div id="tdsNumSamplesByESGenesTable" style="display:block; max-height: 400px;" /><!-- is this in use?-->
                  </div>
                </div>
                <div class="col-xs-12 col-md-5">
                  <div id="tdsNumSamplesByNormESGenesChart" />
                </div>
              </div>
            </section>
          </div>
          <hr>
        </section>
        <section id="donorInfo">
          <div class="row">
            <h4>V10 Donor Info</h4>
            <div class="col-xs-12">
              <div class="row">
                <section id="sex">
                  <div id="donorInfoDownload" class="navLink col-xs-12 col-md-2">
                    <i class="fas fa-download" /> Download
                  </div>
                  <div id="donorSexChart" class="col-xs-12 col-md-5" />
                  <div id="donorAgeChart" class="col-xs-12 col-md-10 col-md-offset-2" />
                </section>
              </div>
              <hr>
              <div class="row">
                <section id="cause">
                  <div id="causeOfDeathDownload" class="navLink col-xs-12 col-md-2">
                    <i class="fas fa-download" /> Download
                  </div>
                  <div id="donorCauseChart" class="col-md-5" />

                  <div class="col-md-5">
                    <table class="col-md-5 table table-striped">
                      <thead>
                        <tr>
                          <th>Cause of Death</th>
                          <th>Age 20 - 39</th>
                          <th>Age 60 - 71</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Traumatic injury</td>
                          <td>57.4%</td>
                          <td>5.6%</td>
                        </tr>
                        <tr>
                          <td>Cerebrovascular</td>
                          <td>19.1%</td>
                          <td>23.2%</td>
                        </tr>
                        <tr>
                          <td>Heart disease</td>
                          <td>15.6%</td>
                          <td>49.2%</td>
                        </tr>
                        <tr>
                          <td>Liver, renal, respiratory</td>
                          <td>5.0%</td>
                          <td>19.8%</td>
                        </tr>
                        <tr>
                          <td>Neurological</td>
                          <td>2.8% </td>
                          <td>2.2% </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
              <section id="tissuesPerDonor">
                <h5>Number of Tissues Per Donor</h5>
                <div class="row">
                  <div id="numOfTissuePerDonorDownload" class="navLink col-xs-12 col-md-2">
                    <i class="fas fa-download" /> Download
                  </div>
                  <div id="donorNumTissueChart" class="col-xs-12 col-md-8" />
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { portalClient } from '@/utils/portal';
import TissueDonutChart from '@/utils/tissueDonutChart';
import summaryPlots from '@/utils/summaryPlots';
export default {
    mounted: function() {
        $('#summary-version').html(portalClient.versionInfo.getMarkup('summary'));
        const sortAlphaBet = function(a, b) {
            if (b.name < a.name) return 1;
            if (b.name > a.name) return -1;
            return 0;
        };
        (new TissueDonutChart()).setupTissueBarCharts(sortAlphaBet);
        $('#tdsTissueSortSelection').change(e => {
            const sortOn = $('#tdsTissueSortSelection option:selected');
            if (sortOn.attr('value') == 'type') {
                // use natural order
                (new TissueDonutChart()).setupTissueBarCharts(sortAlphaBet);
            } else if (sortOn.attr('value') == 'count') {
                // sort by count
                (new TissueDonutChart()).setupTissueBarCharts((a, b) => { return b.rnaSeqSampleCount -a.rnaSeqSampleCount; });
            }
        });
        // additional data summary plots
        const plotAgent = new summaryPlots();
        plotAgent.bindDownloadButtons();
        plotAgent.plotDonorSexChart();
        plotAgent.plotDonorAgeChart();
        plotAgent.plotDonorCauseChart();
        plotAgent.plotDonorNumTissueChart();
        plotAgent.plotSampleRinChart();
    }
};
</script>
