<template>
  <div>
    <ccf-eui
      v-pre
      header="false"
      theme="gtex"
      use-remote-api="false"
      hubmap-data-url=""
      login-disabled="true"
      logo-tooltip="" home-url="https://hubmapconsortium.github.io/ccf-gtex-pilot/"
    />
  </div>
</template>

<script>
export default {
    name: '',
    created() {
        // Create all HTML <head> injections
        const base = document.createElement('base');
        base.setAttribute(
            'href',
            'https://cdn.jsdelivr.net/gh/hubmapconsortium/ccf-ui@gh-pages/'
        );
        const favicon = document.createElement('link');
        favicon.setAttribute('rel', 'icon');
        favicon.setAttribute('type', 'image/x-icon');
        favicon.setAttribute('href', 'favicon.ico');
        const euiScript = document.createElement('script');
        euiScript.setAttribute('src', 'https://cdn.humanatlas.io/ui/ccf-eui/wc.js');
        euiScript.setAttribute('defer', 'defer');
        document.head.appendChild(euiScript);
        document.head.appendChild(base);
    },
    mounted() {
        const eui = document.querySelector('ccf-eui');
        eui.dataSources = [
            'https://ccf-api.hubmapconsortium.org/v1/gtex/rui_locations.jsonld',
            'https://ccf-api.hubmapconsortium.org/v1/hubmap/rui_locations.jsonld'
        ];
    }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import url("https://cdn.humanatlas.io/ui/ccf-eui/styles.css");

body {
  margin: 0;
}
</style>
