<template>
  <div class="container-fluid">
    <!-- QTL violin plot modal root DIVs -->
    <div :id="eqtlViolinDivId" />
    <div :id="sqtlViolinDivId" />
    <div :id="iEqtlScatterDivId" />
    <div class="row">
      <div
        class="col-lg-1"
        role="complementary"
      >
        <!--<div class="col-md-1 gtx-docs-sidebar" id="docpage-nav-menu" style="display:none;">-->
        <nav class="gtx-docs-sidebar hidden-print hidden-xs hidden-sm hidden-md affix">
          <br><br>
          <ul id="sidebar" class="nav">
            <!--this is where the sidebar menu goes-->
          </ul>
        </nav>
      </div>
      <div class="col-md-11">
        <div id="geneSearchResultsNavWrapper">
          <h2>
            Gene Page<img src="@/assets/images/GTEx-gene-icon.svg" width="40px" style="margin: 0px 0px 0px 10px;">
          </h2>

          <div id="geneSearchResultTableDiv">
            <table id="geneSearchResultTable" />
          </div>
          <br>
          <section id="geneExpression">
            <div id="geneExpressionPlotTitleDiv" />

            <!--<span id="expressVersion"></span>-->
            <div id="expressionHelp">
              Data processing and normalization
              <span class="fas fa-info-circle gtooltip"
                    data-toggle="tooltip"
                    data-placement="auto right"
                    title="Expression values are shown in TPM (Transcripts Per Million), calculated from a gene model with
                            isoforms collapsed to a single gene (see Datasets
                            -> Download -> Reference). No other normalization
                            steps have been applied. Box plots are shown as
                            median and 25th and 75th percentiles; points are
                            displayed as outliers if they are above or below
                            1.5 times the interquartile range."
              />
            </div><br>

            <div id="gene-expr-vplot" />
            <!-- tissue filter modal -->
            <div id="gene-expr-vplot-filter-modal"
                 class="modal fade"
                 tabindex="-1"
                 role="dialog"
                 aria-labelledby="Gene Expr Violin Plot Tissue Filter Modal"
                 aria-hidden="true"
                 data-backdrop="static"
            >
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">
                      Tissue Filter
                    </h5>
                    <button id="gene-expr-vplot-filter-modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true"><i class="far fa-times-circle" /></span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div id="gene-expr-vplot-filter-modal-body" class="row" />
                  </div>
                  <div class="modal-footer">
                    <button id="gene-expr-vplot-filter-modal-button" type="button" class="btn btm-sm btn-default" data-dismiss="modal" style="font-size:11px;">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="singleCell">
            <div id="singleCellExpressionPlotTitleDiv" />
            <div id="single-cell-expr-vplot" />
          </section>
          <!-- spliceViz -->
          <section id="gene-transcript-browser-block">
            <div id="gene-transcript-browser-title" />
            <div id="gene-transcript-browser-help" style="display:none;">
              Help
              <span class="fas fa-info-circle gtooltip"
                    data-toggle="tooltip"
                    data-html="true"
                    data-placement="auto right"
                    title="

  <p>Exon-level expression is calculated based on a collapsed gene model. To learn more about the collapsing transcript model, go to Documentation: Analysis Methods. In the exon view, the heatmap summarizes the median read counts per base of each exon of the collapsed gene model across all tissues. The tissues (rows) are hierarchically clustered, using the Euclidean distance and average linkage. The dendrogram scale shows the cluster distance. The exons (columns) are ordered based on genomic location. Below the heatmap, the collapsed gene model is illustrated: exons are represented as rectangles, and introns are scaled up to 1 kb long. Additionally, the junctions between joined exons of all splice variants are mapped out on the model as arcs. At times, an exon on the gene model may show dashed-line borders, which indicates that the exon or part of it has been filtered in the final collapsed model. The individual isoform structures are illustrated below the gene model. By clicking a tissue label (a row label) on the heatmap, the gene model and isoform tracks will change colors scaled by tissue-specific expression; a lollipop plot will appear next to the isoform tracks summarizing the isoform-level expression in the tissue.</p>

  <p>In the junction view, the heatmap summarizes the median raw read counts of junctions from individual isoforms. The tissues (rows) are hierarchically clustered based on the junction expression data, and the junctions (columns) are sorted by genomic location.</p>

  <p>In the isoform expression view, the median expression of each isoform in each tissue is summarized using a heatmap. The tissues (rows) and isoforms (columns) are ordered based on hierarchical clustering. </p>


  <p>All expression quantifications are described in the Documentation: Analysis Methods -> Expression Quantification section of the portal. </p>
                            "
              />
            </div>
            <div id="gene-transcript-browser">
              <div id="messageBox" style="color: #CB181D;" />
              <!-- charts -->
              <div id="isoformNav" style="display:none">
                <!-- Nav tabs -->

                <ul class="nav nav-tabs" role="tablist">
                  <li id="defaultTab" class="nav-item">
                    <a id="exon" class="nav-link" role="tab" data-toggle="tab">Exon Expression</a>
                  </li>
                  <li class="nav-item">
                    <a id="junction" class="nav-link" role="tab" data-toggle="tab">Junction Expression</a>
                  </li>
                  <li class="nav-item">
                    <a id="isoform" class="nav-link" role="tab" data-toggle="tab">Isoform Expression</a>
                  </li>
                </ul>

                <!-- Tab panes -->
                <div
                  class="tab-content"
                  style="margin-top:20px;"
                >
                  <div id="gene-transcript-browser-svg-div" /> <!-- the heatmap/gene model Div -->
                </div>
              </div>
            </div>
          </section>

          <!-- Single Tissue eQTL-->
          <section id="eQTLBlock">
            <div id="downloadTempDiv">
              <!-- do not erase this empty div, this div is in use by
                          the download SVG function for cloning a copy of SVG, editing this copy by adding the missing CSS styles
                          before saving to file
                      -->
            </div>
            <!-- ForestPMPlot documentation modal -->
            <div id="fpmplot-doc-modal" class="modal fade" aria-hidden="true" role="dialog">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">x</span>
                    </button>
                    <h4 class="modal-title">
                      Multi-tissue eQTL Comparison Info
                    </h4>
                  </div>
                  <div class="modal-body">
                    <div id="fpmInfo" class="fpm-info">
                      <div>
                        <br><b> Normalized Effect Size (NES)</b>:The
                        slope of the linear regression of
                        normalized expression data versus the
                        three genotype categories using
                        single-tissue eQTL analysis,
                        representing eQTL effect size. The
                        normalized expression values are based
                        on quantile normalization within each
                        tissue, followed by inverse quantile
                        normalization for each gene across
                        samples.
                      </div>
                      <div>
                        <br><b> p-value</b>:From a t-test that
                        compares observed beta from
                        single-tissue eQTL analysis to a null
                        beta of 0.
                      </div>
                      <div>
                        <br><b> m-value</b>:The posterior
                        probability that an eQTL effect exists
                        in each tissue tested in the
                        cross-tissue meta-analysis. The m-value
                        ranges between 0 and 1 (Han and Eskin,
                        PLoS Genetics 8(3): e1002555, 2012).
                      </div>
                      <div>
                        <br><b> m-value interpretation</b>:<br>Small
                        m-value (e.g. &lt;0.1): The tissue is
                        predicted to NOT have an eQTL effect.
                        <br> Large m-value (e.g. >0.9): The
                        tissue is predicted to HAVE an eQTL
                        effect. <br> Otherwise: The prediction
                        of the existence of an eQTL effect is
                        ambiguous.
                      </div>
                      <br><br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="geneEQTLSearchResultTableDivTitle" />
            <div id="geneEQTLSearchResultTableDiv" />
          </section>

          <!-- Single Tissue sQTL-->
          <section id="sQTLBlock">
            <div id="geneSQTLSearchResultTableDivTitle" />
            <div id="geneSQTLSearchResultTableDiv" />
          </section>

          <!-- Single Tissue ieQTL -->
          <section id="ieQTLBlock" style="font-size: 12px">
            <div id="geneIEQTLSearchResultTableDivTitle" />
            <div id="geneIEQTLSearchResultTableDiv" />
          </section>

          <!-- Single Tissue isQTL -->
          <section id="isQTLBlock" style="font-size: 12px">
            <div id="geneISQTLSearchResultTableDivTitle" />
            <div id="geneISQTLSearchResultTableDiv" />
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { directory, portalClient } from '@/utils/portal';
import TableUtils from '@/utils/table-utils';
import EqtlsPage from '@/components/EqtlsPage';
import EqtlTableMaker, { makeCollapseIconTogglerHandler } from '@/utils/eqtl-table-maker';
import TranscriptPage from '@/components/TranscriptPage';
import SpliceQtlPage from '@/components/SpliceQtlPage';
import QtlTableMaker from '@/mixins/QtlTableMaker';
import downloadUtils from '@/utils/downloadUtil';
import MessageHandler from '@/utils/message-handler';
import router from '@/router/router';
import getServerUrl from '@/utils/getServerUrl.js';
import { RetrieveAllPaginatedData } from '../utils/pagination';

export default {
    mixins: [QtlTableMaker],
    data: function() {
        const host = getServerUrl ? getServerUrl() : '//local.gtexportal.org/rest/'; // TODO: consider including the API version v1 in the root URL
        const version = 'v1/';
        return {
            eqtlViolinDivId: 'eqtl-violin-modal',
            sqtlViolinDivId: 'sqtl-violin-modal',
            iEqtlScatterDivId: 'ieqtl-scatter-modal',
            host: host + version
        };
    },
    beforeRouteUpdate(to, from, next) {
        if (to.params.geneId != from.params.geneId) this.goGeneSearchAction(to.params);
        next();
    },
    mounted: function() {
        this.bindDownloadExpressionButton();
        this.goGeneSearchAction(this.$route.params);
        EqtlsPage.methods.createDialogForQtlViolinPlots(this.eqtlViolinDivId, 'eQTL Violin Plots');
        EqtlsPage.methods.createDialogForQtlViolinPlots(this.sqtlViolinDivId, 'sQTL Violin Plots');
        EqtlsPage.methods.createDialogForQtlViolinPlots(this.iEqtlScatterDivId, 'ieQTL Scatterplots');
    },
    methods: {
        bindDownloadExpressionButton() {
            $('#expressionDownloadButton').click(() => {
                // get the two SVGs in div class='gtex-outer-div' and grab the one that's visible
                const $svgList = $($('.gtex-outer-div svg'));
                const $svgInView = $($svgList[0]).css('display') == 'none' ? $($svgList[1]) : $($svgList[0]);
                downloadUtils.downloadSVGByObject($svgInView, 'expressionDownload.svg', 'downloadTempDiv');
            });
        },

        goGeneSearchAction(argsHash) {
            const geneId = argsHash.geneId;
            $('#asdSearchTermGeneExpr').val(argsHash.geneId);
            $('#asdSearchTermGeneral').val(argsHash.geneId);
            this.clearSearchTable();
            this.clearPlots();
            this.generateSearchResultTable(geneId); // the gene table at the top of the gene page
            this.tableClickHandler();
        },
        generateSearchResultTable(geneId) {
            const entityName = 'gene';
            const objectHeadings = ['Gene Symbol', 'Gencode ID',
                'Entrez Gene', 'Entrez Gene ID', 'Location', 'Gene Description', 'Chromosome',
                'Start', 'End', 'Strand', 'Actions'];
            const theUrl = directory.getGeneSearchUrl().url + '?geneId=' + geneId + '&' + directory.getV10GencodeGenomeVersion();

            const emptyMessage = 'No genes found with ID: ' + geneId;
            const oTable = $('#geneSearchResultTable');
            const tableElt = oTable[0];

            TableUtils.buildTableHtml(tableElt, objectHeadings);

            const buttonProps = {
                'buttons': [{
                    extend: 'copy',
                    exportOptions: { columns: [0, 1, 3, 4, 5] }
                },
                {
                    extend: 'csv',
                    exportOptions: { columns: [1, 2, 3, 4, 5] }
                }]
            };

            const parent = this;
            const columnProps = [
                //{data: "id", visible: false, searchable: false},
                { data: 'geneSymbol' },

                { data: 'gencodeId' },
                { data: 'entrezGeneId', visible: false, searchable: false },
                {
                    defaultContent: '',  // entrezGene link
                    'render': function(data, type, row, meta) {
                        return parent.generateEntrezGeneUrl(row.entrezGeneId);
                    }
                },

                {
                    defaultContent: '', // genomic location
                    'render': function(data, type, row, meta) {
                        return parent.generateLocation(row.chromosome, row.start,
                            row.end, row.strand);
                    }
                },

                { data: 'description' },
                { data: 'chromosome', visible: false, searchable: false },
                { data: 'start', visible: false, searchable: false },
                { data: 'end', visible: false, searchable: false },
                { data: 'strand', visible: false, searchable: false },
                {
                    defaultContent: '',
                    'render': function(data, type, row) {
                        // const geneId = '\'' + row.geneSymbol + '\'';
                      //TODO: add the IGV browser link back in after the full V10 release
                      //`<a href="/home/browseEqtls?location=${row.geneSymbol}">IGV Browser</a>, ` +
                        return parent.generateEnsemblUrl(row.geneSymbol) + ', '
                            + parent.generateUcscUrl(row.chromosome, row.start, row.end);
                    }
                }
            ];

            const geneToShow = undefined;
            let rowSelected = false;
            try {
                const parent = this;
                RetrieveAllPaginatedData(theUrl).then(
                    retrieved_Data => {
                        oTable.dataTable({
                            deferRender: true,
                            data: retrieved_Data,
                            destroy: true,
                            retrieve: true,
                            jQueryUI: true,
                            processing: true,
                            paginationType: 'full_numbers',
                            columns: columnProps,
                            dom: 'B<"clear">lfrtip',
                            orderClasses: false,
                            buttons: buttonProps,
                            language: {
                                emptyTable: 'Please wait - Fetching records'
                            },
                            sorting: [[0, 'asc']], // sort by gene symbol

                            fnInitComplete: function(oSettings) {
                                oSettings.oLanguage.sEmptyTable = emptyMessage;
                                $(oTable).find('.dataTables_empty').html(emptyMessage);
                                function showGene(geneId, geneToShow, data, rowSelected) {
                                    // figure out which gene to show
                                    data.sort((a, b) => {
                                        // sort the array by gene symbol, so that the order of the genes is consistent with the data table
                                        if (a.geneSymbol < b.geneSymbol) return -1;
                                        if (a.geneSymbol > b.geneSymbol) return 1;
                                        return 0;
                                    });

                                    const matchingGenes = data.filter(x => geneId == x.geneSymbolUpper);
                                    geneToShow = matchingGenes.length ? matchingGenes[0] : data[0];
                                    if (geneToShow === undefined) {
                                        const messageHandler = new MessageHandler();
                                        messageHandler.showError(`No gene with ID "${geneId}" found.`, 'Gene ID Not Found');
                                    } else {
                                        parent.showGeneDetails(geneToShow.geneSymbol, geneToShow.gencodeId); // why not just provide the gene object?
                                        $('#docpage-nav-menu').show();
                                        if (!rowSelected) {
                                            // highlight the first row if there was no geneSymbol matching row highlighted already
                                            $('#geneSearchResultTable tr:first-child').addClass('row_selected');
                                        }
                                    }
                                }
                                showGene(geneId, geneToShow, retrieved_Data, rowSelected);
                            },
                            fnCreatedRow: function(row, data, index) {
                                if (data.geneSymbolUpper == geneId && !rowSelected) {
                                    rowSelected = true;
                                    $(row).addClass('row_selected');
                                }
                            }


                        });
                    }
                ).catch();
            } catch (err) {
                const messageHandler = new MessageHandler();
                messageHandler.showError('Internal client error: ' + err + ' Unable to create table for entity: '
                    + entityName);
            }

            // this is required for row_selected styles to work.
            oTable.addClass('display');
            oTable.show(); // in case it was hidden
            return oTable;
        },

        generateEntrezGeneUrl(entrezGeneId) {
            let url = '';
            if (entrezGeneId) {
                url = `<a href="http://www.ncbi.nlm.nih.gov/gene/${entrezGeneId}" target="_blank">${entrezGeneId} <i class="fas fa-external-link-alt"></i></a>`;
            }
            return url;
        },
        generateLocation(chromosome, start, end, strand) {
            return `${chromosome}:${start}-${end}:${strand}`;
        },
        generateEnsemblUrl(geneSymbol) {
            const url = `<a href="http://www.ensembl.org/Homo_sapiens/Gene/ExpressionAtlas?g=${geneSymbol}" target="_blank">Ensembl <i class="fas fa-external-link-alt"></i></a>`;
            return url;
        },
        generateUcscUrl(chromosome, start, end) {
            const url = `<a href="http://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&gtexGene=pack&position=${chromosome}:${start}-${end}" target="_blank">UCSC <i class="fas fa-external-link-alt"></i></a>`;
            return url;
        },
        tableClickHandler() {
            const parent = this;
            /*
             * Allows for single selection in the Datatable.
             * define the row clicking event
             */
            $('#geneSearchResultTable tbody').click(event => {
                // checking if a link was clicked on - do not repopulate the gene page if so
                if (event.target.tagName == 'A') return;
                $('#geneSearchResultTable').children('tbody').children('tr').removeClass('row_selected');
                const $tr = $(event.target).closest('tr');
                $tr.addClass('row_selected');
                const geneSymbol = $tr.children('td:eq(0)').text();
                const gencodeId = $tr.children('td:eq(1)').text();
                $('#docpage-nav-menu').show(); // show the nave menu after the first click
                parent.showGeneDetails(geneSymbol, gencodeId);
            });
        },
        getV8gencodeId(geneName) {
          // Some of the visualizations on this page doesn't work with the V10 gencodeId
          // So separate query for V8 gencodeId
          const url = directory.getGeneUrl().url + '?geneId=' + geneName + '&' + 'gencodeVersion=v26';
          return RetrieveAllPaginatedData(url).then(
              retrieved_data => {
                return retrieved_data[0].gencodeId;
              }
          );
        },
        showGeneDetails(geneName, gencodeId) {
            this.clearPlots();
            this.clearEqtlTable();
            this.createSideBar();
            this.showExpressionPlotForGene(geneName, gencodeId);
            //this.showSingleCellExpressionPlotForGene(geneName, gencodeId);
            this.showTranscriptVizForGene(geneName, gencodeId);
            this.showEqtlsForGene(geneName, gencodeId);
            this.showSqtlsForGene(geneName, gencodeId);
            //this.showIEqtlsForGene(geneName, gencodeId);
            //this.showISqtlsForGene(geneName, gencodeId);
            this.getV8gencodeId(geneName).then(oldGencodeId => {
                this.showSingleCellExpressionPlotForGene(geneName, oldGencodeId);
                this.showIEqtlsForGene(geneName, oldGencodeId);
                this.showISqtlsForGene(geneName, oldGencodeId);
            });
        },
        createSideBar() {
            $('#sidebar').empty();
            const menu = {
                '#gtexmenu': { label: 'Top' },
                '#geneExpression': { label: 'Bulk Tissue Expression' },
                '#singleCell': { label: 'Single Cell Expression' },
                '#gene-transcript-browser-block': { label: 'Exon Expression' },
                '#eQTLBlock': { label: 'Single-Tissue eQTLs' },
                '#sQTLBlock': { label: 'Single-Tissue sQTLs' },
                '#ieQTLBlock': { label: 'Single-Tissue ieQTLs' },
                '#isQTLBlock': { label: 'Single-Tissue isQTLs' }
            };

            Object.keys(menu).forEach(k => {
                const link = $('<a>', { href: k }).text(menu[k].label);
                $(link).click(() => {
                    const $elements = $(k + ' .sectionTogglers'); // this selector pulls out all elements that have the class sectionTogglers
                    $elements.each(function() {
                        const $this = $(this);
                        if ($this.hasClass('collapsed')) {
                            $this.click();
                        }
                    });
                });

                $('#sidebar').append($('<li>', {}).append(link));
            });
        },
        showExpressionPlotForGene(geneName, gencodeId) {
            if (!geneName) { return; }
            // TODO: refactoring. write a helper function to generate section heading
            const title = 'Bulk tissue gene expression for ' + geneName + ' (' + gencodeId + ')';
            const titleDivId = 'geneExpressionPlotTitleDiv';
            const plotDivId = 'gene-expr-vplot';
            const version = portalClient.versionInfo.getMarkup('genePageV10');
            this.makeSectionHeader(titleDivId, plotDivId, 'fa-minus-square', title, version);

            const urls = {
                tissue: directory.getTissueInfoUrl().url + '?' + directory.getV10Dataset(),
                geneExp: `${directory.getGeneExpressionUrl().url}?` + directory.getV10Dataset() + "&" + 'gencodeId='
            };

            const margins = {
                top: 50,
                right: 120,
                bottom: 160,
                left: 70
            };

            const dimensions = {
                h: 370,
                w: (window.innerWidth * 0.85) - margins.right - margins.left
            };

            GeneExpressionViolinPlot.launchBulkTissueViolinPlot(plotDivId, `${plotDivId}-tooltip`, gencodeId, title, urls, margins, dimensions);

            // expand the section on initial load
            $(`#${plotDivId}`).addClass('collapse in');
            this.makeCollapseIconTogglerHandler(titleDivId, plotDivId);
        },
        showSingleCellExpressionPlotForGene(geneName, gencodeId) {
            if (!geneName) { return; }
            const title = 'Single tissue expression for ' + geneName + ' (' + gencodeId + ')';
            const titleDivId = 'singleCellExpressionPlotTitleDiv';
            const plotDivId = 'single-cell-expr-vplot';
            const version = '<h5>Data Source: Single cell snRNA-seq pilot</h5>';
            this.makeSectionHeader(titleDivId, plotDivId, 'fa-minus-square', title, version);
            const margins= { top: 180, right: 50, bottom: 20, left: 80 };

            const dimensions = {
                height: 150,
                width: (window.innerWidth * 0.85) - margins.right - margins.left
            };
            const urls = {
                singleCellExpression: directory.getSingleCellExpressionUrl().url,
                tissue: directory.getTissueInfoUrl().url + '?' + directory.getV10Dataset(),
            };
            GeneExpressionViolinPlot.launchSingleCellViolinPlot(plotDivId, `${plotDivId}-tooltip`, gencodeId, dimensions, margins, urls); // group by tissue, nonzero
        },
        showTranscriptVizForGene(geneName, gencodeId) {
            if (!geneName) { return; }

            const titleDivId = 'gene-transcript-browser-title';
            const title = 'Exon expression for ' + geneName + ' (' + gencodeId + ')';

            const plotDivId = 'gene-transcript-browser';
            const version = portalClient.versionInfo.getMarkup('genePageV10');
            this.makeSectionHeader(titleDivId, plotDivId, 'fa-plus-square', title, version);
            
            $('#gene-transcript-browser').addClass('collapse'); // by default this div is collapsed
            $('#spliceVizHelp').show();
            const tbDiv = 'gene-transcript-browser-svg-div';
            $('#' + tbDiv).html('');
            TranscriptPage.methods.embed('exon', tbDiv, gencodeId);
            this.makeCollapseIconTogglerHandler(titleDivId, plotDivId);
        },
        showEqtlsForGene(geneName, gencodeId) {
            const eqtlTableMaker = new EqtlTableMaker();
            const title = 'Significant Single-Tissue eQTLs for ' + geneName + ' (' + gencodeId + ') in all tissues';
            const titleDivId = 'geneEQTLSearchResultTableDivTitle';
            const $geneEQTLSearchResultTableDiv = $('#geneEQTLSearchResultTableDiv');
            const version = portalClient.versionInfo.getMarkup('genePageV10');
            const $titleDiv = this.makeSectionHeader(titleDivId, 'geneEQTLSearchResultTableDiv', 'fa-plus-square', title, version);

            //TODO: this link is temporarily removed from the Gene page for a V10 release. Put it back later. 
            //const $locusBrowserLink = $('<span>', { class: 'navLink' }).text(`Views QTLs of ${geneName} in the Locus Browser (Gene-centric)`)
                //.on('click', () => { router.push({ name: 'locusBrowserPage', params: { geneId: gencodeId } }); });
            //$titleDiv.append($locusBrowserLink);
          
            // clear the old snp table
            $geneEQTLSearchResultTableDiv.addClass('collapse');
            $geneEQTLSearchResultTableDiv.html(''); // erase everything
            $geneEQTLSearchResultTableDiv.append('<div id="eqtlResultDiv"></div>');
            $geneEQTLSearchResultTableDiv.append('<div id="forestPmDiv" class="col-xs-12"></div>');
            $geneEQTLSearchResultTableDiv.append('<table id="geneEQTLSearchResultTable"></table>');
            if (geneName != null) {
                eqtlTableMaker.generateEqtlByGeneTable(this.eqtlViolinDivId, gencodeId, 'All', 'All', '#geneEQTLSearchResultTable', 'yes');
            }
        },
        showSqtlsForGene(geneName, gencodeId) {
            const component = this;
            const eqtlTableMaker = new EqtlTableMaker();
            const title = 'Significant Single-Tissue sQTLs for ' + geneName + ' (' + gencodeId + ') in all tissues';
            const titleDivId = 'geneSQTLSearchResultTableDivTitle';
            const tableDivId = 'geneSQTLSearchResultTableDiv';
            const $geneSQTLSearchResultTableDiv = $(`#${tableDivId}`);
            const version = portalClient.versionInfo.getMarkup('genePageV10');
            const $titleDiv = this.makeSectionHeader(titleDivId, tableDivId, 'fa-plus-square', title, version);
            
            //TODO: this link is temporarily removed from the Gene page for a V10 release. Put it back later.
            //const $locusBrowserLink = $('<span>', { class: 'navLink' }).text(`Views QTLs of ${geneName} in the Locus Browser (Gene-centric)`)
                //.on('click', () => { router.push({ name: 'locusBrowserPage', params: { geneId: gencodeId } }); });
            //$titleDiv.append($locusBrowserLink);
          
            // clear the old snp table
            $geneSQTLSearchResultTableDiv.addClass('collapse');
            $geneSQTLSearchResultTableDiv.html('<br/>'); // erase everything
            // $geneSQTLSearchResultTableDiv.append('<br/>');
            $geneSQTLSearchResultTableDiv.append('<div id="sqtlResultDiv"></div>');
            $geneSQTLSearchResultTableDiv.append('<table id="geneSQTLSearchResultTable"></table>');
            if (geneName != null) {
                const component = this;
                const url = `${directory.getSingleTissueSqtlUrl().url}?` + directory.getV10Dataset() + '&' + `gencodeId=${gencodeId}`;
                RetrieveAllPaginatedData(url).then(
                    data => {
                        component.generateSqtlTable(data, '#geneSQTLSearchResultTable', component.sqtlViolinDivId);
                    }
                ).catch(
                    response => {
                        const messageHandler = new MessageHandler();
                        messageHandler.showError('Error: Cannot retrieve sQTL table ' + response);
                    }
                );
            }
        },
        showIEqtlsForGene(geneName, gencodeId) {
            const title = 'Significant Single-Tissue ieQTLs for ' + geneName + ' (' + gencodeId + ') in all tissues';
            const titleDivId = 'geneIEQTLSearchResultTableDivTitle';
            const $geneIEQTLSearchResultTableDiv = $('#geneIEQTLSearchResultTableDiv');
            const version = portalClient.versionInfo.getMarkup('singleTissue');
            const $titleDiv = this.makeSectionHeader(titleDivId, 'geneIEQTLSearchResultTableDiv', 'fa-plus-square', title, version);

            $geneIEQTLSearchResultTableDiv.addClass('collapse');
            $geneIEQTLSearchResultTableDiv.html(''); // erase everything
            $geneIEQTLSearchResultTableDiv.append('<div id="eqtlResultDiv"></div>');
            $geneIEQTLSearchResultTableDiv.append('<div id="forestPmDiv" class="col-xs-12"></div>');
            $geneIEQTLSearchResultTableDiv.append('<table id="geneIEQTLSearchResultTable"></table>');
            if (geneName != null) {
                const component = this;
                const url = `${directory.getSingleTissueIEqtlUrl().url}?gencodeId=${gencodeId}`;
                RetrieveAllPaginatedData(url).then(
                    data => {
                        component.generateIEqtlTable(data, '#geneIEQTLSearchResultTable', component.iEqtlScatterDivId);
                    }
                ).catch(
                    response => {
                        const messageHandler = new MessageHandler();
                        messageHandler.showError('Error: Cannot retrieve ieQTL table ' + response);
                    }
                );
            }
        },
        showISqtlsForGene(geneName, gencodeId) {
            const component = this;
            const title = 'Significant Single-Tissue isQTLs for ' + geneName + ' (' + gencodeId + ') in all tissues';
            const titleDivId = 'geneISQTLSearchResultTableDivTitle';
            const $geneISQTLSearchResultTableDiv = $('#geneISQTLSearchResultTableDiv');
            const version = portalClient.versionInfo.getMarkup('singleTissue');
            const $titleDiv = this.makeSectionHeader(titleDivId, 'geneISQTLSearchResultTableDiv', 'fa-plus-square', title, version);

            $geneISQTLSearchResultTableDiv.addClass('collapse');
            $geneISQTLSearchResultTableDiv.html(''); // erase everything
            $geneISQTLSearchResultTableDiv.append('<div id="eqtlResultDiv"></div>');
            $geneISQTLSearchResultTableDiv.append('<table id="geneISQTLSearchResultTable"></table>');

            if (geneName != null) {
                const component = this;
                const url = `${directory.getSingleTissueISqtlUrl().url}?gencodeId=${gencodeId}`;
                RetrieveAllPaginatedData(url).then(
                    data => {
                        component.generateIEqtlTable(data, '#geneIEQTLSearchResultTable', component.iEqtlScatterDivId);

                        component.generateISqtlTable(data, '#geneISQTLSearchResultTable', component.iEqtlScatterDivId);
                    }
                ).catch(
                    response => {
                        const messageHandler = new MessageHandler();
                        messageHandler.showError('Error: Cannot retrieve isQTL table ' + response.responseText);
                    }
                );
            }
        },
        makeSectionHeader(headerDivId, targetDivId, initialIcon, title, version) {
            const attr = {
                'data-target': '#' + targetDivId,
                'data-toggle': 'collapse',
                'aria-expanded': 'false',
                'aria-controls': targetDivId
            };
            const $headerLink = $('<a>', attr);
            $headerLink.addClass('sectionTogglers');
            if (initialIcon == 'fa-plus-square') {
                $headerLink.addClass('collapsed');

                // make sure the target div to be collapsed;
                const $targetDiv = $('#' + targetDivId);
                $targetDiv.removeClass('in');
            }
            $headerLink.append($('<span>', { 'class': 'pull-left far ' + initialIcon }));
            $headerLink.append($('<h4>').text(title));

            const $headerDiv = $('#' + headerDivId);
            $headerDiv.html(''); // always make sure it is empty initially
            $headerDiv.append('<hr>');
            $headerDiv.append($headerLink);
            $headerDiv.append($('<div>').html(version));

            $headerDiv.show();
            return $headerDiv;
        },

        makeCollapseIconTogglerHandler(parentDiv, targetDiv) {
            const $parentDiv = $('#' + parentDiv + ' .sectionTogglers .far');
            const $targetDiv = $('#' + targetDiv);
            $targetDiv.on('show.bs.collapse', () => {
                $parentDiv.removeClass('fa-plus-square')
                    .addClass('fa-minus-square');
            })
                .on('hide.bs.collapse', () => {
                    $parentDiv.removeClass('fa-minus-square')
                        .addClass('fa-plus-square');
                });
        },

        clearSearchTable() {
            $('#geneSearchResultTableDiv').html('<table id="geneSearchResultTable"></table>');
        },
        clearPlots() {
            /*
             * expand the gene expression box plot if it wasn't already
             * necessary so visualization doesn't load on top of other divs
             */
            if (!$('#gene-expr-vplot').hasClass('in')) {
                $('#geneExpressionPlotTitleDiv a').click();
            }

            $('#geneExpressionPlotTitleDiv').html('');
            $('#gene-expr-vplot').html('');
            $('#single-cell-expr-vplot').html('');

            $('#gene-transcript-browser-title').html('');
            // ensures that the transcript browser isn't collapsed
            $('#gene-transcript-browser').removeAttr('class aria-expanded');
            $('#gene-transcript-browser-svg-div').html('');
            $('#gene-transcript-browser-help').hide();
        },
        clearEqtlTable() {
            $('#geneEQTLSearchResultTableDivTitle').html('');
            $('#geneEQTLSearchResultTableDiv').html('');
            $('#eqtlResultDiv').html('');
            $('#forestPmDiv').html('');
        }
    }
};
</script>
